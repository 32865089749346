(function(){
    'use strict';

    var env = {};
    if (window) {
        Object.assign(env, window.__env);
    }

    var baseModule = angular.module('iservice.base', [
        'ngStorage',
        'ngResource',
        'ngCookies',
        'ngAria',
        'ngCacheBuster',
        'ngFileUpload',
        'ui.bootstrap',
        'angularMoment',
        'ui.router',
        'infinite-scroll',
        'ngTouch',
        // jhipster-needle-angularjs-add-module JHipster will add new module here
        'angular-loading-bar',
        'toastr',
        'ui.bootstrap',
        'treeControl',
        'scDatePicker',
        'scModal',
        'exTable',
        'scInput',
        'scTable',
        'ngSelect',
        'ngMenu',
        'ngBtnGroup',
        'ngCron',
        'ngTable'
    ]);

    baseModule.constant('iServiceConfig', env);

    baseModule.run(run);
    run.$inject = ['stateHandler', '$rootScope'];

    function run(stateHandler, $rootScope) {
        stateHandler.initialize();
    }
})();

(function (ng) {
    'use strict';

    ng.module('iservice.base')
        .constant('APPID', 'iservice');
})(angular);
(function() {
    'use strict';

    var alertError = {
        controller: alertErrorController
    };

    angular
        .module('iservice.base')
        .component('alertError', alertError);

    alertErrorController.$inject = ['$scope', 'toastr', '$rootScope', 'iServiceConfig'];

    function alertErrorController ($scope, toastr, $rootScope, iServiceConfig) {
        var vm = this;

        vm.alerts = [];

        function addErrorAlert (message, key, data) {
            toastr.error(message, '错误');
        }

        var cleanHttpErrorListener = $rootScope.$on(iServiceConfig.appid + '.httpError', function (event, httpResponse) {
            event.stopPropagation();
            switch (httpResponse.status) {
            // connection refused, server not reachable
            case 0:
                addErrorAlert('Server not reachable','error.server.not.reachable');
                break;

            case 400:
                addErrorAlert('Invalid request','error.client.invalidate.request');
                break;

            case 404:
                addErrorAlert('Not found','error.url.not.found');
                break;

            default:
                if (httpResponse.data && httpResponse.data.errorMsg) {
                    addErrorAlert(httpResponse.data.errorMsg);
                } else {
                    addErrorAlert(angular.toJson(httpResponse));
                }
            }
        });

        $scope.$on('$destroy', function () {
            if(angular.isDefined(cleanHttpErrorListener) && cleanHttpErrorListener !== null){
                cleanHttpErrorListener();
                vm.alerts = [];
            }
        });
    }
})();

(function (ng) {
    'use strict';

    ng.module('filesModel', [])
        .directive('filesModel', filesModel);

    filesModel.$inject = ['$parse'];

    function filesModel($parse) {
        return {
            link: linkFn
        };

        function linkFn($scope, $element, $attrs){
            var exp = $parse($attrs.filesModel);

            $element.on('change', function(){
                exp.assign($scope, this.files);
                $scope.$apply();
            });
        }
    }
})(angular);
(function(angular, factory) {
    'use strict';
    if (typeof define === 'function' && define.amd) {
        define(['angular'], function(angular) {
            return factory(angular);
        });
    } else {
        return factory(angular);
    }
}(window.angular || null, function(angular) {
    'use strict';

    var app = angular.module("ngSelect", []);
    app.factory('NgSelect', ['$q', '$log', function($q, $log) {
        var ScSelect = function(baseSetting){
            var settings = {
                id:(new Date()).getTime()/1000,
                allList:[],
                list:[],//已选中的id列表

                unShowList: [],//未选中的展示列表
                unselectedList: [],//未选中的所有对象集合列表
                showList: [],//选中的展示列表
                selectedList:[],//选中的所有对象集合列表

                listMap:{},
                config:{
                    meta:{
                        label:'title',
                        value:'value',
                        id:'roleId'
                    }
                },
                getSelected:angular.noop,
                getSelectedId:angular.noop,
                upDataSelects:upDataSelects
            };
            //配置变量名
            if(angular.isDefined(baseSetting) && (baseSetting!={})){
                settings.id = baseSetting.id?baseSetting.id:settings.id;
                settings.allList = baseSetting.allList?baseSetting.allList:settings.allList;
                settings.list = baseSetting.list?baseSetting.list:settings.list;
                settings.unShowList = baseSetting.unShowList?baseSetting.unShowList:settings.unShowList;
                settings.showList = baseSetting.showList?baseSetting.showList:settings.showList;
                settings.unselectedList = baseSetting.unselectedList?baseSetting.unselectedList:settings.unselectedList;

                if(angular.isDefined(baseSetting.config) && (baseSetting.config!={})){
                    if(angular.isDefined(baseSetting.config.meta) && (baseSetting.config.meta!={})){
                        settings.config.meta.label = baseSetting.config.meta.label?baseSetting.config.meta.label:settings.config.meta.label;
                        settings.config.meta.value = baseSetting.config.meta.value?baseSetting.config.meta.value:settings.config.meta.value;
                        settings.config.meta.id = baseSetting.config.meta.id?baseSetting.config.meta.id:settings.config.meta.id;
                    }
                }

            }

            function upDataSelects(data){
                settings.selectedList = [];
                settings.showList = [];
                settings.unselectedList = [];
                settings.unShowList = [];

                if(angular.isDefined(data) && data.length>0){
                    //循环列表做map
                    if(angular.isDefined(settings.list) && settings.list.length>0){

                        //循环选中的列表做map 然后循环所有列表  将未选中的push到unShowList以作展示
                        for(var i=0;i<settings.list.length;i++){
                            settings.listMap[settings.list[i]] = settings.list[i];
                        }

                        for(var i=0;i<data.length;i++){
                            if(settings.listMap[data[i][settings.config.meta.id]]){
                                //该selected属性为双向选择框做基础
                                data[i]['selected'] = false;
                                settings.selectedList.push(data[i]);
                                settings.showList.push(data[i]);
                            }else if(!settings.listMap[data[i][settings.config.meta.id]]){
                                //该selected属性为双向选择框做基础
                                data[i]['selected'] = false;
                                settings.unselectedList.push(data[i]);
                                settings.unShowList.push(data[i]);
                            }
                        }
                    }else{
                        //如果没有给已选择的对象，直接循环allList,放入unselectedList  unShowList
                        for(var i=0;i<data.length;i++){
                            data[i]['selected'] = false ;
                            settings.unselectedList.push(data[i]);
                            settings.unShowList.push(data[i]);
                        }
                    }
                }
            }
            settings.upDataSelects(settings.allList);
            return settings;
        };
        return ScSelect;
    }]);

    //双向选择
    angular.module('ngSelect').directive('scMultiSelect', scMultiSelect);
    scMultiSelect.$inject = [ '$timeout','NgSelect'];
    function scMultiSelect( $timeout,NgSelect){
        return {
            restrict:'EA',
            priority: 1009,
            template:
            '<div class="bothwaySelect"  align="center" style="width:{{w}}px">'+
            '<div class="form-group" style="width:100%">'+
            '<input  type="text" class="form-control" ng-change="filterOptions(unselectedFilter,\'unShowList\',\'unselectedList\')" ng-model="unselectedFilter" placeholder="Searching...">'+
            '</div>'+
            '<div>'+
            '<ul  class="muiltSelect" style="height:{{h}}px">'+
            '<li ng-repeat="li in scModel.unShowList track by $index" class="{{li.selected===true?\'active\':\'\'}}"  ng-model="li" ng-click="selected(li)">{{li[label]}}</li>'+
            '</ul>'+
            '</div>'+
            '</div>'+
            '<div class="pointTo bothwaySelect"  align="center" style="width:{{pointW}}px;margin-top:{{pointToHeight}}px">'+
            '<input type="button" ng-click="allFromLeftToRight()" class="btn btn-info btn-sm"  name="rightAll" id="rightAll" value=">>"/><br/>'+
            '<input type="button" ng-click="fromLeftToRight()" class="btn btn-info btn-sm"  name="right" id="right" value=">"/><br/>'+
            '<input type="button" ng-click="fromRightToLeft()"class="btn btn-info btn-sm"  name="leftAll" id="left" value="<"/><br/>'+
            '<input type="button" ng-click="allFromRightToLeft()"class="btn btn-info btn-sm"  name="left" id="leftAll" value="<<"/>'+
            '</div>'+
            '<div class="bothwaySelect"  align="center" style="width:{{w}}px">'+
            '<div class="form-group" style="width:100%">'+
            '<input type="text" class="form-control" ng-change="filterOptions(selectedFilter,\'showList\',\'selectedList\')" ng-model="selectedFilter" placeholder="Searching...">'+
            '</div>'+
            '<div>'+
            '<ul  class="muiltSelect" style="height:{{h}}px">' +
            '<li ng-repeat="li in scModel.showList track by $index" class="{{li.selected===true?\'active\':\'\'}}" ng-model="li"  ng-click="selected(li)">{{li[label]}}</li>'+
            '</ul>'+
            '</div>'+
            '</div>'
            ,
            scope: {
                scModel:'=scModel',
                w:'=?width',
                h:'=?height',
                pointW:'=?pointWidth'
            },
            link: linkFn
        };
        function linkFn(scope, element, attrs, ctrl){
            scope.label=scope.scModel.config.meta.label;
            scope.value=scope.scModel.config.meta.value;
            scope.id = scope.scModel.config.meta.id;

            //scope.w =120;
            if(!scope.w){
                scope.w = 180;
            }
            if(!scope.h){
                scope.h = 240;
            }
            if(!scope.pointW){
                scope.pointW = 100;
            }

            //设置中间point垂直居中
            $timeout(function(){
                scope.pointToHeight = ((scope.h+44)-172)/2;
            },0);

            scope.selected = function(li){
                if(!li['selected']){
                    li['selected'] = true;
                }else{
                    li['selected'] = false;
                }
            };

            //初始配置map
            scope.unselectedShowMap = {};
            for(var i = 0;i<scope.scModel.unShowList.length;i++){
                scope.unselectedShowMap[scope.scModel.unShowList[i][scope.id]] = scope.scModel.unShowList[i];
            }
            scope.selectedShowMap = {};
            for(var i = 0;i<scope.scModel.showList.length;i++){
                scope.selectedShowMap[scope.scModel.showList[i][scope.id]] = scope.scModel.showList[i];
            }

            //改变左侧选择框
            scope.changeLeft = function(opt){
                for(var i = 0;i<scope.scModel.unselectedList.length;i++){
                    if(scope.scModel.unselectedList[i] == opt){
                        scope.scModel.unselectedList.splice(i,1);
                    }
                }
                delete scope.unselectedShowMap[opt[scope.id]];
                opt['selected']=false;
                scope.scModel.showList.push(opt);
                scope.scModel.selectedList.push(opt);
                scope.selectedShowMap[opt[scope.id]] = opt;
            };
            //改变左侧选择框
            scope.fromLeftToRight = function(){
                var len = scope.scModel.unShowList.length;
                for(var i= len-1 ;i>=0;i--){
                    if(scope.scModel.unShowList[i]['selected']){
                        var opt = scope.scModel.unShowList.splice(i,1);
                        scope.changeLeft(opt[0]);
                    }
                }
            };
            scope.allFromLeftToRight = function(){
                var len = scope.scModel.unShowList.length;
                for(var i= len-1 ;i>=0;i--){
                    var opt = scope.scModel.unShowList.splice(i,1);
                    scope.changeLeft(opt[0]);
                }
            };

            //改变右侧选择框
            scope.changeRight = function(opt){
                for(var i = 0;i<scope.scModel.selectedList.length;i++){
                    if(scope.scModel.selectedList[i] == opt){
                        scope.scModel.selectedList.splice(i,1);
                    }
                }
                delete scope.selectedShowMap[opt[scope.id]];
                opt['selected']=false;
                scope.scModel.unShowList.push(opt);
                scope.scModel.unselectedList.push(opt);
                scope.unselectedShowMap[opt[scope.id]] = opt;
            };
            //改变右侧选择框
            scope.fromRightToLeft = function(){
                var len = scope.scModel.showList.length;
                for(var i= len-1 ;i>=0;i--){
                    if(scope.scModel.showList[i]['selected']){
                        var opt = scope.scModel.showList.splice(i,1);
                        scope.changeRight(opt[0]);
                    }
                }
            };
            scope.allFromRightToLeft = function(){
                var len = scope.scModel.showList.length;
                for(var i= len-1 ;i>=0;i--){
                    var opt = scope.scModel.showList.splice(i,1);
                    scope.changeRight(opt[0]);
                }
            };

            //    过滤
            scope.unselectedFilter = "";
            scope.selectedFilter = "";
            scope.filterOptions = function(value,showList,list){
                if(value.length>0){

                    var reg1= /^[A-Za-z]+\s+[A-Za-z]+$/;
                    var reg2= /^[A-Za-z]+$/;
                    if (reg1.test(value) || reg2.test(value)){
                        value = value.toLowerCase();
                    } //判断是否为英文

                    scope.scModel[showList] = [];
                    for(var i=0;i<scope.scModel[list].length;i++){
                        var str = scope.scModel[list][i][scope.label];
                        //如果选项为英文，则转为小写以便查找
                        if (reg1.test(str) || reg2.test(value)){
                            str = str.toLowerCase();
                        }
                        if(str.indexOf(value)>=0){
                            scope.scModel[showList].push(scope.scModel[list][i]);
                        }
                    }
                }else if(value.length == 0){
                    scope.scModel[showList] = [];
                    for(var i=0;i<scope.scModel[list].length;i++){
                        scope.scModel[showList].push(scope.scModel[list][i]);
                    }
                }
            };

            //获取所有选择的角色
            if(scope.scModel.getSelected === angular.noop){
                scope.scModel.getSelected = function(){
                    return scope.scModel.selectedList;
                }
            }

            //    获取所有选择的角色id
            if(scope.scModel.getSelectedId === angular.noop){
                scope.scModel.getSelectedId=function(){
                    var len = scope.scModel.selectedList.length;
                    var ids = [];
                    for(var i = 0 ; i < len ;i++){
                        ids.push(scope.scModel.selectedList[i][scope.id]);
                    }
                    return ids;
                }
            }
        }
    }

    //下拉多选
    angular.module('ngSelect').directive('scDropSelect', scDropSelect);
    scDropSelect.$inject = [ '$timeout','NgSelect'];
    function scDropSelect( $timeout,NgSelect){
        return {
            restrict:'EA',
            priority: 1009,
            template:
            '<div class="scDropSelect">' +

            '<div class="form-control" ng-click="isShow($event)" style="line-height: {{lineHeight}}px;text-align: center">' +

            '<ul class="hasSelected" ng-click="stopProp($event)" ng-show="!isCombine && len>0">' +
            '<li  id="h{{li[id]}}" ng-repeat="li in scModel.selectedList track by $index">' +
            '<b ng-click="del(li)">×</b>'+
            '<span>{{li[label]}}</span>' +
            '</li>'+
            '</ul>' +
            '<span ng-show="len == 0">请 选 择 下 列 选 项</span>'+


            '<div ng-show="isCombine" class="combineSelected">' +

            '<span ng-if="len==allLen?false:true"   ng-click="dropUl($event)">{{len}}个选项已被选择&nbsp;<b class="caret dropUl"></b></span>'+
            '<span ng-if="len==allLen?true:false"   ng-click="dropUl($event)">所有选项已全部选中&nbsp;<b class="caret dropUl"></b></span>'+
            '<ul class="showLi ulHeight" ng-click="stopProp($event)" ng-show=isExpand>' +
            '<li  id="h{{li[id]}}" ng-repeat="li in scModel.selectedList track by $index">' +
            '<b ng-click="del(li)">×</b>'+
            '<span>{{li[label]}}</span>' +
            '</li>'+
            '</ul>' +
            '</div>'+

            '<b class="caret dropUl"></b>'+

            '</div>'+

            '<div class="showLi"  ng-show="isShowSelect" ng-click="$event.stopPropagation()">' +

            '<div class="form-group" ng-show="needSearch">'+
            '<input type="text" class="form-control" ng-change="filterOptions(selectedFilter,\'unShowList\',\'unselectedList\')" ng-model="selectedFilter" placeholder="Searching...">'+
            '</div>'+

            '<ul class="unSelected ulHeight">' +
            '<li id="{{li[id]}}" ng-repeat="li in scModel.unShowList track by $index">' +
            '<input type="checkbox" ng-click="changeStatus(li)" ng-model="li.multiSelected"/>'+
            '<span>{{li[label]}}</span>' +
            '</li>'+
            '</ul>'+
            '</div>'+

            '</div>'
            ,
            scope: {
                scModel:'=scModel',
                w:'=?width',
                h:'=?height',
                num:'=?max',
                needSearch:'=?needSearch'
            },
            link: linkFn
        };
        function linkFn(scope, element, attrs, ctrl) {
            scope.label = scope.scModel.config.meta.label;
            scope.value = scope.scModel.config.meta.value;
            scope.id = scope.scModel.config.meta.id;

            scope.$watch('scModel.allList', function(newVal, oldVal) {
                if(angular.isDefined(newVal) && angular.isArray(newVal) && newVal.length>0) {
                    scope.scModel.upDataSelects(newVal);
                    scope.addMultiSelected(newVal);
                    scope.len = scope.scModel.selectedList.length;
                    scope.allLen = scope.scModel.allList.length;
                    scope.getCombine(scope.scModel.selectedList.length);
                }
            });

            //给每一个对象添加multiSelected新属性
            scope.addMultiSelected = function(data){
                for(var i=0;i<data.length;i++){
                    data[i]['multiSelected'] = false;
                }
            };
            scope.addMultiSelected(scope.scModel.allList);

            if(scope.w){
                $(".scDropSelect").width(scope.w);
            }else{
                if($(element)[0].localName == 'sc-drop-select'){
                    //判断如果作为元素使用
                    if($(element).parent().width() > 400){
                        $(".scDropSelect").width(400);
                    }
                }else{
                    //判断是否作为属性使用
                    if($(element).width() > 400){
                        $(".scDropSelect").width(400);
                    }
                }
            }

            if(scope.h){
                $(".ulHeight").height(scope.h);
            }

            if(!scope.num){
                scope.num = 4;
            }

            scope.isShowSelect = false;//判断是否需要展开下拉框，默认不展开
            scope.isExpand = false;//判断已合并的对象是否需要展开，默认不展开
            //scope.ulWidth = 0;//计算ul的宽度
            scope.isCombine = false; //判断是否合并所有被选中的对象
            //scope.allWidth = 0;//计算整个已选对象的宽度
            scope.lineHeight = 0;//行高
            //scope.currentLiWidth = 0;//当前单个li的宽度
            scope.len = scope.scModel.selectedList.length;//得到已选择的个数
            scope.allLen = scope.scModel.allList.length;//得到所有个数

            scope.unallMap ={};
            scope.allMap = {};

            //获取当前div的内容高度并设置成line-height，以致于ul保持在垂直居中
            scope.lineHeight = $('.scDropSelect .form-control').height();

            //获取选中的li个数，判断是否合并
            scope.getCombine = function(n){
                if(n>scope.num){
                    scope.isCombine = true;
                }else{
                    scope.isCombine = false;
                    scope.isExpand = false;
                }
            };

            //将未选择的对象的宽做成map
            if(scope.scModel.unselectedList.length>0){
                for(var i=0;i<scope.scModel.unselectedList.length;i++){
                    scope.unallMap[scope.scModel.unselectedList[i][scope.id]] = scope.scModel.unselectedList[i][scope.label].length*12 + 23;
                }
            }

            //将已经选择的对象的宽做成map
            if(scope.scModel.selectedList.length>0){

                scope.getCombine(scope.scModel.selectedList.length);

                for(var i=0;i<scope.scModel.selectedList.length;i++){
                    scope.allMap[scope.scModel.selectedList[i][scope.id]] = scope.scModel.selectedList[i][scope.label].length*12 + 23;
                }
            }

            //计算选中的li总宽度，判断是否合并
            //*****
            // scope.getCombine = function(w){
            //     if(w>scope.ulWidth){
            //         scope.isCombine = true;
            //     }else{
            //         scope.isCombine = false;
            //         scope.isExpand = false;
            //     }
            // };

            //计算当前li宽度，和总计宽度
            //*****
            // scope.getWidth = function(obj,num){
            //     scope.currentLiWidth = $("#h"+obj[scope.id]).outerWidth(true);
            //     scope.allWidth = scope.allWidth + scope.currentLiWidth*num;
            //     console.log(scope.allWidth);
            //     console.log($("#h"+obj[scope.id]));
            //     scope.getCombine(scope.allWidth);
            // };

            //获取已选择框的ul宽度
            //*****
            // $timeout(function(){
            //     scope.ulWidth = $('.scDropSelect .form-control .hasSelected').width();
            // },0);

            //初始化时判断是否有li，然后循环li将width相加赋值给allWidth
            //****
            // scope.getAllLiWidth = function(){
            //     $timeout(function(){
            //         //如果加载时有默认li才执行
            //         if($('.scDropSelect .form-control .hasSelected li')[0]){
            //             //*****
            //             $('.scDropSelect .form-control .hasSelected').children('li').each(function(){
            //                 scope.allWidth = scope.allWidth+$(this).outerWidth(true);
            //             });
            //         }
            //
            //         //调用函数判断是否合并
            //         scope.getCombine(scope.allWidth);
            //     },0);
            // };
            // scope.getAllLiWidth();



            //过滤
            scope.selectedFilter = "";
            scope.filterOptions = function(value,showList,list){
                if(value.length>0){

                    var reg1= /^[A-Za-z]+\s+[A-Za-z]+$/;
                    var reg2= /^[A-Za-z]+$/;
                    if (reg1.test(value) || reg2.test(value)){
                        value = value.toLowerCase();
                    } //判断是否为英文

                    scope.scModel[showList] = [];
                    for(var i=0;i<scope.scModel[list].length;i++){
                        var str = scope.scModel[list][i][scope.label];
                        //如果选项为英文，则转为小写以便查找
                        if (reg1.test(str) || reg2.test(str)){
                            str = str.toLowerCase();
                        }
                        if(str.indexOf(value)>=0){
                            scope.scModel[showList].push(scope.scModel[list][i]);
                        }
                    }
                }else if(value.length == 0){
                    scope.scModel[showList] = [];
                    for(var i=0;i<scope.scModel[list].length;i++){
                        scope.scModel[showList].push(scope.scModel[list][i]);
                    }
                }
            };


            //点击×删除对应的元素
            scope.del = function(obj){
                var index = scope.scModel.selectedList.indexOf(obj);
                var elem = scope.scModel.selectedList.splice(index,1);
                //当在下拉框中未找到，则push进去
                if(scope.scModel.unselectedList.indexOf(obj) == -1){
                    scope.scModel.unselectedList.push(elem[0]);

                    //判断此时是否正在搜索状态
                    if(scope.selectedFilter.length>0){
                        scope.filterOptions(scope.selectedFilter,'unShowList','unselectedList');
                    }else{
                        scope.scModel.unShowList.push(elem[0]);
                    }

                }else{
                    //如果下拉框中存在当前obj，则设置其selected属性为false
                    var ind = scope.scModel.unselectedList.indexOf(obj);
                    scope.scModel.unselectedList[ind]['multiSelected'] = false;
                }

                scope.len = scope.scModel.selectedList.length;
                //调用函数得到总宽
                scope.getCombine(scope.len);
            };

            //点击input，改变展示栏和下拉框的状态
            scope.changeStatus= function(obj){
                //当点击checkbox获取li宽度
                //scope.getLiWidth();

                if(obj['multiSelected']){
                    if(scope.scModel.selectedList.indexOf(obj) == -1){
                        scope.scModel.selectedList.push(obj);
                    }

                    //当obj push到数组中，需要时间
                    //****
                    //调用函数得到总宽
                    //     $timeout(function(){
                    //         scope.getWidth(obj,1);
                    //     },0);
                }else{
                    var index = scope.scModel.selectedList.indexOf(obj);
                    if(index>=0){
                        scope.scModel.selectedList.splice(index,1);
                    }
                    //调用函数得到总宽
                    //****
                    //scope.getWidth(obj,-1);
                }

                scope.len = scope.scModel.selectedList.length;
                //****
                //scope.getCombine(scope.allWidth);
                scope.getCombine(scope.len);
            };

            //当点击ul时，阻止事件冒泡
            scope.stopProp = function(e){
                e.stopPropagation();
            };

            //是否展开下拉框
            scope.isShow = function(e){
                e.stopPropagation();
                if(!scope.isShowSelect){
                    scope.isShowSelect = true;
                }else{
                    scope.isShowSelect = false;
                }
            };

            //点击<b class="caret"></b>展开ul
            scope.dropUl = function(e){
                e.stopPropagation();
                if(!scope.isExpand){
                    scope.isExpand = true;
                }else{
                    scope.isExpand = false;
                }
            };

            $(document).on('click',function(){
                $timeout(function(){
                    scope.isShowSelect = false;
                    scope.isExpand = false;
                },0);
            });

            //获取所有选择的角色
            if(scope.scModel.getSelected === angular.noop){
                scope.scModel.getSelected = function(){
                    return scope.scModel.selectedList;
                }
            }

            //    获取所有选择的角色id
            if(scope.scModel.getSelectedId === angular.noop){
                scope.scModel.getSelectedId=function(){
                    var len = scope.scModel.selectedList.length;
                    var ids = [];
                    for(var i = 0 ; i < len ;i++){
                        ids.push(scope.scModel.selectedList[i][scope.id]);
                    }
                    return ids;
                }
            }
        }
    }

    //下拉单选
    angular.module('ngSelect').directive('scDropSingleSelect', scDropSingleSelect);
    scDropSingleSelect.$inject = [ '$timeout','NgSelect'];
    function scDropSingleSelect( $timeout,NgSelect){
        return {
            restrict:'EA',
            priority: 1009,
            template:
            '<div class="scDropSingleSelect" ng-click="$event.stopPropagation()">' +

            '<div class="form-control" ng-click="isShow($event)" style="line-height: {{lineHeight}}px;text-align: center">' +

            '<span ng-show="scModel.showList.length>0?false:true">请选择下列选项</span>'+

            '<span ng-show="scModel.showList.length>0?true:false">{{scModel.showList[0][label]}}</span>'+

            '<b class="caret dropUl"></b>'+

            '</div>'+

            '<div class="singleSelect"  ng-show="isShowSelect">'+
            '<div class="form-group" ng-show="needSearch">'+
            '<input type="text" class="form-control" ng-change="filterOptions(selectedFilter,\'unShowList\',\'unselectedList\')" ng-model="selectedFilter" placeholder="Searching...">'+
            '</div>'+

            '<ul class="unSingleSelected" style="border:none">' +
            '<li id="sc{{li[id]}}" ng-repeat="li in scModel.unShowList track by $index" ng-click="selected(li)" class="{{li.singleSelected==true?\'active\':\'\'}}">' +
            '<span>{{li[label]}}</span>' +
            '</li>'+
            '</ul>'+
            '</div>'+
            '<div style="clear:both;content:\'\'"></div>'+
            '</div>'
            ,
            scope: {
                scModel:'=?scModel',
                w:'=?width',
                h:'=?height',
                selection:'=?onSelection',
                scList:'=?scList',
                needSearch:'=?needSearch',
                label:'=?label',
                defaultId:'=?idField',
                modelChoose:'=?modelChoose'
            },
            controller : ['$scope', function(scope){
                if(!angular.isDefined(scope.scModel)){
                    //判断如果scope.treeModel没有定义 再判断scope.treeList如果存在则实例化
                    if(angular.isDefined(scope.scList)){
                        scope.scModel = new NgSelect({
                            allList : scope.scList
                        });
                    }else{
                        scope.scModel = new NgSelect({
                            allList : []
                        });
                    }
                }
            }],
            link: linkFn
        };
        function linkFn(scope, element, attrs, ctrl) {
            if(!angular.isDefined(scope.label)){
                scope.label = scope.scModel.config.meta.label;
            }

            scope.value = scope.scModel.config.meta.value;
            scope.id = scope.scModel.config.meta.id;

            scope.showDefault= function(sd){
                var list=scope.scModel.unShowList;
                for(var i=0;i<list.length;i++){
                    if(sd == list[i][scope.defaultId]){
                        if(!list[i]['singleSelected']){
                            scope.selected(list[i]);
                            return ;
                        }else{
                            return;
                        }
                    }
                }
            };

            scope.$watch('scList', function(newVal, oldVal) {
                if(angular.isDefined(newVal) && angular.isArray(newVal) && newVal.length>0){
                    scope.scModel.allList =newVal;
                    scope.scModel.upDataSelects(scope.scModel.allList);
                    scope.addMultiSelected(scope.scModel.allList);
                    if(angular.isDefined(scope.modelChoose) && (scope.modelChoose!=null)){
                        scope.showDefault(scope.modelChoose);
                    }
                }
            });
            scope.$watch('modelChoose', function(newVal, oldVal) {
                if(angular.isDefined(newVal) && (newVal!=null)){
                    scope.showDefault(newVal);
                }else{
                    if(scope.scModel.showList.length>0){
                        scope.scModel.showList[0]['singleSelected'] = false;
                        scope.scModel.showList.splice(0,1);
                    }
                }
            });

            //给每一个对象添加multiSelected新属性
            scope.addMultiSelected = function(data){
                for(var i=0;i<data.length;i++){
                    data[i]['singleSelected'] = false;
                }
            };
            scope.addMultiSelected(scope.scModel.allList);

            if(scope.w){
                $(".scDropSingleSelect").width(scope.w);
            }else{
                if($(element)[0].localName == 'sc-drop-single-select'){
                    //判断如果作为元素使用
                    if($(element).parent().width() > 400){
                        $(".scDropSingleSelect").width(400);
                    }
                }else{
                    //判断是否作为属性使用
                    if($(element).width() > 400){
                        $(".scDropSingleSelect").width(400);
                    }
                }
            }

            if(scope.h){
                $(".unSingleSelected").height(scope.h);
            }

            scope.isShowSelect = false;//判断是否需要展开下拉框，默认不展开
            scope.lineHeight = 0;//行高

            //获取当前div的内容高度并设置成line-height，以致于ul保持在垂直居中
            scope.lineHeight = $('.scDropSelect .form-control').height();

            //是否展开下拉框
            scope.isShow = function(e){
                e.stopPropagation();
                if(!scope.isShowSelect){
                    scope.isShowSelect = true;
                }else{
                    scope.isShowSelect = false;
                }
            };

            $('.scDropSingleSelect').on('mouseleave',function(){
                $timeout(function(){
                    scope.isShowSelect = false;
                },0);
            });

            //过滤
            scope.selectedFilter = "";
            scope.filterOptions = function(value,showList,list){
                if(value.length>0){

                    var reg1= /^[A-Za-z]+\s+[A-Za-z]+$/;
                    var reg2= /^[A-Za-z]+$/;
                    if (reg1.test(value) || reg2.test(value)){
                        value = value.toLowerCase();
                    } //判断是否为英文

                    scope.scModel[showList] = [];
                    for(var i=0;i<scope.scModel[list].length;i++){
                        var str = scope.scModel[list][i][scope.label];
                        //如果选项为英文，则转为小写以便查找
                        if (reg1.test(str) || reg2.test(str)){
                            str = str.toLowerCase();
                        }
                        if(str.indexOf(value)>=0){
                            scope.scModel[showList].push(scope.scModel[list][i]);
                        }
                    }
                }else if(value.length == 0){
                    scope.scModel[showList] = [];
                    for(var i=0;i<scope.scModel[list].length;i++){
                        scope.scModel[showList].push(scope.scModel[list][i]);
                    }
                }
            };

            //当点击li时调用
            scope.selected = function(li){
                // $("#sc"+li[scope.id]).addClass("active").siblings(".active").removeClass("active");
                var len=scope.scModel.unselectedList.length;
                for(var i=0;i<len;i++){
                    if((scope.scModel.unselectedList[i]!=li) && scope.scModel.unselectedList[i]['singleSelected']){
                        scope.scModel.unselectedList[i]['singleSelected'] = false;
                    }
                }
                if(!li['singleSelected']){
                    li['singleSelected'] = true;
                    scope.scModel.showList[0] = li;
                    scope.modelChoose = li[scope.defaultId];

                    //当选中当前的对象
                    //如果scope.selection有定义并且是个函数，则调用该函数并传入当前对象
                    if(angular.isDefined(scope.selection) && angular.isFunction(scope.selection)){
                        scope.selection(li);
                    }
                }else{
                    li['singleSelected'] = false;
                    scope.scModel.showList.splice(0,1);
                    scope.modelChoose = null;

                    //当取消当前选中的对象
                    //如果scope.selection有定义并且是个函数，则调用该函数并传入当前对象
                    if(angular.isDefined(scope.selection) && angular.isFunction(scope.selection)){
                        li={};
                        scope.selection(li);
                    }
                }
            };

            //如果有默认选中的
            if(angular.isDefined(scope.modelChoose) && (scope.modelChoose!=null) ){
                scope.showDefault(scope.modelChoose);
            }

        }
    }

//    下拉选择框
    angular.module('ngSelect').directive('scSelect', scSelect);
    scSelect.$inject = [ '$timeout','NgSelect','$localStorage','$sce','$http','NgTree', '$httpParamSerializer','$parse'];
    function scSelect( $timeout,NgSelect,$localStorage,$sce,$http,NgTree, $httpParamSerializer,$parse){
        return {
            restrict:'EA',
            priority: 1009,
            template:function(elem,attr){
                var html = '<div class="sc-select">' +
                    '<div class="showSelected form-control" ng-click="isShow($event)">' +
                    '<div ng-if="scSelectMode==\'multiple\'">' +

                    '<ul class="hasSelected" ng-click="$event.stopPropagation()" ng-show="!isCombine && scModelOptions.showList.length>0">' +
                    '<li  id="h{{li[id]}}" ng-repeat="li in scModelOptions.showList track by $index">' +
                    '<b ng-click="del(li, true)" ng-if="!scOnlyRead">×</b>'+
                    '<span ng-repeat="key in displayName track by key"><b ng-if="displayName[0]!=key">{{scDisplaySplitter}}</b>{{li[key]}}</span>' +
                    '</li>'+
                    '</ul>' +

                    '<span ng-show="scModelOptions.showList.length == 0">请 选 择 下 列 选 项</span>'+

                    '<div ng-show="isCombine && scModelOptions.showList.length>0" class="combineSelected" ng-click="dropUl($event)">' +

                    '<span>{{scModelOptions.showList.length}}个选项已被选择&nbsp;<b class="caret dropUl"></b></span>'+
                    // '<span ng-if="len==allLen?true:false">所有选项已全部选中&nbsp;<b class="caret dropUl"></b></span>'+
                    '<ul class="showLi ulHeight" ng-click="$event.stopPropagation()">' +
                    '<li  id="h{{li[id]}}" ng-repeat="li in scModelOptions.showList track by $index">' +
                    '<b ng-click="del(li, true)">×</b>'+
                    '<span ng-repeat="key in displayName track by key"><b ng-if="displayName[0]!=key">{{scDisplaySplitter}}</b>{{li[key]}}</span>' +
                    '</li>'+
                    '</ul>' +
                    '</div>'+
                    '</div>'+

                    '<div ng-if="scSelectMode==\'single\'">' +

                    '<span  ng-show="scModelOptions.showList.length>0?false:true">请选择下列选项</span>'+

                    '<span ng-show="scModelOptions.showList.length>0?true:false" ng-repeat="key in displayName track by key"><b ng-if="displayName[0]!=key && scModelOptions.showList[0][key]">{{scDisplaySplitter}}</b>{{scModelOptions.showList[0][key]}}</span>'+

                    '</div>'+

                    '<b class="caret dropUl"></b>'+
                    '</div>'+

                    '<div ng-if="hasTree" class="showUnSelected"  ng-click="clickUnSelected();$event.stopPropagation()">' +
                    '<div class="form-group">'+
                    '<input type="text" ng-if="scFilterMode==\'front\'" class="form-control" ng-change="frontFilter(\'unShowList\',\'unselectedList\',selectedFilter)" ng-model="selectedFilter" placeholder="Searching...">'+

                    '<input type="text" ng-if="scFilterMode==\'background\'" class="form-control" ng-change="backgroundFilter(\'unShowList\',\'unselectedList\',selectedFilter)" ng-model="selectedFilter" placeholder="Searching...">'+
                    '</div>'+
                    '<div class="treeData">'+
                    '<sc-treecontrol tree-model="tree" on-selection="onSelection"';
                if(attr.scDisplayFields){
                    var displayName = attr.scDisplayFields.split(',');

                    html = html +' display-name="'+displayName+'" ';
                }
                if(attr.scDisplaySplitter){
                    var displaySplitter = attr.scDisplaySplitter;
                    html = html + ' display-splitter="'+displaySplitter+'" ';
                }else{
                    html = html + ' display-splitter="--" ';
                }

                html = html+'>'+

                    '</sc-treecontrol>'+
                    '</div>'+
                    '<div ng-if="scSelectMode==\'multiple\'" class="confirmOperation">' +
                    '<button type="button" class="btn btn-info" ng-click="confirmOperation(true)">确认</button>'+
                    '<button type="button" class="btn btn-info" ng-click="isShow($event)">取消</button>'+
                    '</div>'+
                    '</div>'+

                    '<div ng-if="!hasTree" class="showUnSelected"  ng-click="clickUnSelected();$event.stopPropagation()">' +
                    '<div class="form-group">'+
                    '<input type="text" ng-if="scFilterMode==\'front\'" class="form-control" ng-change="frontFilter(\'unShowList\',\'unselectedList\',selectedFilter)" ng-model="selectedFilter" placeholder="Searching...">'+

                    '<input type="text" ng-if="scFilterMode==\'background\'" class="form-control" ng-change="backgroundFilter(\'unShowList\',\'unselectedList\',selectedFilter)" ng-model="selectedFilter" placeholder="Searching...">'+
                    '</div>'+

                    '<ul ng-if="scSelectMode==\'multiple\'" class="unSelected ulHeight">' +
                    '<li class="multipleLi" ng-repeat="li in scModelOptions.unShowList track by li[scValueField]">' +
                    '<input type="checkbox" ng-click="changeStatus(li, true)" ng-model="li.multiSelected"/>'+
                    '<span ng-repeat="key in displayName track by key"><b ng-if="displayName[0]!=key">{{scDisplaySplitter}}</b>{{li[key]}}</span>' +
                    '</li>'+
                    '</ul>'+

                    '<ul ng-if="scSelectMode==\'single\'" class="unSelected ulHeight {{scValueField}}">' +
                    '<li  class="singleLi {{li.singleSelected==true?\'active\':\'\'}}" ng-repeat="li in scModelOptions.unShowList track by li[scValueField]"  ng-click="selected(li)">' +
                    '<span ng-repeat="key in displayName track by key"><b ng-if="displayName[0]!=key">{{scDisplaySplitter}}</b>{{li[key]}}</span>' +
                    '</li>'+
                    '</ul>'+
                    '</div>'+
                    '</div>';
                return html;
            },
            scope:{
                scDataurl: '=?scDataurl',//支持双引号中写入变量、双引号中加单引号再在单引号中写入字符串
                scData:'=?scData',
                scSelected:'=?scSelectedValues',//同工厂里的list  id列表
                scSelection:'=?scSelection',// 选择回调
                scSelectionN:'&?scSelectionN',// 选择回调
                scChange:'=?scChange',// 值变化回调
                scChangeN:'&?scChangeN',// 值变化回调
                scWith:'=?scWith',
                scModel:'=?scModel',//使用者可以获取的选中的对象集合
                scStrictModel:'=?scStrictModel',//使用者可以获取的选中的对象集合（在交互过程中才会赋值）
                scParams:'=?scParams',//使用者可以绑定外部查询参数（用于联动）
                scValue:'=?scValue',//使用者可以获取的选中的值的集合
                scOnlyRead:'=?scOnlyRead',//判断是否禁用组件
                scTreeCode:'=?scTreeCode'//判断是否在选择树形节点对象时有特定的需要获取的字段
            },
            link:linkFn
        };
        function linkFn(scope,element,attr,ctrl){
            // var dUrl = attr.scDataurl;
            // if(dUrl){
            //     var idx = dUrl.indexOf("'");
            //     if(idx>-1){
            //         dUrl = $parse(dUrl)(scope);
            //     }
            //     scope.scDataurl = dUrl;
            // }
            scope.scValueField = attr.scValueField;//code值字段类似id唯一，一般为code
            scope.scSelectMode = attr.scSelectMode?attr.scSelectMode:'single';//选择模式  单选或多选  默认单选
            scope.scDisplaySplitter = attr.scDisplaySplitter?attr.scDisplaySplitter:'--';//展示多个字段的间隔符，默认--
            scope.scFilterMode = attr.scFilterMode?attr.scFilterMode:'front';//可选择过滤  默认前端过滤
            scope.scChildrenFiled = attr.scChildrenFiled?attr.scChildrenFiled:'children';//如果有树形结构   子数组字段名    默认children

            //判断是否定义或者是否为0
            scope.isDefinedOrZero = function(val){
                if(val!==0){
                    return !val;
                }else{
                    return false;
                }
            };
            if (!attr.scData) {
                scope.scData = [];
            }else{
                if (attr.scDefault&& !scope.scValue) {
                    scope.scSelectMode=='multiple'?scope.scValue=[]:scope.scValue='';
                    if (attr.scDefault === 'first') {
                        scope.scSelectMode == 'multiple'?scope.scValue.push(data.content[0][scope.scValueField]):(scope.scValue = data.content[0][scope.scValueField]);
                    } else {
                        scope.scSelectMode == 'multiple'?scope.scValue.push(attr.scDefault):(scope.scValue = attr.scDefault);
                    }
                }
            }
            scope.scDataMap = {};
            var w = $('.sc-select').outerWidth();
            if(w<=160){
                $timeout(function(){
                    $('.sc-select .showLi').addClass('active');
                },0);
                $('.sc-select .showUnSelected').addClass('active');
            }

            scope.showMode = function(m) {
                var sl = scope.scModelOptions.showList;
                var len = sl?sl.length:0;
                var mode = 'list';
                if (len > 0) {
                    if(scope.num && scope.num < len) {
                        mode = 'combin';
                    } else {
                        mode = 'selected';
                    }
                }
                return mode == m;
            };

            scope.scDataPromise = undefined;
            scope.getData = function(){
                return new Promise(function(resolve) {
                var url = scope.scDataurl;
                    if(url) {
                url = attr.scCode?(url+'/'+attr.scCode):(url);
                if(url.indexOf('?')==-1){
                    url = url+'?callback=JSON_CALLBACK&token='+ $localStorage.authenticationToken;
                }else{
                    url =url+'&callback=JSON_CALLBACK&token='+ $localStorage.authenticationToken;
                }
                if (scope.scParams) {
                    url = url + '&' + $httpParamSerializer(scope.scParams);
                }

                $sce.trustAsResourceUrl(url);
                $http.jsonp(url).success(function(data) {
                    if(data.status === 'SUCCESS') {
                        if (angular.isDefined(data.content) && angular.isArray(data.content) && (data.content.length > 0)) {
                            resolve(data.content);
                            scope.scData = data.content;
                            if (attr.scDefault&& !scope.scValue) {
                                scope.scSelectMode=='multiple'?scope.scValue=[]:scope.scValue='';
                                if (attr.scDefault === 'first') {
                                    scope.scSelectMode == 'multiple'?scope.scValue.push(data.content[0][scope.scValueField]):(scope.scValue = data.content[0][scope.scValueField]);
                                } else {
                                    scope.scSelectMode == 'multiple'?scope.scValue.push(attr.scDefault):(scope.scValue = attr.scDefault);
                                }
                            }
                        } else {
                            resolve([]);
                            scope.scData = [];
                        }
                    }
                });
                    } else if(scope.scData) {
                        resolve(scope.scData);
                    } else {
                        resolve([]);
                    }

                });
            };

            //需要展示的字段
            if(attr.scDisplayFields){
                scope.displayName = attr.scDisplayFields.split(',');
            }
            //需要过滤的字段
            if(attr.scFilterFields){
                scope.filterFields = attr.scFilterFields.split(',');
            }
            //存放选中的code列表
            scope.scSelectedValues = [];
            // scope.scSelectMode=='multiple'?scope.scSelectedValues=[]:scope.scSelectedValues='';

            //默认选中所传入的code
            if(scope.scSelected){
                var scSelected = angular.copy(scope.scSelected);
                if (!angular.isArray(scSelected)) {
                    scSelected = [scSelected];
                }

                //判断是否为数组  push到选中的列表中  如果是多选  全部push到scValue中   如果是单选  scValue=列表中的第一个code值
                if(angular.isArray(scSelected) && scSelected.length>0){
                    for(var i=0;i<scSelected.length;i++){
                        scope.scSelectedValues.push(scSelected[i]);
                    }

                    if(scope.scSelectMode=='multiple'){
                        if (!scope.scValue || scope.scValue.length == 0) {
                            scope.scValue = [];
                            for(var i=0;i<scSelected.length;i++){
                                scope.scValue.push(scSelected[i]);
                            }
                        }
                    } else {
                        if (!scope.scValue) {
                            scope.scValue = scSelected[0];
                        }
                    }
                }
            }
            if(!scope.scWith){
                scope.scWith = '';
            }

            scope.scModelOptions={};//存放所需的列表   里面包含UNSelectedList  、showList等
            scope.isShowSelect = false;//判断是否需要展开下拉框，默认不展开
            scope.listMap={};//已选中的对象做map
            scope.selectedFilter = "";//过滤输入的字符串


        scope.getDataNewMap=function(data,scValue,select,code){

            if(angular.isArray(data) && data.length>0){
                for(var i=0;i<data.length;i++){
                    var v;
                    if(code){
                        if(data[i][code]){
                            v = data[i][code];
                        }else if(data[i]['data'] && data[i]['data'][code]){
                            v = data[i]['data'][code];
                        }
                    }else{
                        v = data[i][scope.scValueField];
                    }
                    scope.dataMap[v] = data[i];

                    if(scValue.indexOf(v) < 0) {
                        data[i][select] = false;
                    } else {
                        data[i][select] = true;
                    }
                    if(angular.isArray(data[i][scope.scChildrenFiled]) && data[i][scope.scChildrenFiled].length>0){
                        scope.getDataNewMap(data[i][scope.scChildrenFiled],scValue,select,code);
                    }
                }
            }
        };
        //配置数据
        scope.upDataSelects=function(select){
            if (scope.scDataPromise) {
                return new Promise(function(resolve) {
                    scope.scModelOptions.unselectedList = [];//未选择的所有对象

                    scope.scDataPromise.then(function(data) {
                        var select = scope.scSelectMode=='single'?'singleSelected':'multiSelected';
                        var scVal = scope.scValue;
                        if (!angular.isArray(scVal)) {
                            scVal = [scVal];
                        }
                        scope.dataMap = {};
                        scope.getDataNewMap(data,scVal,select,attr.scTreeCode);//请求后台时创建scDataMap,但请求时间过长，所以创建新的dataMap仅供该函数内部使用

                        if(scope.hasTree){
                            if(attr.scTreeCode){
                                var arr = [];
                                for(var i=0;i<scVal.length;i++){
                                    if(scope.dataMap[scVal[i]]){
                                        arr.push(scope.dataMap[scVal[i]][scope.scValueField]);
                                    }
                                }

                                scope.tree.selectedNodeIds = arr;
                            }else{
                                scope.tree.selectedNodeIds = scVal?angular.copy(scVal):[];
                            }
                        }

                        scope.scModelOptions.unselectedList = [];//未选择的所有对象
                        //判断是否有已选对象
                        if(!scope.scModelOptions.showList) {
                            scope.scModelOptions.showList = [];
                        }

                        var scValue = scope.scValue;
                        var scValueCp = scValue != undefined?scValue:[];
                        if (!angular.isArray(scValueCp)) {
                            scValueCp = [scValueCp];
                        }
                        jQuery.each(data, function(idx, d) {
                            scope.scModelOptions.unselectedList.push(d);
                        });

                        if (scValue != undefined) {
                            if(angular.isArray(scValue) && scValue.length > 0) {
                                // for(var i=0;i<scValue.length;i++){
                                //     var v = angular.copy(scValue[i]);
                                //     var c = parseInt(v);
                                //     if(!isNaN(c)){
                                //         scValue[i] = c;
                                //     }
                                // }
                                var rmIdxes = [];
                                var map = {};
                                scope.scModel = [];
                                scope.scStrictModel = [];
                                var c = attr.scTreeCode?attr.scTreeCode:scope.scValueField;
                                jQuery.each(scope.scModelOptions.showList, function(idx, sl) {
                                    var d;
                                    if(sl[c]){
                                        d = sl[c];
                                    }else if(sl['data'] && sl['data'][c]){
                                        d = sl['data'][c];
                                    }
                                    map[d] = sl;
                                    if(scValue.indexOf(d) < 0) {
                                        rmIdxes.push(idx);
                                    }
                                });
                                for(var i=rmIdxes.length - 1; i>=0; i--) {
                                    scope.scModelOptions.showList.splice(rmIdxes[i], 1);
                                }

                                jQuery.each(scValue, function(idx, sv) {
                                    if(!map[sv]) {
                                        if(scope.dataMap[sv]) {
                                            scope.scModelOptions.showList.push(scope.dataMap[sv]);
                                            scope.scModel.push(scope.dataMap[sv]);
                                            scope.scStrictModel.push(scope.dataMap[sv]);
                                        }else{
                                            var o = {};
                                            o[scope.scValueField] = sv;
                                            o[scope.displayName[0]] = sv;
                                            scope.scModelOptions.showList.push(o);
                                            scope.scModel.push(o);
                                            scope.scStrictModel.push(o);
                                        }
                                    }else{
                                        // scope.scModelOptions.showList.push(scope.dataMap[sv]);
                                        scope.scModel.push(scope.dataMap[sv]);
                                        scope.scStrictModel.push(scope.dataMap[sv]);
                                    }
                                });
                            } else if(angular.isArray(scValue)) {
                                $timeout(function(){
                                    scope.scModelOptions.showList = [];
                                });

                                scope.scModel = [];
                                scope.scStrictModel = [];
                                emptySelected(data);
                            }else{
                                // $timeout(function(){
                                    scope.scModelOptions.showList = [];
                                // });
                                if(scope.dataMap[scValue]) {
                                    $timeout(function(){
                                        scope.scModelOptions.showList.push(scope.dataMap[scValue]);
                                    });

                                    scope.scModel = scope.dataMap[scValue];
                                    scope.scStrictModel = scope.dataMap[scValue];
                                } else {
                                    var o = {};
                                    o[scope.scValueField] = scValue;
                                    o[scope.displayName[0]] = scValue;
                                    scope.scModelOptions.showList.push(o);
                                    $timeout(function(){
                                        scope.scModelOptions.showList.push(o);
                                    });
                                    scope.scModel = o;
                                    scope.scStrictModel = o;
                                }
                            }
                        } else {
                            scope.scModel = undefined;
                            scope.scStrictModel = undefined;
                            emptySelected(data);
                        }

                        if (scope.scFilterMode=='front') {
                            scope.frontFilter('unShowList','unselectedList',scope.selectedFilter);
                        } else {
                            scope.backgroundFilter('unShowList','unselectedList',scope.selectedFilter);
                        }

                        function emptySelected(data) {
                            $timeout(function(){
                                    scope.scModelOptions.showList = [];
                                });
                            for(var i=0;i<data.length;i++){
                                data[i][select] = false ;
                            }
                        }

                            resolve(scope.scValue);
                        });
                    });
                }

                return new Promise(function(resolve) {
                    resolve(scope.scValue);
                });
            };

            //配置树形结构数据
            scope.configTree=function(data,id,code){
                if(angular.isArray(data) && data.length>0){
                    for(var i=0;i<data.length;i++){
                        if(id){
                            data[i]['treeId'] = id+'_'+(i+1);
                        }else{
                            data[i]['treeId'] =i+1+'';
                        }
                        if(code){
                            if(data[i][code]){
                                scope.scValueMap[data[i][code]]  = data[i];
                            }else if(data[i]['data'] && data[i]['data'][code]){
                                scope.scValueMap[data[i]['data'][code]]  = data[i];
                            }
                        }
                        scope.treeIdMap[data[i][scope.scValueField]] = data[i];
                        scope.treeMap[data[i]['treeId']] = data[i];
                        if(data[i][scope.scChildrenFiled]){
                            scope.configTree(data[i][scope.scChildrenFiled],data[i]['treeId'],code);
                        }
                    }
                }
            };

            //搜索树形数据
            scope.searchTree = function(data,value,list){
                if(angular.isArray(data) && data.length>0){
                    for(var i=0;i<data.length;i++){
                        var str = data[i][scope.displayName[0]];//按需要拼接展示内容，以便搜索
                        for(var j=1;j<scope.displayName.length;j++){
                            str= str+scope.scDisplaySplitter+data[i][scope.displayName[j]];
                        }
                        str = str.toLowerCase();
                        if(str.indexOf(value)>=0){
                            var ids = data[i]['treeId'].split('_');
                            if(list.indexOf(ids[0]) == -1){
                                list.push(ids[0]);
                            }
                        }
                        if(data[i][scope.scChildrenFiled]){
                            scope.searchTree(data[i][scope.scChildrenFiled],value,list);
                        }
                    }
                }
            };

            //前端过滤 三个参数为  输入的字符  展示的已选中的对象  未选择的对象
            scope.frontFilter = function(showList,list,value){
                if(scope.hasTree){
                    if(value && value.length>0){
                        scope.treeList = [];
                        scope.searchTree(scope.scData,value,scope.treeList);
                        scope.tree.data=[];
                        for(var i=0;i<scope.treeList.length;i++){
                            if(scope.treeMap[scope.treeList[i]]){
                                scope.tree.data.push(scope.treeMap[scope.treeList[i]]);
                            }
                        }
                    }else{
                        scope.tree.data=[];
                        for(var i=0;i<scope.scData.length;i++){
                            scope.tree.data.push(scope.scData[i]);
                        }
                    }
                }else{
                    // var value= scope.selectedFilter;
                    if(value && value.length>0){
                        var reg1= /^[A-Za-z]+\s+[A-Za-z]+$/;
                        var reg2= /^[A-Za-z]+$/;
                        if (reg1.test(value) || reg2.test(value)){
                            value = value.toLowerCase();
                        } //判断是否为英文

                        scope.scModelOptions[showList] = [];
                        for(var i=0;i<scope.scModelOptions[list].length;i++){
                            var str = scope.scModelOptions[list][i][scope.displayName[0]];//按需要拼接展示内容，以便搜索
                            for(var j=1;j<scope.displayName.length;j++){
                                str= str+scope.scDisplaySplitter+scope.scModelOptions[list][i][scope.displayName[j]];
                            }
                            str = str.toLowerCase();
                            if(str.indexOf(value)>=0){
                                scope.scModelOptions[showList].push(scope.scModelOptions[list][i]);
                            }
                        }
                    }else if(value.length == 0){
                        scope.scModelOptions[showList] = [];
                        for(var i=0;i<scope.scModelOptions[list].length;i++){
                            scope.scModelOptions[showList].push(scope.scModelOptions[list][i]);
                        }
                    }
                }
            };

            //后台过滤
            scope.backgroundFilter = function(value,showList,list){
                //每一次变化都会请求后台 故不需要判断是否有过滤条件
                var reg1= /^[A-Za-z]+\s+[A-Za-z]+$/;
                var reg2= /^[A-Za-z]+$/;
                if (reg1.test(value) || reg2.test(value)){
                    value = value.toLowerCase();
                } //判断是否为英文
                var select = '';
                if(scope.scSelectMode == 'single'){
                    select  = 'singleSelected';
                }else{
                    select = 'multiSelected';
                }

                //先将展示的list置为空
                scope.scModelOptions[showList] = [];
                scope.scModelOptions[list] = [];
                //一系列拼接url
                var url = scope.scDataurl;
                url = attr.scCode?(url+'/'+attr.scCode):(url);
                if(url.indexOf('?')==-1){
                    url = url+'?callback=JSON_CALLBACK&token='+ $localStorage.authenticationToken;
                }else{
                    url =url+'&callback=JSON_CALLBACK&token='+ $localStorage.authenticationToken;
                }

                if(angular.isArray(scope.filterFields) && scope.filterFields.length>0){
                    var u = {};
                    for(var i=0;i<scope.filterFields.length;i++){
                        u[scope.filterFields[i]] = value;
                    }
                    url = url+'&'+$.param(u);
                }
                //请求数据
                $sce.trustAsResourceUrl(url);
                $http.jsonp(url).success(function(data) {
                    if(data.status === 'SUCCESS') {
                        if (angular.isDefined(data.content) && angular.isArray(data.content) && (data.content.length > 0)) {
                            if(scope.hasTree){
                                scope.tree.data = data.content;
                            }else{
                                var dataC = data.content;
                                var len = dataC.length;
                                if(len>0){
                                    for(var i=0;i<len;i++){
                                        if(scope.listMap[dataC[i][scope.scValueField]]){
                                            dataC[i][select] = true;
                                        }else{
                                            dataC[i][select] = false;
                                        }
                                    }
                                    scope.scModelOptions[showList]=dataC;
                                    scope.scModelOptions[list]=dataC;
                                }
                            }
                        } else {
                            if(scope.hasTree){
                                scope.tree.data = [];
                            }else{
                                scope.scModelOptions[showList] = [];
                                scope.scModelOptions[list] = [];
                            }
                        }
                    }
                });
            };

            //获取值
            scope.getValue = function(data){
                // console.log('getValue');
                // if(angular.isArray(data) && data.length>0){
                // scope.scValue = undefined;
                //     scope.scValue=[];
                //     for(var i=0;i<data.length;i++){
                //         scope.scValue.push(data[i][attr.scValueField]);
                //     }
                // }else if(angular.isObject(data)){
                //     scope.scValue = undefined;
                //     scope.scValue = data[attr.scValueField];
                // }
            };

            //更新对象数组 值数组 多选下拉框的合并状态
            scope.updateStatus = function(mode, strict){
                if(mode == 'multiple'){
                    if (!scope.scModelOptions.showList) {
                        scope.scModelOptions.showList = [];
                    }
                    scope.len = scope.scModelOptions.showList.length;
                    scope.getCombine(scope.len);
                }
            };

            //获取自制tree map中某一元素的父节点对象
            scope.getParent = function(obj){
                var id = obj['treeId'].split('_');
                if(angular.isArray(id) && id.length>1){
                    var len = id.length;
                    id.splice(len-1,1);
                    if(id.length>1){
                        return id.join('_');
                    }else if(id.length==1){
                        return id[0];
                    }
                }else{
                    return undefined;
                }
            };

            //改变子节点状态
            // scope.changeChildren = function(obj){
            //     if(angular.isArray(obj[scope.scChildrenFiled]) && obj[scope.scChildrenFiled].length>0){
            //         //删除展示列表中的元素
            //         var children = obj[scope.scChildrenFiled];
            //         for(var i=0;i<children.length;i++){
            //             scope.updateSelected(children[i]);
            //             children[i]['checked'] = false;
            //             var indx = scope.scModelOptions.showList.indexOf(children[i]);
            //             if(indx>=0){
            //                 scope.scModelOptions.showList.splice(indx,1);
            //             }
            //             scope.changeChildren(children[i]);
            //         }
            //     }
            // };

            //改变父节点状态
            scope.changeParent = function(obj){
                if(obj[attr.parentId]){
                    //删除展示列表中的元素
                    var menuId = scope.getParent(obj);
                    var children = scope.treeMap[menuId][scope.scChildrenFiled];
                    for(var i=0;i<children.length;i++){
                        if(children[i]['checked']){
                            return;
                        }
                    }
                    var id = scope.getParent(obj);
                    scope.treeMap[id]['checked'] = false;
                    scope.updateSelected(scope.treeMap[id]);
                    scope.changeParent(scope.treeMap[id]);
                }
            };

            //更新默认选中的列表
            scope.updateSelected = function(obj){
                var sv = scope.scSelectedValues;
                for(var i=0;i<sv.length;i++){
                    if(sv[i] == obj[scope.scValueField]){
                        sv.splice(i,1);
                    }
                }
            };

            //如果是单选
            if(scope.scSelectMode=='single'){
                scope.selected = function(li){
                            if(li['singleSelected']){
                                scope.scValue = undefined;
                            }else{
                                scope.scValue = li[scope.scValueField];
                            }
                            $(element).find('.showUnSelected').slideUp();
                        };
                scope.confirmOperation = function(strict){
                            $(element).find('.showUnSelected').slideUp();
                        };
                scope.onSelection = function(node){
                            if(scope.scModelOptions.showList[0] && scope.scModelOptions.showList[0][scope.scValueField] == node[scope.scValueField]){
                                scope.scValue = undefined;
                                $timeout(function(){
                                    scope.scModelOptions.showList = [];
                                });
                            }else{
                                scope.scValue = node[scope.scValueField];
                            }
                            //树形选中将在upDataSelects完成，此处只做删除
                            $(element).find('.showUnSelected').slideUp();
                        };
            }

            //å¦‚æžœæ˜¯å¤šé€‰
            if(scope.scSelectMode=='multiple'){
                //获取选中的li个数，判断是否合并
                scope.getCombine = function(n){
                    var divW = element.find('.showSelected').width();
                    var allW = 0;
                    if(scope.isCombine){
                        if(n<=1){
                            scope.isCombine = false;
                            scope.isExpand = false;
                            $(element).find('.showLi').slideUp();
                        }else{
                            $timeout(function(){
                                element.find('.hasSelected').find('li').each(function(){
                                    allW = allW+$(this).children('span').text().length*12+25;
                                });
                                if(allW<divW){
                                    scope.isCombine = false;
                                    scope.isExpand = false;
                                    $(element).find('.showLi').slideUp();
                                }
                            });
                        }
                    }else{
                        if(n>1){
                            $timeout(function(){
                                element.find('.hasSelected').find('li').each(function(){
                                    allW = allW+$(this).children('span').text().length*12+25;
                                });
                                if(allW>divW){
                                    scope.isCombine = true;
                                }
                            });
                        }
                    }
                };

                //点击×删除对应的元素
                scope.del = function(obj, strict){
                            if(scope.hasTree){
                                $timeout(function(){
                                    var input = element.find('#'+obj[scope.scValueField]);
                                    if(input.length>0){
                                        input.click();
                                        scope.confirmOperation(strict);
                                    }else{
                                        var idx = scope.scModelOptions.showList.indexOf(obj);
                                        obj['checked'] = false;
                                        var ele = scope.scModelOptions.showList.splice(idx,1);
                                        scope.updateSelected(obj);//删除当前对象在默认选中列表中的值
                                        scope.changeParent(obj);//改变当前对象的子节点的选中状态

                                        //判断是否合并  并且  得到对象数组和值数组
                                        scope.updateStatus('multiple', strict);
                                    }
                                },0);
                            }else{
                                obj['multiSelected'] = false;
                                scope.changeStatus(obj, strict);
                            }
                        };

                //点击input，改变展示栏和下拉框的状态
                scope.changeStatus= function(obj, strict){
                    if (!scope.scValue) {
                        scope.scValue = [];
                    }
                    var idx = scope.scValue.indexOf(obj[scope.scValueField]);
                    if(obj['multiSelected'] && idx < 0){
                        scope.scValue.push(obj[scope.scValueField]);
                    }else if(!obj['multiSelected'] && idx >= 0){
                        scope.scValue.splice(idx,1);
                            }
                            // scope.updateStatus('multiple', strict);
                        };

                //点击<b class="caret"></b>展开ul
                scope.dropUl = function(e){
                    e.stopPropagation();
                    scope.isExpand = !scope.isExpand;
                    // $(element).find('.showUnSelected').hide();
                    $(element).find('.showLi').slideToggle();
                };
            }

            scope.getDataMap=function(data,scValue,select){
                if(angular.isArray(data) && data.length>0){
                            for(var i=0;i<data.length;i++){
                                var v = data[i][scope.scValueField];
                                scope.scDataMap[v] = data[i];

                                if(scValue.indexOf(v) < 0) {
                                    data[i][select] = false;
                                } else {
                                    data[i][select] = true;
                                }
                                if(angular.isArray(data[i][scope.scChildrenFiled]) && data[i][scope.scChildrenFiled].length>0){
                                    scope.getDataMap(data[i][scope.scChildrenFiled],scValue,select);
                                }
                            }
                        }
            };

            //判断是否有属性
            scope.hasAttr = function(o){
                for(var key in o){
                    return true;
                }
                return false;
            };

            //监听scData的变化
            $timeout(function() {
                var scDataUnwatch = scope.$watch('scData',function(newVal,oldVal){
                        scope.hasTree = false;
                        scope.scDataMap = {};
                        var select = scope.scSelectMode=='single'?'singleSelected':'multiSelected';

                        var scValue = scope.scValue?scope.scValue:[];
                        if (!angular.isArray(scValue)) {
                            scValue = [scValue];
                        }

                        if(angular.isArray(newVal) && newVal.length>0){
                            for(var i=0;i<newVal.length;i++){
                                var v = newVal[i][scope.scValueField];
                                scope.scDataMap[v] = newVal[i];

                                if(scValue.indexOf(v) < 0) {
                                    newVal[i][select] = false;
                                } else {
                                    newVal[i][select] = true;
                                }

                                if(angular.isArray(newVal[i][scope.scChildrenFiled]) && newVal[i][scope.scChildrenFiled].length>0){
                                    scope.hasTree = true;
                                    scope.getDataMap(newVal[i][scope.scChildrenFiled],scValue,select);
                                }
                            }
                        }

                        if(scope.hasTree){
                            scope.treeMap = {};//自定义一种id 将所有对象做成map 为前端过滤准备
                            scope.treeIdMap ={};//以传入的scValueFiled作为id 做成map
                            scope.scValueMap = {};//

                            scope.treeList = [];
                            scope.configTree(newVal,undefined,attr.scTreeCode);

                            var arr = [];
                            var value = angular.copy(scValue);
                            value = value?value:[];
                            for(var i=0;i<value.length;i++){
                                if(scope.scDataMap[value[i]]){
                                    arr.push(scope.scDataMap[value[i]][scope.scValueField]);
                                }
                            }

                            scope.tree =  new NgTree({
                                data :scope.scData,
                                selectedNodeIds:arr,//赋值之前先判断每个id值是否为最底层
                                config:{
                                    meta:{
                                        titleField : attr.scTitle?attr.scTitle:'title',
                                        parentIdField : attr.parentId?attr.parentId:'parentId',
                                        nodeIdField : attr.scValueField?attr.scValueField:'nodeId',
                                        childField : attr.scChildrenFiled?attr.scChildrenFiled:'children',
                                        needCheck: true
                                    }
                                }
                            });
                        }

                        //如果是单选
                        if(scope.scSelectMode=='single'){
                            if(scope.hasTree){
                                scope.tree.config.meta.needCheck = false;
                                // scope.scModelOptions.showList = [];
                                // var lis = scope.scValue;
                                // if(angular.isArray(lis) && lis.length>0){
                                //     if(!angular.isArray(scope.treeIdMap[lis[0]][scope.scChildrenFiled]) || !(scope.treeIdMap[lis[0]][scope.scChildrenFiled].length>0)){
                                //         scope.scModelOptions.showList.push(scope.treeIdMap[lis[0]]);
                                //         scope.tree.selectedNodeIds = lis;
                                //     }else{
                                //         scope.tree.selectedNodeIds = [];
                                //     }
                                // }else if(lis){
                                //     if(scope.treeIdMap[lis] && (!angular.isArray(scope.treeIdMap[lis][scope.scChildrenFiled]) || !(scope.treeIdMap[lis][scope.scChildrenFiled].length>0))){
                                //         scope.scModelOptions.showList.push(scope.treeIdMap[lis]);
                                //         scope.tree.selectedNodeIds = [lis];
                                //     }else{
                                //         scope.tree.selectedNodeIds = [];
                                //     }
                                // }
                                // scope.updateStatus('single');
                            }else{
                                //配置数据
                                scope.upDataSelects('singleSelected');
                            }
                        }

                        //如果是多选
                        if(scope.scSelectMode=='multiple'){
                            scope.isExpand = false;//判断已合并的对象是否需要展开，默认不展开
                            scope.isCombine = false; //判断是否合并所有被选中的对象
                            scope.num = 3;//最多展示已选中4个

                            if(scope.hasTree){
                                scope.scModelOptions.showList = [];
                                var list = scope.scValue?scope.scValue:[];
                                var len = list.length;
                                // if(attr.scTreeCode){
                                //     for(var i=len-1;i>=0;i--){
                                //         if(scope.scValueMap[list[i]] && angular.isArray(scope.scValueMap[list[i]][scope.scChildrenFiled]) && scope.scValueMap[list[i]][scope.scChildrenFiled].length>0){
                                //             list.splice(i,1);
                                //             continue;
                                //         }
                                //     }
                                // }else{
                                //     for(var i=len-1;i>=0;i--){
                                //         if(scope.treeIdMap[list[i]] && angular.isArray(scope.treeIdMap[list[i]][scope.scChildrenFiled]) && scope.treeIdMap[list[i]][scope.scChildrenFiled].length>0){
                                //             list.splice(i,1);
                                //             continue;
                                //         }
                                //     }
                                // }
                                scope.confirmOperation = function(strict){
                                    var list = scope.tree.getSelectedNodes();
                                    scope.getCombine(list.length);
                                    // scope.scModelOptions.showList = [];
                                    scope.scSelectedValues = [];
                                    scope.scValue = [];
                                    if(angular.isArray(list) && list.length>0){
                                        for(var i=0;i<list.length;i++){
                                            // if(angular.isArray(list[i][scope.scChildrenFiled]) && list[i][scope.scChildrenFiled].length>0){
                                                // continue;
                                            // }else{
                                                // scope.scModelOptions.showList.push(list[i]);
                                                var c = attr.scTreeCode?attr.scTreeCode:scope.scValueField;

                                                var v;
                                                if(list[i][c]){
                                                    v= list[i][c];
                                                }else if(list[i]['data'] && list[i]['data'][c]){
                                                    v= list[i]['data'][c];
                                                }
                                                scope.scValue.push(v);
                                            // }
                                        }

                                    }
                                    $(element).find('.showUnSelected').slideUp();
                                    scope.updateStatus('multiple', strict);
                                };
                            }else{
                                if(angular.isArray(scope.scSelectedValues)){
                                    scope.len = scope.scSelectedValues.length;//得到已选择的个数
                                }else{
                                    scope.len = 0;
                                }
                                if(angular.isArray(scope.scData)){
                                    scope.allLen = scope.scData.length;//得到所有个数
                                }else{
                                    scope.allLen = 0;
                                }
                                //配置数据
                                scope.upDataSelects('multiSelected');
                            }
                        }
                    });

                var scSelectedValuesUnwatch = scope.$watchCollection('scModelOptions.showList', function(newVal, oldVal) {
                        if (newVal) {
                            var item = newVal[newVal.length - 1];
                        }

                    scope.updateStatus(scope.scSelectMode);
                });

                var scValueUnwatch = undefined;
                if(scope.scSelectMode=='single'){
                    scValueUnwatch = scope.$watch('scValue', function(newVal, oldVal) {
                        if (!(oldVal === undefined && newVal === undefined)) {
                            var oldModel = scope.scModel;
                            var whenSelected = scope.upDataSelects('singleSelected');

                            whenSelected.then(function() {
                                if(angular.isObject(scope.scDataMap) && scope.hasAttr(scope.scDataMap) && scope.scDataMap[newVal]){
                                    scope.scDataMap[newVal]['singleSelected'] = true;
                                    if(oldVal!=newVal && scope.scDataMap[oldVal]){
                                        scope.scDataMap[oldVal]['singleSelected'] = false;
                                    }
                                }


                                if (newVal) {
                                    if(scope.scSelection && angular.isFunction(scope.scSelection)) {
                                        $timeout(function() {
                                            scope.scSelection(newVal);
                                        });
                                    }
                                }

                                if(scope.scSelectionN && angular.isFunction(scope.scSelectionN)) {
                                        scope.$selection = newVal;
                                        scope.$apply(scope.scSelectionN);
                                    }

                                if (scope.scChange && angular.isFunction(scope.scChange)) {
                                    $timeout(function() {
                                        scope.scChange(newVal, scope.scModel);
                                    });
                                }

                                if (scope.scChangeN && angular.isFunction(scope.scChangeN)) {
                                    scope.$model = scope.scModel;
                                    scope.$oldModel = oldModel;
                                    scope.$value = newVal;
                                    $timeout(function() {
                                        scope.$apply(scope.scChangeN);
                                    });
                                }

                                if(attr.scStrictValue) {
                                    scope.scStrictValue = newVal;
                                }
                            });
                        }
                    });
                }else {
                    scValueUnwatch = scope.$watchCollection('scValue', function(newVal, oldVal) {
                        if (!((oldVal?oldVal:[]).length === 0 && (newVal?newVal:[]).length === 0)) {
                            var oldModel = scope.scModel;
                            var whenSelected = scope.upDataSelects('multiSelected');
                            whenSelected.then(function() {
                                if(newVal && newVal.length > 0) {
                                    if(scope.scSelection && angular.isFunction(scope.scSelection)) {
                                            $timeout(function() {
                                                scope.scSelection(newVal[newVal.length - 1]);
                                            });
                                        }

                                    if(scope.scSelectionN && angular.isFunction(scope.scSelectionN)) {
                                            scope.$selection = newVal[newVal.length - 1];
                                            scope.$apply(scope.scSelectionN);
                                        }
                                }

                                if (scope.scChange && angular.isFunction(scope.scChange)) {
                                        $timeout(function() {
                                            scope.scChange(newVal, scope.scModel);
                                        });
                                    }

                                if (scope.scChangeN && angular.isFunction(scope.scChangeN)) {
                                        scope.$model = scope.scModel;
                                        scope.$oldModel = oldModel;
                                        scope.$value = newVal;
                                        $timeout(function() {
                                            scope.$apply(scope.scChangeN);
                                        });
                                    }

                                if(attr.scStrictValue) {
                                        scope.scStrictValue = newVal;
                                    }
                            });
                        }
                    });
                }
                var scParamsUnwatch = scope.$watch('scParams', function(newVal, oldVal) {
                    if (!(oldVal === undefined && newVal === undefined)) {
                          scope.scDataPromise = scope.getData();
                    }
                });
                scope.$on('$destroy', function() {
                    scDataUnwatch();
                    scValueUnwatch();
                    scParamsUnwatch();
                      scSelectedValuesUnwatch();
                });
            });

            // 判断判断是否只读
            var isDisabledPop = scope.$watch('scOnlyRead',function(newVal,oldVal){
                if(newVal){
                    element.find('.showSelected').attr('disabled','disabled');
                    scope.isShow = function(e){
                        e.stopPropagation();
                    };
                }else{
                    //是否展开下拉框
                    scope.isShow = function(e){
                    e.stopPropagation();
                    if(!scope.scDataPromise) {
                        $timeout(function() {
                            scope.scDataPromise = scope.getData();
                        });
                    }

                    $('.showUnSelected').not($(element).find('.showUnSelected')).hide();
                    $('.filterTable').hide();
                    $(element).find('.showLi').hide();
                    scope.isShowSelect = !scope.isShowSelect;
                    $(element).find('.showUnSelected').slideToggle();
                    };
                }

            });




            scope.clickUnSelected = function(){
                if(scope.isExpand){
                    scope.isExpand=false;
                    $(element).find('.showLi').slideUp();
                }
            };

            $(document).on('click',function(){
                $timeout(function(){
                    scope.isShowSelect = false;
                    scope.isExpand = false;
                    $(element).find('.showLi').slideUp();
                    $(element).find('.showUnSelected').slideUp();
                },0);
            });

            scope.scDataPromise = scope.getData();
        }
    }

//    弹出框
    angular.module('ngSelect').directive('scSelectPop', scSelectPop);
    scSelectPop.$inject = [ '$timeout','$localStorage','$sce','$http', '$httpParamSerializer'];
    function scSelectPop($timeout,$localStorage,$sce,$http, $httpParamSerializer){
        return{
            restrict:'EA',
            priority: 1009,
            template:function(elem,attr){
                var data = [];

                var tableTitle = "选择";
                if (attr.scTableTitle) {
                    tableTitle = attr.scTableTitle;
                }

                var html=
                    '<div class="scSelectPop">' +
                    // '<input type="text"  style="max-width:360px" class="form-control" value="test">'+
                    '<div class="form-control" ng-click="isShow($event)">' ;

                if(attr.scSelectMode=='multiple'){
                    html = html +'<div>'+
                        '<ul class="hasSelected" ng-click="$event.stopPropagation()" ng-show="!isCombine && len>0">' +
                        '<li ng-repeat="li in options.showList track by li[scValueField]" class="text-fit">' +
                        '<b ng-click="del(li, true)" ng-if="!scOnlyRead">×</b>'+
                        '<span ng-repeat="key in displayName track by key"><b ng-if="(displayName[0]!=key)&&(li[key])&&(li[displayName[$index-1]])">{{scDisplaySplitter}}</b>{{li[key]}}</span>' +
                        '</li>'+
                        '</ul>' +

                        '<span ng-show="len == 0">请选择下列选项</span>'+

                        '<div ng-show="isCombine"   ng-click="dropUl($event)" class="combineSelected">' +

                        '<span ng-if="len==allLen?false:true">{{len}}个选项已被选择&nbsp;<b class="caret dropUl"></b></span>'+
                        // '<span ng-if="len==allLen?true:false"   ng-click="dropUl($event)">所有选项已全部选中&nbsp;<b class="caret dropUl"></b></span>'+
                        '<ul class="showLi ulHeight" ng-click="$event.stopPropagation()">' +
                        '<li ng-repeat="li in options.showList track by li[scValueField]">' +
                        '<b ng-click="del(li, true)">×</b>'+
                        '<span ng-repeat="key in displayName track by key"><b ng-if="(displayName[0]!=key)&&(li[key])&&(li[displayName[$index-1]])">{{scDisplaySplitter}}</b>{{li[key]}}</span>' +
                        '</li>'+
                        '</ul>' +
                        '</div>'+
                        '<span class="glyphicon glyphicon-search"></span>'+
                        '</div>';
                }else{
                    html = html + '<div>' +

                        '<span  ng-show="options.showList.length>0?false:true">请选择下列选项</span>'+

                        '<b ng-show="options.showList.length>0 && !scOnlyRead" ng-click="del(options.showList[0], true);$event.stopPropagation()">×</b>'+

                        '<span ng-show="options.showList.length>0?true:false" ng-repeat="key in displayName track by key"><b ng-if="(displayName[0]!=key)&&(options.showList[0][key])&&(options.showList[0][displayName[$index-1]])">{{scDisplaySplitter}}</b>{{options.showList[0][key]}}</span>'+

                        '<span class="glyphicon glyphicon-search"></span>'+
                        '</div>';
                }

                var $elem = jQuery(elem);
                var $table = $elem.find('[sc-table]');
                $table.attr('sc-data-fn', 'getData');
                $table.attr('table-params', 'tableParams');

                var $selectAll = $elem.find('[title-component="sc-select-all"]');
                if ($selectAll) {
                    $selectAll.attr('data-component-config', "{'stSelectMode':'" + (attr.scSelectMode?attr.scSelectMode:'single') + "'}");
                }

                html = html + '</div>'+

                    '<div class="filterTable scSelectPopTable" ng-click="clickUnSelected();$event.stopPropagation()">'+
                    '<div class="chooseGoods" style="text-align:left">' +
                    '<button type="button" class="btn btn-info" ng-click="confirmChoose(true)">确 定</button>'+
                    '<button type="button" class="btn" ng-click="cancelChoose();$event.stopPropagation()">取 消</button>'+
                    '</div>'+
                    '<div style="position:relative">'+
                    $elem.html() +
                    '</div>'+
                   '</div></div>';
                return html;
            },
            scope:{
                scDataurl:'=?scDataurl',
                scModel:'=?scModel',
                scStrictModel:'=?scStrictModel',//使用者可以获取的选中的对象集合（在交互过程中才会赋值）
                scSelection:'=?scSelection',// 选择回调
                scSelectionN:'&?scSelectionN',// 选择回调
                scChange:'=?scChange', // 值变化回调
                scChangeN:'&?scChangeN', // 值变化回调
                scValue:'=?scValue',//使用者可以获取的选中的值的集合
                scParams:'=?scParams',//使用者可以绑定外部查询参数（用于联动）
                scWith:'=?scWith',
                scSelectedValues:'=?scSelectedValues',
                scOnlyRead:'=?scOnlyRead',
                scValueObj:'=?scValueObj',
                scValueFieldObj:'=?scValueFieldObj'
            },
            link: {pre: linkFn}
        };
        function linkFn(scope,element,attr,ctrl){
            scope.isUndefinedOrNull = function(val) {
                return angular.isUndefined(val) || val === null ;
            };

            scope.isDefinedOrZero = function(val){
                if(val!==0){
                    return !val;
                }else{
                    return false;
                }
            };
            scope.isExactSearch = false;
            scope.scValueField = attr.scValueField?attr.scValueField:'code';//标识每个对象唯一的字段 默认code
            if(angular.isArray(scope.scValueFieldObj) && scope.scValueFieldObj.length>0){
                var field = '';
                var len = scope.scValueFieldObj.length;
                for(var i=0;i<len;i++){
                    field+=scope.scValueFieldObj[i]+'_@';
                }
                scope.isExactSearch = true;
                scope.scValueField = field;
            }

                if(scope.scValueObj && angular.isObject(scope.scValueObj)){
                    var value = '';
                    for(var key in scope.scValueObj){
                        var str = $.trim(scope.scValueObj[key]);
                        value+=str?str:'';
                        value+='_@';
                    }
                    scope.scValue = value;
            }

            scope.data = [];
            scope.dataMap = {};

        // scope.scValueField = attr.scValueField?attr.scValueField:'code';//标识每个对象唯一的字段 默认code

        //展示的字段
        if(attr.scSelectedDisplayFields){
            scope.displayName = attr.scSelectedDisplayFields.split(',');
        }

        //选择的模式  默认单选
        if(!attr.scSelectMode){
            attr.scSelectMode = 'single';
        }else if(attr.scSelectMode == 'multiple'){
            if(scope.scValue && !angular.isArray(scope.scValue)){
                scope.scValue = [scope.scValue];
            }
        }

        //展示字段的分隔符  默认--
        if(!attr.scDisplaySplitter){
            scope.scDisplaySplitter = '--';
        }else{
            scope.scDisplaySplitter = attr.scDisplaySplitter;
        }

            //多选单选的公共属性
            scope.options = {};
            scope.options.showList = [];//展示已选中的对象
            scope.isShowSelect = false;//判断是否需要展开下拉框，默认不展开

            //判断对象是否为空
            scope.isEmptyObject = function(obj){
                for(var key in obj){
                    return false;
                }
                return true;
            };

            scope.selectedValues = [];//用于存储选中的对象的valueField值列表

            if(scope.scSelectedValues){
                var scSelectedValues = angular.copy(scope.scSelectedValues);
                if (!angular.isArray(scSelectedValues)) {
                    scSelectedValues = [scSelectedValues];
                }
                    //////////////////////////////
                if (scope.isDefinedOrZero(scope.scValue)) {
                    if (attr.scSelectMode == 'single') {
                        scope.scValue = angular.copy(scSelectedValues[0]);
                    }else{
                        scope.scValue = angular.copy(scSelectedValues);
                    }
                }
            }



            scope.showMode = function(m) {
                var sl = scope.scModelOptions.showList;
                var len = sl?sl.length:0;
                var mode = 'list';
                if (len > 0) {
                    if(scope.num && scope.num < len) {
                        mode = 'combin';
                    } else {
                        mode = 'selected';
                    }
                }
                return mode == m;
            };

            //获取选中的li个数，判断是否合并
            // $timeout(function(){
            //     scope.divW = element.children('.scSelectPop').children('.form-control').width();
            // });
            scope.len = 0;//初始化为0
            scope.getCombine = function(n){
                var divW = element.children('.scSelectPop').children('.form-control').width();
                var allW = 0;
                if(scope.isCombine){
                    if(n<=1){
                        scope.isCombine = false;
                        scope.isExpand = false;
                        $(element).find('.showLi').slideUp();
                    }else{
                        element.find('.combineSelected').find('li').each(function(){
                            allW = allW+$(this).children('span').text().length*12+23;
                        });
                        if(allW<divW){
                             scope.isCombine = false;
                            scope.isExpand = false;
                            $(element).find('.showLi').slideUp();
                        }
                    }
                }else{
                    if(n>1){
                        $timeout(function(){
                            element.find('.hasSelected').find('li').each(function(){
                                allW = allW+$(this).children('span').text().length*12+23;
                            });
                            if(allW>divW){
                                scope.isCombine = true;
                            }
                        });

                    }
                }
            };

            // scope.$watch(function(){
            //     return element.find('.hasSelected').find('li').length;
            // },function(newVal,oldVal){
            //     scope.getCombine(newVal);
            // });

            //点击<b class="caret"></b>展开ul
            scope.dropUl = function(e){
                e.stopPropagation();
                scope.isExpand = !scope.isExpand;
                $(element).find('.showLi').slideToggle();
            };

            // 请求
            scope.getData = function(inParams,isSingleRequest) {
                if(scope.scDataurl){
                    var params = inParams ? inParams : {};
                    if (scope.scParams) {
                        for(var key in scope.scParams){
                            if(scope.scParams[key]){
                                params[key] = scope.scParams[key];
                                inParams[key] = scope.scParams[key];
                            }
                        }
                        // params = angular.merge(params, scope.scParams);
                    }

                    var url = scope.scDataurl;
                    url = attr.scCode?(url+'/'+attr.scCode):(url);
                    var u = $httpParamSerializer(params);
                    if(url.indexOf('?')==-1){
                        url = url+'?callback=JSON_CALLBACK&token='+ $localStorage.authenticationToken+'&'+u;
                    }else{
                        url =url+'&callback=JSON_CALLBACK&token='+ $localStorage.authenticationToken+'&'+u;
                    }
                    $sce.trustAsResourceUrl(url);

                    var promise = new Promise(function(resolve, reject) {
                        $http.jsonp(url).success(function(data) {
                            if(data.status === 'SUCCESS') {
                                if (angular.isDefined(data.content) && angular.isObject(data.content) && (data.content.datas.length > 0)) {


                                    if(scope.isExactSearch){
                                        for(var i=0;i<data.content.datas.length;i++){
                                            var value = '';
                                            for(var j=0;j<scope.scValueFieldObj.length;j++){
                                                var str = $.trim(data.content.datas[i][scope.scValueFieldObj[j]]);
                                                value+=str?str:'';
                                                value+='_@';
                                            }
                                            data.content.datas[i][scope.scValueField] = value;
                                        }
                                    }

                                    if(!isSingleRequest){
                                        scope.data = data.content.datas;
                                        inParams.total = data.content.tc;

                                        if(attr.scSelectMode=="single" && (scope.scValue || scope.scValue==0)){
                                            for(var k=0;k<scope.data.length;k++){
                                            // if(scope.data[k][scope.scValueField] == scope.scValue){
                                            //     scope.data[k]['isSelected'] = true;
                                            // }else{
                                            //     scope.data[k]['isSelected'] = false;
                                            // }
                                                scope.data[k]['isSelected'] = (scope.data[k][scope.scValueField] == scope.scValue);
                                            }
                                        }

                                        if(attr.scSelectMode=="multiple" && angular.isArray(scope.scValue) && scope.scValue.length>0){
                                            for(var t=0;t<scope.data.length;t++){
                                                if(scope.scValue.indexOf(scope.data[t][scope.scValueField])>-1){
                                                    scope.data[t]['isSelected'] = true;
                                                }

                                            }
                                        }
                                    }

                                    resolve(data.content.datas);


                                } else {
                                    resolve([]);
                                    if(!isSingleRequest){
                                        scope.data = [];
                                        inParams.total = 0;
                                    }
                                }
                            }
                        });
                    });
                    scope.promise = promise;
                    return promise;
                }

                var promise = new Promise(function(resolve, reject) {
                    resolve([]);
                    scope.data = [];
                    inParams.total = 0;
                });
                scope.promise = promise;
                return promise;
            };

            //为多选时，一次性定义好的属性
            if (attr.scSelectMode == 'multiple') {
                    scope.isExpand = false; //判断已合并的对象是否需要展开，默认不展开
                    scope.isCombine = false; //判断是否合并所有被选中的对象
                    scope.num = 3; //最多展示已选中4个
                }

            //实例化
            scope.tableParams = {};

            //添加或删除scope.selectedValues数组中的对应的值
            scope.addValue = function(isAdd,value,mode){
                var list = scope.selectedValues;
                if(mode == 'multi'){
                    if(isAdd){
                        value&&list.push(value);
                    }else{
                        if(angular.isArray(list) && list.length>0){
                            for(var i=0;i<list.length;i++){
                                if(list[i] == value){
                                    list.splice(i,1);
                                    break;
                                }
                            }
                        }
                    }
                }else{
                    if(isAdd){
                        list[0] = value;
                    }else{
                        scope.selectedValues=[];
                    }
                }
            };

            scope.updateStatus = function() {
                    if(attr.scSelectMode=='multiple'){
                        scope.len = scope.options.showList.length;
                        scope.getCombine(scope.len);
                        }
                    };
            scope.updateModel = function() {
                        return new Promise(function(resolve) {
                        if (attr.scSelectMode=='multiple') {

                            if(scope.isUndefinedOrNull(scope.scValue)  || scope.scValue.length === 0) {
                                scope.scModel = [];
                                    resolve(scope.scModel);
                            } else {
                                var map = {};
                                scope.scModel = scope.scModel?scope.scModel:[];
                                jQuery.each(scope.scModel, function(midx, mdata) {
                                    map[mdata[scope.scValueField]] = 1;
                                });

                                var rmIdxes = [];
                                jQuery.each(scope.scModel, function(midx, mdata) {
                                    if (scope.scValue.indexOf(mdata[scope.scValueField]) < 0) {
                                        rmIdxes.push(midx);
                                    }
                                });

                                var addValues = [];
                                jQuery.each(scope.scValue, function(midx, mdata) {
                                    if (!map[mdata]) {
                                        addValues.push(mdata);
                                    }
                                });

                                for (var i = rmIdxes.length - 1; i>=0; i--) {
                                    scope.scModel.splice(rmIdxes[i], 1);
                                }
                                // var promise = scope.tableParams.reload();
                                // console.log(promise);
                                // var param = {pn:1, ps:10};
                                // var promise = scope.getData(param);
                                if(!scope.promise || !angular.isFunction(scope.promise.then)){
                                    scope.tableParams.reload();
                                }

                                jQuery.each(addValues, function(vidx, vdata) {
                                    if (scope.dataMap[vdata]) {
                                        scope.scModel.push(angular.copy(scope.dataMap[vdata]));
                                    } else {
                                        scope.promise.then(function(data){
                                            var resolved = false;
                                            var isExsit = false;//判断是否在当前请求到的数据中存在
                                            angular.forEach(data, function(item) {
                                                if (item[scope.scValueField] == vdata) {
                                                    scope.scModel.push(item);
                                                    isExsit = true;
                                                    resolve(scope.scModel);
                                                    resolved = true;
                                                }
                                            });
                                            // 如果不存在就手动组装一个对象
                                            if(!isExsit){
                                                var o = {};
                                                o[scope.displayName[0]] = vdata.length>0?vdata:'未知选项';
                                                o[scope.scValueField] = vdata;
                                                scope.scModel.push(o);
                                                resolve(scope.scModel);
                                                resolved = true;
                                            }
                                        });
                                    }
                                });

                                resolve(scope.scModel);
                            }
                        } else {
                            if (scope.isDefinedOrZero(scope.scValue)) {
                                scope.scModel = undefined;
                                    resolve(scope.scModel);
                            } else {
                                if(scope.dataMap[scope.scValue]) {
                                    scope.scModel = scope.dataMap[scope.scValue];
                                        resolve(scope.scModel);
                                } else {
                                        var param = {pn:1, ps:10};
                                        // param[scope.scValueField] = scope.scValue;

                                        if(scope.isExactSearch){
                                            var field = scope.scValueField.split('_@');
                                            var value = scope.scValue.split('_@');
                                            for(var i=0;i<field.length;i++){
                                                if(field[i]){
                                                    param[field[i]] = value[i];
                                                }
                                            }
                                            param['isExactSearch'] = scope.isExactSearch;
                                        }else{
                                            param[scope.scValueField] = scope.scValue;
                                        }


                                        var promise = scope.getData(param,true);
                                        promise.then(function(data) {
                                            var resolved = false;
                                            angular.forEach(data, function(item) {
                                                if (item[scope.scValueField] == scope.scValue) {
                                                    scope.scModel = item;
                                                    resolve(scope.scModel);
                                                    resolved = true;
                                                }
                                            });

                                            if (!resolved) {
                                                var o = {};
                                                o[scope.displayName[0]] = scope.scValue;
                                                o[scope.scValueField] = scope.scValue;
                                                scope.scModel = o;
                                                resolve(scope.scModel);
                                            }
                                        }, function() {
                                            var o = {};
                                            o[scope.displayName[0]] = scope.scValue;
                                            o[scope.scValueField] = scope.scValue;
                                            scope.scModel = o;
                                            resolve(scope.scModel);
                                        });
                            }
                        }
                            }
                        });
                    };

            if (attr.scSelectMode == 'multiple') {
                //确认商品选择
                scope.confirmChoose =  function(strict){
                    if(angular.isFunction(scope.tableParams.getSelected)){
                                if(scope.isUndefinedOrNull(scope.scValue)) {
                                    scope.scValue = [];
                                }

                                var d = scope.tableParams.getSelected();
                                var scValue = scope.scValue;
                                var list = scope.options.showList;
                                var value = null;
                                var isAdd = true;
                                angular.forEach(scope.data, function(tdata, tidx) {
                                    var idx = scValue.indexOf(tdata[scope.scValueField]);

                                    var selected = tdata['isSelected'];
                                    if (selected && idx < 0) {
                                        scValue.push(tdata[scope.scValueField]);
                                    } else if(!selected && idx >= 0) {
                                        scValue.splice(idx, 1);
                                        }
                                });
                                    }

                    if(scope.scSelection && angular.isFunction(scope.scSelection)) {
                                    scope.scSelection(angular.copy(d));
                            }

                    if(scope.scSelectionN && angular.isFunction(scope.scSelectionN)) {
                                scope.$selection = angular.copy(d);
                                scope.$apply(scope.scSelectionN);
                            }

                    // if(strict) {
                    //                 scope.updateModel();
                    //             scope.scStrictModel = scope.scModel;
                    // }

                    $('.filterTable').slideUp();
                };

                //点击×删除对应的元素
                scope.del = function(obj, strict){
                            obj['isSelected'] = false;
                            var scValue = scope.scValue;
                            var idx = scValue.indexOf(obj[scope.scValueField]);
                            if (idx >= 0) {
                                scValue.splice(idx, 1);
                            }

                            // if(strict) {
                            //     scope.updateModel();
                            //     scope.scStrictModel = scope.scModel;
                            // }
                        };
            }

            if(attr.scSelectMode=='single'){
                        //确认商品选择
                        scope.confirmChoose =  function(strict){
                            if(angular.isFunction(scope.tableParams.getSelected)){
                                var d = scope.tableParams.getSelected();
                                if (d && d.length > 0) {
                                    scope.scValue = angular.copy(d[0][scope.scValueField]);
                                } else {
                                    scope.scValue = undefined;
                                }
                            }

                            if(scope.scSelection && angular.isFunction(scope.scSelection)) {
                                scope.scSelection(angular.copy(d[0]));
                            }
                            if(scope.scSelectionN && angular.isFunction(scope.scSelectionN)) {
                                scope.$selection = angular.copy(d[0]);
                                scope.$apply(scope.scSelectionN);
                            }
                            // if(strict) {
                            //     scope.updateModel();
                            //     scope.scStrictModel = scope.scModel;
                            // }

                            $('.filterTable').slideUp();
                        };

                        //点击×删除对应的元素
                        scope.del = function(obj, strict){
                            obj['isSelected'] = false;
                            for(var i=0;i<scope.data.length;i++){
                                if(scope.data[i][scope.scValueField] == obj[scope.scValueField]){
                                    scope.data[i]['isSelected'] = false;
                                }
                            }
                            scope.scValue = undefined;
                            // if(strict) {
                            //     scope.updateModel();
                            //     scope.scStrictModel = scope.scModel;
                            // }
                        };
                    }

            scope.updateTableStatus = function() {
                        var values = scope.options.showList;
                        var map = {};
                        jQuery.each(values, function(vidx, vdata) {
                            map[vdata[scope.scValueField]] = vdata['isSelected'];
                        });
                        jQuery.each(scope.data, function(didx, ddata) {
                            if(map[ddata[scope.scValueField]]) {
                                ddata['isSelected'] = true;
                            } else {
                                ddata['isSelected'] = false;
                            }
                        });
                    };

            // 监听
            $timeout(function() {
                function getArray(val) {
                            if (val === undefined||val===null) {
                                return [];
                            }
                            return val;
                        }
                var dataUnwatch = scope.$watchCollection('data', function(newVal, oldVal) {
                            if (!(newVal.length === 0 && oldVal.length === 0)) {
                                scope.dataMap = {};

                                if (newVal && !scope.isUndefinedOrNull(newVal)) {
                                    jQuery.each(newVal, function(idx, v) {
                                        scope.dataMap[v[scope.scValueField]] = v;
                                    });
                                }

                                // scope.updateTableStatus();
                            }
                });

                var scParamsUnwatch = scope.$watch('scParams', function (newVal, oldVal) {
                    if (!(newVal === undefined && oldVal === undefined)) {
                        scope.tableParams.reload();
                    }
                });

                var showListUnwatch = scope.$watchCollection('options.showList', function(newVal, oldVal) {
                            if (!(newVal.length === 0 && oldVal.length === 0)) {
                                scope.updateTableStatus();
                                    scope.updateStatus();
                            }
                        });

                var scValueUnwatch = undefined;
                var scModelUnwatch = undefined;
                if (attr.scSelectMode == 'single') {
                            scModelUnwatch = scope.$watch('scModel', function(newVal, oldVal) {
                                if (!(oldVal === undefined && newVal === undefined)) {
                                    scope.options.showList = [];
                                    if (newVal && !scope.isDefinedOrZero(newVal[scope.scValueField])) {
                                        if(oldVal) {
                                            oldVal['isSelected'] = false;
                                        }
                                        newVal['isSelected'] = true;
                                        scope.options.showList.push(newVal);
                                        scope.scStrictModel = angular.copy(newVal);
                                    }
                                }
                            });

                            scValueUnwatch = scope.$watch('scValue', function(newVal, oldVal) {
                                if (!(oldVal === undefined && newVal === undefined)) {
                                    var oldModel = scope.scModel;
                                    var promise = scope.updateModel();

                                    promise.then(function() {
                                    if (scope.scChange && angular.isFunction(scope.scChange)) {
                                        $timeout(function() {
                                            scope.scChange(newVal, scope.scModel);
                                        });
                                    }

                                    if (scope.scChangeN && angular.isFunction(scope.scChangeN)) {
                                        scope.$model = scope.scModel;
                                        scope.$oldModel = oldModel;
                                        scope.$value = newVal;
                                        $timeout(function() {
                                            scope.$apply(scope.scChangeN);
                                        });
                                    }
                                    });
                                }
                            });
                        } else {
                            scModelUnwatch = scope.$watchCollection('scModel', function(newVal, oldVal) {
                                if (!(getArray(newVal).length === 0 && getArray(oldVal).length === 0)) {
                                    scope.options.showList = [];
                                    scope.scStrictModel = [];
                                    if (newVal && newVal.length > 0) {
                                        for(var i=0;i<newVal.length;i++){
                                            newVal[i]['isSelected'] = true;
                                        }
                                        scope.options.showList = newVal;
                                        scope.scStrictModel = angular.copy(newVal);
                                    }

                                    if (scope.scChange && angular.isFunction(scope.scChange)) {
                                        $timeout(function() {
                                            scope.scChange(newVal, scope.scModel);
                                        });
                                    }

                                    if (scope.scChangeN && angular.isFunction(scope.scChangeN)) {
                                        scope.$model = scope.scModel;
                                        scope.$value = newVal;
                                        $timeout(function() {
                                            scope.$apply(scope.scChangeN);
                                        });
                                    }
                                }
                            });

                            scValueUnwatch = scope.$watchCollection('scValue', function(newVal, oldVal) {
                                if (!(getArray(newVal).length === 0 && getArray(oldVal).length === 0)) {
                                    scope.updateModel();
                                }
                                            });
                                }
                scope.$on('$destroy', function() {
                    dataUnwatch();
                    scParamsUnwatch();
                            if (scModelUnwatch) {
                                scModelUnwatch();
                            }
                            if (scValueUnwatch) {
                                scValueUnwatch();
                            }
                            showListUnwatch();
                });
            });

            scope.clickUnSelected = function(){
                if(scope.isExpand){
                    scope.isExpand=false;
                    $(element).find('.showLi').slideUp();
                }
            };

            scope.cancelChoose = function(){
                if(scope.data && angular.isArray(scope.data)) {
                    jQuery.each(scope.data, function(idx, d) {
                        if (scope.scValue) {
                            if (angular.isArray(scope.scValue) && scope.scValue.indexOf(d[scope.scValueField]) >= 0) {
                                d['isSelected'] = true;
                            } else if(!angular.isArray(scope.scValue) && scope.scValue == d[scope.scValueField]) {
                                d['isSelected'] = true;
                            } else {
                                d['isSelected'] = false;
                            }
                        } else {
                            d['isSelected'] = false;
                        }
                    });
                }

                scope.isShowSelect = false;
                $(element).find('.filterTable').slideUp();
            };

            //监听是否禁用
            var isDisabledPop = scope.$watch('scOnlyRead',function(newVal,oldVal){
                if(newVal){
                    element.find('.scSelectPop').children('.form-control').attr('disabled','disabled');
                    scope.isShow = function(e){
                        e.stopPropagation();
                    };
                }else{
                    element.find('.scSelectPop').children('.form-control').removeAttr('disabled');
                    scope.isShow = function(e){
                        e.stopPropagation();
                        $('.filterTable').not($(element).find('.filterTable')).hide();

                        scope.isShowSelect = !scope.isShowSelect;
                        $(element).find('.filterTable').slideToggle();

                        // scope.tableParams.reload();
                    };
                }
            });

            //如果当前弹出框在uib-modal中，设置最大宽度为100%，不超出模态框
            var div= element.find('.filterTable.scSelectPopTable');
            if(div.parents('.modal-body')[0]){
                div.css('max-width','100%');
            }

            $(document).on('click',function(){
                $timeout(function(){
                    scope.isShowSelect = false;
                    scope.isExpand = false;
                    $(element).find('.showLi').slideUp();
                    $(element).find('.filterTable').slideUp();
                },0);
            });

            scope.changeX = function(){
                var table = $(element).find('.scSelectPop');
                var left = table.offset().left;
                var w = window.innerWidth;
                if((w-left)<840){
                    $(element).find('.filterTable').css('left',-840+w-left);
                }else{
                    $(element).find('.filterTable').css('left',0);
                }
            };
            $timeout(function(){
                scope.changeX();
            },0);
            $(window).resize(function(){
                scope.changeX();
            });
        }
    }

    //分配规则
    angular.module('ngSelect').directive('scSelectPopAllocRule',scSelectPopAllocRule);
    scSelectPopAllocRule.$inject=['$timeout','NgSelect','iServiceConfig'];
    function scSelectPopAllocRule($timeout,NgSelect,iServiceConfig){
        return{
            restrict:'EA',
            priority: 1009,
            template:function(elem,attr){
                var html =
                        '<div sc-select-pop sc-dataurl="\''+iServiceConfig.iwhStrategyHost+'/web/alloc-rule/rest\'"'+
                        ' sc-selected-display-fields="name"'+
                        ' sc-value-field="code"'
                    ;
                if(attr.scSelectMode){
                    html = html+' sc-select-mode="'+attr.scSelectMode+'"';
                }
                if(attr.scSelection){
                    html = html+' sc-selection="'+attr.scSelection+'"';
                }
                if(attr.scChange){
                    html = html+' sc-change="'+attr.scChange+'"';
                }
                if(attr.scChangeN){
                    html = html+' sc-change-n="'+attr.scChangeN+'"';
                }
                if(attr.scModel){
                    html = html+' sc-model="'+attr.scModel+'"';
                }
                if(attr.scStrictModel){
                    html = html+' sc-strict-model="'+attr.scStrictModel+'"';
                }
                if(attr.scParams){
                    html = html+' sc-params="'+attr.scParams+'"';
                }
                if(attr.scValue){
                    html = html+' sc-value="'+attr.scValue+'"';
                }
                if(attr.scWith){
                    html=html+' sc-with="'+attr.scWith+'"';
                }
                if(attr.scCode){
                    html=html+' sc-code="'+attr.scCode+'"';
                }
                if(attr.scSelectedValues){
                    html = html+' sc-select-values="'+attr.scSelectedValues+'"';
                }
                if(attr.scOnlyRead){
                    html = html+' sc-only-read="'+attr.scOnlyRead+'"';
                }
                html = html+'>' ;
                var div = '<div sc-table show-filter=\'true\'';
                // if(attr.scFixedCol == ''){
                var fixedTailCounts=1,fixedFrontCounts=1;
                if(attr.scFixedTailCounts || attr.scFixedTailCounts == 0){
                    fixedTailCounts = parseInt(attr.scFixedTailCounts);
                }
                if(attr.scFixedFrontCounts || attr.scFixedFrontCounts == 0){
                    fixedFrontCounts = parseInt(attr.scFixedFrontCounts);
                }
                div+= ' sc-pop-fixed-col sc-fixed-front-counts="'+fixedFrontCounts+'" sc-fixed-tail-counts="'+fixedTailCounts+'"';
                // }
                if(attr.scPopTableDrag == ''){
                    div+=' sc-pop-table-drag';
                }
                if(attr.scPopTableDragWidth == ''){
                    div+=' sc-pop-table-drag-width';
                }
                div+='>';
                html+=div+
                    '<div row-define>' +
                    '<div column-define title-component="sc-select-all" data-component="sc-select-row=\'row\'"></div>' +
                    '<div column-define data-title="编码" data-filter="code"><span>{{row.code}}</span></div>' +
                    '<div column-define data-title="名称" data-filter="name"><span>{{row.name}}</span></div>' +
                    '<div column-define data-title="预分配规则" data-filter="preallocateRule"><span>{{row.preallocateRule}}</span></div>' +
                    '<div column-define data-title="创建人"><span>{{row.entryName}}</span></div>' +
                    '<div column-define data-title="创建时间"><span>{{row.entryDt}}</span></div>' +
                    '<div column-define data-title="更新人"><span>{{row.updateName}}</span></div>' +
                    '<div column-define data-title="更新时间"><span>{{row.updateDt}}</span></div>' +
                    '</div></div>';
                return html;
            },
            controller:['$scope',function($scope) {
            }],
            link:linkFn
        };
        function linkFn(scope,element,attrs,ctrl){
            scope.filterConfig = "{'data':[{'id':1,'title':'JasperReport'},{'id':2,'title':'Birt'},{'id':3,'title':'自定义'}],'scValueField':'id','title':'title'}";
        }
    }

    //拣选单规则
    angular.module('ngSelect').directive('scSelectPopPkoRule',scSelectPopPkoRule);
    scSelectPopPkoRule.$inject=['$timeout','NgSelect','iServiceConfig'];
    function scSelectPopPkoRule($timeout,NgSelect,iServiceConfig){
        return{
            restrict:'EA',
            priority: 1009,
            template:function(elem,attr){
                var html =
                        '<div sc-select-pop sc-dataurl="\''+iServiceConfig.iwhStrategyHost+'/web/pko-rule/rest\'"'+
                        ' sc-selected-display-fields="name"'+
                        ' sc-value-field="code"'
                    ;
                if(attr.scSelectMode){
                    html = html+' sc-select-mode="'+attr.scSelectMode+'"';
                }
                if(attr.scSelection){
                    html = html+' sc-selection="'+attr.scSelection+'"';
                }
                if(attr.scChange){
                    html = html+' sc-change="'+attr.scChange+'"';
                }
                if(attr.scChangeN){
                    html = html+' sc-change-n="'+attr.scChangeN+'"';
                }
                if(attr.scModel){
                    html = html+' sc-model="'+attr.scModel+'"';
                }
                if(attr.scStrictModel){
                    html = html+' sc-strict-model="'+attr.scStrictModel+'"';
                }
                if(attr.scParams){
                    html = html+' sc-params="'+attr.scParams+'"';
                }
                if(attr.scValue){
                    html = html+' sc-value="'+attr.scValue+'"';
                }
                if(attr.scWith){
                    html=html+' sc-with="'+attr.scWith+'"';
                }
                if(attr.scCode){
                    html=html+' sc-code="'+attr.scCode+'"';
                }
                if(attr.scSelectedValues){
                    html = html+' sc-select-values="'+attr.scSelectedValues+'"';
                }
                if(attr.scOnlyRead){
                    html = html+' sc-only-read="'+attr.scOnlyRead+'"';
                }
                html = html+'>' ;
                var div = '<div sc-table show-filter=\'true\'';
                // if(attr.scFixedCol == ''){
                var fixedTailCounts=1,fixedFrontCounts=1;
                if(attr.scFixedTailCounts || attr.scFixedTailCounts == 0){
                    fixedTailCounts = parseInt(attr.scFixedTailCounts);
                }
                if(attr.scFixedFrontCounts || attr.scFixedFrontCounts == 0){
                    fixedFrontCounts = parseInt(attr.scFixedFrontCounts);
                }
                div+= ' sc-pop-fixed-col sc-fixed-front-counts="'+fixedFrontCounts+'" sc-fixed-tail-counts="'+fixedTailCounts+'"';
                // }
                if(attr.scPopTableDrag == ''){
                    div+=' sc-pop-table-drag';
                }
                if(attr.scPopTableDragWidth == ''){
                    div+=' sc-pop-table-drag-width';
                }
                div+='>';
                html+=div+
                    '<div row-define>' +
                    '<div column-define title-component="sc-select-all" data-component="sc-select-row=\'row\'"></div>' +
                    '<div column-define data-title="编码" data-filter="code"><span>{{row.code}}</span></div>' +
                    '<div column-define data-title="名称" data-filter="name"><span>{{row.name}}</span></div>' +
                    '<div column-define data-title="预分配规则" data-filter="preallocateRule"><span>{{row.preallocateRule}}</span></div>' +
                    '<div column-define data-title="创建人"><span>{{row.entryName}}</span></div>' +
                    '<div column-define data-title="创建时间"><span>{{row.entryDt}}</span></div>' +
                    '<div column-define data-title="更新人"><span>{{row.updateName}}</span></div>' +
                    '<div column-define data-title="更新时间"><span>{{row.updateDt}}</span></div>' +
                    '</div></div>';
                return html;
            },
            controller:['$scope',function($scope) {
            }],
            link:linkFn
        };
        function linkFn(scope,element,attrs,ctrl){
            scope.filterConfig = "{'data':[{'id':1,'title':'JasperReport'},{'id':2,'title':'Birt'},{'id':3,'title':'自定义'}],'scValueField':'id','title':'title'}";
        }
    }

    //打印规则
    angular.module('ngSelect').directive('scSelectPopPrintRule',scSelectPopPrintRule);
    scSelectPopPrintRule.$inject=['$timeout','NgSelect','iServiceConfig'];
    function scSelectPopPrintRule($timeout,NgSelect,iServiceConfig){
        return{
            restrict:'EA',
            priority: 1009,
            template:function(elem,attr){
                var html =
                        '<div sc-select-pop sc-dataurl="\''+iServiceConfig.iwhStrategyHost+'/web/print-rule/rest\'"'+
                        ' sc-selected-display-fields="name"'+
                        ' sc-value-field="code"'
                    ;
                if(attr.scSelectMode){
                    html = html+' sc-select-mode="'+attr.scSelectMode+'"';
                }
                if(attr.scSelection){
                    html = html+' sc-selection="'+attr.scSelection+'"';
                }
                if(attr.scChange){
                    html = html+' sc-change="'+attr.scChange+'"';
                }
                if(attr.scChangeN){
                    html = html+' sc-change-n="'+attr.scChangeN+'"';
                }
                if(attr.scModel){
                    html = html+' sc-model="'+attr.scModel+'"';
                }
                if(attr.scStrictModel){
                    html = html+' sc-strict-model="'+attr.scStrictModel+'"';
                }
                if(attr.scParams){
                    html = html+' sc-params="'+attr.scParams+'"';
                }
                if(attr.scValue){
                    html = html+' sc-value="'+attr.scValue+'"';
                }
                if(attr.scWith){
                    html=html+' sc-with="'+attr.scWith+'"';
                }
                if(attr.scCode){
                    html=html+' sc-code="'+attr.scCode+'"';
                }
                if(attr.scSelectedValues){
                    html = html+' sc-select-values="'+attr.scSelectedValues+'"';
                }
                if(attr.scOnlyRead){
                    html = html+' sc-only-read="'+attr.scOnlyRead+'"';
                }
                html = html+'>' ;
                var div = '<div sc-table show-filter=\'true\'';
                // if(attr.scFixedCol == ''){
                var fixedTailCounts=1,fixedFrontCounts=1;
                if(attr.scFixedTailCounts || attr.scFixedTailCounts == 0){
                    fixedTailCounts = parseInt(attr.scFixedTailCounts);
                }
                if(attr.scFixedFrontCounts || attr.scFixedFrontCounts == 0){
                    fixedFrontCounts = parseInt(attr.scFixedFrontCounts);
                }
                div+= ' sc-pop-fixed-col sc-fixed-front-counts="'+fixedFrontCounts+'" sc-fixed-tail-counts="'+fixedTailCounts+'"';
                // }
                if(attr.scPopTableDrag == ''){
                    div+=' sc-pop-table-drag';
                }
                if(attr.scPopTableDragWidth == ''){
                    div+=' sc-pop-table-drag-width';
                }
                div+='>';
                html+=div+
                    '<div row-define>' +
                    '<div column-define title-component="sc-select-all" data-component="sc-select-row=\'row\'"></div>' +
                    '<div column-define data-title="编码" data-filter="code"><span>{{row.code}}</span></div>' +
                    '<div column-define data-title="名称" data-filter="name"><span>{{row.name}}</span></div>' +
                    '<div column-define data-title="预分配规则" data-filter="preallocateRule"><span>{{row.preallocateRule}}</span></div>' +
                    '<div column-define data-title="创建人"><span>{{row.entryName}}</span></div>' +
                    '<div column-define data-title="创建时间"><span>{{row.entryDt}}</span></div>' +
                    '<div column-define data-title="更新人"><span>{{row.updateName}}</span></div>' +
                    '<div column-define data-title="更新时间"><span>{{row.updateDt}}</span></div>' +
                    '</div></div>';
                return html;
            },
            controller:['$scope',function($scope) {
            }],
            link:linkFn
        };
        function linkFn(scope,element,attrs,ctrl){
            scope.filterConfig = "{'data':[{'id':1,'title':'JasperReport'},{'id':2,'title':'Birt'},{'id':3,'title':'自定义'}],'scValueField':'id','title':'title'}";
        }
    }

    //租户
    angular.module('ngSelect').directive('scSelectPopTenant',scSelectPopTenant);
    scSelectPopTenant.$inject=['$timeout','NgSelect','iServiceConfig'];
    function scSelectPopTenant($timeout,NgSelect,iServiceConfig){
        return{
            restrict:'EA',
            priority: 1009,
            template:function(elem,attr){
                var html =
                    '<div sc-select-pop sc-dataurl="\''+iServiceConfig.iuserHost+'/web/tenantRest/list\'"'+
                    ' sc-selected-display-fields="companyName"'+
                    ' sc-value-field="tenantId"'
                ;
                if(attr.scSelectMode){
                    html = html+' sc-select-mode="'+attr.scSelectMode+'"';
                }
                if(attr.scSelection){
                    html = html+' sc-selection="'+attr.scSelection+'"';
                }
                if(attr.scChange){
                    html = html+' sc-change="'+attr.scChange+'"';
                }
                if(attr.scChangeN){
                    html = html+' sc-change-n="'+attr.scChangeN+'"';
                }
                if(attr.scModel){
                    html = html+' sc-model="'+attr.scModel+'"';
                }
                if(attr.scStrictModel){
                    html = html+' sc-strict-model="'+attr.scStrictModel+'"';
                }
                if(attr.scParams){
                    html = html+' sc-params="'+attr.scParams+'"';
                }
                if(attr.scValue){
                    html = html+' sc-value="'+attr.scValue+'"';
                }
                if(attr.scWith){
                    html=html+' sc-with="'+attr.scWith+'"';
                }
                if(attr.scCode){
                    html=html+' sc-code="'+attr.scCode+'"';
                }
                if(attr.scSelectedValues){
                    html = html+' sc-select-values="'+attr.scSelectedValues+'"';
                }
                if(attr.scOnlyRead){
                    html = html+' sc-only-read="'+attr.scOnlyRead+'"';
                }
                html = html+'>' ;
                var div = '<div sc-table show-filter=\'true\'';
                // if(attr.scFixedCol == ''){
                    var fixedTailCounts=1,fixedFrontCounts=1;
                    if(attr.scFixedTailCounts || attr.scFixedTailCounts == 0){
                        fixedTailCounts = parseInt(attr.scFixedTailCounts);
                    }
                    if(attr.scFixedFrontCounts || attr.scFixedFrontCounts == 0){
                        fixedFrontCounts = parseInt(attr.scFixedFrontCounts);
                    }
                    div+= ' sc-pop-fixed-col sc-fixed-front-counts="'+fixedFrontCounts+'" sc-fixed-tail-counts="'+fixedTailCounts+'"';
                // }
                if(attr.scPopTableDrag == ''){
                    div+=' sc-pop-table-drag';
                }
                if(attr.scPopTableDragWidth == ''){
                    div+=' sc-pop-table-drag-width';
                }
                div+='>';
                html+=div+
                    '<div row-define>' +
                    '<div column-define title-component="sc-select-all" data-component="sc-select-row=\'row\'"></div>' +
                    '<div column-define data-title="租户ID" data-filter="tenantId"><span>{{row.tenantId}}</span></div>' +
                    '<div column-define data-title="租户编码" data-filter="tenantCode"><span>{{row.tenantCode}}</span></div>' +
                    '<div column-define data-title="类型" data-filter="companyName"><span>{{row.companyName}}</span></div>' +
                    '<div column-define data-title="公司地址" data-filter="companyAddr"><span>{{row.companyAddr}}</span></div>' +
                    '<div column-define data-title="联系人" data-filter="contactName"><span>{{row.contactName}}</span></div>' +
                    '<div column-define data-title="联系方式" data-filter="contactMobile"><span>{{row.contactMobile}}</span></div>' +
                    '</div></div>';
                return html;
            },
            controller:['$scope',function($scope) {
            }],
            link:linkFn
        };
        function linkFn(scope,element,attrs,ctrl){
            scope.filterConfig = "{'data':[{'id':1,'title':'JasperReport'},{'id':2,'title':'Birt'},{'id':3,'title':'自定义'}],'scValueField':'id','title':'title'}";
        }
    }

    //IreportReport
    angular.module('ngSelect').directive('scSelectPopIreportReport',scSelectPopIreportReport);
    scSelectPopIreportReport.$inject=['$timeout','NgSelect','iServiceConfig'];
    function scSelectPopIreportReport($timeout,NgSelect,iServiceConfig){
        return{
            restrict:'EA',
            priority: 1009,
            template:function(elem,attr){
                var html =
                        '<div sc-select-pop sc-dataurl="\''+iServiceConfig.ireportHost+'/web/reportRest/list\'"'+
                        ' sc-selected-display-fields="name"'+
                        ' sc-value-field="code"'
                    ;
                if(attr.scSelectMode){
                    html = html+' sc-select-mode="'+attr.scSelectMode+'"';
                }
                if(attr.scSelection){
                    html = html+' sc-selection="'+attr.scSelection+'"';
                }
                if(attr.scChange){
                    html = html+' sc-change="'+attr.scChange+'"';
                }
                if(attr.scChangeN){
                    html = html+' sc-change-n="'+attr.scChangeN+'"';
                }
                if(attr.scModel){
                    html = html+' sc-model="'+attr.scModel+'"';
                }
                if(attr.scStrictModel){
                    html = html+' sc-strict-model="'+attr.scStrictModel+'"';
                }
                if(attr.scParams){
                    html = html+' sc-params="'+attr.scParams+'"';
                }
                if(attr.scValue){
                    html = html+' sc-value="'+attr.scValue+'"';
                }
                if(attr.scWith){
                    html=html+' sc-with="'+attr.scWith+'"';
                }
                if(attr.scCode){
                    html=html+' sc-code="'+attr.scCode+'"';
                }
                if(attr.scSelectedValues){
                    html = html+' sc-select-values="'+attr.scSelectedValues+'"';
                }
                if(attr.scOnlyRead){
                    html = html+' sc-only-read="'+attr.scOnlyRead+'"';
                }
                html = html+'>' ;
                var div = '<div sc-table show-filter=\'true\'';
                // if(attr.scPopFixedCol == ''){
                    var fixedTailCounts=1,fixedFrontCounts=1;
                    if(attr.scFixedTailCounts || attr.scFixedTailCounts == 0){
                        fixedTailCounts = parseInt(attr.scFixedTailCounts);
                    }
                    if(attr.scFixedFrontCounts || attr.scFixedFrontCounts == 0){
                        fixedFrontCounts = parseInt(attr.scFixedFrontCounts);
                    }
                    div+= ' sc-pop-fixed-col sc-fixed-front-counts="'+fixedFrontCounts+'" sc-fixed-tail-counts="'+fixedTailCounts+'"';
                // }
                if(attr.scPopTableDrag == ''){
                    div+=' sc-pop-table-drag';
                }
                if(attr.scPopTableDragWidth == ''){
                    div+=' sc-pop-table-drag-width';
                }
                div+='>';
                html+=div+
                    '<div row-define>' +
                    '<div column-define title-component="sc-select-all" data-component="sc-select-row=\'row\'"></div>' +
                    '<div column-define data-title="名称" data-filter="name"><span>{{row.name}}</span></div>' +
                    '<div column-define data-title="编码" data-filter="code"><span>{{row.code}}</span></div>' +
                    '<div column-define data-title="类型" data-filter="type" filter-component="select" filter-component-config="{\'data\':[{\'type\':1,\'title\':\'JasperReport\'},{\'type\':2,\'title\':\'Birt Report\'},{\'type\':3,\'title\':\'自定义\'}],\'scValueField\':\'type\',\'title\':\'title\'}"><span>{{row.typeName}}</span></div>' +
                    '<div column-define data-title="数据源类型" data-filter="dsType"  filter-component="select" filter-component-config="{\'data\':[{\'dsType\':1,\'title\':\'DB数据源\'},{\'dsType\':2,\'title\':\'JSON数据源\'}],\'scValueField\':\'dsType\',\'title\':\'title\'}"><span>{{row.dsTypeName}}</span></div>' +
                    '<div column-define data-title="模块编码" data-filter="moduleCode"><span>{{row.moduleCode}}</span></div>' +
                    '<div column-define data-title="模块名称" ><span>{{row.moduleName}}</span></div>' +
                    '</div></div>';
                return html;
            },
            controller:['$scope',function($scope) {
            }],
            link:linkFn
        };
        function linkFn(scope,element,attrs,ctrl){
            scope.filterConfig = "{'data':[{'id':1,'title':'JasperReport'},{'id':2,'title':'Birt'},{'id':3,'title':'自定义'}],'scValueField':'id','title':'title'}";
        }
    }

    //IprintPrinter
    angular.module('ngSelect').directive('scSelectPopIprintPrinter',scSelectPopIprintPrinter);
    scSelectPopIprintPrinter.$inject=['$timeout','NgSelect','iServiceConfig'];
    function scSelectPopIprintPrinter($timeout,NgSelect,iServiceConfig){
        return{
            restrict:'EA',
            priority: 1009,
            template:function(elem,attr){
                var html =
                        '<div sc-select-pop sc-dataurl="\''+iServiceConfig.iprintHost+'/web/printerRest/list\'"'+
                        ' sc-selected-display-fields="name"'+
                        ' sc-value-field="code"'
                    ;
                if(attr.scSelectMode){
                    html = html+' sc-select-mode="'+attr.scSelectMode+'"';
                }
                if(attr.scSelection){
                    html = html+' sc-selection="'+attr.scSelection+'"';
                }
                if(attr.scChange){
                    html = html+' sc-change="'+attr.scChange+'"';
                }
                if(attr.scChangeN){
                    html = html+' sc-change-n="'+attr.scChangeN+'"';
                }
                if(attr.scModel){
                    html = html+' sc-model="'+attr.scModel+'"';
                }
                if(attr.scStrictModel){
                    html = html+' sc-strict-model="'+attr.scStrictModel+'"';
                }
                if(attr.scParams){
                    html = html+' sc-params="'+attr.scParams+'"';
                }
                if(attr.scValue){
                    html = html+' sc-value="'+attr.scValue+'"';
                }
                if(attr.scWith){
                    html=html+' sc-with="'+attr.scWith+'"';
                }
                if(attr.scCode){
                    html=html+' sc-code="'+attr.scCode+'"';
                }
                if(attr.scSelectedValues){
                    html = html+' sc-select-values="'+attr.scSelectedValues+'"';
                }
                if(attr.scOnlyRead){
                    html = html+' sc-only-read="'+attr.scOnlyRead+'"';
                }
                html = html+'>' ;
                var div = '<div sc-table show-filter=\'true\'';
                // if(attr.scFixedCol == ''){
                    var fixedTailCounts=1,fixedFrontCounts=1;
                    if(attr.scFixedTailCounts || attr.scFixedTailCounts == 0){
                        fixedTailCounts = parseInt(attr.scFixedTailCounts);
                    }
                    if(attr.scFixedFrontCounts || attr.scFixedFrontCounts == 0){
                        fixedFrontCounts = parseInt(attr.scFixedFrontCounts);
                    }
                    div+= ' sc-pop-fixed-col sc-fixed-front-counts="'+fixedFrontCounts+'" sc-fixed-tail-counts="'+fixedTailCounts+'"';
                // }
                if(attr.scPopTableDrag == ''){
                    div+=' sc-pop-table-drag';
                }
                if(attr.scPopTableDragWidth == ''){
                    div+=' sc-pop-table-drag-width';
                }
                div+='>';
                html+=div+
                    '<div row-define>' +
                    '<div column-define title-component="sc-select-all" data-component="sc-select-row=\'row\'"></div>' +
                    '<div column-define data-title="名称" data-filter="name"><span>{{row.name}}</span></div>' +
                    '<div column-define data-title="编码" data-filter="code"><span>{{row.code}}</span></div>' +
                    '<div column-define data-title="客户端编码" data-filter="clientCode"><span>{{row.clientCode}}</span></div>' +
                    '<div column-define data-title="打印服务名称" data-filter="serviceName"><span>{{row.serviceName}}</span></div>' +
                    '</div></div>';
                return html;
            },
            controller:['$scope',function($scope) {
            }],
            link:linkFn
        };
        function linkFn(scope,element,attrs,ctrl){
        }
    }

    // idata code下拉框
    angular.module('ngSelect').directive('scSelectIdataCode', scSelectIdataCode);
    scSelectIdataCode.$inject = [ '$timeout','NgSelect','iServiceConfig'];
    function scSelectIdataCode($timeout,NgSelect,iServiceConfig){
        return{
            restrict:'EA',
            priority: 1009,
            template:function(elem,attr){
                var html =
                        '<div sc-select sc-dataurl="\''+iServiceConfig.idataHost+'/web/codeRest/getCodeByType\'" sc-filter-fields="name,code" ' +
                        'sc-filter-mode="front" ' +
                        'sc-display-fields="name" sc-value-field="code" '
                    ;
                if(attr.scSelectedValues){
                    html = html+' sc-selected-values="'+attr.scSelectedValues+'"';
                }
                if(attr.scSelectMode){
                    html = html+' sc-select-mode="'+attr.scSelectMode+'"';
                }
                if(attr.scSelection){
                    html = html+' sc-selection="'+attr.scSelection+'"';
                }
                if(attr.scSelectionN){
                    html = html+' sc-selection-n="'+attr.scSelectionN+'"';
                }
                if(attr.scChange){
                    html = html+' sc-change="'+attr.scChange+'"';
                }
                if(attr.scChangeN){
                    html = html+' sc-change-n="'+attr.scChangeN+'"';
                }
                if(attr.scModel){
                    html = html+' sc-model="'+attr.scModel+'"';
                }
                if(attr.scStrictModel){
                    html = html+' sc-strict-model="'+attr.scStrictModel+'"';
                }
                if(attr.scParams){
                    html = html+' sc-params="'+attr.scParams+'"';
                }
                if(attr.scValue){
                    html = html+' sc-value="'+attr.scValue+'"';
                }
                if(attr.scDefault){
                    html = html+' sc-default="'+attr.scDefault+'"';
                }
                if(attr.scWith){
                    html=html+' sc-with="'+attr.scWith+'"';
                }
                if(attr.scCode){
                    html=html+' sc-code="'+attr.scCode+'"';
                }
                if(attr.scOnlyRead){
                    html = html+' sc-only-read="'+attr.scOnlyRead+'"';
                }
                html = html+'></div>';
                return html;
            },
            controller:['$scope',function($scope) {
            }],
            link:linkFn
        };
        function linkFn(scope,element,attr,ctrl){
        }
    }

    //idata 存储分类下拉框
    angular.module('ngSelect').directive('scSelectIdataStoreType', scSelectIdataStoreType);
    scSelectIdataStoreType.$inject = [ '$timeout','NgSelect','iServiceConfig'];
    function scSelectIdataStoreType($timeout,NgSelect,iServiceConfig){
        return{
            restrict:'EA',
            priority: 1009,
            template:function(elem,attr){
                var html =
                        '<div sc-select sc-dataurl="\''+iServiceConfig.idataHost+'/web/skuStorageType/getAll\'" sc-filter-fields="name,code" ' +
                        'sc-filter-mode="front" ' +
                        'sc-display-fields="name" sc-value-field="code" '
                    ;
                if(attr.scSelectedValues){
                    html = html+' sc-selected-values="'+attr.scSelectedValues+'"';
                }
                if(attr.scSelectMode){
                    html = html+' sc-select-mode="'+attr.scSelectMode+'"';
                }
                if(attr.scSelection){
                    html = html+' sc-selection="'+attr.scSelection+'"';
                }
                if(attr.scSelectionN){
                    html = html+' sc-selection-n="'+attr.scSelectionN+'"';
                }
                if(attr.scChange){
                    html = html+' sc-change="'+attr.scChange+'"';
                }
                if(attr.scChangeN){
                    html = html+' sc-change-n="'+attr.scChangeN+'"';
                }
                if(attr.scModel){
                    html = html+' sc-model="'+attr.scModel+'"';
                }
                if(attr.scStrictModel){
                    html = html+' sc-strict-model="'+attr.scStrictModel+'"';
                }
                if(attr.scParams){
                    html = html+' sc-params="'+attr.scParams+'"';
                }
                if(attr.scValue){
                    html = html+' sc-value="'+attr.scValue+'"';
                }
                if(attr.scDefault){
                    html = html+' sc-default="'+attr.scDefault+'"';
                }
                if(attr.scWith){
                    html=html+' sc-with="'+attr.scWith+'"';
                }
                if(attr.scCode){
                    html=html+' sc-code="'+attr.scCode+'"';
                }
                if(attr.scOnlyRead){
                    html = html+' sc-only-read="'+attr.scOnlyRead+'"';
                }
                html = html+'></div>';
                return html;
            },
            controller:['$scope',function($scope) {
            }],
            link:linkFn
        };
        function linkFn(scope,element,attr,ctrl){
        }
    }

    //   idata货主弹出框 货主列表
    angular.module('ngSelect').directive('scSelectPopIdataOwner',scSelectPopIdataOwner);
    scSelectPopIdataOwner.$inject=['$timeout','NgSelect','iServiceConfig'];
    function scSelectPopIdataOwner($timeout,NgSelect,iServiceConfig){
        return{
            restrict:'EA',
            priority: 1009,
            template:function(elem,attr){
                var html =
                        '<div sc-select-pop sc-dataurl="\''+iServiceConfig.idataHost+'/web/ownerRest/getOwnerList\'"'+
                        ' sc-value-field="code"'
                    ;
                if (attr.scSelectedDisplayFields) {
                    html = html +' sc-selected-display-fields="'+attr.scSelectedDisplayFields+'"';
                } else {
                    html = html +' sc-selected-display-fields="name"';
                }
                if(attr.scSelectMode){
                    html = html+' sc-select-mode="'+attr.scSelectMode+'"';
                }
                if(attr.scSelection){
                    html = html+' sc-selection="'+attr.scSelection+'"';
                }
                if(attr.scChange){
                    html = html+' sc-change="'+attr.scChange+'"';
                }
                if(attr.scChangeN){
                    html = html+' sc-change-n="'+attr.scChangeN+'"';
                }
                if(attr.scModel){
                    html = html+' sc-model="'+attr.scModel+'"';
                }
                if(attr.scStrictModel){
                    html = html+' sc-strict-model="'+attr.scStrictModel+'"';
                }
                if(attr.scParams){
                    html = html+' sc-params="'+attr.scParams+'"';
                }
                if(attr.scValue){
                    html = html+' sc-value="'+attr.scValue+'"';
                }
                if(attr.scDefault){
                    html = html+' sc-default="'+attr.scDefault+'"';
                }
                if(attr.scWith){
                    html=html+' sc-with="'+attr.scWith+'"';
                }
                if(attr.scCode){
                    html=html+' sc-code="'+attr.scCode+'"';
                }
                if(attr.scSelectedValues){
                    html = html+' sc-select-values="'+attr.scSelectedValues+'"';
                }
                if(attr.scOnlyRead){
                    html = html+' sc-only-read="'+attr.scOnlyRead+'"';
                }
                html = html+'>' ;
                var div = '<div sc-table show-filter=\'true\'';
                // if(attr.scFixedCol == ''){
                    var fixedTailCounts=1,fixedFrontCounts=1;
                    if(attr.scFixedTailCounts || attr.scFixedTailCounts == 0){
                        fixedTailCounts = parseInt(attr.scFixedTailCounts);
                    }
                    if(attr.scFixedFrontCounts || attr.scFixedFrontCounts == 0){
                        fixedFrontCounts = parseInt(attr.scFixedFrontCounts);
                    }
                    div+= ' sc-pop-fixed-col sc-fixed-front-counts="'+fixedFrontCounts+'" sc-fixed-tail-counts="'+fixedTailCounts+'"';
                // }
                if(attr.scPopTableDrag == ''){
                    div+=' sc-pop-table-drag';
                }
                if(attr.scPopTableDragWidth == ''){
                    div+=' sc-pop-table-drag-width';
                }
                div+='>';
                html+=div+
                        // '<div sc-table show-filter=\'true\'>' +
                        '<div row-define>' +
                        '<div column-define title-component="sc-select-all" data-component="sc-select-row=\'row\'"></div>' +
                        // '<div column-define data-title="货主id" data-filter="id"><span>{{row.id}}</span></div>' +
                        '<div column-define data-title="货主名称" data-filter="name"><span>{{row.name}}</span></div>' +
                        '<div column-define data-title="货主编码" data-filter="code"><span>{{row.code}}</span></div>' +
                        '<div column-define data-title="货主全称" data-filter="fullName"><span>{{row.fullName}}</span></div>' +
                        '<div column-define data-title="货主英文名称" data-filter="englishName"><span>{{row.englishName}}</span></div>' +
                        // '<div column-define data-title="租户的tenantId" data-filter="tenantId"><span>{{row.tenantId}}</span></div>' +
                    '</div></div>';
                return html;
            },
            controller:['$scope',function($scope) {
            }],
            link:linkFn
        };
        function linkFn(scope,element,attrs,ctrl){
        }
    }

    //idata 商品包装下拉框
    angular.module('ngSelect').directive('scSelectIdataPack', scSelectIdataPack);
    scSelectIdataPack.$inject = [ '$timeout','NgSelect','iServiceConfig'];
    function scSelectIdataPack($timeout,NgSelect,iServiceConfig){
        return{
            restrict:'EA',
            priority: 1009,
            template:function(elem,attr){
                var html =
                        '<div sc-select sc-dataurl="\''+iServiceConfig.idataHost+'/web/skuPackRest/getSkuPackList\'" sc-filter-fields="code,name" ' +
                        'sc-filter-mode="front" ' +
                        'sc-display-fields="code,name" sc-value-field="code" '
                    ;
                if(attr.scSelectedValues){
                    html = html+' sc-selected-values="'+attr.scSelectedValues+'"';
                }
                if(attr.scSelectMode){
                    html = html+' sc-select-mode="'+attr.scSelectMode+'"';
                }
                if(attr.scSelection){
                    html = html+' sc-selection="'+attr.scSelection+'"';
                }
                if(attr.scSelectionN){
                    html = html+' sc-selection-n="'+attr.scSelectionN+'"';
                }
                if(attr.scChange){
                    html = html+' sc-change="'+attr.scChange+'"';
                }
                if(attr.scChangeN){
                    html = html+' sc-change-n="'+attr.scChangeN+'"';
                }
                if(attr.scModel){
                    html = html+' sc-model="'+attr.scModel+'"';
                }
                if(attr.scStrictModel){
                    html = html+' sc-strict-model="'+attr.scStrictModel+'"';
                }
                if(attr.scParams){
                    html = html+' sc-params="'+attr.scParams+'"';
                }
                if(attr.scValue){
                    html = html+' sc-value="'+attr.scValue+'"';
                }
                if(attr.scDefault){
                    html = html+' sc-default="'+attr.scDefault+'"';
                }
                if(attr.scWith){
                    html=html+' sc-with="'+attr.scWith+'"';
                }
                if(attr.scCode){
                    html=html+' sc-code="'+attr.scCode+'"';
                }
                if(attr.scOnlyRead){
                    html = html+' sc-only-read="'+attr.scOnlyRead+'"';
                }
                html = html+'></div>';
                return html;
            },
            controller:['$scope',function($scope) {
            }],
            link:linkFn
        };
        function linkFn(scope,element,attr,ctrl){
        }
    }

    //iwh_data模块
    //     iwh-data code下拉框
    angular.module('ngSelect').directive('scSelectIwhDataCode', scSelectIwhDataCode);
    scSelectIwhDataCode.$inject = [ '$timeout','NgSelect','iServiceConfig'];
    function scSelectIwhDataCode($timeout,NgSelect,iServiceConfig){
        return{
            restrict:'EA',
            priority: 1009,
            template:function(elem,attr){
                var html =
                        '<div sc-select sc-dataurl="\''+iServiceConfig.iwhDataHost+'/web/codeRest/getCodeList\'" sc-filter-fields="name,code" ' +
                        'sc-filter-mode="front" ' +
                        'sc-display-fields="name" sc-value-field="code" '
                    ;
                if(attr.scSelectedValues){
                    html = html+' sc-selected-values="'+attr.scSelectedValues+'"';
                }
                if(attr.scSelectMode){
                    html = html+' sc-select-mode="'+attr.scSelectMode+'"';
                }
                if(attr.scSelection){
                    html = html+' sc-selection="'+attr.scSelection+'"';
                }
                if(attr.scSelectionN){
                    html = html+' sc-selection-n="'+attr.scSelectionN+'"';
                }
                if(attr.scChange){
                    html = html+' sc-change="'+attr.scChange+'"';
                }
                if(attr.scChangeN){
                    html = html+' sc-change-n="'+attr.scChangeN+'"';
                }
                if(attr.scModel){
                    html = html+' sc-model="'+attr.scModel+'"';
                }
                if(attr.scStrictModel){
                    html = html+' sc-strict-model="'+attr.scStrictModel+'"';
                }
                if(attr.scParams){
                    html = html+' sc-params="'+attr.scParams+'"';
                }
                if(attr.scValue){
                    html = html+' sc-value="'+attr.scValue+'"';
                }
                if(attr.scDefault){
                    html = html+' sc-default="'+attr.scDefault+'"';
                }
                if(attr.scWith){
                    html=html+' sc-with="'+attr.scWith+'"';
                }
                if(attr.scCode){
                    html=html+' sc-code="'+attr.scCode+'"';
                }
                if(attr.scOnlyRead){
                    html = html+' sc-only-read="'+attr.scOnlyRead+'"';
                }
                html = html+'></div>';
                return html;
            },
            controller:['$scope',function($scope) {
            }],
            link:linkFn
        };
        function linkFn(scope,element,attr,ctrl){
        }
    }

  //iwh-data 商品单位下拉框
    angular.module('ngSelect').directive('scSelectIwhDataPack', scSelectIwhDataPack);
    scSelectIwhDataPack.$inject = [ '$timeout','NgSelect','iServiceConfig'];
    function scSelectIwhDataPack($timeout,NgSelect,iServiceConfig){
        return{
            restrict:'EA',
            priority: 1009,
            template:function(elem,attr){
                var html =
                        '<div sc-select sc-dataurl="\''+iServiceConfig.iwhDataHost+'/web/skuPackRest/getSkuPackList\'" sc-filter-fields="code,name" ' +
                        'sc-filter-mode="front" ' +
                        'sc-display-fields="code,name" sc-value-field="code" '
                    ;
                if(attr.scSelectedValues){
                    html = html+' sc-selected-values="'+attr.scSelectedValues+'"';
                }
                if(attr.scSelectMode){
                    html = html+' sc-select-mode="'+attr.scSelectMode+'"';
                }
                if(attr.scSelection){
                    html = html+' sc-selection="'+attr.scSelection+'"';
                }
                if(attr.scSelectionN){
                    html = html+' sc-selection-n="'+attr.scSelectionN+'"';
                }
                if(attr.scChange){
                    html = html+' sc-change="'+attr.scChange+'"';
                }
                if(attr.scChangeN){
                    html = html+' sc-change-n="'+attr.scChangeN+'"';
                }
                if(attr.scModel){
                    html = html+' sc-model="'+attr.scModel+'"';
                }
                if(attr.scStrictModel){
                    html = html+' sc-strict-model="'+attr.scStrictModel+'"';
                }
                if(attr.scParams){
                    html = html+' sc-params="'+attr.scParams+'"';
                }
                if(attr.scValue){
                    html = html+' sc-value="'+attr.scValue+'"';
                }
                if(attr.scDefault){
                    html = html+' sc-default="'+attr.scDefault+'"';
                }
                if(attr.scWith){
                    html=html+' sc-with="'+attr.scWith+'"';
                }
                if(attr.scCode){
                    html=html+' sc-code="'+attr.scCode+'"';
                }
                if(attr.scOnlyRead){
                    html = html+' sc-only-read="'+attr.scOnlyRead+'"';
                }
                html = html+'></div>';
                return html;
            },
            controller:['$scope',function($scope) {
            }],
            link:linkFn
        };
        function linkFn(scope,element,attr,ctrl){
        }
    }
    
    //iwh-data 商品单位下拉框
    angular.module('ngSelect').directive('scSelectIwhDataUom', scSelectIwhDataUom);
    scSelectIwhDataUom.$inject = [ '$timeout','NgSelect','iServiceConfig'];
    function scSelectIwhDataUom($timeout,NgSelect,iServiceConfig){
        return{
            restrict:'EA',
            priority: 1009,
            template:function(elem,attr){
                var html =
                        '<div sc-select sc-dataurl="\''+iServiceConfig.iwhDataHost+'/web/skuPackRest/getSkuPackUomList\'" sc-filter-fields="code,name" ' +
                        'sc-filter-mode="front" ' +
                        'sc-display-fields="code,name" sc-value-field="code" '
                    ;
                if(attr.scSelectedValues){
                    html = html+' sc-selected-values="'+attr.scSelectedValues+'"';
                }
                if(attr.scSelectMode){
                    html = html+' sc-select-mode="'+attr.scSelectMode+'"';
                }
                if(attr.scSelection){
                    html = html+' sc-selection="'+attr.scSelection+'"';
                }
                if(attr.scSelectionN){
                    html = html+' sc-selection-n="'+attr.scSelectionN+'"';
                }
                if(attr.scChange){
                    html = html+' sc-change="'+attr.scChange+'"';
                }
                if(attr.scChangeN){
                    html = html+' sc-change-n="'+attr.scChangeN+'"';
                }
                if(attr.scModel){
                    html = html+' sc-model="'+attr.scModel+'"';
                }
                if(attr.scStrictModel){
                    html = html+' sc-strict-model="'+attr.scStrictModel+'"';
                }
                if(attr.scParams){
                    html = html+' sc-params="'+attr.scParams+'"';
                }
                if(attr.scValue){
                    html = html+' sc-value="'+attr.scValue+'"';
                }
                if(attr.scDefault){
                    html = html+' sc-default="'+attr.scDefault+'"';
                }
                if(attr.scWith){
                    html=html+' sc-with="'+attr.scWith+'"';
                }
                if(attr.scCode){
                    html=html+' sc-code="'+attr.scCode+'"';
                }
                if(attr.scOnlyRead){
                    html = html+' sc-only-read="'+attr.scOnlyRead+'"';
                }
                html = html+'></div>';
                return html;
            },
            controller:['$scope',function($scope) {
            }],
            link:linkFn
        };
        function linkFn(scope,element,attr,ctrl){
        }
    }

    //iwh-data 仓库区段弹出框
    angular.module('ngSelect').directive('scSelectPopIwhDataSection', scSelectPopIwhDataSection);
    scSelectPopIwhDataSection.$inject = [ '$timeout','NgSelect','iServiceConfig'];
    function scSelectPopIwhDataSection($timeout,NgSelect,iServiceConfig){
        return{
            restrict:'EA',
            priority: 1009,
            template:function(elem,attr){
                var html =
                        '<div sc-select-pop sc-dataurl="\''+iServiceConfig.iwhDataHost+'/web/warehouseRest/getSectionList\'"'+
                        ' sc-selected-display-fields="code,name"'+
                        ' sc-value-field="code"'
                    ;
                if(attr.scSelectMode){
                    html = html+' sc-select-mode="'+attr.scSelectMode+'"';
                }
                if(attr.scSelection){
                    html = html+' sc-selection="'+attr.scSelection+'"';
                }
                if(attr.scChange){
                    html = html+' sc-change="'+attr.scChange+'"';
                }
                if(attr.scChangeN){
                    html = html+' sc-change-n="'+attr.scChangeN+'"';
                }
                if(attr.scModel){
                    html = html+' sc-model="'+attr.scModel+'"';
                }
                if(attr.scStrictModel){
                    html = html+' sc-strict-model="'+attr.scStrictModel+'"';
                }
                if(attr.scParams){
                    html = html+' sc-params="'+attr.scParams+'"';
                }
                if(attr.scValue){
                    html = html+' sc-value="'+attr.scValue+'"';
                }
                if(attr.scWith){
                    html=html+' sc-with="'+attr.scWith+'"';
                }
                if(attr.scCode){
                    html=html+' sc-code="'+attr.scCode+'"';
                }
                if(attr.scOnlyRead){
                    html = html+' sc-only-read="'+attr.scOnlyRead+'"';
                }
                if(attr.scSelectedValues){
                    html = html+' sc-select-values="'+attr.scSelectedValues+'"';
                }
                html = html+'>' ;
                var div = '<div sc-table show-filter=\'true\'';
                // if(attr.scFixedCol == ''){
                    var fixedTailCounts=1,fixedFrontCounts=1;
                    if(attr.scFixedTailCounts || attr.scFixedTailCounts == 0){
                        fixedTailCounts = parseInt(attr.scFixedTailCounts);
                    }
                    if(attr.scFixedFrontCounts || attr.scFixedFrontCounts == 0){
                        fixedFrontCounts = parseInt(attr.scFixedFrontCounts);
                    }
                    div+= ' sc-pop-fixed-col sc-fixed-front-counts="'+fixedFrontCounts+'" sc-fixed-tail-counts="'+fixedTailCounts+'"';
                // }
                if(attr.scPopTableDrag == ''){
                    div+=' sc-pop-table-drag';
                }
                if(attr.scPopTableDragWidth == ''){
                    div+=' sc-pop-table-drag-width';
                }
                div+='>';
                html+=div+
                    '<div row-define>' +
                    '<div column-define title-component="sc-select-all" data-component="sc-select-row=\'row\'"></div>' +
                    '<div column-define data-title="区段名称" data-filter="name"><span>{{row.name}}</span></div>' +
                    '<div column-define data-title="区段代码" data-filter="code"><span>{{row.code}}</span></div>' +
                    '</div></div>';
                return html;
            },
            controller:['$scope',function($scope) {
            }],
            link:linkFn
        };
        function linkFn(scope,element,attr,ctrl){
        }
    }

  //iwh-data 仓库工作区域弹出框
    angular.module('ngSelect').directive('scSelectPopIwhDataRegion', scSelectPopIwhDataRegion);
    scSelectPopIwhDataRegion.$inject = [ '$timeout','NgSelect','iServiceConfig'];
    function scSelectPopIwhDataRegion($timeout,NgSelect,iServiceConfig){
        return{
            restrict:'EA',
            priority: 1009,
            template:function(elem,attr){
                var html =
                        '<div sc-select-pop sc-dataurl="\''+iServiceConfig.iwhDataHost+'/web/warehouseRest/getRegionList\'"'+
                        ' sc-selected-display-fields="code,name"'+
                        ' sc-value-field="code"'
                    ;
                if(attr.scSelectMode){
                    html = html+' sc-select-mode="'+attr.scSelectMode+'"';
                }
                if(attr.scSelection){
                    html = html+' sc-selection="'+attr.scSelection+'"';
                }
                if(attr.scChange){
                    html = html+' sc-change="'+attr.scChange+'"';
                }
                if(attr.scChangeN){
                    html = html+' sc-change-n="'+attr.scChangeN+'"';
                }
                if(attr.scModel){
                    html = html+' sc-model="'+attr.scModel+'"';
                }
                if(attr.scStrictModel){
                    html = html+' sc-strict-model="'+attr.scStrictModel+'"';
                }
                if(attr.scParams){
                    html = html+' sc-params="'+attr.scParams+'"';
                }
                if(attr.scValue){
                    html = html+' sc-value="'+attr.scValue+'"';
                }
                if(attr.scWith){
                    html=html+' sc-with="'+attr.scWith+'"';
                }
                if(attr.scCode){
                    html=html+' sc-code="'+attr.scCode+'"';
                }
                if(attr.scOnlyRead){
                    html = html+' sc-only-read="'+attr.scOnlyRead+'"';
                }
                if(attr.scSelectedValues){
                    html = html+' sc-select-values="'+attr.scSelectedValues+'"';
                }
                 html = html+'>' ;
                var div = '<div sc-table show-filter=\'true\'';
                // if(attr.scFixedCol == ''){
                    var fixedTailCounts=1,fixedFrontCounts=1;
                    if(attr.scFixedTailCounts || attr.scFixedTailCounts == 0){
                        fixedTailCounts = parseInt(attr.scFixedTailCounts);
                    }
                    if(attr.scFixedFrontCounts || attr.scFixedFrontCounts == 0){
                        fixedFrontCounts = parseInt(attr.scFixedFrontCounts);
                    }
                    div+= ' sc-pop-fixed-col sc-fixed-front-counts="'+fixedFrontCounts+'" sc-fixed-tail-counts="'+fixedTailCounts+'"';
                // }
                if(attr.scPopTableDrag == ''){
                    div+=' sc-pop-table-drag';
                }
                if(attr.scPopTableDragWidth == ''){
                    div+=' sc-pop-table-drag-width';
                }
                div+='>';
                html+=div+
                '<div row-define>' +
                '<div column-define title-component="sc-select-all" data-component="sc-select-row=\'row\'"></div>' +
                '<div column-define data-title="区域名称" data-filter="name"><span>{{row.name}}</span></div>' +
                '<div column-define data-title="区域编号" data-filter="code"><span>{{row.code}}</span></div>' +
                    '</div></div>';
                return html;
            },
            controller:['$scope',function($scope) {
            }],
            link:linkFn
        };
        function linkFn(scope,element,attr,ctrl){
        }
    }
    
    //iwh-data 仓库库区弹出框
    angular.module('ngSelect').directive('scSelectPopIwhDataZone', scSelectPopIwhDataZone);
    scSelectPopIwhDataZone.$inject = [ '$timeout','NgSelect','iServiceConfig'];
    function scSelectPopIwhDataZone($timeout,NgSelect,iServiceConfig){
        return{
            restrict:'EA',
            priority: 1009,
            template:function(elem,attr){
                var html =
                        '<div sc-select-pop sc-dataurl="\''+iServiceConfig.iwhDataHost+'/web/warehouseRest/getZoneList\'"'+
                        ' sc-selected-display-fields="code,name"'+
                        ' sc-value-field="code"'
                    ;
                if(attr.scSelectMode){
                    html = html+' sc-select-mode="'+attr.scSelectMode+'"';
                }
                if(attr.scSelection){
                    html = html+' sc-selection="'+attr.scSelection+'"';
                }
                if(attr.scChange){
                    html = html+' sc-change="'+attr.scChange+'"';
                }
                if(attr.scChangeN){
                    html = html+' sc-change-n="'+attr.scChangeN+'"';
                }
                if(attr.scModel){
                    html = html+' sc-model="'+attr.scModel+'"';
                }
                if(attr.scStrictModel){
                    html = html+' sc-strict-model="'+attr.scStrictModel+'"';
                }
                if(attr.scParams){
                    html = html+' sc-params="'+attr.scParams+'"';
                }
                if(attr.scValue){
                    html = html+' sc-value="'+attr.scValue+'"';
                }
                if(attr.scWith){
                    html=html+' sc-with="'+attr.scWith+'"';
                }
                if(attr.scCode){
                    html=html+' sc-code="'+attr.scCode+'"';
                }
                if(attr.scOnlyRead){
                    html = html+' sc-only-read="'+attr.scOnlyRead+'"';
                }
                if(attr.scSelectedValues){
                    html = html+' sc-select-values="'+attr.scSelectedValues+'"';
                }
                html = html+'>' ;
                var div = '<div sc-table show-filter=\'true\'';
                // if(attr.scFixedCol == ''){
                    var fixedTailCounts=1,fixedFrontCounts=1;
                    if(attr.scFixedTailCounts || attr.scFixedTailCounts == 0){
                        fixedTailCounts = parseInt(attr.scFixedTailCounts);
                    }
                    if(attr.scFixedFrontCounts || attr.scFixedFrontCounts == 0){
                        fixedFrontCounts = parseInt(attr.scFixedFrontCounts);
                    }
                    div+= ' sc-pop-fixed-col sc-fixed-front-counts="'+fixedFrontCounts+'" sc-fixed-tail-counts="'+fixedTailCounts+'"';
                // }
                if(attr.scPopTableDrag == ''){
                    div+=' sc-pop-table-drag';
                }
                if(attr.scPopTableDragWidth == ''){
                    div+=' sc-pop-table-drag-width';
                }
                div+='>';
                html+=div+
                '<div row-define>' +
                '<div column-define title-component="sc-select-all" data-component="sc-select-row=\'row\'"></div>' +
                '<div column-define data-title="库区名称" data-filter="name"><span>{{row.name}}</span></div>' +
                    '<div column-define data-title="库区编号" data-filter="code"><span>{{row.code}}</span></div>' +
                    '</div></div>';
                return html;
            },
            controller:['$scope',function($scope) {
            }],
            link:linkFn
        };
        function linkFn(scope,element,attr,ctrl){
        }
    }

    //iwh-data 仓库库位弹出框
    angular.module('ngSelect').directive('scSelectPopIwhDataLocation', scSelectPopIwhDataLocation);
    scSelectPopIwhDataLocation.$inject = [ '$timeout','NgSelect','iServiceConfig'];
    function scSelectPopIwhDataLocation($timeout,NgSelect,iServiceConfig){
        return{
            restrict:'EA',
            priority: 1009,
            template:function(elem,attr){
                var html =
                        '<div sc-select-pop sc-dataurl="\''+iServiceConfig.iwhDataHost+'/web/warehouseRest/getLocationList\'"'+
                        ' sc-selected-display-fields="code"'+
                        ' sc-value-field="code"'
                    ;
                if(attr.scSelectMode){
                    html = html+' sc-select-mode="'+attr.scSelectMode+'"';
                }
                if(attr.scSelection){
                    html = html+' sc-selection="'+attr.scSelection+'"';
                }
                if(attr.scChange){
                    html = html+' sc-change="'+attr.scChange+'"';
                }
                if(attr.scChangeN){
                    html = html+' sc-change-n="'+attr.scChangeN+'"';
                }
                if(attr.scModel){
                    html = html+' sc-model="'+attr.scModel+'"';
                }
                if(attr.scStrictModel){
                    html = html+' sc-strict-model="'+attr.scStrictModel+'"';
                }
                if(attr.scParams){
                    html = html+' sc-params="'+attr.scParams+'"';
                }
                if(attr.scValue){
                    html = html+' sc-value="'+attr.scValue+'"';
                }
                if(attr.scWith){
                    html=html+' sc-with="'+attr.scWith+'"';
                }
                if(attr.scCode){
                    html=html+' sc-code="'+attr.scCode+'"';
                }
                if(attr.scOnlyRead){
                    html = html+' sc-only-read="'+attr.scOnlyRead+'"';
                }
                if(attr.scSelectedValues){
                    html = html+' sc-select-values="'+attr.scSelectedValues+'"';
                }
                html = html+'>' ;
                var div = '<div sc-table show-filter=\'true\'';
                // if(attr.scFixedCol == ''){
                    var fixedTailCounts=1,fixedFrontCounts=1;
                    if(attr.scFixedTailCounts || attr.scFixedTailCounts == 0){
                        fixedTailCounts = parseInt(attr.scFixedTailCounts);
                    }
                    if(attr.scFixedFrontCounts || attr.scFixedFrontCounts == 0){
                        fixedFrontCounts = parseInt(attr.scFixedFrontCounts);
                    }
                    div+= ' sc-pop-fixed-col sc-fixed-front-counts="'+fixedFrontCounts+'" sc-fixed-tail-counts="'+fixedTailCounts+'"';
                // }
                if(attr.scPopTableDrag == ''){
                    div+=' sc-pop-table-drag';
                }
                if(attr.scPopTableDragWidth == ''){
                    div+=' sc-pop-table-drag-width';
                }
                div+='>';
                html+=div+
                '<div row-define>' +
                '<div column-define title-component="sc-select-all" data-component="sc-select-row=\'row\'"></div>' +
                    '<div column-define data-title="库位编号" data-filter="code"><span>{{row.code}}</span></div>' +
                '<div column-define data-title="库区编号" data-filter="zoneCode"><span>{{row.zoneCode}}</span></div>' +
                '<div column-define data-title="库区名称" data-filter="zoneName"><span>{{row.zoneName}}</span></div>' +
                    '</div></div>';
                return html;
            },
            controller:['$scope',function($scope) {
            }],
            link:linkFn
        };
        function linkFn(scope,element,attr,ctrl){
        }
    }

    //  iwh-inner 库存列表
    angular.module('ngSelect').directive('scSelectPopIwhInner',scSelectPopIwhInner);
    scSelectPopIwhInner.$inject=['$timeout','NgSelect','iServiceConfig'];
    function scSelectPopIwhInner($timeout,NgSelect,iServiceConfig){
        return{
            restrict:'EA',
            priority: 1009,
            template:function(elem,attr){
                var html =
                        '<div sc-select-pop sc-dataurl="\''+iServiceConfig.iwhInventoryHost+'/web/inventoryDetailRest/getInventoryDetailList\'"'+
                        ' sc-selected-display-fields="skuCode"'+
                        ' sc-value-field="updateName"'
                    ;
                if(attr.scSelectMode){
                    html = html+' sc-select-mode="'+attr.scSelectMode+'"';
                }
                if(attr.scSelection){
                    html = html+' sc-selection="'+attr.scSelection+'"';
                }
                if(attr.scChange){
                    html = html+' sc-change="'+attr.scChange+'"';
                }
                if(attr.scChangeN){
                    html = html+' sc-change-n="'+attr.scChangeN+'"';
                }
                if(attr.scModel){
                    html = html+' sc-model="'+attr.scModel+'"';
                }
                if(attr.scStrictModel){
                    html = html+' sc-strict-model="'+attr.scStrictModel+'"';
                }
                if(attr.scParams){
                    html = html+' sc-params="'+attr.scParams+'"';
                }
                if(attr.scValue){
                    html = html+' sc-value="'+attr.scValue+'"';
                }
                if(attr.scWith){
                    html=html+' sc-with="'+attr.scWith+'"';
                }
                if(attr.scCode){
                    html=html+' sc-code="'+attr.scCode+'"';
                }
                if(attr.scSelectedValues){
                    html = html+' sc-select-values="'+attr.scSelectedValues+'"';
                }
                if(attr.scOnlyRead){
                    html = html+' sc-only-read="'+attr.scOnlyRead+'"';
                }
                if(attr.scValueObj){
                    html = html+' sc-value-obj="'+attr.scValueObj+'"';
                }
                if(attr.scValueFieldObj){
                    html = html+' sc-value-field-obj="'+attr.scValueFieldObj+'"';
                }
                html = html+'>' ;
                var div = '<div sc-table show-filter=\'true\'';
                // if(attr.scFixedCol == ''){
                    var fixedTailCounts=1,fixedFrontCounts=1;
                    if(attr.scFixedTailCounts || attr.scFixedTailCounts == 0){
                        fixedTailCounts = parseInt(attr.scFixedTailCounts);
                    }
                    if(attr.scFixedFrontCounts || attr.scFixedFrontCounts == 0){
                        fixedFrontCounts = parseInt(attr.scFixedFrontCounts);
                    }
                    div+= ' sc-pop-fixed-col sc-fixed-front-counts="'+fixedFrontCounts+'" sc-fixed-tail-counts="'+fixedTailCounts+'"';
                // }
                if(attr.scPopTableDrag == ''){
                    div+=' sc-pop-table-drag';
                }
                if(attr.scPopTableDragWidth == ''){
                    div+=' sc-pop-table-drag-width';
                }
                div+='>';
                html+=div+
                    '<div row-define>' +
                    '<div column-define title-component="sc-select-all" data-component="sc-select-row=\'row\'"></div>' +
                    // '<div column-define data-title="租户ID" data-filter="tenantId"><span>{{row.tenantId}}</span></div>' +
                    // '<div column-define data-title="仓库编码" data-filter="warehouseCode"><span>{{row.warehouseCode}}</span></div>' +
                    '<div column-define data-title=" 货主编码" data-filter="ownerCode"><span>{{row.ownerCode}}</span></div>' +
                    '<div column-define data-title="货主名称"><span>{{row.ownerName}}</span></div>' +
                    '<div column-define data-title="商品编码" data-filter="skuCode"><span>{{row.skuCode}}</span></div>' +
                    '<div column-define data-title="商品名称"><span>{{row.skuName}}</span></div>' +
                    '<div column-define data-title="批次号" data-filter="lot"><span>{{row.lot}}</span></div>' +
                    '<div column-define data-title="库位编码" data-filter="locCode"><span>{{row.locCode}}</span></div>' +
                    '<div column-define data-title="托盘LPN" data-filter="lpn"><span>{{row.lpn}}</span></div>' +
                    '<div column-define data-title="箱号" data-filter="caseNum"><span>{{row.caseNum}}</span></div>' +
                    '<div column-define data-title="库存量" data-filter="qty"><span>{{row.qty}}</span></div>' +
                    '<div column-define data-title="分配量" data-filter="allocatedQty"><span>{{row.allocatedQty}}</span></div>' +
                    '<div column-define data-title="拣货量" data-filter="pickedQty"><span>{{row.pickedQty}}</span></div>' +
                    '</div></div>';
                return html;
            },
            controller:['$scope',function($scope) {
            }],
            link:linkFn
        };
        function linkFn(scope,element,attrs,ctrl){
        }
    }

    //  iwh-inner 库存列表---与上面库内列表组件一样  只是重新命名  但依然保留scSelectPopIwhInner组件
    angular.module('ngSelect').directive('scSelectPopIwhInventory',scSelectPopIwhInventory);
    scSelectPopIwhInventory.$inject=['$timeout','NgSelect','iServiceConfig'];
    function scSelectPopIwhInventory($timeout,NgSelect,iServiceConfig){
        return{
            restrict:'EA',
            priority: 1009,
            template:function(elem,attr){
                var html =
                        '<div sc-select-pop sc-dataurl="\''+iServiceConfig.iwhInventoryHost+'/web/inventoryDetailRest/getInventoryDetailList\'"'+
                        ' sc-selected-display-fields="skuCode"'+
                        ' sc-value-field="updateName"'
                    ;
                if(attr.scSelectMode){
                    html = html+' sc-select-mode="'+attr.scSelectMode+'"';
                }
                if(attr.scSelection){
                    html = html+' sc-selection="'+attr.scSelection+'"';
                }
                if(attr.scChange){
                    html = html+' sc-change="'+attr.scChange+'"';
                }
                if(attr.scChangeN){
                    html = html+' sc-change-n="'+attr.scChangeN+'"';
                }
                if(attr.scModel){
                    html = html+' sc-model="'+attr.scModel+'"';
                }
                if(attr.scStrictModel){
                    html = html+' sc-strict-model="'+attr.scStrictModel+'"';
                }
                if(attr.scParams){
                    html = html+' sc-params="'+attr.scParams+'"';
                }
                if(attr.scValue){
                    html = html+' sc-value="'+attr.scValue+'"';
                }
                if(attr.scWith){
                    html=html+' sc-with="'+attr.scWith+'"';
                }
                if(attr.scCode){
                    html=html+' sc-code="'+attr.scCode+'"';
                }
                if(attr.scSelectedValues){
                    html = html+' sc-select-values="'+attr.scSelectedValues+'"';
                }
                if(attr.scOnlyRead){
                    html = html+' sc-only-read="'+attr.scOnlyRead+'"';
                }
                if(attr.scValueObj){
                    html = html+' sc-value-obj="'+attr.scValueObj+'"';
                }
                if(attr.scValueFieldObj){
                    html = html+' sc-value-field-obj="'+attr.scValueFieldObj+'"';
                }
                html = html+'>' ;
                var div = '<div sc-table show-filter=\'true\'';
                // if(attr.scFixedCol == ''){
                var fixedTailCounts=1,fixedFrontCounts=1;
                if(attr.scFixedTailCounts || attr.scFixedTailCounts == 0){
                    fixedTailCounts = parseInt(attr.scFixedTailCounts);
                }
                if(attr.scFixedFrontCounts || attr.scFixedFrontCounts == 0){
                    fixedFrontCounts = parseInt(attr.scFixedFrontCounts);
                }
                div+= ' sc-pop-fixed-col sc-fixed-front-counts="'+fixedFrontCounts+'" sc-fixed-tail-counts="'+fixedTailCounts+'"';
                // }
                if(attr.scPopTableDrag == ''){
                    div+=' sc-pop-table-drag';
                }
                if(attr.scPopTableDragWidth == ''){
                    div+=' sc-pop-table-drag-width';
                }
                div+='>';
                html+=div+
                    '<div row-define>' +
                    '<div column-define title-component="sc-select-all" data-component="sc-select-row=\'row\'"></div>' +
                    // '<div column-define data-title="租户ID" data-filter="tenantId"><span>{{row.tenantId}}</span></div>' +
                    // '<div column-define data-title="仓库编码" data-filter="warehouseCode"><span>{{row.warehouseCode}}</span></div>' +
                    '<div column-define data-title=" 货主编码" data-filter="ownerCode"><span>{{row.ownerCode}}</span></div>' +
                    '<div column-define data-title="货主名称"><span>{{row.ownerName}}</span></div>' +
                    '<div column-define data-title="商品编码" data-filter="skuCode"><span>{{row.skuCode}}</span></div>' +
                    '<div column-define data-title="商品名称"><span>{{row.skuName}}</span></div>' +
                    '<div column-define data-title="批次号" data-filter="lot"><span>{{row.lot}}</span></div>' +
                    '<div column-define data-title="库位编码" data-filter="locCode"><span>{{row.locCode}}</span></div>' +
                    '<div column-define data-title="托盘LPN" data-filter="lpn"><span>{{row.lpn}}</span></div>' +
                    '<div column-define data-title="箱号" data-filter="caseNum"><span>{{row.caseNum}}</span></div>' +
                    '<div column-define data-title="库存量" data-filter="qty"><span>{{row.qty}}</span></div>' +
                    '<div column-define data-title="分配量" data-filter="allocatedQty"><span>{{row.allocatedQty}}</span></div>' +
                    '<div column-define data-title="拣货量" data-filter="pickedQty"><span>{{row.pickedQty}}</span></div>' +
                    '</div></div>';
                return html;
            },
            controller:['$scope',function($scope) {
            }],
            link:linkFn
        };
        function linkFn(scope,element,attrs,ctrl){
        }
    }

    //库存批次选择组件
    angular.module('ngSelect').directive('scSelectPopIwhInventoryLot',scSelectPopIwhInventoryLot);
    scSelectPopIwhInventoryLot.$inject=['$timeout','NgSelect','iServiceConfig'];
    function scSelectPopIwhInventoryLot($timeout,NgSelect,iServiceConfig){
        return{
            restrict:'EA',
            priority: 1009,
            template:function(elem,attr){
                var html =
                        '<div sc-select-pop sc-dataurl="\''+iServiceConfig.iwhInventoryHost+'/web/lotInventoryRest/getLot\'"'+
                        ' sc-value-field="lot"'
                    ;
                if (attr.scSelectedDisplayFields) {
                    html = html +' sc-selected-display-fields="'+attr.scSelectedDisplayFields+'"';
                } else {
                    html = html +' sc-selected-display-fields="lot"';
                }
                if(attr.scSelectMode){
                    html = html+' sc-select-mode="'+attr.scSelectMode+'"';
                }
                if(attr.scSelection){
                    html = html+' sc-selection="'+attr.scSelection+'"';
                }
                if(attr.scChange){
                    html = html+' sc-change="'+attr.scChange+'"';
                }
                if(attr.scChangeN){
                    html = html+' sc-change-n="'+attr.scChangeN+'"';
                }
                if(attr.scModel){
                    html = html+' sc-model="'+attr.scModel+'"';
                }
                if(attr.scStrictModel){
                    html = html+' sc-strict-model="'+attr.scStrictModel+'"';
                }
                if(attr.scParams){
                    html = html+' sc-params="'+attr.scParams+'"';
                }
                if(attr.scValue){
                    html = html+' sc-value="'+attr.scValue+'"';
                }
                if(attr.scWith){
                    html=html+' sc-with="'+attr.scWith+'"';
                }
                if(attr.scCode){
                    html=html+' sc-code="'+attr.scCode+'"';
                }
                if(attr.scSelectedValues){
                    html = html+' sc-select-values="'+attr.scSelectedValues+'"';
                }
                if(attr.scOnlyRead){
                    html = html+' sc-only-read="'+attr.scOnlyRead+'"';
                }
                if(attr.scValueObj){
                    html = html+' sc-value-obj="'+attr.scValueObj+'"';
                }
                if(attr.scValueFieldObj){
                    html = html+' sc-value-field-obj="'+attr.scValueFieldObj+'"';
                }
                html = html+'>' ;
                var div = '<div sc-table show-filter=\'true\'';
                // if(attr.scFixedCol == ''){
                var fixedTailCounts=1,fixedFrontCounts=1;
                if(attr.scFixedTailCounts || attr.scFixedTailCounts == 0){
                    fixedTailCounts = parseInt(attr.scFixedTailCounts);
                }
                if(attr.scFixedFrontCounts || attr.scFixedFrontCounts == 0){
                    fixedFrontCounts = parseInt(attr.scFixedFrontCounts);
                }
                div+= ' sc-pop-fixed-col sc-fixed-front-counts="'+fixedFrontCounts+'" sc-fixed-tail-counts="'+fixedTailCounts+'"';
                // }
                if(attr.scPopTableDrag == ''){
                    div+=' sc-pop-table-drag';
                }
                if(attr.scPopTableDragWidth == ''){
                    div+=' sc-pop-table-drag-width';
                }
                div+='>';
                html+=div+
                    '<div row-define>' +
                    '<div column-define title-component="sc-select-all" data-component="sc-select-row=\'row\'"></div>' +
                    '<div column-define data-title=" 批次号" data-filter="lot"><span>{{row.lot}}</span></div>' +
                    '<div column-define data-title="货主编码" data-filter="ownerCode"><span>{{row.ownerCode}}</span></div>' +
                    '<div column-define data-title="货主名称"><span>{{row.ownerName}}</span></div>' +
                    '<div column-define data-title="商品编码" data-filter="skuCode"><span>{{row.skuCode}}</span></div>' +
                    '<div column-define data-title="商品名称"><span>{{row.skuName}}</span></div>' +
                    '<div column-define data-title="可用数量"><span>{{row.canUseQty}}</span></div>' +
                    '<div column-define data-title="库存数量"><span>{{row.qty}}</span></div>' +
                    '<div column-define data-title="预分配数量"><span>{{row.preallocatedQty}}</span></div>' +
                    '<div column-define data-title="分配数量"><span>{{row.allocatedQty}}</span></div>' +
                    '<div column-define data-title="拣货数量"><span>{{row.pickedQty}}</span></div>' +
                    '<div column-define data-title="锁定类型"><span>{{row.lockType}}</span></div>' +
                    '<div column-define data-title="批次属性"><span>{{row.lotAttribute}}</span></div>' +
                    '<div column-define data-title="批次属性01"><span>{{row.lotAttribute01}}</span></div>' +
                    '<div column-define data-title="批次属性02"><span>{{row.lotAttribute02}}</span></div>' +
                    '<div column-define data-title="批次属性03"><span>{{row.lotAttribute03}}</span></div>' +
                    '<div column-define data-title="批次属性04"><span>{{row.lotAttribute04}}</span></div>' +
                    '<div column-define data-title="批次属性05"><span>{{row.lotAttribute05}}</span></div>' +
                    '<div column-define data-title="批次属性06"><span>{{row.lotAttribute06}}</span></div>' +
                    '<div column-define data-title="批次属性07"><span>{{row.lotAttribute07}}</span></div>' +
                    '<div column-define data-title="批次属性08"><span>{{row.lotAttribute08}}</span></div>' +
                    '<div column-define data-title="批次属性09"><span>{{row.lotAttribute09}}</span></div>' +
                    '<div column-define data-title="批次属性10"><span>{{row.lotAttribute10}}</span></div>' +
                    '<div column-define data-title="批次属性11"><span>{{row.lotAttribute11}}</span></div>' +
                    '<div column-define data-title="批次属性12"><span>{{row.lotAttribute12}}</span></div>' +
                    '<div column-define data-title="创建人"><span>{{row.entryName}}</span></div>' ;
                return html;
            },
            controller:['$scope',function($scope) {
            }],
            link:linkFn
        };
        function linkFn(scope,element,attrs,ctrl){
        }
    }

    //获取容器列表
    angular.module('ngSelect').directive('scSelectIwhDataContainer', scSelectIwhDataContainer);
    scSelectIwhDataContainer.$inject = [ '$timeout','NgSelect','iServiceConfig'];
    function scSelectIwhDataContainer($timeout,NgSelect,iServiceConfig){
        return{
            restrict:'EA',
            priority: 1009,
            template:function(elem,attr){
                var html =
                        '<div sc-select sc-dataurl="\''+iServiceConfig.iwhDataHost+'/web/containerGroupRest/getContainerGroupList\'" '+
                        ' sc-filter-fields="remark,code" ' +
                        ' sc-filter-mode="front" ' +
                        'sc-display-fields="code,remark" sc-value-field="code"  '
                    ;
                if(attr.scSelectedValues){
                    html = html+' sc-selected-values="'+attr.scSelectedValues+'"';
                }
                if(attr.scSelectMode){
                    html = html+' sc-select-mode="'+attr.scSelectMode+'"';
                }
                if(attr.scSelection){
                    html = html+' sc-selection="'+attr.scSelection+'"';
                }
                if(attr.scSelectionN){
                    html = html+' sc-selection-n="'+attr.scSelectionN+'"';
                }
                if(attr.scChange){
                    html = html+' sc-change="'+attr.scChange+'"';
                }
                if(attr.scChangeN){
                    html = html+' sc-change-n="'+attr.scChangeN+'"';
                }
                if(attr.scModel){
                    html = html+' sc-model="'+attr.scModel+'"';
                }
                if(attr.scStrictModel){
                    html = html+' sc-strict-model="'+attr.scStrictModel+'"';
                }
                if(attr.scParams){
                    html = html+' sc-params="'+attr.scParams+'"';
                }
                if(attr.scValue){
                    html = html+' sc-value="'+attr.scValue+'"';
                }
                if(attr.scDefault){
                    html = html+' sc-default="'+attr.scDefault+'"';
                }
                if(attr.scWith){
                    html=html+' sc-with="'+attr.scWith+'"';
                }
                if(attr.scCode){
                    html=html+' sc-code="'+attr.scCode+'"';
                }
                if(attr.scOnlyRead){
                    html = html+' sc-only-read="'+attr.scOnlyRead+'"';
                }
                html = html+'></div>';
                return html;
            },
            controller:['$scope',function($scope) {
            }],
            link:linkFn
        };
        function linkFn(scope,element,attr,ctrl){
        }
    }

    // idata 供应商弹出框
    angular.module('ngSelect').directive('scSelectPopIdataSupplier', scSelectPopIdataSupplier);
    scSelectPopIdataSupplier.$inject = [ '$timeout','NgSelect','iServiceConfig'];
    function scSelectPopIdataSupplier($timeout,NgSelect,iServiceConfig){
        return{
            restrict:'EA',
            priority: 1009,
            template:function(elem,attr){
                var html =
                        '<div sc-select-pop sc-dataurl="\''+iServiceConfig.idataHost+'/web/supplierRest/getSupplierList\'"'+
                        ' sc-selected-display-fields="fullName"'+
                        ' sc-value-field="code"'
                    ;
                if(attr.scSelectMode){
                    html = html+' sc-select-mode="'+attr.scSelectMode+'"';
                }
                if(attr.scSelection){
                    html = html+' sc-selection="'+attr.scSelection+'"';
                }
                if(attr.scChange){
                    html = html+' sc-change="'+attr.scChange+'"';
                }
                if(attr.scChangeN){
                    html = html+' sc-change-n="'+attr.scChangeN+'"';
                }
                if(attr.scModel){
                    html = html+' sc-model="'+attr.scModel+'"';
                }
                if(attr.scStrictModel){
                    html = html+' sc-strict-model="'+attr.scStrictModel+'"';
                }
                if(attr.scParams){
                    html = html+' sc-params="'+attr.scParams+'"';
                }
                if(attr.scValue){
                    html = html+' sc-value="'+attr.scValue+'"';
                }
                if(attr.scWith){
                    html=html+' sc-with="'+attr.scWith+'"';
                }
                if(attr.scCode){
                    html=html+' sc-code="'+attr.scCode+'"';
                }
                if(attr.scOnlyRead){
                    html = html+' sc-only-read="'+attr.scOnlyRead+'"';
                }
                if(attr.scSelectedValues){
                    html = html+' sc-select-values="'+attr.scSelectedValues+'"';
                }
                html = html+'>' ;
                var div = '<div sc-table show-filter=\'true\'';
                // if(attr.scFixedCol == ''){
                    var fixedTailCounts=1,fixedFrontCounts=1;
                    if(attr.scFixedTailCounts || attr.scFixedTailCounts == 0){
                        fixedTailCounts = parseInt(attr.scFixedTailCounts);
                    }
                    if(attr.scFixedFrontCounts || attr.scFixedFrontCounts == 0){
                        fixedFrontCounts = parseInt(attr.scFixedFrontCounts);
                    }
                    div+= ' sc-pop-fixed-col sc-fixed-front-counts="'+fixedFrontCounts+'" sc-fixed-tail-counts="'+fixedTailCounts+'"';
                // }
                if(attr.scPopTableDrag == ''){
                    div+=' sc-pop-table-drag';
                }
                if(attr.scPopTableDragWidth == ''){
                    div+=' sc-pop-table-drag-width';
                }
                div+='>';
                html+=div+
                    '<div row-define>' +
                '<div column-define title-component="sc-select-all" data-component="sc-select-row=\'row\'"></div>' +
                '<div column-define data-title="编码" data-filter="code"><span>{{row.code}}</span></div>' +
                '<div column-define data-title="简称" data-filter="name"><span>{{row.name}}</span></div>' +
                // '<div column-define data-title="id" data-filter="id"><span>{{row.id}}</span></div>' +
                '<div column-define data-title="商品编码" data-filter="skuCode"><span>{{row.skuCode}}</span></div>' +
                '<div column-define data-title="货主编码" data-filter="ownerCode"><span>{{row.ownerCode}}</span></div>' +
                '<div column-define data-title="简称英文/拼音" data-filter="englishName"><span>{{row.englishName}}</span></div>' +
                '<div column-define data-title="全称" data-filter="fullName"><span>{{row.fullName}}</span></div>' +
                '<div column-define data-title="优先级" data-filter="priority"><span>{{row.priority}}</span></div>' +
                '<div column-define data-title="详细地址" data-filter="address"><span>{{row.address}}</span></div>' +
                '<div column-define data-title="联系人" data-filter="contact"><span>{{row.contact}}</span></div>' +
                '<div column-define data-title="联系人电话" data-filter="phone"><span>{{row.phone}}</span></div>' +
                '<div column-define data-title="联系人传真" data-filter="fax"><span>{{row.fax}}</span></div>' +
                '<div column-define data-title="联系人电子邮件" data-filter="email"><span>{{row.email}}</span></div>' +
                    '</div></div>';
                return html;
            },
            controller:['$scope',function($scope) {
            }],
            link:linkFn
        };
        function linkFn(scope,element,attr,ctrl){
        }
    }

    //idata 承运商弹出框
    angular.module('ngSelect').directive('scSelectPopIdataCarrier', scSelectPopIdataCarrier);
    scSelectPopIdataCarrier.$inject = [ '$timeout','NgSelect','iServiceConfig'];
    function scSelectPopIdataCarrier($timeout,NgSelect,iServiceConfig){
        return{
            restrict:'EA',
            priority: 1009,
            template:function(elem,attr){
                var html =
                        '<div sc-select-pop sc-dataurl="\''+iServiceConfig.idataHost+'/web/carrierRest/getCarrierList\'"'+
                        ' sc-value-field="code"'+
                        ' sc-display-splitter="---"'
                    ;

                if (attr.scSelectedDisplayFields) {
                    html = html +' sc-selected-display-fields="'+attr.scSelectedDisplayFields+'"';
                } else {
                    html = html +' sc-selected-display-fields="name"';
                }
                if(attr.scSelectMode){
                    html = html+' sc-select-mode="'+attr.scSelectMode+'"';
                }
                if(attr.scSelection){
                    html = html+' sc-selection="'+attr.scSelection+'"';
                }
                if(attr.scChange){
                    html = html+' sc-change="'+attr.scChange+'"';
                }
                if(attr.scChangeN){
                    html = html+' sc-change-n="'+attr.scChangeN+'"';
                }
                if(attr.scModel){
                    html = html+' sc-model="'+attr.scModel+'"';
                }
                if(attr.scStrictModel){
                    html = html+' sc-strict-model="'+attr.scStrictModel+'"';
                }
                if(attr.scParams){
                    html = html+' sc-params="'+attr.scParams+'"';
                }
                if(attr.scValue){
                    html = html+' sc-value="'+attr.scValue+'"';
                }
                if(attr.scWith){
                    html=html+' sc-with="'+attr.scWith+'"';
                }
                if(attr.scCode){
                    html=html+' sc-code="'+attr.scCode+'"';
                }
                if(attr.scOnlyRead){
                    html = html+' sc-only-read="'+attr.scOnlyRead+'"';
                }
                if(attr.scSelectedValues){
                    html = html+' sc-select-values="'+attr.scSelectedValues+'"';
                }
                html = html+'>' ;
                var div = '<div sc-table show-filter=\'true\'';
                // if(attr.scFixedCol == ''){
                    var fixedTailCounts=1,fixedFrontCounts=1;
                    if(attr.scFixedTailCounts || attr.scFixedTailCounts == 0){
                        fixedTailCounts = parseInt(attr.scFixedTailCounts);
                    }
                    if(attr.scFixedFrontCounts || attr.scFixedFrontCounts == 0){
                        fixedFrontCounts = parseInt(attr.scFixedFrontCounts);
                    }
                    div+= ' sc-pop-fixed-col sc-fixed-front-counts="'+fixedFrontCounts+'" sc-fixed-tail-counts="'+fixedTailCounts+'"';
                // }
                if(attr.scPopTableDrag == ''){
                    div+=' sc-pop-table-drag';
                }
                if(attr.scPopTableDragWidth == ''){
                    div+=' sc-pop-table-drag-width';
                }
                div+='>';
                html+=div+
                    '<div row-define>' +
                '<div column-define title-component="sc-select-all" data-component="sc-select-row=\'row\'"></div>' +
                // '<div column-define data-title="承运商ID" data-filter="id"><span>{{row.id}}</span></div>' +
                // '<div column-define data-title="租户ID" data-filter="tenantId"><span>{{row.tenantId}}</span></div>' +
                '<div column-define data-title="承运商简称" data-filter="name"><span>{{row.name}}</span></div>' +
                '<div column-define data-title="承运商编码" data-filter="code"><span>{{row.code}}</span></div>' +
                '<div column-define data-title="货主编码" data-filter="ownerCode"><span>{{row.ownerCode}}</span></div>' +
                '<div column-define data-title="承运商英文名称" data-filter="englishName"><span>{{row.englishName}}</span></div>' +
                '<div column-define data-title="承运商全称" data-filter="fullName"><span>{{row.fullName}}</span></div>' +
                '<div column-define data-title="详细地址" data-filter="address"><span>{{row.address}}</span></div>' +
                '<div column-define data-title="联系人" data-filter="contact"><span>{{row.contact}}</span></div>' +
                '<div column-define data-title="联系人电话" data-filter="phone"><span>{{row.phone}}</span></div>' +
                '<div column-define data-title="联系人传真" data-filter="fax"><span>{{row.fax}}</span></div>' +
                '<div column-define data-title="联系人电子邮件" data-filter="email"><span>{{row.email}}</span></div>' +
                '<div column-define data-title="说明" data-filter="remark"><span>{{row.remark}}</span></div>' +
                    '</div></div>';
                return html;
            },
            controller:['$scope',function($scope) {
            }],
            link:linkFn
        };
        function linkFn(scope,element,attr,ctrl){
        }
    }

    //idata 客户弹出框
    angular.module('ngSelect').directive('scSelectPopIdataCustomer', scSelectPopIdataCustomer);
    scSelectPopIdataCustomer.$inject = [ '$timeout','NgSelect','iServiceConfig'];
    function scSelectPopIdataCustomer($timeout,NgSelect,iServiceConfig){
        return{
            restrict:'EA',
            priority: 1009,
            template:function(elem,attr){
                var html =
                        '<div sc-select-pop sc-dataurl="\''+iServiceConfig.idataHost+'/web/customerRest/getCustomerList\'"'+
                        ' sc-value-field="code"'
                    ;
                if (attr.scSelectedDisplayFields) {
                    html = html +' sc-selected-display-fields="'+attr.scSelectedDisplayFields+'"';
                } else {
                    html = html +' sc-selected-display-fields="fullName"';
                }
                if(attr.scSelectMode){
                    html = html+' sc-select-mode="'+attr.scSelectMode+'"';
                }
                if(attr.scSelection){
                    html = html+' sc-selection="'+attr.scSelection+'"';
                }
                if(attr.scChange){
                    html = html+' sc-change="'+attr.scChange+'"';
                }
                if(attr.scChangeN){
                    html = html+' sc-change-n="'+attr.scChangeN+'"';
                }
                if(attr.scModel){
                    html = html+' sc-model="'+attr.scModel+'"';
                }
                if(attr.scStrictModel){
                    html = html+' sc-strict-model="'+attr.scStrictModel+'"';
                }
                if(attr.scParams){
                    html = html+' sc-params="'+attr.scParams+'"';
                }
                if(attr.scValue){
                    html = html+' sc-value="'+attr.scValue+'"';
                }
                if(attr.scWith){
                    html=html+' sc-with="'+attr.scWith+'"';
                }
                if(attr.scCode){
                    html=html+' sc-code="'+attr.scCode+'"';
                }
                if(attr.scOnlyRead){
                    html = html+' sc-only-read="'+attr.scOnlyRead+'"';
                }
                if(attr.scSelectedValues){
                    html = html+' sc-select-values="'+attr.scSelectedValues+'"';
                }
                html = html+'>' ;
                var div = '<div sc-table show-filter=\'true\'';
                // if(attr.scFixedCol == ''){
                    var fixedTailCounts=1,fixedFrontCounts=1;
                    if(attr.scFixedTailCounts || attr.scFixedTailCounts == 0){
                        fixedTailCounts = parseInt(attr.scFixedTailCounts);
                    }
                    if(attr.scFixedFrontCounts || attr.scFixedFrontCounts == 0){
                        fixedFrontCounts = parseInt(attr.scFixedFrontCounts);
                    }
                    div+= ' sc-pop-fixed-col sc-fixed-front-counts="'+fixedFrontCounts+'" sc-fixed-tail-counts="'+fixedTailCounts+'"';
                // }
                if(attr.scPopTableDrag == ''){
                    div+=' sc-pop-table-drag';
                }
                if(attr.scPopTableDragWidth == ''){
                    div+=' sc-pop-table-drag-width';
                }
                div+='>';
                html+=div+
                    '<div row-define>' +
                    '<div column-define title-component="sc-select-all" data-component="sc-select-row=\'row\'"></div>' +
                    '<div column-define data-title="客户名称" data-filter="fullName"><span>{{row.fullName}}</span></div>' +
                    '<div column-define data-title="客户编码" data-filter="code"><span>{{row.code}}</span></div>' +
                    '<div column-define data-title="地址"><span>{{row.address}}</span></div>' +
                    '</div></div>';
                return html;
            },
            controller:['$scope',function($scope) {
            }],
            link:linkFn
        };
        function linkFn(scope,element,attr,ctrl){
        }
    }

    //idata 商品弹出框
    angular.module('ngSelect').directive('scSelectPopIdataSku', scSelectPopIdataSku);
    scSelectPopIdataSku.$inject = [ '$timeout','NgSelect','iServiceConfig'];
    function scSelectPopIdataSku($timeout,NgSelect,iServiceConfig){
        return{
            restrict:'EA',
            priority: 1009,
            template:function(elem,attr){
                var html =
                        '<div sc-select-pop sc-dataurl="\''+iServiceConfig.idataHost+'/web/skuRest/getSkuList\'"'+
                        // ' sc-selected-display-fields="name"'+
                        ' sc-value-field="code"'
                    ;
                if (attr.scSelectedDisplayFields) {
                    html = html +' sc-selected-display-fields="'+attr.scSelectedDisplayFields+'"';
                } else {
                    html = html +' sc-selected-display-fields="name"';
                }
                if(attr.scSelectMode){
                    html = html+' sc-select-mode="'+attr.scSelectMode+'"';
                }
                if(attr.scSelection){
                    html = html+' sc-selection="'+attr.scSelection+'"';
                }
                if(attr.scChange){
                    html = html+' sc-change="'+attr.scChange+'"';
                }
                if(attr.scChangeN){
                    html = html+' sc-change-n="'+attr.scChangeN+'"';
                }
                if(attr.scModel){
                    html = html+' sc-model="'+attr.scModel+'"';
                }
                if(attr.scStrictModel){
                    html = html+' sc-strict-model="'+attr.scStrictModel+'"';
                }
                if(attr.scParams){
                    html = html+' sc-params="'+attr.scParams+'"';
                }
                if(attr.scValue){
                    html = html+' sc-value="'+attr.scValue+'"';
                }
                if(attr.scWith){
                    html=html+' sc-with="'+attr.scWith+'"';
                }
                if(attr.scCode){
                    html=html+' sc-code="'+attr.scCode+'"';
                }
                if(attr.scOnlyRead){
                    html = html+' sc-only-read="'+attr.scOnlyRead+'"';
                }
                if(attr.scSelectedValues){
                    html = html+' sc-select-values="'+attr.scSelectedValues+'"';
                }
                html = html+'>' ;
                var div = '<div sc-table show-filter=\'true\'';
                // if(attr.scFixedCol == ''){
                    var fixedTailCounts=1,fixedFrontCounts=1;
                    if(attr.scFixedTailCounts || attr.scFixedTailCounts == 0){
                        fixedTailCounts = parseInt(attr.scFixedTailCounts);
                    }
                    if(attr.scFixedFrontCounts || attr.scFixedFrontCounts == 0){
                        fixedFrontCounts = parseInt(attr.scFixedFrontCounts);
                    }
                    div+= ' sc-pop-fixed-col sc-fixed-front-counts="'+fixedFrontCounts+'" sc-fixed-tail-counts="'+fixedTailCounts+'"';
                // }
                if(attr.scPopTableDrag == ''){
                    div+=' sc-pop-table-drag';
                }
                if(attr.scPopTableDragWidth == ''){
                    div+=' sc-pop-table-drag-width';
                }
                div+='>';
                html+=div+
                '<div row-define>' +
                '<div column-define title-component="sc-select-all" data-component="sc-select-row=\'row\'"></div>' +
                    '<div column-define data-title="名称" data-filter="name"><span>{{row.name}}</span></div>' +
                    '<div column-define data-title="编码" data-filter="code"><span>{{row.code}}</span></div>' +
                '<div column-define data-title="货主编码" data-filter="ownerCode"><span>{{row.ownerCode}}</span></div>' +
                '<div column-define data-title="长" data-filter="length"><span>{{row.length}}</span></div>' +
                '<div column-define data-title="宽" data-filter="width"><span>{{row.width}}</span></div>' +
                '<div column-define data-title="高" data-filter="height"><span>{{row.height}}</span></div>' +
                '<div column-define data-title="体积" data-filter="cube"><span>{{row.cube}}</span></div>' +
                '<div column-define data-title="毛重" data-filter="grosswgt"><span>{{row.grosswgt}}</span></div>' +
                '<div column-define data-title="净重" data-filter="netwgt"><span>{{row.netwgt}}</span></div>' +
                '<div column-define data-title="皮重" data-filter="skinwgt"><span>{{row.skinwgt}}</span></div>' +
                    '</div></div>';
                return html;
            },
            controller:['$scope',function($scope) {
            }],
            link:linkFn
        };
        function linkFn(scope,element,attr,ctrl){
        }
    }

    //idata 商品分类下拉框
    angular.module('ngSelect').directive('scSelectIdataSkuType', scSelectIdataSkuType);
    scSelectIdataSkuType.$inject = [ '$timeout','NgSelect','iServiceConfig'];
    function scSelectIdataSkuType($timeout,NgSelect,iServiceConfig){
        return{
            restrict:'EA',
            priority: 1009,
            template:function(elem,attr){
                var html =
                        '<div sc-select sc-dataurl="\''+iServiceConfig.idataHost+'/web/skucategoryRest/treedata\'" sc-filter-fields="name,code" ' +
                        ' sc-filter-mode="front" ' +
                        'sc-display-fields="title" sc-value-field="nodeId" parent-id="parentId" '
                    ;
                if(attr.scSelectedValues){
                    html = html+' sc-selected-values="'+attr.scSelectedValues+'"';
                }
                if(attr.scSelectMode){
                    html = html+' sc-select-mode="'+attr.scSelectMode+'"';
                }
                if(attr.scSelection){
                    html = html+' sc-selection="'+attr.scSelection+'"';
                }
                if(attr.scSelectionN){
                    html = html+' sc-selection-n="'+attr.scSelectionN+'"';
                }
                if(attr.scChange){
                    html = html+' sc-change="'+attr.scChange+'"';
                }
                if(attr.scChangeN){
                    html = html+' sc-change-n="'+attr.scChangeN+'"';
                }
                if(attr.scModel){
                    html = html+' sc-model="'+attr.scModel+'"';
                }
                if(attr.scStrictModel){
                    html = html+' sc-strict-model="'+attr.scStrictModel+'"';
                }
                if(attr.scParams){
                    html = html+' sc-params="'+attr.scParams+'"';
                }
                if(attr.scValue){
                    html = html+' sc-value="'+attr.scValue+'"';
                }
                if(attr.scDefault){
                    html = html+' sc-default="'+attr.scDefault+'"';
                }
                if(attr.scWith){
                    html=html+' sc-with="'+attr.scWith+'"';
                }
                if(attr.scCode){
                    html=html+' sc-code="'+attr.scCode+'"';
                }
                if(attr.scOnlyRead){
                    html = html+' sc-only-read="'+attr.scOnlyRead+'"';
                }
                if(attr.scTreeCode){
                    html = html+' sc-tree-code="'+attr.scTreeCode+'"';
                }
                html = html+'></div>';
                return html;
            },
            controller:['$scope',function($scope) {
            }],
            link:linkFn
        };
        function linkFn(scope,element,attr,ctrl){
        }
    }

    // iwh-data 仓库通道弹出框
    angular.module('ngSelect').directive('scSelectPopIwhDataChannel', scSelectPopIwhDataChannel);
    scSelectPopIwhDataChannel.$inject = [ '$timeout','NgSelect','iServiceConfig'];
    function scSelectPopIwhDataChannel($timeout,NgSelect,iServiceConfig){
        return{
            restrict:'EA',
            priority: 1009,
            template:function(elem,attr){
                var html =
                        '<div sc-select-pop sc-dataurl="\''+iServiceConfig.iwhDataHost+'/web/warehouseRest/getRoadwayList\'"'+
                        ' sc-selected-display-fields="code,name"'+
                        ' sc-value-field="code"'+
                        ' sc-display-splitter="---"'
                    ;
                if(attr.scSelectMode){
                    html = html+' sc-select-mode="'+attr.scSelectMode+'"';
                }
                if(attr.scSelection){
                    html = html+' sc-selection="'+attr.scSelection+'"';
                }
                if(attr.scChange){
                    html = html+' sc-change="'+attr.scChange+'"';
                }
                if(attr.scChangeN){
                    html = html+' sc-change-n="'+attr.scChangeN+'"';
                }
                if(attr.scModel){
                    html = html+' sc-model="'+attr.scModel+'"';
                }
                if(attr.scStrictModel){
                    html = html+' sc-strict-model="'+attr.scStrictModel+'"';
                }
                if(attr.scParams){
                    html = html+' sc-params="'+attr.scParams+'"';
                }
                if(attr.scValue){
                    html = html+' sc-value="'+attr.scValue+'"';
                }
                if(attr.scWith){
                    html=html+' sc-with="'+attr.scWith+'"';
                }
                if(attr.scCode){
                    html=html+' sc-code="'+attr.scCode+'"';
                }
                if(attr.scOnlyRead){
                    html = html+' sc-only-read="'+attr.scOnlyRead+'"';
                }
                if(attr.scSelectedValues){
                    html = html+' sc-select-values="'+attr.scSelectedValues+'"';
                }
                html = html+'>' ;
                var div = '<div sc-table show-filter=\'true\'';
                // if(attr.scFixedCol == ''){
                    var fixedTailCounts=1,fixedFrontCounts=1;
                    if(attr.scFixedTailCounts || attr.scFixedTailCounts == 0){
                        fixedTailCounts = parseInt(attr.scFixedTailCounts);
                    }
                    if(attr.scFixedFrontCounts || attr.scFixedFrontCounts == 0){
                        fixedFrontCounts = parseInt(attr.scFixedFrontCounts);
                    }
                    div+= ' sc-pop-fixed-col sc-fixed-front-counts="'+fixedFrontCounts+'" sc-fixed-tail-counts="'+fixedTailCounts+'"';
                // }
                if(attr.scPopTableDrag == ''){
                    div+=' sc-pop-table-drag';
                }
                if(attr.scPopTableDragWidth == ''){
                    div+=' sc-pop-table-drag-width';
                }
                div+='>';
                html+=div+
                    '<div row-define>' +
                '<div column-define title-component="sc-select-all" data-component="sc-select-row=\'row\'"></div>' +
                '<div column-define data-title="名称" data-filter="name"><span>{{row.name}}</span></div>' +
                '<div column-define data-title="编码" data-filter="code"><span>{{row.code}}</span></div>' +
                    '</div></div>';
                return html;
            },
            controller:['$scope',function($scope) {
            }],
            link:linkFn
        };
        function linkFn(scope,element,attr,ctrl){
        }
    }

    //组织选择--下拉选择框--2017/12/20
    angular.module('ngSelect').directive('scSelectOrg', scSelectOrg);
    scSelectOrg.$inject = [ '$timeout','NgSelect','iServiceConfig'];
    function scSelectOrg($timeout,NgSelect,iServiceConfig){
        return{
            restrict:'EA',
            priority: 1009,
            template:function(elem,attr){
                var html =
                        '<div sc-select sc-dataurl="\''+iServiceConfig.iuserHost+'/web/org/treedata\'" sc-filter-fields="name,code" ' +
                        ' sc-filter-mode="front" ' +
                        'sc-display-fields="title" sc-value-field="nodeId" parent-id="parentId" '
                    ;
                if(attr.scSelectedValues){
                    html = html+' sc-selected-values="'+attr.scSelectedValues+'"';
                }
                if(attr.scSelectMode){
                    html = html+' sc-select-mode="'+attr.scSelectMode+'"';
                }
                if(attr.scSelection){
                    html = html+' sc-selection="'+attr.scSelection+'"';
                }
                if(attr.scSelectionN){
                    html = html+' sc-selection-n="'+attr.scSelectionN+'"';
                }
                if(attr.scChange){
                    html = html+' sc-change="'+attr.scChange+'"';
                }
                if(attr.scChangeN){
                    html = html+' sc-change-n="'+attr.scChangeN+'"';
                }
                if(attr.scModel){
                    html = html+' sc-model="'+attr.scModel+'"';
                }
                if(attr.scStrictModel){
                    html = html+' sc-strict-model="'+attr.scStrictModel+'"';
                }
                if(attr.scParams){
                    html = html+' sc-params="'+attr.scParams+'"';
                }
                if(attr.scValue){
                    html = html+' sc-value="'+attr.scValue+'"';
                }
                if(attr.scDefault){
                    html = html+' sc-default="'+attr.scDefault+'"';
                }
                if(attr.scWith){
                    html=html+' sc-with="'+attr.scWith+'"';
                }
                if(attr.scCode){
                    html=html+' sc-code="'+attr.scCode+'"';
                }
                if(attr.scOnlyRead){
                    html = html+' sc-only-read="'+attr.scOnlyRead+'"';
                }
                if(attr.scTreeCode){
                    html = html+' sc-tree-code="'+attr.scTreeCode+'"';
                }
                html = html+'></div>';
                return html;
            },
            controller:['$scope',function($scope) {
            }],
            link:linkFn
        };
        function linkFn(scope,element,attr,ctrl){
        }
    }

    // 单据(so)--ship--弹出框--2017/12/20
    angular.module('ngSelect').directive('scSelectPopSo', scSelectPopSo);
    scSelectPopSo.$inject = [ '$timeout','NgSelect','iServiceConfig'];
    function scSelectPopSo($timeout,NgSelect,iServiceConfig){
        return{
            restrict:'EA',
            priority: 1009,
            template:function(elem,attr){
            	var iplanUrl = iServiceConfig.iplanHost + '/web/soRest/list';
                var html =
                        '<div sc-select-pop sc-dataurl="\''+ iplanUrl + '\'"'+
                        ' sc-value-field="soNum"'
                    ;
                if (attr.scSelectedDisplayFields) {
                    html = html +' sc-selected-display-fields="'+attr.scSelectedDisplayFields+'"';
                } else {
                    html = html +' sc-selected-display-fields="soNum"';
                }
                if(attr.scSelectMode){
                    html = html+' sc-select-mode="'+attr.scSelectMode+'"';
                }
                if(attr.scSelection){
                    html = html+' sc-selection="'+attr.scSelection+'"';
                }
                if(attr.scChange){
                    html = html+' sc-change="'+attr.scChange+'"';
                }
                if(attr.scChangeN){
                    html = html+' sc-change-n="'+attr.scChangeN+'"';
                }
                if(attr.scModel){
                    html = html+' sc-model="'+attr.scModel+'"';
                }
                if(attr.scStrictModel){
                    html = html+' sc-strict-model="'+attr.scStrictModel+'"';
                }
                if(attr.scParams){
                    html = html+' sc-params="'+attr.scParams+'"';
                }
                if(attr.scValue){
                    html = html+' sc-value="'+attr.scValue+'"';
                }
                if(attr.scWith){
                    html=html+' sc-with="'+attr.scWith+'"';
                }
                if(attr.scCode){
                    html=html+' sc-code="'+attr.scCode+'"';
                }
                if(attr.scOnlyRead){
                    html = html+' sc-only-read="'+attr.scOnlyRead+'"';
                }
                if(attr.scSelectedValues){
                    html = html+' sc-select-values="'+attr.scSelectedValues+'"';
                }
                html = html+'>' ;
                var div = '<div sc-table show-filter=\'true\'';
                // if(attr.scFixedCol == ''){
                var fixedTailCounts=1,fixedFrontCounts=1;
                if(attr.scFixedTailCounts || attr.scFixedTailCounts == 0){
                    fixedTailCounts = parseInt(attr.scFixedTailCounts);
                }
                if(attr.scFixedFrontCounts || attr.scFixedFrontCounts == 0){
                    fixedFrontCounts = parseInt(attr.scFixedFrontCounts);
                }
                div+= ' sc-pop-fixed-col sc-fixed-front-counts="'+fixedFrontCounts+'" sc-fixed-tail-counts="'+fixedTailCounts+'"';
                // }
                if(attr.scPopTableDrag == ''){
                    div+=' sc-pop-table-drag';
                }
                if(attr.scPopTableDragWidth == ''){
                    div+=' sc-pop-table-drag-width';
                }
                div+='>';
                html+=div+
                    '<div row-define>' +
                    '<div column-define title-component="sc-select-all" data-component="sc-select-row=\'row\'"></div>' +
                    '<div column-define data-title="出库单号" data-filter="soNum"><span>{{row.soNum}}</span></div>' +
                    '<div column-define data-title="外部单号" data-filter="extSoNum"><span>{{row.extSoNum}}</span></div>' +
                    '<div column-define data-title="状态描述" ><span>{{row.statusText}}</span></div>' +
                    '<div column-define data-title="订单类型描述" ><span>{{row.typeText}}</span></div>' +
                    '<div column-define data-title="订单总数量"><span>{{row.totalQty}}</span></div>' +
                    '<div column-define data-title="发货数量"><span>{{row.shipQty}}</span></div>' +
                    '<div column-define data-title="货主编码" data-filter="ownerCode"><span>{{row.ownerCode}}</span></div>' +
                    '<div column-define data-title="货主名称" data-filter="ownerName"><span>{{row.ownerName}}</span></div>' +
                    '<div column-define data-title="订单时间"><span>{{row.orderTime | date:"yyyy-MM-dd HH:mm:ss"}}</span></div>' +
                    '</div></div>';
                return html;
            },
            controller:['$scope',function($scope) {
            }],
            link:linkFn
        };
        function linkFn(scope,element,attr,ctrl){
        }
    }

    // 单据(asn)--receipt--弹出框--2017/12/20
    angular.module('ngSelect').directive('scSelectPopAsn', scSelectPopAsn);
    scSelectPopAsn.$inject = [ '$timeout','NgSelect','iServiceConfig'];
    function scSelectPopAsn($timeout,NgSelect,iServiceConfig){
        return{
            restrict:'EA',
            priority: 1009,
            template:function(elem,attr){
            	var iplanUrl = iServiceConfig.iplanHost + '/web/asnRest/list';
                var html =
                        '<div sc-select-pop sc-dataurl="\''+ iplanUrl +'\'"'+
                        ' sc-value-field="asnNum"'
                    ;
                if (attr.scSelectedDisplayFields) {
                    html = html +' sc-selected-display-fields="'+attr.scSelectedDisplayFields+'"';
                } else {
                    html = html +' sc-selected-display-fields="asnNum"';
                }
                if(attr.scSelectMode){
                    html = html+' sc-select-mode="'+attr.scSelectMode+'"';
                }
                if(attr.scSelection){
                    html = html+' sc-selection="'+attr.scSelection+'"';
                }
                if(attr.scChange){
                    html = html+' sc-change="'+attr.scChange+'"';
                }
                if(attr.scChangeN){
                    html = html+' sc-change-n="'+attr.scChangeN+'"';
                }
                if(attr.scModel){
                    html = html+' sc-model="'+attr.scModel+'"';
                }
                if(attr.scStrictModel){
                    html = html+' sc-strict-model="'+attr.scStrictModel+'"';
                }
                if(attr.scParams){
                    html = html+' sc-params="'+attr.scParams+'"';
                }
                if(attr.scValue){
                    html = html+' sc-value="'+attr.scValue+'"';
                }
                if(attr.scWith){
                    html=html+' sc-with="'+attr.scWith+'"';
                }
                if(attr.scCode){
                    html=html+' sc-code="'+attr.scCode+'"';
                }
                if(attr.scOnlyRead){
                    html = html+' sc-only-read="'+attr.scOnlyRead+'"';
                }
                if(attr.scSelectedValues){
                    html = html+' sc-select-values="'+attr.scSelectedValues+'"';
                }
                html = html+'>' ;
                var div = '<div sc-table show-filter=\'true\'';
                // if(attr.scFixedCol == ''){
                var fixedTailCounts=1,fixedFrontCounts=1;
                if(attr.scFixedTailCounts || attr.scFixedTailCounts == 0){
                    fixedTailCounts = parseInt(attr.scFixedTailCounts);
                }
                if(attr.scFixedFrontCounts || attr.scFixedFrontCounts == 0){
                    fixedFrontCounts = parseInt(attr.scFixedFrontCounts);
                }
                div+= ' sc-pop-fixed-col sc-fixed-front-counts="'+fixedFrontCounts+'" sc-fixed-tail-counts="'+fixedTailCounts+'"';
                // }
                if(attr.scPopTableDrag == ''){
                    div+=' sc-pop-table-drag';
                }
                if(attr.scPopTableDragWidth == ''){
                    div+=' sc-pop-table-drag-width';
                }
                div+='>';
                html+=div+
                    '<div row-define>' +
                    '<div column-define title-component="sc-select-all" data-component="sc-select-row=\'row\'"></div>' +
                    '<div column-define data-title="ASN单号" data-filter="asnNum"><span>{{row.asnNum}}</span></div>' +
                    '<div column-define data-title="外部单号" data-filter="extNum"><span>{{row.extNum}}</span></div>' +
                    '<div column-define data-title="状态描述" ><span>{{row.statusText}}</span></div>' +
                    '<div column-define data-title="订单类型描述" ><span>{{row.typeText}}</span></div>' +
                    '<div column-define data-title="待收货数量"><span>{{row.qty}}</span></div>' +
                    '<div column-define data-title="已收货数量"><span>{{row.receiveQty}}</span></div>' +
                    '<div column-define data-title="货主编码" data-filter="ownerCode"><span>{{row.ownerCode}}</span></div>' +
                    '<div column-define data-title="货主名称" data-filter="ownerName"><span>{{row.ownerName}}</span></div>' +
                    '<div column-define data-title="订单时间"><span>{{row.asnTime | date:"yyyy-MM-dd HH:mm:ss"}}</span></div>' +
                    '</div></div>';
                return html;
            },
            controller:['$scope',function($scope) {
            }],
            link:linkFn
        };
        function linkFn(scope,element,attr,ctrl){
        }
    }

    //iwh-strategy rule-type下拉框
    angular.module('ngSelect').directive('scSelectStrategyRule', ['$timeout','NgSelect','iServiceConfig', function($timeout,NgSelect,iServiceConfig){
        return{
            restrict:'EA',
            priority: 1009,
            template:function(elem,attr){
                var url = iServiceConfig.iwhStrategyHost+'/web/components/rule?rule=';
                if(attr.scRule) {
                    url += attr.scRule;
                }
                var html =
                        '<div sc-select sc-dataurl="\'' + url + '\'" sc-filter-fields="name,code" ' +
                        'sc-filter-mode="front" ' +
                        'sc-display-fields="name" sc-value-field="code" '
                    ;
                if(attr.scSelectedValues){
                    html = html+' sc-selected-values="'+attr.scSelectedValues+'"';
                }
                if(attr.scSelectMode){
                    html = html+' sc-select-mode="'+attr.scSelectMode+'"';
                }
                if(attr.scSelection){
                    html = html+' sc-selection="'+attr.scSelection+'"';
                }
                if(attr.scSelectionN){
                    html = html+' sc-selection-n="'+attr.scSelectionN+'"';
                }
                if(attr.scChange){
                    html = html+' sc-change="'+attr.scChange+'"';
                }
                if(attr.scChangeN){
                    html = html+' sc-change-n="'+attr.scChangeN+'"';
                }
                if(attr.scModel){
                    html = html+' sc-model="'+attr.scModel+'"';
                }
                if(attr.scStrictModel){
                    html = html+' sc-strict-model="'+attr.scStrictModel+'"';
                }
                if(attr.scParams){
                    html = html+' sc-params="'+attr.scParams+'"';
                }
                if(attr.scValue){
                    html = html+' sc-value="'+attr.scValue+'"';
                }
                if(attr.scDefault){
                    html = html+' sc-default="'+attr.scDefault+'"';
                }
                if(attr.scWith){
                    html=html+' sc-with="'+attr.scWith+'"';
                }
                if(attr.scCode){
                    html=html+' sc-code="'+attr.scCode+'"';
                }
                if(attr.scOnlyRead){
                    html = html+' sc-only-read="'+attr.scOnlyRead+'"';
                }
                html = html+'></div>';
                return html;
            },
            controller:['$scope',function($scope) {
            }],
            link:function (scope,element,attr,ctrl){
            }
        };
        
    }]);

}));
(function(angular, factory) {
    'use strict';
    if (typeof define === 'function' && define.amd) {
        define(['angular'], function(angular) {
            return factory(angular);
        });
    } else {
        return factory(angular);
    }
}(window.angular || null, function(ng) {
    'use strict';

    var app = ng.module("ngSelect");

    app.directive('scSelectAddr', scSelectAddr);
    scSelectAddr.$inject = ['$http','$sce','$localStorage','$timeout', 'iServiceConfig'];
    function scSelectAddr($http,$sce,$localStorage,$timeout,iServiceConfig) {
        return {
            restrict: 'EA',
            template: function (element, attrs) {
                var html =
                    '<div class="area">' +
                    '<div class="areaContent form-control">'+
                        '<b class="areaDelete" ng-click="areaDelete($event)" ng-if="!scOnlyRead && (citySelected||areaSelected)">×</b>'+
                    '<div  ng-if="scFixedField">'+
                    '<div ng-if="!isShowSelected()"><span>请选择所在地区 </span></div>' +
                    '<div ng-if="isShowSelected()">' +
                    '<span ng-show="isShow(\'country\')">{{countrySelected}} </span><b ng-show="isShow(\'country\') &&countrySelected&&provinceSelected">/</b>'+
                    '<span ng-show="isShow(\'province\')">{{provinceSelected}} </span><b ng-show="isShow(\'province\') &&provinceSelected&&citySelected">/</b>'+
                    '<span ng-show="isShow(\'city\')">{{citySelected}}{{len}} </span><b  ng-show="isShow(\'city\') &&citySelected&&areaSelected">/</b>'+
                    '<span ng-show="isShow(\'area\')">{{areaSelected}}</span>'+
                    '</div>'+
                    '</div>'+
                    '<div  ng-if="!scFixedField">'+
                    '<div ng-if="!isShowSelected()"><span>请选择所在地区 </span></div>' +
                    '<div ng-if="isShowSelected()">' +
                    '<span ng-show="isShow(\'country\')">{{countrySelected}} </span><b ng-show="isShow(\'country\') &&countrySelected&&provinceSelected">/</b>'+
                    '<span ng-show="isShow(\'province\')">{{provinceSelected}} </span><b ng-show="isShow(\'province\') &&provinceSelected&&citySelected">/</b>'+
                    '<span ng-show="isShow(\'city\')">{{citySelected}}</span><bng-show="isShow(\'city\') &&citySelected&&areaSelected">/</b>'+
                    '<span ng-show="isShow(\'area\')">{{areaSelected}}</span>'+
                    '</div>'+
                    '</div>'+
                    '<b class="areaArrow"></b>'+
                    '</div>'+
                    '<div class="areaSelect">' +
                    '<b>×</b>'+
                    '<div class="tab1" id="tab1">' +
                    '<div class="menu">' +
                    '<ul>'+
                    '<li id="one1" ng-click="setTab(1)" ng-show="isShowAll(\'country\')&&(isCurrent==1?true:true)" class="{{cursel==1?\'off\':\'\'}}"><span ng-if="!country">请选择</span>' +
                    '<span ng-if="country"  title="{{country}}">{{country}} </span><b class="areaArrow"></b></li>' +
                    '<li id="one2" ng-show="isShowAll(\'province\')&&(isCurrent==2?true:country)" ng-click="setTab(2)" class="{{cursel==2?\'off\':\'\'}}"><span ng-if="!province">请选择</span>' +
                    '<span ng-if="province"  title="{{province}}">{{province}} </span><b class="areaArrow"></b></li>' +
                    '<li id="one3" ng-show="isShowAll(\'city\')&&(isCurrent==3?true:province)" ng-click="setTab(3)" class="{{cursel==3?\'off\':\'\'}}"><span ng-if="!city">请选择</span>' +
                    '<span ng-if="city"  title="{{city}}">{{city}}</span><b class="areaArrow"></b></li>' +
                    '<li id="one4"  ng-show="isShowAll(\'area\')&&(isCurrent==4?true:city)" ng-click="setTab(4)" class="{{cursel==4?\'off\':\'\'}}"><span ng-if="!area">请选择</span>' +
                    '<span ng-if="area"  title="{{area}}">{{area}}</span><b class="areaArrow"></b></li>'+
                    '</ul>' +
                    '</div>' +
                    '<div class="menudiv">' +
                    '<div id="con_one_1" ng-show="isShowAll(\'country\')" class="{{cursel==1?\'active\':\'\'}}">' +
                    '<ul>' +
                    '<li ng-repeat="a in countryAll track by $index" ng-click="getAreas(a,0)">' +
                    '<span title="{{a.name}}">{{a.name}}</span>'+
                    '</li>'+
                    '</ul>'+
                    '</div>' +
                    '<div id="con_one_2" ng-show="isShowAll(\'province\')" class="{{cursel==2?\'active\':\'\'}}">' +
                    '<ul>' +
                    '<li ng-repeat="a in provinceAll track by $index" ng-click="getAreas(a,1)">' +
                    '<span title="{{a.name}}">{{a.name}}</span>'+
                    '</li>'+
                    '</ul>'+
                    '</div>' +
                    '<div id="con_one_3" ng-show="isShowAll(\'city\')"  class="{{cursel==3?\'active\':\'\'}}">' +
                    '<ul>' +
                    '<li ng-repeat="a in cityAll track by $index" ng-click="getAreas(a,2)">' +
                    '<span title="{{a.name}}">{{a.name}}</span>'+
                    '</li>'+
                    '</ul>'+
                    '</div>' +
                    '<div id="con_one_4" ng-show="isShowAll(\'area\')" class="{{cursel==4?\'active\':\'\'}}">' +
                    '<ul>' +
                    '<li ng-repeat="a in areaAll track by $index" ng-click="getAreas(a,3)">' +
                    '<span title="{{a.name}}">{{a.name}}</span>'+
                    '</li>'+
                    '</ul>'+
                    '</div>'+
                    '</div>' +
                    '</div>' +
                    '</div>'+
                    '</div>';
                return html;
            },
            scope: {
                scModel: '=?scModel',
                scCountry:'=?scCountry',
                scProvince:'=?scProvince',
                scCity:'=?scCity',
                scArea:'=?scArea',
                scDisplayMode:'=?scDisplayMode',
                scFixedField:'=?scFixedField',
                scFixedValue:'=?scFixedValue',
                scOnlyRead:'=?scOnlyRead'
            },
            link:{
                pre:linkFn
            }
        };
        function linkFn(scope,element, attrs, ctrl) {
            scope.grade = ['country','province','city','area'];
            scope.countryCode = null;//用于克隆传入code以便监听
            scope.provinceCode = null;
            scope.cityCode = null;
            scope.areaCode = null;
            scope.country = null;//tabs展示
            scope.province = null;
            scope.city = null;
            scope.area = null;
            scope.countrySelected = null;//展示已选中
            scope.provinceSelected = null;
            scope.citySelected = null;
            scope.areaSelected = null;
            scope.countryAll = [];//所有国家、省、市、区
            scope.provinceAll=[];
            scope.cityAll = [];
            scope.areaAll = [];
            scope.isCurrent = 1;
            if(!angular.isArray(scope.scDisplayMode)){
                scope.scDisplayMode = [];
            } else {
                scope.grade = ['country'].concat(scope.scDisplayMode);
            }
            var address = iServiceConfig.idataHost + '/web/address';
            //异步请求
            scope.getRequest = function (area,code,isSelf){
                var url = address +'/'+ area;
                if(isSelf){
                    url = url+'/'+'self'
                }
                if(code){
                    url=url+'/'+code;
                }
                url = url +'?callback=JSON_CALLBACK&token='+$localStorage.authenticationToken;
                $sce.trustAsResourceUrl(url);
                return $http.jsonp(url).success(function(data) {
                    if(data.status === 'SUCCESS') {
                        if (angular.isDefined(data.content) && angular.isArray(data.content) && (data.content.length > 0)) {
                        } else {
                        }
                    }
                });
            };

            //如果没有固定地区等级
            if(!scope.scFixedField){
                var p=  scope.getRequest('country');
                p.then(function(resp) {
                    scope.countryAll = resp.data.content;
                });
            }
            //如果有固定地区等级
            if(scope.scFixedField == 'country' && !scope.scCountry){
                var p=  scope.getRequest('province',scope.scFixedValue);
                p.then(function(resp) {
                    if(angular.isArray(resp.data.content)){
                        scope.provinceAll = resp.data.content;
                        scope.cursel = 2;
                    }
                });
            }
            if(scope.scFixedField == 'province'  && !scope.scProvince){
                var p=  scope.getRequest('city',scope.scFixedValue);
                p.then(function(resp) {
                    if(angular.isArray(resp.data.content)){
                        scope.cityAll = resp.data.content;
                        scope.cursel = 3;
                    }
                });
            }
            if(scope.scFixedField == 'city'  && !scope.scCity){
                var p=  scope.getRequest('area',scope.scFixedValue);
                p.then(function(resp) {
                    if(angular.isArray(resp.data.content)){
                        scope.areaAll = resp.data.content;
                        scope.cursel = 4;
                    }
                });
            }
            //判断默认的下一级地区是否在所请求所有同级地区之中
            scope.inWhich = function(all,a,isFixed){
                if(!isFixed){
                    for(var i=0;i<all.length;i++){
                        if(all[i]['name'] == a){
                            return true;
                        }
                    }
                    return false;
                }else{
                    return true;
                }
            };

            scope.countryCode = angular.copy(scope.scCountry);//用于克隆传入code以便监听
            scope.provinceCode = angular.copy(scope.scProvince);
            scope.cityCode = angular.copy(scope.scCity);
            scope.areaCode = angular.copy(scope.scArea);
            scope.codeExist = function(data,code){
                for(var i=0;i<data.length;i++){
                    if(data[i]['code'] == code){
                        return data[i]['name'];
                    }
                }
                return false;
            };

            scope.flog = true;//用于控制外部更新绑定值时，不触发点击监听的code
            scope.canReset = true;//用于控制点击时改变了区数组，不能触发数组监听器
            scope.canResetCollection = true;//用于控制点击时不触发集合监听器

            //监听各级code
            var chooseCountry = scope.$watch('countryCode',function(newVal,oldVal){
                    if(newVal){
                        //如果有固定并且为country，则以固定值为准
                        if(scope.scFixedField && scope.scFixedField=='country' && newVal !=scope.scFixedValue){
                            newVal= scope.scFixedValue;
                        }
                        var getSelf = scope.getRequest('country',newVal,true);
                        getSelf.then(function(resp){
                            if(resp.data.content) {
                                scope.cursel = 2;
                                scope.country = resp.data.content.name;
                                scope.countrySelected = resp.data.content.name;
                            }else{
                                scope.cursel = 1;
                            }
                        });
                        var getAll = scope.getRequest('province',newVal,false);
                        getAll.then(function (resp){
                            if(angular.isArray(resp.data.content) && resp.data.content.length>0){
                                scope.provinceAll = resp.data.content;
                            }else{
                                scope.provinceAll = [];
                            }
                        });
                    }
                });
            var chooseProvince = scope.$watch('provinceAll',function(newVal,oldVal){
                if(scope.canReset){
                    var fixedValue = scope.provinceCode;
                    if(scope.scFixedField && scope.scFixedField=='province'){
                        fixedValue= scope.scFixedValue;
                    }
                    if(angular.isArray(newVal) && newVal.length>0 && scope.codeExist(newVal,fixedValue)){
                        scope.province = scope.codeExist(newVal,fixedValue);
                        scope.provinceSelected =scope.province;
                        scope.cursel = 3;
                        var getAll = scope.getRequest('city',fixedValue,false);
                        getAll.then(function (resp){
                            if(angular.isArray(resp.data.content) && resp.data.content.length>0){
                                scope.cityAll = resp.data.content;
                            }else{
                                scope.cityAll = [];
                            }
                        });
                    }else{
                        scope.cursel = 2;
                    }
                }
            });
            var chooseCity = scope.$watch('cityAll',function(newVal,oldVal){
                if(scope.canReset){
                    var fixedValue = scope.cityCode;
                    if(scope.scFixedField && scope.scFixedField=='city'){
                        fixedValue= scope.scFixedValue;
                    }
                    if(angular.isArray(newVal) && newVal.length>0 && scope.codeExist(newVal,fixedValue)){
                        scope.city = scope.codeExist(newVal,fixedValue);
                        scope.citySelected =scope.city;
                        scope.cursel = 4;
                        var getAll = scope.getRequest('area',fixedValue,false);
                        getAll.then(function (resp){
                            if(angular.isArray(resp.data.content) && resp.data.content.length>0){
                                scope.areaAll = resp.data.content;
                            }else{
                                scope.areaAll = [];
                            }
                        });
                    }else{
                        scope.cursel = 3;
                    }
                }
            });
            var chooseArea= scope.$watch('areaAll',function(newVal,oldVal){
                if(scope.canReset){
                    var fixedValue = scope.areaCode;
                    if(angular.isArray(newVal) && newVal.length>0 && scope.codeExist(newVal,fixedValue)){
                        scope.area = scope.codeExist(newVal,fixedValue);
                        scope.areaSelected =scope.area;
                        scope.cursel = 4;
                    }
                }
            });

            scope.changeWatch = function(){
                var country = angular.copy(scope.countryCode);
                scope.countryCode = null;
                $timeout(function(){
                    scope.countryCode = country;
                },0);
            };
            // scope.changeWatch();

            //判断是否有固定地区级别
            if(scope.scFixedField){
                for(var i=0;i<scope.grade.length;i++){
                    if(scope.grade[i] == scope.scFixedField){
                        scope.gradeShow = scope.grade.slice(i+1);
                        scope.gradeShowAll  = scope.gradeShow;
                        // scope.scDefault(true);
                        scope.isCurrent = i+2;
                    }
                    continue;
                }
            }else{
                if(angular.isArray(scope.scDisplayMode) &&scope.scDisplayMode.length>0){
                    scope.gradeShow = scope.scDisplayMode;
                }else{
                    scope.gradeShow =scope.grade;
                }
                scope.gradeShowAll = scope.grade;
                // scope.scDefault(false);
            }

            scope.isShow = function(a){
                if(scope.gradeShow.indexOf(a)>=0){
                    return true;
                }else{
                    return false;
                }
            };//用于判断已选中的展示
            scope.isShowAll = function(a){
                if(scope.gradeShowAll.indexOf(a) >=0){
                    return true;
                }else{
                    return false;
                }
            };//用于判断tab和下拉展示

            scope.isCountry = scope.isShow('country');
            scope.isProvince = scope.isShow('province');
            scope.isCity = scope.isShow('city');
            scope.isArea = scope.isShow('area');
            scope.isShowSelected = function(){
                var country,province,city,area;
                if(scope.scFixedField){
                    // country =scope.isCountry&&scope.countrySelected;
                    province = scope.isProvince&&scope.provinceSelected;
                    city = scope.isCity&&scope.citySelected;
                    area = scope.isArea&&scope.areaSelected;
                    return (province||city||area);
                }else if (scope.scDisplayMode.length>0){
                    country = scope.isCountry&&scope.countrySelected;
                    province = scope.isProvince&&scope.provinceSelected;
                    city = scope.isCity&&scope.citySelected;
                    area = scope.isArea&&scope.areaSelected;
                    if(country||province||city|| area){
                        return true;
                    }else{
                        return false;
                    }
                }else{
                    return (scope.countrySelected||scope.provinceSelected|| scope.citySelected||scope.areaSelected);
                }
            };

            //tab页
            scope.setTab = function (cursel,isReset){
                scope.cursel = cursel;
                if(isReset){
                    var menudiv = document.getElementById("con_one_"+cursel);
                    $(menudiv).find('li.active').removeClass('active');
                }
            };

            //选择地区点击时监听器
            var clickProvince = scope.$watch('provinceCode',function(newVal,oldVal){
                if(scope.flog && newVal &&newVal!==oldVal){
                    scope.province = scope.codeExist(scope.provinceAll,newVal);
                    var p=  scope.getRequest(scope.grade[2],newVal);
                    p.then(function(resp) {
                        scope.cityAll = resp.data.content;
                    });
                    scope.setTab(3,true);
                    scope.city = null;
                    scope.area = null;
                }
            });
            var clickCity = scope.$watch('cityCode',function(newVal,oldVal){
                if(scope.flog && newVal &&newVal!==oldVal){
                    scope.city = scope.codeExist(scope.cityAll,newVal);
                    scope.area = null;

                    var done = selectDone('city');
                    if (!done) {
                        var p = scope.getRequest(scope.grade[3], newVal);
                        p.then(function (resp) {
                            scope.areaAll = resp.data.content;
                        });
                        scope.setTab(4, true);
                    }
                }
            });
            var clickArea = scope.$watch('areaCode',function(newVal,oldVal){
                if(scope.flog && newVal &&newVal!==oldVal){
                    scope.area = scope.codeExist(scope.areaAll,newVal);

                    selectDone('area');
                }
            });

            function selectDone(step) {
                var grade = scope.grade[scope.grade.length - 1];
                if (grade === step) {
                    $(element).find('.areaSelect').removeClass('active');

                    scope.countrySelected = scope.country;
                    scope.provinceSelected = scope.province;
                    scope.citySelected = scope.city;
                    if (step === 'area') {
                        scope.areaSelected = scope.area;
                    }

                    scope.canResetCollection = false;
                    scope.scCountry = scope.countryCode;
                    scope.scProvince = scope.provinceCode;
                    scope.scCity = scope.cityCode;
                    if (step === 'area') {
                        scope.scArea = scope.areaCode;
                    }

                    if(scope.scModel){
                        scope.scModel = {
                            country:{
                                name:scope.countrySelected,
                                code:scope.countryCode
                            },
                            province:{
                                name:scope.provinceSelected,
                                code:scope.provinceCode
                            },
                            city:{
                                name:scope.citySelected,
                                code:scope.cityCode
                            }
                        };
                        if (step === 'area') {
                            scope.scModel['area'] = {
                                name:scope.areaSelected,
                                code:scope.areaCode
                            };
                        }
                    }

                    return true;
                }

                return false;
            }

            //获取所点击选中的国家/省/市以下的省/市/区
            scope.getAreas = function(a,area){
                if(area == 0){
                    scope.countryCode = a.code;
                }else if(area==1){
                    scope.provinceCode = a.code;
                }else if(area==2){
                    scope.cityCode = a.code;
                }else if(area == 3){
                    scope.areaCode = a.code;
                }
                scope.flog = true;
                scope.canReset = false;
            };

            //监听外部传入变量
            var  allCode = scope.$watchCollection(function(){
                return [scope.scProvince,scope.scCity,scope.scArea];
            },function(newVal,oldVal){
                if(scope.canResetCollection && newVal && newVal!==oldVal){
                    scope.flog = false;
                    scope.canReset = true;
                    scope.provinceCode = angular.copy(scope.scProvince);
                    scope.cityCode = angular.copy(scope.scCity);
                    scope.areaCode = angular.copy(scope.scArea);
                    scope.country = null;
                    scope.countrySelected = null;
                    scope.province = null;
                    scope.provinceSelected = null;
                    scope.city = null;
                    scope.citySelected = null;
                    scope.area = null;
                    scope.areaSelected = null;
                    scope.changeWatch();
                }
                scope.canResetCollection = true;
            });

            //删除已选中的地区
            scope.areaDelete = function(){
                scope.countrySelected = null;
                scope.provinceSelected = null;
                scope.citySelected = null;
                scope.areaSelected = null;

                scope.country = null;
                scope.province = null;
                scope.city = null;
                scope.area = null;

                // scope.canResetCollection = false;
                scope.scCountry  = null;
                scope.scProvince  = null;
                scope.scCity =  null;
                scope.scArea = null;

                scope.countryCode = null;
                scope.provinceCode = null;
                scope.cityCode = null;
                scope.areaCode = null;

                if(scope.isCountry){
                    scope.setTab(1,true);
                    return;
                }
                if(scope.isProvince){
                    scope.setTab(2,true);
                    return;
                }
                if(scope.isCity){
                    scope.setTab(3,true);
                    return;
                }
                if(scope.isArea){
                    scope.setTab(4,true);
                }
            };

            scope.changeX= function(){
                var area = $(element).children('.area');
                var left = area.offset().left;
                var w = window.innerWidth;
                if((w-left)<480){
                    area.find('.areaSelect').addClass('right');
                }else{
                    area.find('.areaSelect').removeClass('right');
                }
            };
            $timeout(function(){
                scope.changeX();
            },0);
            $(window).resize(function(){
                scope.changeX();
            });

            var isOnlyRead = scope.$watch('scOnlyRead',function(newVal,oldVal){
                if(newVal){
                    element.find('.areaContent').addClass('off').attr('disabled','disabled');
                    $(element).children('.area').on('mouseenter',function(){

                    });
                }else{
                    element.find('.areaContent').removeClass('off').removeAttr('disabled');
                    $(element).children('.area').on('mouseenter',function(){
                    $(this).children('.areaContent').addClass('active');
                    $(this).children('.areaSelect').addClass('active');
                });
                }
            });
            $(element).children('.area').on('mouseleave',function(){
                $(this).children('.areaContent').removeClass('active');
                $(this).children('.areaSelect').removeClass('active');
            });
            $('.menudiv').delegate('li','click',function(){
                $(this).addClass('active').siblings('.active').removeClass('active');
            });
            $(element).find('.areaSelect').children('b').on('click',function (){
                $(element).find('.areaSelect').removeClass('active');
            });
        }
    }
}));
(function(ng) {
    'use strict';

    ng.module('exTable', ['ngTable','ui.bootstrap']).run(run);

    run.$inject = ['$templateCache'];
    function run($templateCache) {
        $templateCache.put('ex-table/headerCheckbox.html', '<input ex-select-all type="checkbox" class="select-all" value="" />');
        $templateCache.put('ng-table/filters/dateSelect.html','<div ex-date></div>');
        $templateCache.put('ng-table/filters/dateTimeSelect.html','<div ex-date-time></div>');
        $templateCache.put('ng-table/filters/startDate.html','<div ex-start-date></div>');
        $templateCache.put('ng-table/filters/endDate.html','<div ex-end-date></div>');
        $templateCache.put('ng-table/filters/startDateTime.html','<div ex-start-date-time></div>');
        $templateCache.put('ng-table/filters/endDateTime.html','<div ex-end-date-time></div>');
        $templateCache.put('ng-table/filters/startNum.html','<div ex-start-num class="ex-start-num"></div>');
        $templateCache.put('ng-table/filters/endNum.html','<div ex-end-num class="ex-end-num"></div>');
        $templateCache.put('ng-table/filters/scSelect.html','<div sc-select sc-dataurl="filterOption()[\'scDataUrl\']" ></div>');
        $templateCache.put('ng-table/filters/scTableTest.html','<nav><ul class="pagination">' +
            '<li><a ng-click="selectPage(1)">首页</a>' +
            '</li><li><a ng-click="selectPage(currentPage - 1)">&lt;</a>' +
            '</li><li><a><page-select></page-select> / {{numPages}}</a>' +
            '</li><li><a ng-click="selectPage(currentPage + 1)">&gt;</a>' +
            '</li><li><a ng-click="selectPage(numPages)">尾页</a></li>' +
            '<li><span style="border-right: 0px;border-top: 0px;border-bottom: 0px;cursor: pointer;padding: 6px 10px;">每页{{stItemsByPage}}条，共{{totalItemCount}}条</span></li>' +
            '</ul>' +
            '</nav>'+
            '<div class="pageBtn btn-group">' +
            '<button type="button" ng-repeat="row in counts" class="btn btn-default" ng-click="setItemsByPage(row)" ng-class="{\'active\':stItemsByPage==row}"><span>{{row}}</span></button>'+
            // '<button type="button" ng-click="stItemsByPage=25" ng-class="{\'active\':stItemsByPage==25}" class="btn btn-default"><span>25</span></button>'+
            // '<button type="button" ng-click="stItemsByPage=50" ng-class="{\'active\':stItemsByPage==50}" class="btn btn-default"><span>50</span></button>'+
            // '<button type="button" ng-click="stItemsByPage=100" ng-class="{\'active\':stItemsByPage==100}" class="btn btn-default"><span>100</span></button>'+
            '</div>'
        );
    }

    ng.module('exTable').directive('exSelect', exSelect);
    exSelect.$inject = ['ngTableEventsChannel', '$timeout'];
    function exSelect(ngTableEventsChannel, $timeout) {
        return {
            require: '^ngTable',
            priority: 999,
            template: '<input type="checkbox" class="select-one" ng-model="row.selected"/>',
            scope: {
                row: '=exSelect',
                params: '=exTableParams',
                scSelection:'&?scSelection'
            },
            link: linkFn
        };

        function linkFn(scope, element, attrs, ctrl) {
            var selectMode = attrs.selectMode || 'multiple'; // single or multiple
            var params = scope.params;
            var row = scope.row;
            if (!ng.isObject(params.lastSelect)) {
                params.lastSelect = null;
            }
            if (!ng.isFunction(params.selects)) {
                params.selects = [];
            }
            if (!ng.isFunction(params.select)) {
                params.select = select;
            }
            if (!ng.isFunction(params.getSelected)) {
                params.getSelected = getSelected;
            }
            if (!ng.isObject(params.exOnAfterReloadData)) {
                params.exOnAfterReloadData = true;
                var code = attrs.exCode?attrs.exCode:'code';
                var id = 'id';
                ngTableEventsChannel.onAfterReloadData(function(ngTable, newData, oldData) {
                    params.lastSelect = null;
                    params.selects = [];
                    ng.forEach(newData, function(item) {
                        ng.forEach(oldData, function (oldItem) {
                            if ((oldItem[code] && item[code] === oldItem[code])||(oldItem[id] && item[id] === oldItem[id]) && oldItem.selected) {
                                $timeout(function() {
                                    params.select(item, selectMode, true);
                                });
                            }
                        });
                    });
                }, scope, params);
            }

            function indexObj(item, arr) {
                var code = attrs.exCode?attrs.exCode:'code';
                var id = 'id';

                for (var i=0; i<arr.length; i++) {
                    var oldItem = arr[i];
                    if ((oldItem[code] && item[code] === oldItem[code]) || (oldItem[id] && item[id] === oldItem[id])) {
                        return i;
                    }
                }

                return -1;
            }
            // scope.selOneModal = false;

            $timeout(function() {
            var input = $(element).find('input');
                var unwatch = scope.$watch(function(){
                return input.prop('checked');
            },function(newVal,oldVal){
                if(newVal!=oldVal && newVal !== row.selected){
                    params.select(row, selectMode);
                }
            });

                var unwatch2 = scope.$watch('row.selected', function(newValue) {
                if (newValue) {
                    element.parent().addClass('ex-selected');
                    ng.element(element.children()).attr('checked', true);
                } else {
                    element.parent().removeClass('ex-selected');
                    ng.element(element.children()).attr('checked', false);
                }

                var idx = indexObj(row, params.selects);
                if(newValue && idx < 0) {
                    params.selects.push(row);
                } else if(!newValue && idx >= 0) {
                    params.selects.splice(idx, 1);
                }
            });

                  scope.$on('$destroy', function () {
                    unwatch();
                    unwatch2();
                  });
            });

            function getSelected() {
                return params.selects;
            }

            function select(row, mode, forceSelect) {
                if (mode === 'single') {
                    if (params.lastSelect) {
                        params.lastSelect.selected = false;
                    }
                    row.selected = true;
                    params.lastSelect = row;
                     params.selects[0] =row;
                } else {
                    row.selected = forceSelect || !!!row.selected;
                    var idx = indexObj(row, params.selects);
                    if(row.selected && idx < 0) {
                        params.selects.push(row);
                    } else if(!row.selected && idx >= 0) {
                        params.selects.splice(idx, 1);
                    }
                }
            }
        }
    }

    ng.module('exTable').directive('exSelectAll', exSelectAll);
    exSelectAll.$inject = ['ngTableEventsChannel', '$timeout'];
    function exSelectAll(ngTableEventsChannel, $timeout) {
        return {
            priority: 1001,
            terminal: true,
            link: linkFn
        };

        function linkFn(scope, element, attrs, ctrl) {
            element.on('change', function() {
                var checked = element.prop('checked');
                angular.forEach($(element).parents('table').find('input.select-one'), function(item) {
                    var $item = jQuery(item);
                    if ($item.prop('checked') !== checked) {
                        $item.click();
                    }
                });
            });
        }
    }

    ng.module('exTable').directive('fixedFlCells', fixedFlCells);
    fixedFlCells.$inject = ['$timeout'];
    function fixedFlCells($timeout) {
        return {
            // require: '^ngTable',
            // priority: 500,
            // scope: false,
            link: linkFn
        };
        function linkFn(scope, element, attrs, ctrl) {
            var tableBordered =  $(element);//table元素
            scope.setTable  = function(){
                //添加div
                if(!tableBordered.parent().hasClass('ng-table-container')){
                    var ngTableContainer = angular.element(document.createElement('div')).addClass('ng-table-container');
                    tableBordered.wrap(ngTableContainer);
                    var ngContainer = angular.element(document.createElement('div')).css('position','relative');
                    tableBordered.parent('.ng-table-container').wrap(ngContainer);

                    tableBordered.parent('.ng-table-container').on('scroll', function(event) {
                        $('.popover').remove();
                        $('.exDatepicker').removeClass('active');
                    });
                }

                tableBordered.find('tr').each(function() {
                    var t = $(this);

                    if (t.hasClass('ng-table-sort-header') || t.hasClass('ng-table-group-header') || t.hasClass('ng-table-filters')) {
                        // if (t.hasClass('ng-table-filters')) {
                        //     // 居中元素
                        //     var firstFilter = t.children().first().find('div.filter-cell');
                        //     var lastFilter = t.children().last().find('div.filter-cell');
                        //     if (firstFilter.length > 0) {
                        //         firstFilter.css('height', '100%');
                        //         firstFilter.children().css({
                        //             position: 'relative',
                        //             top: '50%',
                        //             transform: 'translateY(-50%)'
                        //         });
                        //     }
                        //     if (lastFilter.length > 0) {
                        //         lastFilter.css('height', '100%');
                        //         lastFilter.children().css({
                        //             position: 'relative',
                        //             top: '50%',
                        //             transform: 'translateY(-50%)'
                        //         });
                        //     }
                        // }

                        var maxH = 0;
                        t.find('th').each(function() {
                            if($(this).outerHeight() > maxH) {
                                maxH = $(this).outerHeight();
                            }
                        });

                        t.find('th').each(function() {
                            $(this).outerHeight(maxH);
                        });
                    }

                });
                tableBordered.addClass('fixed-columns');
            };

            scope.$watch(function(){
                return tableBordered.height();
            },function(newVal,oldVal){
                if(newVal>0){
                    scope.setTable();
                }
            });
        }
    }

    // ng.module('exTable').directive('exDate', exDate);
    // exDate.$inject = ['ngTableEventsChannel', '$timeout'];
    // function exDate(ngTableEventsChannel, $timeout) {
    //     return {
    //         restrict:'EA',
    //         priority: 1009,
    //         template:
    //         '<div class="exDate" ng-click="chooseDate($event);$event.stopPropagation()">'+
    //             '<input class="dateModel" ex-date-input sd="startDateTime" ed="endDateTime"  ng-model="params.filter()[name]">'+
    //             '<div class="dateShow" ng-show="startD||endD" title="{{startD}}~{{endD}}">' +
    //                 '<span>{{startD}}</span>' +
    //                 '<span ng-show="startD||endD">~</span>' +
    //                 '<span>{{endD}}</span>' +
    //             '</div>'+
    //
    //             '<div ng-show="!( startD||endD)">' +
    //                 '<span class="chooseDate glyphicon glyphicon-time" ng-click="chooseDate();$event.stopPropagation()">' +
    //                 '</span>'+
    //             '</div>'+
    //
    //             '<div class="exDatepicker" ng-click="$event.stopPropagation()">'+
    //
    //                 '<div>'+
    //                     '<div class="input-group">'+
    //                         //'<span class="input-span">起始时间:</span>'+
    //                         '<input type="text" class="form-control "  current-text="{{\'今天\'}}" clear-text="{{\'清除\'}}"close-text="{{\'关闭\'}}"   uib-datepicker-popup="{{format}}"  ng-model="startDate"  is-open="popup1.opened" datepicker-options="dateOptions" ng-required="true" close-text="Close"  />'+
    //                         '<span class="input-group-btn">'+
    //                             '<button type="button" class="btn btn-default" ng-click="open1()"><i class="glyphicon glyphicon-calendar"></i></button>'+
    //                         '</span>'+
    //                     '</div>'+
    //                 '</div>'+
    //
    //                 '<span>~</span>'+
    //
    //                 '<div>'+
    //                     '<div class="input-group">'+
    //                     //'<span class="input-span">截止时间:</span>'+
    //                         '<input type="text" class="form-control "  current-text="{{\'今天\'}}" clear-text="{{\'清除\'}}"close-text="{{\'关闭\'}}"  uib-datepicker-popup="{{format}}" ng-model="endDate" is-open="popup2.opened" datepicker-options="dateOptions" ng-required="true" close-text="Close"  />'+
    //                         '<span class="input-group-btn">'+
    //                             '<button type="button" class="btn btn-default" ng-click="open2()"><i class="glyphicon glyphicon-calendar"></i></button>'+
    //                         '</span>'+
    //                     '</div>'+
    //                 '</div>'+
    //
    //                 '<div ng-show="(!(popup2.opened||popup1.opened))" style="display: block;text-align:left;padding-top:5px;">' +
    //                     '<button class="btn btn-info" ng-click="confirmChoose();$event.stopPropagation()">确认</button>'+
    //                     '<button style="margin-left: 5px;" class="btn btn-danger" ng-click="clearChoose();$event.stopPropagation()">清除</button>'+
    //                 '</div>'+
    //             '</div>'+
    //         '</div>',
    //         scope: true,
    //         link: linkFn
    //     };
    //     function linkFn(scope,element, attrs, ctrls) {
    //         //当加载片段时设置其中一个祖先元素为inline-block，以此使两个日期选择框在同一行
    //         //$(element).parent().addClass('displayDate');
    //         // var th = $(element).parents('th');
    //         // if(th.length>0){
    //         //     $timeout(function(){
    //         //         var w = th.width();
    //         //         if(w<164){
    //         //             $('.exDate>.dateShow>span').css('display','block');
    //         //         }else{
    //         //             th.width(w);
    //         //         }
    //         //     },0);
    //         // }
    //         //日期控件JS
    //
    //         scope.startDateTime = '';
    //         scope.endDateTime = '';
    //         scope.startDate = '';
    //         scope.endDate = '';
    //         scope.startD = '';
    //         scope.endD = '';
    //         var exDatepicker = $(element).find('.exDate>.exDatepicker');
    //         //根据th的padding+border设置日期框的上外边距
    //         exDatepicker.css('margin-top',($(element).parents('th').outerHeight()-$(element).parents('th').height())/2);
    //
    //         scope.confirmChoose = function(){
    //             //开始日期
    //             if(scope.startDate != scope.startDateTime){
    //                 if(scope.startDate){
    //                     scope.startDateTime = scope.startDate;
    //                     scope.startD = formatDate(scope.startDate);
    //                 }else{
    //                     scope.startDateTime = '';
    //                     scope.startD = '';
    //                 }
    //                 //判断是否还显示日期选择框
    //                 if(!scope.endDate){
    //                     if(exDatepicker.hasClass('active')){
    //                         exDatepicker.removeClass('active');
    //                     }
    //                 }
    //             }
    //             //结束日期
    //             if(scope.endDate != scope.endDateTime){
    //                 if(scope.endDate){
    //                     scope.endDateTime = scope.endDate;
    //                     scope.endD = formatDate(scope.endDate);
    //                 }else{
    //                     scope.endDateTime = '';
    //                     scope.endD = '';
    //                 }
    //
    //                 if(!scope.startDate){
    //                     if(exDatepicker.hasClass('active')){
    //                         exDatepicker.removeClass('active');
    //                     }
    //                 }
    //             }
    //         };
    //         //清除所有时间
    //         scope.clearChoose = function () {
    //             scope.startDate='';
    //             scope.startDateTime = '';
    //             scope.startD = '';
    //             scope.endDate='';
    //             scope.endDateTime = '';
    //             scope.endD = '';
    //             exDatepicker.removeClass('active');
    //         };
    //
    //         var formatDate = function (date) {
    //             var y = date.getFullYear();
    //             var m = date.getMonth() + 1;
    //             m = m < 10 ? '0' + m : m;
    //             var d = date.getDate();
    //             d = d < 10 ? ('0' + d) : d;
    //             return y + '-' + m + '-' + d;
    //         };
    //
    //         scope.chooseDate = function(e){
    //             if(!exDatepicker.hasClass('active')){
    //                 exDatepicker.addClass('active');
    //             }
    //         };
    //
    //         $(document).on('click',function(){
    //             if(exDatepicker.hasClass('active')){
    //                 exDatepicker.removeClass('active');
    //             }
    //         });
    //
    //         var today = function () {
    //             scope.dt = new Date();
    //         };
    //         today();
    //
    //         var clear = function () {
    //             scope.dt = null;
    //         };
    //
    //         scope.inlineOptions = {
    //             customClass: scope.getDayClass,
    //             minDate: new Date(),
    //             showWeeks: true
    //         };
    //
    //         scope.dateOptions = {
    //             //dateDisabled: disabled,//禁选周末
    //             formatYear: 'yy',
    //             maxDate: new Date(2020, 5, 22),
    //             minDate: new Date(),
    //             startingDay: 1
    //         };
    //
    //         // Disable weekend selection
    //         // function disabled(data) {
    //         //     var date = data.date,
    //         //         mode = data.mode;
    //         //     return mode === 'day' && (date.getDay() === 0 || date.getDay() === 6);
    //         // }
    //
    //         scope.toggleMin = function () {
    //             scope.inlineOptions.minDate = scope.inlineOptions.minDate ? null : new Date();
    //             scope.dateOptions.minDate = scope.inlineOptions.minDate;
    //         };
    //
    //         scope.toggleMin();
    //
    //         scope.open1 = function () {
    //             scope.popup1.opened = true;
    //         };
    //
    //         scope.open2 = function () {
    //             scope.popup2.opened = true;
    //         };
    //
    //         scope.setDate = function (year, month, day) {
    //             scope.dt = new Date(year, month, day);
    //         };
    //
    //         scope.formats = ['dd-MMMM-yyyy', 'yyyy/MM/dd', 'dd.MM.yyyy', 'yyyy-MM-dd', 'shortDate'];
    //         scope.format =  scope.formats[3];
    //         scope.altInputFormats = ['M!/d!/yyyy'];
    //
    //         scope.popup1 = {
    //             opened: false
    //         };
    //
    //         scope.popup2 = {
    //             opened: false
    //         };
    //
    //         var tomorrow = new Date();
    //         tomorrow.setDate(tomorrow.getDate() + 1);
    //         var afterTomorrow = new Date();
    //         afterTomorrow.setDate(tomorrow.getDate() + 1);
    //         scope.events = [
    //             {
    //                 date: tomorrow,
    //                 status: 'full'
    //             },
    //             {
    //                 date: afterTomorrow,
    //                 status: 'partially'
    //             }
    //         ];
    //
    //         scope.getDayClass=function(data) {
    //             var date = data.date,
    //                 mode = data.mode;
    //             if (mode === 'day') {
    //                 var dayToCheck = new Date(date).setHours(0, 0, 0, 0);
    //
    //                 for (var i = 0; i < scope.events.length; i++) {
    //                     var currentDay = new Date(scope.events[i].date).setHours(0, 0, 0, 0);
    //
    //                     if (dayToCheck === currentDay) {
    //                         return scope.events[i].status;
    //                     }
    //                 }
    //             }
    //             return '';
    //         }
    //     }
    // }
    //
    // ng.module('exTable').directive('exDateTime', exDateTime);
    // exDateTime.$inject = ['ngTableEventsChannel', '$timeout'];
    // function exDateTime(ngTableEventsChannel, $timeout) {
    //     return {
    //         restrict:'EA',
    //         priority: 1009,
    //         template:
    //         '<div class="exDate" ng-click="chooseDate();$event.stopPropagation()">'+
    //             '<input class="dateModel" ex-date-input sd="startDateTime" ed="endDateTime"  ng-model="params.filter()[name]">'+
    //             '<div class="dateShow" ng-show="startD||endD" title="{{startD[0]}} {{startD[1]}}~{{endD[0]}} {{endD[1]}}">' +
    //                 '<span>{{startD[0]}}&nbsp;{{startD[1]}}</span>' +
    //                 '<span ng-show="startD||endD">~</span>' +
    //                 '<span>{{endD[0]}}&nbsp;{{endD[1]}}</span>' +
    //             '</div>'+
    //
    //             '<div ng-show="!( startD||endD)">' +
    //                 '<span class="chooseDate glyphicon glyphicon-time" ng-click="chooseDate();$event.stopPropagation()">' +
    //                 '</span>'+
    //             '</div>'+
    //
    //             '<div class="exDatepickerTime" ng-click="$event.stopPropagation()">'+
    //
    //                 '<div>'+
    //                     '<div class="input-group">'+
    //                         //'<span class="input-span">起始时间:</span>'+
    //                         '<input type="text" class="form-control "  current-text="{{\'今天\'}}" clear-text="{{\'清除\'}}"close-text="{{\'关闭\'}}"   uib-datepicker-popup="{{format}}"  ng-model="startDate"  is-open="popup1.opened" datepicker-options="dateOptions" ng-required="true" close-text="Close"  />'+
    //                         '<span class="input-group-btn">'+
    //                             '<button type="button" class="btn btn-default" ng-click="open1()"><i class="glyphicon glyphicon-calendar"></i></button>'+
    //                         '</span>'+
    //                     '</div>'+
    //                 '</div>'+
    //
    //                 '<span>~</span>'+
    //
    //                 '<div>'+
    //                     '<div class="input-group">'+
    //                         //'<span class="input-span">截止时间:</span>'+
    //                         '<input type="text" class="form-control "  current-text="{{\'今天\'}}" clear-text="{{\'清除\'}}"close-text="{{\'关闭\'}}"  uib-datepicker-popup="{{format}}" ng-model="endDate" is-open="popup2.opened" datepicker-options="dateOptions" ng-required="true" close-text="Close"  />'+
    //                         '<span class="input-group-btn">'+
    //                             '<button type="button" class="btn btn-default" ng-click="open2()"><i class="glyphicon glyphicon-calendar"></i></button>'+
    //                         '</span>'+
    //                     '</div>'+
    //                 '</div>'+
    //
    //                 '<div ng-show="(!(popup2.opened||popup1.opened))" style="display: block;text-align:left;padding-top:5px;">' +
    //                     '<button class="btn btn-info" ng-click="confirmChoose();$event.stopPropagation()">确认</button>'+
    //                     '<button style="margin-left: 5px;" class="btn btn-danger" ng-click="clearChoose();$event.stopPropagation()">清除</button>'+
    //
    //         '</div>'+
    //             '</div>'+
    //         '</div>',
    //         scope: true,
    //         link: linkFn
    //     };
    //     function linkFn(scope,element, attrs, ctrls) {
    //         //当加载片段时设置其中一个祖先元素为inline-block，以此使两个日期选择框在同一行
    //         //$(element).parent().addClass('displayDate');
    //         // var th = $(element).parents('th');
    //         // if(th.length>0){
    //         //     $timeout(function(){
    //         //         var w = th.width();
    //         //         if(w<164){
    //         //             $('.exDate>.dateShow>span').css('display','block');
    //         //         }else{
    //         //             th.width(w);
    //         //         }
    //         //     },0);
    //         // }
    //         //日期控件JS
    //         scope.startDateTime = '';
    //         scope.endDateTime = '';
    //         scope.startDate = '';
    //         scope.endDate = '';
    //         scope.startD = '';
    //         scope.endD = '';
    //         var exDatepickerTime = $(element).find('.exDate>.exDatepickerTime');
    //         //根据th的padding+border设置日期框的上外边距
    //         exDatepickerTime.css('margin-top',($(element).parents('th').outerHeight()-$(element).parents('th').height())/2);
    //
    //         scope.confirmChoose = function(){
    //             //开始日期
    //             if(scope.startDate != scope.startDateTime){
    //                 if(scope.startDate){
    //                     scope.startDateTime = scope.startDate;
    //                     scope.startD = formatDateTime(scope.startDate);
    //                 }else{
    //                     scope.startDateTime = '';
    //                     scope.startD = [];
    //                 }
    //                 //判断是否还显示日期选择框
    //                 if(!scope.endDate){
    //                     if(exDatepickerTime.hasClass('active')){
    //                         exDatepickerTime.removeClass('active');
    //                     }
    //                 }
    //             }
    //             //结束日期
    //             if(scope.endDate != scope.endDateTime){
    //                 if(scope.endDate){
    //                     scope.endDateTime = scope.endDate;
    //                     scope.endD = formatDateTime(scope.endDate);
    //                 }else{
    //                     scope.endDateTime = '';
    //                     scope.endD = [];
    //                 }
    //
    //                 if(!scope.startDate){
    //                     if(exDatepickerTime.hasClass('active')){
    //                         exDatepickerTime.removeClass('active');
    //                     }
    //                 }
    //             }
    //
    //         };
    //
    //         //清除所有时间
    //         scope.clearChoose = function () {
    //             scope.startDate='';
    //             scope.startDateTime = '';
    //             scope.startD = [];
    //             scope.endDate='';
    //             scope.endDateTime = '';
    //             scope.endD = [];
    //             exDatepickerTime.removeClass('active');
    //         };
    //
    //         var formatDateTime = function (date) {
    //                 var y = date.getFullYear();
    //                 var m = date.getMonth() + 1;
    //                 m = m < 10 ? ('0' + m) : m;
    //                 var d = date.getDate();
    //                 d = d < 10 ? ('0' + d) : d;
    //                 var h = date.getHours();
    //                 h = h < 10 ? ('0' + h) : h;
    //                 var minute = date.getMinutes();
    //                 minute = minute < 10 ? ('0' + minute) : minute;
    //                 var seconds = date.getSeconds();
    //                 seconds = seconds < 10 ? ('0' + seconds) : seconds;
    //                 return [y + '-' + m + '-' + d,h+':'+minute+':'+seconds];
    //         };
    //
    //         scope.chooseDate = function(){
    //             if(!exDatepickerTime.hasClass('active')){
    //                 exDatepickerTime.addClass('active');
    //             }
    //         };
    //         $(document).on('click',function(){
    //             if(exDatepickerTime.hasClass('active')){
    //                 exDatepickerTime.removeClass('active');
    //             }
    //         });
    //
    //         var today = function () {
    //             scope.dt = new Date();
    //         };
    //         today();
    //
    //         var clear = function () {
    //             scope.dt = null;
    //         };
    //
    //         scope.inlineOptions = {
    //             customClass: scope.getDayClass,
    //             minDate: new Date(),
    //             showWeeks: true
    //         };
    //
    //         scope.dateOptions = {
    //             //dateDisabled: disabled,//禁选周末
    //             formatYear: 'yy',
    //             maxDate: new Date(2020, 5, 22),
    //             minDate: new Date(),
    //             startingDay: 1
    //         };
    //
    //         // Disable weekend selection
    //         // function disabled(data) {
    //         //     var date = data.date,
    //         //         mode = data.mode;
    //         //     return mode === 'day' && (date.getDay() === 0 || date.getDay() === 6);
    //         // }
    //
    //         scope.toggleMin = function () {
    //             scope.inlineOptions.minDate = scope.inlineOptions.minDate ? null : new Date();
    //             scope.dateOptions.minDate = scope.inlineOptions.minDate;
    //         };
    //
    //         scope.toggleMin();
    //
    //         scope.open1 = function () {
    //             scope.popup1.opened = true;
    //         };
    //
    //         scope.open2 = function () {
    //             scope.popup2.opened = true;
    //         };
    //
    //         scope.setDate = function (year, month, day) {
    //             scope.dt = new Date(year, month, day);
    //         };
    //
    //         scope.formats = ['dd-MMMM-yyyy', 'yyyy/MM/dd', 'dd.MM.yyyy', 'yyyy-MM-dd HH:mm:ss', 'shortDate'];
    //         scope.format =  scope.formats[3];
    //         scope.altInputFormats = ['M!/d!/yyyy'];
    //
    //         scope.popup1 = {
    //             opened: false
    //         };
    //
    //         scope.popup2 = {
    //             opened: false
    //         };
    //
    //         var tomorrow = new Date();
    //         tomorrow.setDate(tomorrow.getDate() + 1);
    //         var afterTomorrow = new Date();
    //         afterTomorrow.setDate(tomorrow.getDate() + 1);
    //         scope.events = [
    //             {
    //                 date: tomorrow,
    //                 status: 'full'
    //             },
    //             {
    //                 date: afterTomorrow,
    //                 status: 'partially'
    //             }
    //         ];
    //
    //         scope.getDayClass=function(data) {
    //             var date = data.date,
    //                 mode = data.mode;
    //             if (mode === 'day') {
    //                 var dayToCheck = new Date(date).setHours(0, 0, 0, 0);
    //
    //                 for (var i = 0; i < scope.events.length; i++) {
    //                     var currentDay = new Date(scope.events[i].date).setHours(0, 0, 0, 0);
    //
    //                     if (dayToCheck === currentDay) {
    //                         return scope.events[i].status;
    //                     }
    //                 }
    //             }
    //             return '';
    //         }
    //     }
    // }


    ng.module('exTable').directive('exStartNum', exStartNum);
    exStartNum.$inject = ['ngTableEventsChannel', '$timeout'];
    function exStartNum(ngTableEventsChannel, $timeout) {
        return {
            restrict:'EA',
            priority: 1009,
            template:
                    '<input type="text" class="form-control" ng-model="params.filter()[name]">',
            scope: true,
            link: linkFn
        };
        function linkFn(scope,elem,attrs,ctrl){
            elem.parent('.filter-cell')
            .css({'display':'inline-block',
                    'float':'none',
                    'width': 'inherit',
                    'min-width':'60px'
            });
        }
    }

    ng.module('exTable').directive('exEndNum', exEndNum);
    exEndNum.$inject = ['ngTableEventsChannel', '$timeout'];
    function exEndNum(ngTableEventsChannel, $timeout) {
        return {
            restrict:'EA',
            priority: 1009,
            template:
                    '<span>~</span><input type="text"  class="form-control" ng-model="params.filter()[name]">',
            scope: true,
            link: linkFn
        };
        function linkFn(scope,elem,attrs,ctrl){
            elem.parent('.filter-cell')
            .css({'display':'inline-block',
                    'float':'none',
                    'width': 'inherit',
                    'min-width':'76px'
            });
        }
    }

    ng.module('exTable').directive('exStartDateInput', exStartDateInput);
    exStartDateInput.$inject = ['ngTableEventsChannel', '$timeout'];
    function exStartDateInput(ngTableEventsChannel, $timeout){
        return {
            require: ['ngModel'],
            restrict:'EA',
            priority: 1009,
            scope: {
                startDate:'=sd',
            },
            link: linkFn
        };
        function linkFn(scope,element, attrs, ctrl){
            var ngModel = ctrl[0];
            scope.$watch('startDate',function(newVal,oldVal){
                ngModel.$setViewValue(newVal);
            });
        }
    }

    ng.module('exTable').directive('exEndDateInput', exEndDateInput);
    exEndDateInput.$inject = ['ngTableEventsChannel', '$timeout'];
    function exEndDateInput(ngTableEventsChannel, $timeout){
        return {
            require: ['ngModel'],
            restrict:'EA',
            priority: 1009,
            scope: {
                endDate:'=ed'
            },
            link: linkFn
        };
        function linkFn(scope,element, attrs, ctrl){
            var ngModel = ctrl[0];
            scope.$watch('endDate',function(newVal,oldVal){
                ngModel.$setViewValue(newVal);
            });
        }
    }

    ng.module('exTable').directive('exStartDate', exStartDate);
    exStartDate.$inject = ['ngTableEventsChannel', '$timeout'];
    function exStartDate(ngTableEventsChannel, $timeout) {
        return {
            restrict:'EA',
            priority: 1009,
            template:
            '<div class="exStartDate"  ng-click="chooseDate($event);$event.stopPropagation()">'+
                '<input type="text" class="dateModel" ex-start-date-input sd="startDateTime" ng-model="params.filter()[name]">'+
                '<div class="dateShow" ng-show="startD" title="{{startD}}">' +
                    '<span>{{startD}}</span>'+
                '</div>'+
                '<div class="dateShow" ng-show="!startD">' +
                    '<span class="chooseDate glyphicon glyphicon-time" ng-click="chooseDate($event);$event.stopPropagation()"></span>'+
                '</div>'+
                '<div class="exDatepicker"  ng-click="$event.stopPropagation()">'+
                    '<div class="input-group">'+
                        //'<span class="input-span">起始时间:</span>'+
                        '<input type="text" class="form-control "  current-text="{{\'今天\'}}" clear-text="{{\'清除\'}}"close-text="{{\'关闭\'}}"   uib-datepicker-popup="{{format}}"  ng-model="startDate"  is-open="popup1.opened" datepicker-options="dateOptions" ng-required="true" close-text="Close"  />'+
                        '<span class="input-group-btn">'+
                            '<button type="button" class="btn btn-default" ng-click="open1()"><i class="glyphicon glyphicon-calendar"></i></button>'+
                        '</span>'+
                    '</div>'+

                    '<div ng-show="!popup1.opened" style="display: block;text-align:left;padding-top:5px;">' +
                        '<button class="btn btn-info" ng-click="confirmChoose();$event.stopPropagation()">确认</button>'+
                        '<button style="margin-left: 1px;" class="btn btn-danger" ng-click="clearChoose();$event.stopPropagation()">清除</button>'+
                        '<button style="margin-left: 1px;" class="btn btn-success" ng-click="closeChoose();$event.stopPropagation()">关闭</button>'+
                    '</div>'+
                '</div>' +
            '</div>',
            scope: true,
            link: linkFn
        };

        function linkFn(scope,element, attrs, ctrl) {
            // 当加载片段时设置其中一个祖先元素为inline-block，以此使两个日期选择框在同一行
            $(element).parent('.filter-cell').addClass('displayDate');

            scope.startDateTime = '';
            scope.startDate = '';
            scope.startD = '';
            var exDatepicker = $(element).find('.exStartDate>.exDatepicker');
            //根据th的padding+border设置日期框的上外边距
            exDatepicker.css('margin-top',($(element).parents('th').outerHeight()-$(element).parents('th').height())/2);

            scope.confirmChoose = function(){
                //开始日期
                if(scope.startDate != scope.startDateTime){
                    if(scope.startDate){
                        scope.startDateTime = scope.startDate;
                        scope.startD = formatDate(scope.startDate);
                    }else{
                        scope.startDateTime = '';
                        scope.startD = '';
                    }
                }
                //隐藏选择
                if(exDatepicker.hasClass('active')){
                    exDatepicker.removeClass('active');
                }
            };
            //清除所有时间
            scope.clearChoose = function () {
                scope.startDate='';
                scope.startDateTime = '';
                scope.startD = '';
                exDatepicker.removeClass('active');
            };
            //关闭选择框
            scope.closeChoose = function () {
                exDatepicker.removeClass('active');
            };

            var formatDate = function (date) {
                var y = date.getFullYear();
                var m = date.getMonth() + 1;
                m = m < 10 ? '0' + m : m;
                var d = date.getDate();
                d = d < 10 ? ('0' + d) : d;
                return y + '-' + m + '-' + d;
            };

            scope.chooseDate = function(e){
                if(!exDatepicker.hasClass('active')){
                    exDatepicker.addClass('active');
                    scope.startDate = scope.startDateTime;
                    scope.popup1.opened = true;
                }else{
                    exDatepicker.removeClass('active');
                    scope.popup1.opened = false;
                }
            };

            $(document).on('click',function(){
                if(exDatepicker.hasClass('active')){
                    exDatepicker.removeClass('active');
                }
            });


            //日期控件JS
            var today = function () {
                scope.dt = new Date();
            };
            today();

            var clear = function () {
                scope.dt = null;
            };

            scope.inlineOptions = {
                customClass: scope.getDayClass,
                minDate: new Date(),
                showWeeks: true
            };

            scope.dateOptions = {
                //dateDisabled: disabled,//禁选周末
                formatYear: 'yy',
                maxDate: new Date(2020, 5, 22),
                minDate: new Date(),
                startingDay: 1
            };

            // Disable weekend selection
            // function disabled(data) {
            //     var date = data.date,
            //         mode = data.mode;
            //     return mode === 'day' && (date.getDay() === 0 || date.getDay() === 6);
            // }

            scope.toggleMin = function () {
                scope.inlineOptions.minDate = scope.inlineOptions.minDate ? null : new Date();
                scope.dateOptions.minDate = scope.inlineOptions.minDate;
            };

            scope.toggleMin();

            scope.open1 = function () {
                scope.popup1.opened = true;
            };

            scope.open2 = function () {
                scope.popup2.opened = true;
            };

            scope.setDate = function (year, month, day) {
                scope.dt = new Date(year, month, day);
            };

            scope.formats = ['dd-MMMM-yyyy', 'yyyy/MM/dd', 'dd.MM.yyyy', 'yyyy-MM-dd', 'shortDate'];
            scope.format =  scope.formats[3];
            scope.altInputFormats = ['M!/d!/yyyy'];

            scope.popup1 = {
                opened: false
            };

            scope.popup2 = {
                opened: false
            };

            var tomorrow = new Date();
            tomorrow.setDate(tomorrow.getDate() + 1);
            var afterTomorrow = new Date();
            afterTomorrow.setDate(tomorrow.getDate() + 1);
            scope.events = [
                {
                    date: tomorrow,
                    status: 'full'
                },
                {
                    date: afterTomorrow,
                    status: 'partially'
                }
            ];

            scope.getDayClass=function(data) {
                var date = data.date,
                    mode = data.mode;
                if (mode === 'day') {
                    var dayToCheck = new Date(date).setHours(0, 0, 0, 0);

                    for (var i = 0; i < scope.events.length; i++) {
                        var currentDay = new Date(scope.events[i].date).setHours(0, 0, 0, 0);

                        if (dayToCheck === currentDay) {
                            return scope.events[i].status;
                        }
                    }
                }
                return '';
            }
        }
    }

    ng.module('exTable').directive('exEndDate', exEndDate);
    exEndDate.$inject = ['ngTableEventsChannel', '$timeout'];
    function exEndDate(ngTableEventsChannel, $timeout) {
        return {
            restrict:'EA',
            priority: 1009,
            template:
            '<div class="exEndDate"  ng-click="chooseDate($event);$event.stopPropagation()">'+
                '<input type="text" class="dateModel" ex-end-date-input ed="endDateTime" ng-model="params.filter()[name]">'+

                '&nbsp;<span>~</span>&nbsp;'+

                '<div class="dateShow" ng-show="endD" title="{{endD}}">' +
                    '<span>{{endD}}</span>'+
                '</div>'+

                '<div class="dateShow" ng-show="!endD">' +
                    '<span class="chooseDate glyphicon glyphicon-time" ng-click="chooseDate($event);$event.stopPropagation()"></span>'+
                '</div>'+

                '<div class="exDatepicker"  ng-click="$event.stopPropagation()">'+
                    '<div class="input-group">'+
                        //'<span class="input-span">起始时间:</span>'+
                        '<input type="text" class="form-control "  current-text="{{\'今天\'}}" clear-text="{{\'清除\'}}"close-text="{{\'关闭\'}}"   uib-datepicker-popup="{{format}}"  ng-model="endDate"  is-open="popup2.opened" datepicker-options="dateOptions" ng-required="true" close-text="Close"  />'+
                        '<span class="input-group-btn">'+
                            '<button type="button" class="btn btn-default" ng-click="open2()"><i class="glyphicon glyphicon-calendar"></i></button>'+
                        '</span>'+
                    '</div>'+

                    '<div ng-show="!popup2.opened" style="display: block;text-align:left;padding-top:5px;">' +
                        '<button class="btn btn-info" ng-click="confirmChoose();$event.stopPropagation()">确认</button>'+
                        '<button style="margin-left: 1px;" class="btn btn-danger" ng-click="clearChoose();$event.stopPropagation()">清除</button>'+
                        '<button style="margin-left: 1px;" class="btn btn-success" ng-click="closeChoose();$event.stopPropagation()">关闭</button>'+
                    '</div>'+
                '</div>' +
            '</div>',
            scope: true,
            link: linkFn
        };

        function linkFn(scope, element, attrs, ctrl) {
            $(element).parent('.filter-cell').addClass('displayDate');

            scope.endDateTime = '';
            scope.endDate = '';
            scope.endD = '';
            var exDatepicker = $(element).find('.exEndDate>.exDatepicker');
            //根据th的padding+border设置日期框的上外边距
            exDatepicker.css('margin-top',($(element).parents('th').outerHeight()-$(element).parents('th').height())/2);

            scope.confirmChoose = function(){
                //开始日期
                if(scope.endDate != scope.endDateTime){
                    if(scope.endDate){
                        scope.endDateTime = scope.endDate;
                        scope.endD = formatDate(scope.endDate);
                    }else{
                        scope.endDateTime = '';
                        scope.endD = '';
                    }
                }
                //隐藏选择
                exDatepicker.removeClass('active');
            };
            //清除所有时间
            scope.clearChoose = function () {
                scope.endDate='';
                scope.endDateTime = '';
                scope.endD = '';
                exDatepicker.removeClass('active');
            };

            //关闭选择框
            scope.closeChoose = function () {
                exDatepicker.removeClass('active');
            };

            var formatDate = function (date) {
                var y = date.getFullYear();
                var m = date.getMonth() + 1;
                m = m < 10 ? '0' + m : m;
                var d = date.getDate();
                d = d < 10 ? ('0' + d) : d;
                return y + '-' + m + '-' + d;
            };

            scope.chooseDate = function(e){
                if(!exDatepicker.hasClass('active')){
                    exDatepicker.addClass('active');
                    scope.endDate = scope.endDateTime;
                    scope.popup2.opened = true;
                }else{
                    exDatepicker.removeClass('active');
                    scope.popup2.opened = false;
                }
            };

            $(document).on('click',function(){
                if(exDatepicker.hasClass('active')){
                    exDatepicker.removeClass('active');
                }
            });

            //日期控件JS
            var today = function () {
                scope.dt = new Date();
            };
            today();
            var clear = function () {
                scope.dt = null;
            };
            scope.inlineOptions = {
                customClass: scope.getDayClass,
                minDate: new Date(),
                showWeeks: true
            };

            scope.dateOptions = {
                //dateDisabled: disabled,//禁选周末
                formatYear: 'yy',
                maxDate: new Date(2020, 5, 22),
                minDate: new Date(),
                startingDay: 1
            };

            // Disable weekend selection
            // function disabled(data) {
            //     var date = data.date,
            //         mode = data.mode;
            //     return mode === 'day' && (date.getDay() === 0 || date.getDay() === 6);
            // }

            scope.toggleMin = function () {
                scope.inlineOptions.minDate = scope.inlineOptions.minDate ? null : new Date();
                scope.dateOptions.minDate = scope.inlineOptions.minDate;
            };

            scope.toggleMin();

            scope.open1 = function () {
                scope.popup1.opened = true;
            };

            scope.open2 = function () {
                scope.popup2.opened = true;
            };

            scope.setDate = function (year, month, day) {
                scope.dt = new Date(year, month, day);
            };

            scope.formats = ['dd-MMMM-yyyy', 'yyyy/MM/dd', 'dd.MM.yyyy', 'yyyy-MM-dd', 'shortDate'];
            scope.format =  scope.formats[3];
            scope.altInputFormats = ['M!/d!/yyyy'];

            scope.popup1 = {
                opened: false
            };

            scope.popup2 = {
                opened: false
            };

            // var tomorrow = new Date();
            // tomorrow.setDate(tomorrow.getDate() );
            // var afterTomorrow = new Date();
            // afterTomorrow.setDate(tomorrow.getDate() );
            var tomorrow = new Date();
            tomorrow.setDate(tomorrow.getDate() + 2);
            var afterTomorrow = new Date(tomorrow);
            afterTomorrow.setDate(tomorrow.getDate() + 2);
            scope.events = [
                {
                    date: tomorrow,
                    status: 'full'
                },
                {
                    date: afterTomorrow,
                    status: 'partially'
                }
            ];

            scope.getDayClass=function(data) {
                var date = data.date,
                    mode = data.mode;
                if (mode === 'day') {
                    var dayToCheck = new Date(date).setHours(0, 0, 0, 0);

                    for (var i = 0; i < scope.events.length; i++) {
                        var currentDay = new Date(scope.events[i].date).setHours(0, 0, 0, 0);

                        if (dayToCheck === currentDay) {
                            return scope.events[i].status;
                        }
                    }
                }
                return '';
            }
        }
    }

    ng.module('exTable').directive('exStartDateTime', exStartDateTime);
    exStartDateTime.$inject = ['ngTableEventsChannel', '$timeout'];
    function exStartDateTime(ngTableEventsChannel, $timeout) {
        return {
            restrict:'EA',
            priority: 1009,
            template:
            '<div class="exStartDateTime"  ng-click="chooseDate($event);$event.stopPropagation()">'+
                '<input type="text" class="dateModel" ex-start-date-input sd="startDateTime" ng-model="params.filter()[name]">'+
                '<div class="dateShow" ng-show="startD" title="{{startD}}">' +
                    '<span>{{startD}}</span>'+
                '</div>'+
                '<div class="dateShow" ng-show="!startD">' +
                    '<span class="chooseDate glyphicon glyphicon-time" ng-click="chooseDate($event);$event.stopPropagation()"></span>'+
                '</div>'+
                '<div class="exDatepicker"  ng-click="$event.stopPropagation()">'+
                    '<div class="input-group">'+
                        //'<span class="input-span">起始时间:</span>'+
                        '<input type="text" class="form-control "  current-text="{{\'今天\'}}" clear-text="{{\'清除\'}}"close-text="{{\'关闭\'}}"   uib-datepicker-popup="{{format}}"  ng-model="startDate"  is-open="popup1.opened" datepicker-options="dateOptions" ng-required="true" close-text="Close"  />'+
                        '<span class="input-group-btn">'+
                            '<button type="button" class="btn btn-default" ng-click="open1()"><i class="glyphicon glyphicon-calendar"></i></button>'+
                        '</span>'+
                    '</div>'+

                    '<div ng-show="!popup1.opened" style="display: block;text-align:left;padding-top:5px;">' +
                        '<button class="btn btn-info" ng-click="confirmChoose();$event.stopPropagation()">确认</button>'+
                        '<button style="margin-left: 1px;" class="btn btn-danger" ng-click="clearChoose();$event.stopPropagation()">清除</button>'+
                        '<button style="margin-left: 1px;" class="btn btn-success" ng-click="closeChoose();$event.stopPropagation()">关闭</button>'+

                    '</div>'+
                '</div>' +
            '</div>',
            scope: true,
            link: linkFn
        };

        function linkFn(scope,element, attrs, ctrl) {
            //当加载片段时设置其中一个祖先元素为inline-block，以此使两个日期选择框在同一行
            //$(element).parent().addClass('displayDate');
            // 当加载片段时设置其中一个祖先元素为inline-block，以此使两个日期选择框在同一行
            $(element).parent('.filter-cell').addClass('displayDate');

            scope.startDateTime = '';
            scope.startDate = '';
            scope.startD = '';
            var exDatepicker = $(element).find('.exStartDateTime>.exDatepicker');
            //根据th的padding+border设置日期框的上外边距
            exDatepicker.css('margin-top',($(element).parents('th').outerHeight()-$(element).parents('th').height())/2);

            //确认选择
            scope.confirmChoose = function(){
                //开始日期
                if(scope.startDate != scope.startDateTime){
                    if(scope.startDate){
                        scope.startDateTime = scope.startDate;
                        scope.startD = formatDateTime(scope.startDate);
                    }else{
                        scope.startDateTime = '';
                        scope.startD = '';
                    }
                }
                //隐藏选择
                if(exDatepicker.hasClass('active')){
                    exDatepicker.removeClass('active');
                }
            };

            //清除所有时间
            scope.clearChoose = function () {
                scope.startDate='';
                scope.startDateTime = '';
                scope.startD = '';
                exDatepicker.removeClass('active');
            };

            //关闭选择框
            scope.closeChoose = function () {
                exDatepicker.removeClass('active');
            };

            var formatDateTime = function (date) {
                var y = date.getFullYear();
                var m = date.getMonth() + 1;
                m = m < 10 ? ('0' + m) : m;
                var d = date.getDate();
                d = d < 10 ? ('0' + d) : d;
                var h = date.getHours();
                h = h < 10 ? ('0' + h) : h;
                var minute = date.getMinutes();
                minute = minute < 10 ? ('0' + minute) : minute;
                var seconds = date.getSeconds();
                seconds = seconds < 10 ? ('0' + seconds) : seconds;
                return y + '-' + m + '-' + d+' '+h+':'+minute+':'+seconds;
            };

            scope.chooseDate = function(e){
                if(!exDatepicker.hasClass('active')){
                    exDatepicker.addClass('active');
                    scope.startDate = scope.startDateTime;
                    scope.popup1.opened = true;
                }else{
                    exDatepicker.removeClass('active');
                    scope.popup1.opened = false;
                }
            };

            $(document).on('click',function(){
                if(exDatepicker.hasClass('active')){
                    exDatepicker.removeClass('active');
                }
            });

            //日期控件JS
            var today = function () {
                scope.dt = new Date();
            };
            today();

            var clear = function () {
                scope.dt = null;
            };

            scope.inlineOptions = {
                customClass: scope.getDayClass,
                minDate: new Date(),
                showWeeks: true
            };

            scope.dateOptions = {
                //dateDisabled: disabled,//禁选周末
                formatYear: 'yy',
                maxDate: new Date(2020, 5, 22),
                minDate: new Date(),
                startingDay: 1,
                locale: 'zh-cn'

            };

            // Disable weekend selection
            // function disabled(data) {
            //     var date = data.date,
            //         mode = data.mode;
            //     return mode === 'day' && (date.getDay() === 0 || date.getDay() === 6);
            // }

            scope.toggleMin = function () {
                scope.inlineOptions.minDate = scope.inlineOptions.minDate ? null : new Date();
                scope.dateOptions.minDate = scope.inlineOptions.minDate;
            };

            scope.toggleMin();

            scope.open1 = function () {
                scope.popup1.opened = true;
            };

            scope.open2 = function () {
                scope.popup2.opened = true;
            };

            scope.setDate = function (year, month, day) {
                scope.dt = new Date(year, month, day);
            };

            scope.formats = ['dd-MMMM-yyyy', 'yyyy/MM/dd', 'dd.MM.yyyy', 'yyyy-MM-dd HH:mm:ss', 'shortDate'];
            scope.format =  scope.formats[3];
            scope.altInputFormats = ['M!/d!/yyyy'];

            scope.popup1 = {
                opened: false
            };

            scope.popup2 = {
                opened: false
            };

            var tomorrow = new Date();
            tomorrow.setDate(tomorrow.getDate() + 1);
            var afterTomorrow = new Date();
            afterTomorrow.setDate(tomorrow.getDate() + 1);
            scope.events = [
                {
                    date: tomorrow,
                    status: 'full'
                },
                {
                    date: afterTomorrow,
                    status: 'partially'
                }
            ];

            scope.getDayClass=function(data) {
                var date = data.date,
                    mode = data.mode;
                if (mode === 'day') {
                    var dayToCheck = new Date(date).setHours(0, 0, 0, 0);

                    for (var i = 0; i < scope.events.length; i++) {
                        var currentDay = new Date(scope.events[i].date).setHours(0, 0, 0, 0);

                        if (dayToCheck === currentDay) {
                            return scope.events[i].status;
                        }
                    }
                }
                return '';
            }
        }
    }

    ng.module('exTable').directive('exEndDateTime', exEndDateTime);
    exEndDateTime.$inject = ['ngTableEventsChannel', '$timeout'];
    function exEndDateTime(ngTableEventsChannel, $timeout) {
        return {
            restrict:'EA',
            priority: 1009,
            template:
            '<div class="exEndDateTime"  ng-click="chooseDate($event);$event.stopPropagation()">'+
                '<input type="text" class="dateModel" ex-end-date-input ed="endDateTime" ng-model="params.filter()[name]">'+

                '&nbsp;<span>~</span>&nbsp;'+

                '<div class="dateShow" ng-show="endD" title="{{endD}}">' +
                    '<span>{{endD}}</span>'+
                '</div>'+

                '<div class="dateShow" ng-show="!endD">' +
                    '<span class="chooseDate glyphicon glyphicon-time" ng-click="chooseDate($event);$event.stopPropagation()"></span>'+
                '</div>'+

                '<div class="exDatepicker"  ng-click="$event.stopPropagation()">'+
                    '<div class="input-group">'+
                        //'<span class="input-span">起始时间:</span>'+
                        '<input type="text" class="form-control "  current-text="{{\'今天\'}}" clear-text="{{\'清除\'}}"close-text="{{\'关闭\'}}"   uib-datepicker-popup="{{format}}"  ng-model="endDate"  is-open="popup2.opened" datepicker-options="dateOptions" ng-required="true" close-text="Close"  />'+
                        '<span class="input-group-btn">'+
                            '<button type="button" class="btn btn-default" ng-click="open2()"><i class="glyphicon glyphicon-calendar"></i></button>'+
                        '</span>'+
                    '</div>'+

                    '<div ng-show="!popup2.opened" style="display: block;text-align:left;padding-top:5px;">' +
                        '<button class="btn btn-info" ng-click="confirmChoose();$event.stopPropagation()">确认</button>'+
                        '<button style="margin-left: 1px;" class="btn btn-danger" ng-click="clearChoose();$event.stopPropagation()">清除</button>'+
                        '<button style="margin-left: 1px;" class="btn btn-success" ng-click="closeChoose();$event.stopPropagation()">关闭</button>'+

                    '</div>'+
                '</div>' +
            '</div>',
            scope: true,
            link: linkFn
        };

        function linkFn(scope, element, attrs, ctrl) {

            $(element).parent('.filter-cell').addClass('displayDate');

            scope.endDateTime = '';
            scope.endDate = '';
            scope.endD = '';
            var exDatepicker = $(element).find('.exEndDateTime>.exDatepicker');
            //根据th的padding+border设置日期框的上外边距
            exDatepicker.css('margin-top',($(element).parents('th').outerHeight()-$(element).parents('th').height())/2);

            scope.confirmChoose = function(){
                //开始日期
                if(scope.endDate != scope.endDateTime){
                    if(scope.endDate){
                        scope.endDateTime = scope.endDate;
                        scope.endD = formatDateTime(scope.endDate);
                    }else{
                        scope.endDateTime = '';
                        scope.endD = '';
                    }
                }
                //隐藏选择
                exDatepicker.removeClass('active');
            };
            //清除所有时间
            scope.clearChoose = function () {
                scope.endDate='';
                scope.endDateTime = '';
                scope.endD = '';
                exDatepicker.removeClass('active');
            };

            //关闭选择框
            scope.closeChoose = function () {
                exDatepicker.removeClass('active');
            };

            var formatDateTime = function (date) {
                var y = date.getFullYear();
                var m = date.getMonth() + 1;
                m = m < 10 ? ('0' + m) : m;
                var d = date.getDate();
                d = d < 10 ? ('0' + d) : d;
                var h = date.getHours();
                h = h < 10 ? ('0' + h) : h;
                var minute = date.getMinutes();
                minute = minute < 10 ? ('0' + minute) : minute;
                var seconds = date.getSeconds();
                seconds = seconds < 10 ? ('0' + seconds) : seconds;
                return y + '-' + m + '-' + d+' '+h+':'+minute+':'+seconds;
            };

            scope.chooseDate = function(e){
                if(!exDatepicker.hasClass('active')){
                    exDatepicker.addClass('active');
                    scope.endDate = scope.endDateTime;
                    scope.popup2.opened = true;
                }else{
                    exDatepicker.removeClass('active');
                    scope.popup2.opened = false;
                }
            };

            $(document).on('click',function(){
                if(exDatepicker.hasClass('active')){
                    exDatepicker.removeClass('active');
                }
            });

            //日期控件JS
            var today = function () {
                scope.dt = new Date();
            };
            today();
            var clear = function () {
                scope.dt = null;
            };
            scope.inlineOptions = {
                customClass: scope.getDayClass,
                minDate: new Date(),
                showWeeks: true
            };

            scope.dateOptions = {
                //dateDisabled: disabled,//禁选周末
                formatYear: 'yy',
                maxDate: new Date(2020, 5, 22),
                minDate: new Date(),
                startingDay: 1,
                locale: 'zh-cn'

            };

            // Disable weekend selection
            // function disabled(data) {
            //     var date = data.date,
            //         mode = data.mode;
            //     return mode === 'day' && (date.getDay() === 0 || date.getDay() === 6);
            // }

            scope.toggleMin = function () {
                scope.inlineOptions.minDate = scope.inlineOptions.minDate ? null : new Date();
                scope.dateOptions.minDate = scope.inlineOptions.minDate;
            };

            scope.toggleMin();

            scope.open1 = function () {
                scope.popup1.opened = true;
            };

            scope.open2 = function () {
                scope.popup2.opened = true;
            };

            scope.setDate = function (year, month, day) {
                scope.dt = new Date(year, month, day);
            };

            scope.formats = ['dd-MMMM-yyyy', 'yyyy/MM/dd', 'dd.MM.yyyy', 'yyyy-MM-dd HH:mm:ss', 'shortDate'];
            scope.format =  scope.formats[3];
            scope.altInputFormats = ['M!/d!/yyyy'];

            scope.popup1 = {
                opened: false
            };

            scope.popup2 = {
                opened: false
            };

            // var tomorrow = new Date();
            // tomorrow.setDate(tomorrow.getDate() );
            // var afterTomorrow = new Date();
            // afterTomorrow.setDate(tomorrow.getDate() );
            var tomorrow = new Date();
            tomorrow.setDate(tomorrow.getDate() + 2);
            var afterTomorrow = new Date(tomorrow);
            afterTomorrow.setDate(tomorrow.getDate() + 2);
            scope.events = [
                {
                    date: tomorrow,
                    status: 'full'
                },
                {
                    date: afterTomorrow,
                    status: 'partially'
                }
            ];

            scope.getDayClass=function(data) {
                var date = data.date,
                    mode = data.mode;
                if (mode === 'day') {
                    var dayToCheck = new Date(date).setHours(0, 0, 0, 0);

                    for (var i = 0; i < scope.events.length; i++) {
                        var currentDay = new Date(scope.events[i].date).setHours(0, 0, 0, 0);

                        if (dayToCheck === currentDay) {
                            return scope.events[i].status;
                        }
                    }
                }
                return '';
            };
        }
    }

    ng.module('exTable').directive('timeSelect', timeSelect);
    timeSelect.$inject = ['ngTableEventsChannel', '$timeout'];
    function timeSelect(ngTableEventsChannel, $timeout) {
        return {
            restrict:'EA',
            priority: 1009,
            template: '<div class="input-group dateTimeSelect">'+
            //'<span class="input-span">起始时间:</span>'+
            '<input type="text" class="form-control "  current-text="{{\'今天\'}}" clear-text="{{\'清除\'}}" close-text="{{\'关闭\'}}"   uib-datepicker-popup="{{format}}"  ng-model="dt" is-open="popup1.opened" datepicker-options="dateOptions" ng-required="true" close-text="Close"  />'+
            '<span class="input-group-btn">'+
            '<button type="button" class="btn btn-default" ng-click="open1()"><i class="glyphicon glyphicon-calendar"></i></button>'+
            '</span>'+
            '</div>',
            scope: {
                nowTime:'=?nowTime',
                getTime:'=?getTime',
                scModel:'=scModel'
            },
            link: linkFn
        };
        function linkFn(scope,element, attrs, ctrl) {
            if(scope.scModel) {
                scope.nowTime = scope.scModel;
            }
            //当加载片段时设置其中一个祖先元素为inline-block，以此使两个日期选择框在同一行
            //$(element).parent().addClass('displayDate');
            //日期控件JS
            var today = function (t) {
                if(angular.isDefined(t)){
                    scope.dt = t;
                }else{
                    scope.dt = new Date();
                }
            };
            today(scope.nowTime);

            //检测nowTime，为异步请求
            scope.$watch('nowTime', function(newVal, oldVal) {
                if(angular.isDefined(newVal)){
                    today(newVal);
                }
            });

            scope.$watch('dt', function(newVal, oldVal) {
                if(angular.isDefined(newVal)){
                    scope.scModel = newVal;
                    if(angular.isDefined(scope.getTime) && angular.isFunction(scope.getTime)){
                        scope.getTime(newVal);
                    }
                }
            });

            var clear = function () {
                scope.dt = null;
            };

            scope.inlineOptions = {
                customClass: scope.getDayClass,
                minDate: new Date(),
                showWeeks: true
            };

            scope.dateOptions = {
                //dateDisabled: disabled,//禁选周末
                formatYear: 'yy',
                maxDate: new Date(2020, 5, 22),
                minDate: new Date(),
                startingDay: 1,
                locale: 'zh-cn'

            };

            // Disable weekend selection
            // function disabled(data) {
            //     var date = data.date,
            //         mode = data.mode;
            //     return mode === 'day' && (date.getDay() === 0 || date.getDay() === 6);
            // }

            scope.toggleMin = function () {
                scope.inlineOptions.minDate = scope.inlineOptions.minDate ? null : new Date();
                scope.dateOptions.minDate = scope.inlineOptions.minDate;
            };

            scope.toggleMin();

            scope.open1 = function () {
                scope.popup1.opened = true;
            };

            scope.open2 = function () {
                scope.popup2.opened = true;
            };

            scope.setDate = function (year, month, day) {
                scope.dt = new Date(year, month, day);
            };

            scope.formats = ['dd-MMMM-yyyy', 'yyyy/MM/dd', 'dd.MM.yyyy', 'yyyy-MM-dd HH:mm:ss', 'shortDate'];
            scope.format =  scope.formats[3];
            scope.altInputFormats = ['M!/d!/yyyy'];

            scope.popup1 = {
                opened: false
            };

            scope.popup2 = {
                opened: false
            };

            var tomorrow = new Date();
            tomorrow.setDate(tomorrow.getDate() + 1);
            var afterTomorrow = new Date();
            afterTomorrow.setDate(tomorrow.getDate() + 1);
            scope.events = [
                {
                    date: tomorrow,
                    status: 'full'
                },
                {
                    date: afterTomorrow,
                    status: 'partially'
                }
            ];

            scope.getDayClass=function(data) {
                var date = data.date,
                    mode = data.mode;
                if (mode === 'day') {
                    var dayToCheck = new Date(date).setHours(0, 0, 0, 0);

                    for (var i = 0; i < scope.events.length; i++) {
                        var currentDay = new Date(scope.events[i].date).setHours(0, 0, 0, 0);

                        if (dayToCheck === currentDay) {
                            return scope.events[i].status;
                        }
                    }
                }
                return '';
            }
        }
    }

    ng.module('exTable').directive('scPopover', scPopover);
    scPopover.$inject = ['ngTableEventsChannel', '$timeout'];
    function scPopover(ngTableEventsChannel, $timeout) {
        return {
            restrict:'EA',
            priority: 1009,
            scope: {
                scContent:'=?scContent'
            },
            link: linkFn
        };
        function linkFn(scope,element, attrs, ctrl) {

            var elem = $(element);
            var td = elem.parent('td');

            scope.makeNum = function(){
                var num = '';
                for(var i=0;i<6;i++){
                    num+=Math.floor(Math.random()*10+1);
                }
                return num;
            };

            td.on('click',function(e){
                e.stopPropagation();

                if(td.children('.scPopover').length == 0){

                    $('.scPopover').remove();//删除已经弹出的title框

                    var id=scope.makeNum();//获取一个ID值

                    var html = '<div class="scPopover">' +//声明代码片段
                        '<div class="scArrow"></div>'+
                        '<div class="scContent">scope.scContent</div>'+
                        '</div>';

                    td.append(html);//为td末尾加上title框

                    var popover = td.children('.scPopover');//找到当前title框

                    popover.on('click',function(event){
                        event.stopPropagation();
                    });//阻止事件冒泡

                    popover.attr('id','scPopover'+id);//设置 id 值

                    var left=parseInt(td.css('padding-left'))+td.width()/2;
                    var bottom=td.outerHeight()+11;
                    var marginLeft = popover.outerWidth()/2;

                    popover.css({
                        left:left+'px',
                        bottom:bottom+'px',
                        'margin-left':-marginLeft+'px'
                    });//设置title框定位
                }
            });


            $(document).on('click',function(){
                var scPopover = $('.scPopover');
                if(scPopover.length>0){
                    scPopover.remove();
                }
            })
        }
    }

    // ng.module('exTable').directive('dataPopover', dataPopover);
    // dataPopover.$inject = ['ngTableEventsChannel','$timeout'];
    // function dataPopover(ngTableEventsChannel,$timeout){
    //     return{
    //         restrict:'A',
    //         priority: 1009,
    //         template:
    //             '<div class="dataTitle">' +
    //                 '<div class="arrowTitle"></div>'+
    //                 '<div class="contentTitle">{{contentTitle}}</div>'+
    //             '</div>'
    //         ,
    //         scope:{
    //             contentPopover:'=?contentPopover'
    //         },
    //         link:linkFn
    //     };
    //     function linkFn(scope,element,attrs,ctrl){
    //         console.log(element);
    //     }
    // }

})(angular);


(function(ng, factory) {
    'use strict';
    if (typeof define === 'function' && define.amd) {
        define(['angular'], function(ng) {
            return factory(ng);
        });
    } else {
        return factory(ng);
    }
}(window.angular || null, function(ng) {
    'use strict';

    var app = ng.module('scTable', ['smart-table']).run(config);

    app.factory('scPopTableEventsChannel',scPopTableEventsChannel);
    scPopTableEventsChannel.$inject = ['$rootScope'];
    function  scPopTableEventsChannel($rootScope){
        var events = {};
        events = addChangeEvent('afterReloadData', events);
        return events;

        /////////
        function addChangeEvent(eventName, target){
            var fnName = eventName.charAt(0).toUpperCase() + eventName.substring(1);
            var event = {};
            event['on' + fnName] = createEventSubscriptionFn(eventName);
            event['publish' + fnName] = createPublishEventFn(eventName);
            return angular.extend(target, event);
        }

        function createEventSubscriptionFn(eventName){
            return function subscription(handler/*[, eventSelector or $scope][, eventSelector]*/){
                // shorthand for subscriber to only receive events from a specific publisher instance
                return $rootScope.$on('scTable:' + eventName, function(event, params/*, ...args*/){
                    // don't send events published by the internal NgTableParams created by ngTableController
                    var eventArgs = rest(arguments, 2);

                    var fnArgs = [params].concat(eventArgs);
                    // if (eventSelector.apply(this, fnArgs)){
                    handler.apply(this, fnArgs);
                    // }
                });
            }
        }

        function createPublishEventFn(eventName){
            return function publish(/*args*/){
                var fnArgs = ['scTable:' + eventName].concat(Array.prototype.slice.call(arguments));
                $rootScope.$broadcast.apply($rootScope, fnArgs);
            }
        }

        function rest(array, n) {
            return Array.prototype.slice.call(array, n == null ? 1 : n);
        }
    }

    /**
     * 配置smartTable
     *  pagination: {
    *  template: 'template/smart-table/pagination.html',
    *  itemsByPage: 10,
    *  displayedPages: 5
    *},
     *search: {
    *  delay: 400, // ms
    *  inputEvent: 'input'
    *},
     *select: {
    *  mode: 'single',
    *  selectedClass: 'st-selected'
    *},
     *sort: {
    *  ascentClass: 'st-sort-ascent',
    *  descentClass: 'st-sort-descent',
    *  descendingFirst: false,
    *  skipNatural: false,
    *  delay:300
    *},
     *pipe: {
    *  delay: 100 //ms
    *}
     **/
    config.$inject = ['stConfig'];
    function config(stConfig) {
        stConfig.select.selectedClass = 'ex-selected';
    }

    run.$inject = ['$templateCache'];
    function run($templateCache) {
        $templateCache.put('sc-table/filters/scTableTest.html','<nav><ul class="pagination">' +
            '<li><a ng-click="selectPage(1)">首页</a>' +
            '</li><li><a ng-click="selectPage(currentPage - 1)">&lt;</a>' +
            '</li><li><a><page-select></page-select> / {{numPages}}</a>' +
            '</li><li><a ng-click="selectPage(currentPage + 1)">&gt;</a>' +
            '</li><li><a ng-click="selectPage(numPages)">尾页</a></li>' +
            '<li><span style="border-right: 0px;border-top: 0px;border-bottom: 0px;cursor: pointer;padding: 6px 10px;">每页{{stItemsByPage}}条，共{{totalItemCount}}条</span></li>' +
            '</ul>' +
            '</nav>'+
            '<div class="pageBtn btn-group">' +
            '<button type="button" ng-repeat="row in counts" class="btn btn-default" ng-click="setItemsByPage(row)" ng-class="{\'active\':stItemsByPage==row}"><span>{{row}}</span></button>'+
            // '<button type="button" ng-click="stItemsByPage=25" ng-class="{\'active\':stItemsByPage==25}" class="btn btn-default"><span>25</span></button>'+
            // '<button type="button" ng-click="stItemsByPage=50" ng-class="{\'active\':stItemsByPage==50}" class="btn btn-default"><span>50</span></button>'+
            // '<button type="button" ng-click="stItemsByPage=100" ng-class="{\'active\':stItemsByPage==100}" class="btn btn-default"><span>100</span></button>'+
            '</div>'
        );
    }


    /**
     * 表格主程序
     */
    ng.module('scTable')
        .directive('scTable', scTable);
    scTable.$inject = ['$timeout','scPopTableEventsChannel'];
    function scTable($timeout,scPopTableEventsChannel) {
        return {
            restrict: 'A',
            template: function(element, attrs) {
                var colDefines = jQuery(element).find('[column-define]');
                var html = '<table st-table="displayRows" st-safe-src="rows" st-table-params="tableParams" init-load="true" ';
                var fixedFirst = 'tableFixedFirst';
                // if(attrs.scPopFixedCol == ''){
                    fixedFirst = '';
                    html+='sc-fixed-col sc-is-component="1"';
                    var fixedTailCounts=1,fixedFrontCounts=1;
                    if(attrs.scFixedTailCounts || attrs.scFixedTailCounts == 0){
                        fixedTailCounts = parseInt(attrs.scFixedTailCounts);
                    }
                    if(attrs.scFixedFrontCounts || attrs.scFixedFrontCounts == 0){
                        fixedFrontCounts = parseInt(attrs.scFixedFrontCounts);
                    }
                    html+=' sc-fixed-front-counts="'+fixedFrontCounts+'" sc-fixed-tail-counts="'+fixedTailCounts+'" ';
                // }
                // if(attrs.scPopTableDrag == ''){
                    html+=' ng-table-drag';
                // }
                // if(attrs.scPopTableDragWidth == ''){
                    html+=' ng-table-drag-width';
                // }
                if (attrs.scDataFn) {
                    html += ' st-pipe="getData" class="table table-condensed table-bordered '+fixedFirst+' table-striped ng-table">';
                    // html += ' st-pipe="getData" class="table table-condensed table-bordered';
                    // if(attrs.scFixedCol == undefined){
                    //     html += ' tableFixedFirst';
                    // }
                    // html +=' table-striped ng-table">';
                } else {
                    html += ' st-pipe="getData" class="table table-condensed table-bordered '+fixedFirst+' table-striped ng-table">';
                    // html += ' class="table table-condensed table-bordered';
                    // if(attrs.scFixedCol == undefined){
                    //     html += ' tableFixedFirst';
                    // }
                    // html +=' table-striped ng-table">';
                }

                var titleTr = '<tr class="ng-table-sort-header">';
                var filterTr = '<tr ng-if="showFilter">';
                var bodyTr = '<tr ng-repeat="row in displayRows track by $index">';
                ng.forEach(colDefines, function(cd, idx) {
                    var colDef = jQuery(cd);
                    var span = colDef.children('span')[0];
                    // titleTr += '<th title="" class="header" role="button" tabindex="0"';
                    // if(colDef.attr('data-sort')){
                    //      titleTr+=('st-table-sort="'+colDef.attr('data-sort')+'"');
                    // }
                    // console.log(colDef.attr('data-title'));
                    // if(colDef.attr('data-title')) {
                    //     titleTr += ('><span class="sort-indicator">' + colDef.attr('data-title') + '</span>');
                    // }else if(colDef.attr('title-component')) {
                    //     titleTr += (' ' + colDef.attr('title-component') + '>');
                    // } else {
                    //     titleTr += '>';
                    // }
                    // titleTr += '</th>';

                    var th;
                    th = jQuery('<th  class="header" role="button" tabindex="0"><span></span></th>');
                    if(colDef.attr('data-sort')){
                        th.children('span').append('<b class="dataSort" st-table-sort="'+colDef.attr('data-sort')+'"></b>');
                    }
                    if(colDef.attr('data-title')){
                        th.children('span').append('<span class="dragColumns" style="display:inline-block">'+colDef.attr('data-title')+'</span>');
                    }
                    th = th[0].outerHTML;
                    if(colDef.attr('title-component')) {
                        th = ('<th ' + colDef.attr('title-component') + '></th>');
                    }
                    titleTr += th;

                    filterTr += '<th class="filter">';
                    var filterProp = colDef.attr('data-filter');
                    if(filterProp) {
                        filterTr += '<div class="filter-cell s12';
                        if (idx == colDefines.length - 1) {
                            filterTr += ' last">';
                        } else {
                            filterTr += '">';
                        }

                        var filterType = colDef.attr('filter-component');
                        if (!filterType || filterType == 'text') {
                            filterTr += ('<input type="text" st-search="' + filterProp + '" class="input-filter form-control ng-pristine ng-untouched ng-valid ng-empty" placeholder="">');
                        } else if(colDef.attr('data-component')) {
                            filterTr += (' ' + colDef.attr('data-component') + '>');
                        }else if(filterType == 'select'){
                            filterTr += ('<sc-tab-select filter-config="'+colDef.attr('filter-component-config')+'"></sc-tab-select>');
                        }

                        filterTr += '</div>';
                    }
                    filterTr += '</th>';

                    var componentAttr = colDef.attr('data-component');
                    var expr = colDef.html();
                    if (componentAttr) {
                        bodyTr += ('<td ' + componentAttr);
                        var componentConf = colDef.attr('data-component-config');
                        if (componentConf) {
                            componentConf = componentConf.replace(/'/g, '"'); // 转成标准JSON格式，双引号
                            var componentConfObj = ng.fromJson(componentConf);

                            for(var key in componentConfObj) {
                                var compConfAttr = camelToDash(key);
                                var compConfAttrValue = componentConfObj[key];
                                bodyTr += (' ' + compConfAttr + '=\'' + compConfAttrValue + '\'');
                            }
                        }
                        bodyTr += '>';
                    } else {
                        bodyTr += '<td>';
                        if(!span){
                            bodyTr += '<span>';
                        }
                        bodyTr += expr;
                        if(!span){
                            bodyTr += '</span>';
                        }
                    }
                    bodyTr += '</td>';
                });
                titleTr += '</tr>';
                filterTr += '</tr>';
                bodyTr += '</tr>';

                html += '<thead>';
                html += titleTr;
                html += filterTr;
                html += '</thead>';

                html += '<tbody ng-show="!loadingData">';
                html += bodyTr;
                html += '</tbody>';

                html += '<tbody ng-show="loadingData">';
                html += '<tr>';
                html += '<td colspan="' + colDefines.length + '" class="{{loadingData?\'loadingData\':\'\'}}">';
                html += '<span>数据加载中...</span>';
                html += '</tr>';

                html += '</tbody>';

                html = html + '<tfoot>' +
                    '<tr>' +
                    '<td colspan="' + colDefines.length + '">' +
                    ' <div  st-items-by-page="showBtn" st-pagination="" st-template="ng-table/filters/scTableTest.html" >'+
                    '</div>'+
                    '</td>' +
                    '</tr>' +
                    '</tfoot>'+
                '</table>';
                return html;
            },
            scope: {
                'scDataFn': '=?scDataFn',
                'showFilter': '=?showFilter',
                'tableParams': '=?tableParams'
            },
            link: {
                pre: function(scope,element,attr) {
                    scope.rows = [];
                    scope.loadingData = false;

                    var nowTime = new Date();
                    function rnd(){
                        var random = Math.floor(Math.random()*9+1);
                        return random;
                    }
                    var r = "";
                    for(var i=0;i<3;i++){
                        r+= rnd();
                    }
                    var tabId = nowTime.getTime()+r;
                    element.children('table').attr('pop-table-id',tabId);

                    scope.getData = function(tableState) {
                        return new Promise(
                            function(resolve,reject){
                                var pagination = tableState.pagination;
                                var start = pagination.start || 0; // 是数据下标
                                var number = pagination.number || 10; // 页面条数
                                var page = start / number + 1; // 页数
                                var params = tableState.search.predicateObject;
                                var orderB = tableState.sort.predicate;
                                var orderT = 'ASC';
                                if(tableState.sort.reverse){
                                    orderT = 'DESC';
                                }
                                params = ng.merge({pn: page, start: start, ps: number}, params);
                                if(orderB){
                                    params = ng.merge({orderBy:orderB,orderType:orderT},params);
                                }

                                if(scope.scDataFn && ng.isFunction(scope.scDataFn)) {
                                    scope.loadingData = true;
                                    scope.scDataFn(params).then(function (result) {
                                        $timeout(function() {
                                            scPopTableEventsChannel.publishAfterReloadData(scTable,result,scope.displayRows,tabId);
                                            scope.loadingData = false;
                                            scope.rows = result;
                                            scope.displayRows = result;
                                            tableState.pagination.numberOfPages = parseInt(params.total / number) + (params.total % number > 0?1 : 0);
                                            tableState.pagination.totalItemCount = params.total;

                                            resolve(result);
                                            //set the number of pages so the pagination can update
                                        });
                                    }, function(data) {
                                        scope.loadingData = false;
                                        reject(data);
                                    });
                                }
                            }
                        )
                    };
                }
            }
        };
    }

    /**
     *选择过滤
     */
    ng.module('scTable')
        .directive('scTabSelect',scTabSelect);
    scTabSelect.$inject =[];
    function scTabSelect(){
        return{
            restrict:'EA',
            require: '^stTable',
            template:function(elem,attrs){
                return '<select class="form-control" ng-model="selectedOption" ng-change="optionChanged(selectedOption)" ng-options="opt for opt in distinctItems"></select>';
            },
            scope:{
                filterConfig:'=filterConfig'
            },
            link:{
                pre:function(scope,element,attr,ctrl){
                    scope.distinctItems = ['All'];
                    scope.prodicate = 'title';
                    scope.searchProdicate = 'id';//初始化配置

                    //如果有scValueField和title则替换配置
                    if(scope.filterConfig.title){
                        scope.prodicate = scope.filterConfig.title;
                    }
                    if(scope.filterConfig.scValueField){
                        scope.searchProdicate = scope.filterConfig.scValueField;
                    }

                    //获取显示的数组
                    scope.getArray = function(data){
                        if(angular.isArray(data) && data.length>0){
                            var temp = [];
                            angular.forEach(data,function(item){
                                var value = item[scope.prodicate];
                                if(value && temp.indexOf(value)===-1){
                                    temp.push(value);
                                }
                            });
                            scope.distinctItems = scope.distinctItems.concat(temp);
                        }
                        scope.selectedOption = scope.distinctItems[0];
                    };

                    //请求展示数据
                    scope.getData = function(url) {
                        return new promise(function(resolve){
                            if(url){
                                url = url+'?callback=JSON_CALLBACK&token='+ $localStorage.authenticationToken;
                                $sce.trustAsResourceUrl(url);
                                $http.jsonp(url).success(function (data) {
                                    if (data.status === 'SUCCESS') {
                                        if (angular.isDefined(data.content) && angular.isObject(data.content) && (data.content.datas.length > 0)) {
                                            // scope.rows = data.content.datas;
                                            resolve(data.content);
                                        } else {
                                            // scope.rows = [];
                                            resolve([]);
                                        }
                                    }
                                });
                            }
                        });
                    };

                    if(scope.filterConfig.url){
                        var promise =   scope.getData(scope.filterConfig.url);
                        promise.then(function(data){
                            scope.getArray(data);
                        });
                    }

                    if(scope.filterConfig.data){
                        scope.getArray(scope.filterConfig.data);
                    }

                    scope.optionChanged = function(selectedOption) {
                        var query = {};
                        for(var i=0;i<scope.filterConfig.data.length;i++){
                            if(scope.filterConfig.data[i][scope.prodicate] == selectedOption){
                                query.distinct = scope.filterConfig.data[i][scope.searchProdicate];
                                continue;
                            }
                        }
                        if (query.distinct === 'All') {
                            query.distinct = '';
                        }

                        ctrl.search(query.distinct , scope.searchProdicate);
                    };
                }
            }
        }
    }


    /**
     *选择过滤
     */
    ng.module('scTable')
        .directive('scTableSelect',scTableSelect);
    scTableSelect.$inject =[];
    function scTableSelect(){
        return{
            restrict:'EA',
            template:function(elem,attrs){
                var id = 'id';
                if(angular.isString(attrs.filterConfig)){
                    var config = attrs.filterConfig.split('\'');
                    var ind = config.indexOf('scValueField');
                    if(ind!==-1){
                        id = config[ind+2];
                    }
                }
                var html ='<select st-search="'+id+'" class="form-control">'+
                                        '<option value="">All</option>'+
                                        '<option ng-repeat="row in rows" value="{{row[scValueField]}}">{{row[filterProp]}}</option>'+
                                    '</select>';
                return html;
            },
            scope:{
                filterConfig:'=?filterConfig'
            },
            link:{
                pre:function(scope,element,attr,ctrl){
                    scope.filterProp = 'title';
                    scope.scValueField = 'id';
                    scope.isUndefinedOrNull = function(val) {
                        return angular.isUndefined(val) || val === null ;
                    };
                    //如果是json字符串，转为对象
                    if(angular.isString(scope.filterConfig)){
                        angular.toJson(scope.filterConfig);
                    }
                    //配置过滤属性
                    if(!scope.isUndefinedOrNull(scope.filterConfig.title)){
                        scope.filterProp = scope.filterConfig.title;
                    }
                    if(!scope.isUndefinedOrNull(scope.filterConfig.scValueField)){
                        scope.scValueField = scope.filterConfig.scValueField;
                    }

                    //请求展示数据
                    scope.getData = function(url) {
                        if(url){
                            url = url+'?callback=JSON_CALLBACK&token='+ $localStorage.authenticationToken;
                            $sce.trustAsResourceUrl(url);
                            $http.jsonp(url).success(function(data) {
                                if(data.status === 'SUCCESS') {
                                        if (angular.isDefined(data.content) && angular.isObject(data.content) && (data.content.datas.length > 0)) {
                                            scope.rows = data.content.datas;
                                        } else {
                                            scope.rows = [];
                                        }
                                    }
                            });
                        }
                    };
                    if(scope.filterConfig.url){
                        scope.getData(scope.filterConfig.url);
                    }
                    if(scope.filterConfig.data){
                        scope.rows = scope.filterConfig.data;
                    }

                }
            }
        }
    }

    /**
    *分页输入框
    */
    ng.module('scTable')
        .directive('pageSelect', function() {
            return {
                restrict: 'E',
                template: '<input type="text" class="select-page" ng-model="inputPage" ng-change="selectPage(inputPage)">'
                ,
                link: function(scope, element, attrs) {
                    scope.$watch('currentPage', function(c) {
                        scope.inputPage = c;
                    });
                }
            }
        });

    /**
     * 选择行checkbox
     */
    ng.module('scTable')
        .directive('scSelectRow', ['stConfig', function (stConfig) {
            return {
                restrict: 'A',
                require: '^stTable',
                template: '<input type="checkbox" class="sc-select" ng-checked="row.isSelected">',
                scope: {
                    row: '=scSelectRow'
                },
                link: function (scope, element, attr, ctrl) {
                    var mode = attr.stSelectMode || stConfig.select.mode;
                    element.parent('tr').bind('click', function () {
                        scope.$apply(function () {
                            ctrl.select(scope.row, mode);
                        });
                    });

                    scope.$watch('row.isSelected', function (newValue) {
                        if (newValue === true) {
                            element.parent('tr').addClass(stConfig.select.selectedClass);
                        } else {
                            element.parent('tr').removeClass(stConfig.select.selectedClass);
                        }
                    });
                }
            };
        }]);

    /**
     * 选择所有行checkbox
     */
    ng.module('scTable')
        .directive('scSelectAll', ['stConfig', function (stConfig) {
            return {
                restrict: 'A',
                template: '<input type="checkbox">',
                link: function (scope, element, attr, ctrl) {
                    element.bind('click', function () {
                        var ele = jQuery(element);
                        var checked = ele.find('input[type="checkbox"]').prop('checked');
                        ele.closest('table').find('.sc-select').each(function(idx, item) {
                            var $item = jQuery(item);
                            if ($item.prop('checked') != checked) {
                                $item.click();
                            }
                        });
                    });
                }
            };
        }]);


    /**
     * 固定普通表格列
     */
    ng.module('scTable')
        .directive('scFixedCol',['$timeout','scTableEventsChannel','scPopTableEventsChannel',function($timeout,scTableEventsChannel,scPopTableEventsChannel){
            return{
                restrict:'A',
                // template:function(){},
                scope:false,
                link:{
                    pre:function(scope,elem,attr,ctrl){
                        var tableBordered =  $(elem);//table元素
                        var  fixedFrontCounts;
                        var  fixedTailCounts;
                        if(attr.scFixedTailCounts || attr.scFixedTailCounts == 0){
                            fixedTailCounts = parseInt(attr.scFixedTailCounts);
                        }else{
                            fixedTailCounts = 1;
                        }
                        if(attr.scFixedFrontCounts || attr.scFixedFrontCounts == 0){
                            fixedFrontCounts = parseInt(attr.scFixedFrontCounts);
                        }else{
                            fixedFrontCounts = 1;
                        }

                        var getTotalTailWidth = function(idx,arr){
                            var sum = 0;
                            for(var i=idx;i<arr.length;i++){
                                sum+=arr[i];
                            }
                            return sum;
                        };
                        var getLeft = function (idx,arr) {
                            var sum = 0;
                            for(var i=0;i<idx;i++){
                                sum+=arr[i];
                            }
                            sum-=idx;//每个td都有边框，需反向多移动idx个
                            return sum;
                        };

                        var initTime = 0;
                        var frontColWidth = [];
                        var tailColWidth = [];
                        scope.resetTable = function(){
                            // if(!tableBordered.parent().hasClass('sc-table-container')){
                            //     // var scTableContainer = angular.element(document.createElement('div')).addClass('sc-table-container');
                            //     var scTableContainer = angular.element(document.createElement('div')).css('overflow','auto').addClass('sc-table-container');
                            //     tableBordered.wrap(scTableContainer);
                            //     var scContainer = angular.element(document.createElement('div')).css('position','relative');
                            //     tableBordered.parent('.sc-table-container').wrap(scContainer);
                            //     //
                            //     // tableBordered.parent('.sc-table-container').on('scroll', function(event) {
                            //     //     $('.popover').remove();
                            //     //     $('.exDatepicker').removeClass('active');
                            //     // });
                            // }
                            tableBordered.css('visibility','hidden');

                            var frontWidthArr = [];//存储需要固定的列的宽度--前
                            var tailWidthArr = [];//存储需要固定的列的宽度--后
                            var tdLen = $(tableBordered.find('tr')[0]).children().length;
                            var trLen = tableBordered.children().not('tfoot').children('tr').length;
                            //如果总列数大于前后固定列数相加
                            if(tdLen>fixedFrontCounts+fixedTailCounts){
                                for(var i=0;i<trLen;i++){
                                    var tr = tableBordered[0].rows[i];
                                    if(tr){
                                        $(tableBordered[0].rows[i].cells[fixedFrontCounts]).css('padding-left',0);
                                    }
                                }
                                var tbodyTr = $(tableBordered.children('tbody')[0]).children('tr');
                                for(var k=0;k<tbodyTr.length;k++){
                                    var tr = $(tbodyTr[k]);
                                    for(var r=0;r<fixedFrontCounts;r++){
                                        var td = tr.children('td').eq(r);
                                        td.css('width','initial');
                                    }
                                    for(var q=tdLen-fixedTailCounts;q<tdLen;q++){
                                        var td = tr.children('td').eq(q);
                                        td.css('width','initial');
                                    }
                                }

                                var firstTr = tableBordered.children('thead').find('tr:first-child');
                                var lastTr = $(tableBordered.children('tbody')[0]).find('tr:last');

                                var thead = tableBordered.children('thead');
                                if(initTime<1){
                                    ng.forEach(thead.children('tr'),function(trEle,idx){
                                        var tr = angular.element(trEle);
                                        for(var i=0;i<fixedFrontCounts;i++){
                                            var th = tr.children('th').eq(i);
                                            var tw=0,firstTrIdxThWidth = 0;
                                            tw = th.outerWidth();
                                            firstTrIdxThWidth = frontColWidth[i]?frontColWidth[i]:0;
                                            if(firstTrIdxThWidth>tw){
                                                tw = firstTrIdxThWidth;
                                            }
                                            // tw+=5;
                                            // frontColWidth.push(firstTr.children('th').eq(i).outerWidth());
                                            frontColWidth[i] = tw;
                                        }
                                        for(var j=tdLen-1;j>=tdLen-fixedTailCounts;j--){
                                            var th = tr.children('th').eq(j);
                                            var tw=0,firstTrIdxThWidth = 0;
                                            tw = th.outerWidth();
                                            firstTrIdxThWidth = tailColWidth[j]?tailColWidth[j]:0;
                                            if(firstTrIdxThWidth>tw){
                                                tw = firstTrIdxThWidth;
                                            }
                                            tw+=5;
                                            // tailColWidth.push(firstTr.children('th').eq(j).outerWidth());
                                            tailColWidth[j-tdLen+fixedTailCounts] = tw;
                                        }
                                    });
                                    initTime+=1;
                                }
                                // if(initTime<1){
                                //     for(var i=0;i<fixedFrontCounts;i++){
                                //         frontColWidth.push(firstTr.children('th').eq(i).outerWidth());
                                //     }
                                //     for(var j=tdLen-1;j>=tdLen-fixedTailCounts;j--){
                                //         tailColWidth.push(firstTr.children('th').eq(j).outerWidth());
                                //     }
                                //     initTime+=1;
                                // }

                                // ng.forEach(lastTr.children(),function(tdEle,idx){
                                //     var td = angular.element(tdEle);
                                //     var tw=0,firstTrIdxThWidth = 0;
                                //     if(idx<fixedFrontCounts ){
                                //         tw = td.outerWidth();
                                //         firstTrIdxThWidth = frontColWidth[idx];
                                //         if(firstTrIdxThWidth>tw){
                                //             tw = firstTrIdxThWidth;
                                //         }
                                //         frontWidthArr.push(tw);
                                //     }else if(tdLen-idx-1<fixedTailCounts){
                                //         tw = td.outerWidth();
                                //         firstTrIdxThWidth = tailColWidth[tdLen-idx-1];
                                //         if(firstTrIdxThWidth>tw){
                                //             tw = firstTrIdxThWidth;
                                //         }
                                //         tailWidthArr.push(tw);
                                //     }
                                // });
                                var tbody = $(tableBordered.children('tbody')[0]);
                                ng.forEach(tbody.children('tr'),function(trEle,idx){
                                    var tr = angular.element(trEle);
                                    for(var i=0;i<fixedFrontCounts;i++){
                                        var td = tr.children('td').eq(i);
                                        var tw=0,firstTrIdxThWidth = 0;
                                        tw = td.outerWidth();
                                        firstTrIdxThWidth = frontColWidth[i];
                                        if(firstTrIdxThWidth>tw){
                                            tw = firstTrIdxThWidth;
                                        }
                                        // tw+=5;
                                        if(!frontWidthArr[i] || tw>frontWidthArr[i]){
                                            frontWidthArr[i] = tw;
                                        }
                                    }
                                    for(var j=tdLen-fixedTailCounts;j<tdLen;j++){
                                        var td = tr.children('td').eq(j);
                                        var tw=0,firstTrIdxThWidth = 0;
                                        tw = td.outerWidth();
                                        firstTrIdxThWidth = tailColWidth[tdLen-j-1];
                                        if(firstTrIdxThWidth>tw){
                                            tw = firstTrIdxThWidth;
                                        }
                                        // tw+=5;
                                        if(!tailWidthArr[j-tdLen+fixedTailCounts] || tw>tailWidthArr[j-tdLen+fixedTailCounts]){
                                            tailWidthArr[j-tdLen+fixedTailCounts] = tw;
                                        }
                                    }
                                });
                                if(frontWidthArr.length==0){
                                    frontWidthArr = angular.copy(frontColWidth);
                                }
                                if(tailWidthArr.length==0){
                                    tailWidthArr = angular.copy(tailColWidth);
                                }

                                var paddingRight = getTotalTailWidth(0,tailWidthArr);
                                //循环行
                                tableBordered.children().not('tfoot').children('tr').each(function(){
                                    var t = $(this);
                                    var fixedFrontWidthSum = 3;//用于设置起始固定列的padding-left
                                    var absoluteRight = paddingRight;//用于设置末尾固定列的right右间距

                                    //循环列
                                    ng.forEach(t.children(),function(tdEle,idx){
                                        var td = angular.element(tdEle);
                                        //
                                        if(idx<fixedFrontCounts){
                                            td.addClass('activeLeft backColor');
                                            td.outerWidth(frontWidthArr[idx]);
                                            if(idx!==0){
                                                //如果不是第一列 设置定位左间距 left
                                                td.css('left',getLeft(idx,frontWidthArr)+'px');
                                            }
                                            fixedFrontWidthSum+=frontWidthArr[idx];
                                        }else if(idx === fixedFrontCounts){
                                            //如果相等 表示当前列已经不再需要固定  设置padding-left
                                            td.css('padding-left',fixedFrontWidthSum+'px');
                                        }

                                        if(tdLen-idx-1<fixedTailCounts){
                                            td.addClass('activeRight backColor');
                                            td.outerWidth(tailWidthArr[fixedTailCounts-tdLen+idx]);
                                            absoluteRight -=tailWidthArr[fixedTailCounts-tdLen+idx];
                                            if(tdLen-idx-1!==0){
                                                //如果不是最后一列 设置定位右间距 right
                                                td.css('right',absoluteRight-tdLen+idx+1+'px');
                                            }
                                        }else if(tdLen-idx-1===fixedTailCounts){
                                            // var  paddingRight = getTotalWidth(0,tailWidthArr);
                                            td.css('padding-right',paddingRight+'px');
                                        }
                                    });

                                    //设置每行高度
                                    var maxH = 0;
                                    t.children().each(function() {
                                        if($(this).outerHeight() > maxH) {
                                            maxH = $(this).outerHeight();
                                        }
                                    });

                                    t.children().each(function() {
                                        $(this).outerHeight(maxH);
                                    });
                                });
                            }

                            tableBordered.css('visibility','visible');
                        };

                        scope.isChangeHeight = true;
                        scope.isChangeData = false;
                        if(elem.attr('sc-is-component') == 1){
                            var popTableId = elem.attr('pop-table-id');
                            scPopTableEventsChannel.onAfterReloadData(function(scTable, newData, oldData,tabId) {
                                if(tabId == popTableId && angular.isArray(newData) && newData.length>0 && scope.isChangeData){
                                    $timeout(function(){
                                        scope.resetTable();
                                    });
                                }
                            });
                            scope.$watch(function(){
                                return elem.height();
                            },function(newVal,oldVal){
                                if(newVal>0 && scope.isChangeHeight){
                                    scope.isChangeData = true;
                                    scope.isChangeHeight = false;
                                    $timeout(function(){
                                        scope.resetTable();
                                    });
                                }
                            });
                        }

                        if(!elem.attr('sc-is-component') || elem.attr('sc-is-component')==0){
                            var tableId = elem.attr('table-id');
                            scTableEventsChannel.onAfterReloadData(function(ngTable, newData, oldData,tabId) {
                                if(tabId == tableId && angular.isArray(newData) && newData.length>0){
                                    $timeout(function(){
                                        scope.resetTable();
                                    });
                                }
                            });
                            scope.$watch(function(){
                                return elem.height();
                            },function(newVal,oldVal){
                                if(newVal>0){
                                    $timeout(function(){
                                        scope.resetTable();
                                    });
                                }
                            });
                        }
                    }
                }
            }
        }]);

    function camelToDash(str) {
        if (str) {
            str = str.replace(/([a-z])([A-Z])/g, '$1-$2').toLowerCase();
        }

        return str;
    }
}));


(function(ng) {
    'use strict';

    ng.module('scTabs', []).run(run);

    run.$inject = ['$templateCache'];
    function run($templateCache) {
        $templateCache.put("scTabs/tab-right.html",
            "<li class=\"uib-tab nav-item tab-right\">\n" +
            "<div uib-tab-heading-transclude>\n" +
            "  {{heading}}\n" +
            "</div>\n" +
            "</li>\n" +
            "");
    }
}(angular));
(function(ng) {
    'use strict';

    ng.module('scDatePicker', ['ui.bootstrap', 'ui.bootstrap.datetimepicker']);

    ng.module('scDatePicker').directive('scDatepicker', scDatepicker);
    scDatepicker.$inject = [];
    function scDatepicker() {
        return {
            restrict: 'EA',
            template: function(element, attrs) {
                var dtFormat = 'yyyy-MM-dd HH:mm';
                if (attrs.scDateFormat) {
                    dtFormat = attrs.scDateFormat;
                }
                var html = '<p class="input-group">' +
                    '<input type="text" class="form-control" datetime-picker="' + dtFormat + '" ng-model="scModel" is-open="isOpen" datepicker-options="datepickerOptions" timepicker-options="timepickerOptions" />' +
                    '<span class="input-group-btn">' +
                    '<button type="button" class="btn btn-default" ng-click="openCalendar($event)"><i class="glyphicon glyphicon-calendar"></i></button>' +
                    '</span>' +
                    '</p>';

                return html;
            },
            scope: {
                scModel: '=?scModel',
                scOnlyRead:'=?scOnlyRead'
            },
            link: {
                pre: linkFn
            }
        };

        function linkFn(scope,element, attrs, ctrl) {
            scope.isOpen = false;
            scope.openCalendar = openCalendar;
            scope.datepickerOptions = {};
            scope.timepickerOptions = {showMeridian: false};

            if (angular.isString(scope.scModel)) {
                scope.scModel = new Date(scope.scModel);
            } else if (angular.isNumber(scope.scModel)) {
                scope.scModel = new Date(scope.scModel);
            }

            function openCalendar(event) {
                scope.isOpen = !scope.isOpen;
            }

            var unwatch = scope.$watch('scModel', function(newVal, oldVal) {
                if (!(newVal == undefined && oldVal == undefined)) {
                    if (newVal) {
                        if (angular.isString(newVal)) {
                            scope.scModel = new Date(newVal);
                        } else if (angular.isNumber(newVal)) {
                            scope.scModel = new Date(newVal);
                        }
                    }
                }
            });

            scope.$on('$destroy', function() {
                unwatch();
            });
            // if(scope.scOnlyRead){
            //     var input = element.children('p').children('.form-control');
            //     input.attr('disabled','disabled');
            //     input.siblings('span').children().attr('disabled','disabled');
            // }
            var input = element.children('p').children('.form-control');
            var btn = input.siblings('span').children();
            var isDisabledPop = scope.$watch('scOnlyRead',function(newVal,oldVal){
                if(newVal){
                    input.attr('disabled','disabled');
                    btn.attr('disabled','disabled');
                }else{
                    //是否展开下拉框
                    input.removeAttr('disabled');
                    btn.removeAttr('disabled');
                }
            });
        }
    }
}(angular));
(function(angular, factory) {
    'use strict';
    if (typeof define === 'function' && define.amd) {
        define(['angular'], function(angular) {
            return factory(angular);
        });
    } else {
        return factory(angular);
    }
}(window.angular || null, function(angular) {
    'use strict';

    var app = angular.module("ngTree", []);

    app.factory('NgTree', ['$q', '$log', '$timeout', function($q, $log, $timeout) {
        var ScTree = function(baseSetting){
            var settings = {
                id:(new Date()).getTime()/1000,
                data : [],
                options:{
                    templateUrl : 'ng-tree/tree.html'
                },
                dataMap :  {},
                config:{
                    meta:{
                        titleField : 'title',
                        parentIdField : 'parentId',
                        nodeIdField : 'nodeId',
                        childField : 'children',
                        needCheck: false
                    }
                },
                selectedNodeIds : [],
                getAllNodes:[],//所有已选中的对象
                getAllIds:[],//所有已选中的对象的id值
                //添加子节点或根节点
                addNode: addNode,
                //获取选中的所有id
                getSelectedIds: angular.noop,
                //获取选中的所有node
                getSelectedNodes: angular.noop,
                //获取节点对象
                getNodeById: getNodeById,
                //是否展开全部树
                expandAllNodes:angular.noop,
                //是否收缩全部树
                collapseAllNodes:angular.noop,
                //刷新节点
                updateNode: updateNode,
                //实例化之后被传入自定义组件，继承scope中实现展开及高亮的方法
                _select: angular.noop,
                //根据data数组构建element[id]->element键值对
                buildDataMap: buildDataMap,
                //根据selectedNodeIds更新node状态
                updateNodeBySelectedNodeIds: updateNodeBySelectedNodeIds,
                //更改默认选中的id列表
                changeConfirmSelected:changeConfirmSelected,
                //重新加载树
                scReload:scReload
            };

            if(angular.isDefined(baseSetting)){
                settings.id = baseSetting.id?baseSetting.id:settings.id;
                settings.data = baseSetting.data?baseSetting.data:settings.data;

                if(angular.isDefined(baseSetting.config)){
                    if(angular.isDefined(baseSetting.config.meta)){
                        settings.config.meta = baseSetting.config.meta;
                    }
                }

                settings.selectedNodeIds = baseSetting.selectedNodeIds?baseSetting.selectedNodeIds:settings.selectedNodeIds;
            }

            if(angular.isDefined(baseSetting.options)){
                if(angular.isDefined(baseSetting.options.nodeChildren)){
                    settings.options.nodeChildren = baseSetting.options.nodeChildren;
                }
            }else{
                settings.options = {
                    templateUrl : 'ng-tree/tree.html'
                }
            }

            function buildDataMap(data) {
                if(angular.isArray(data) && data.length>0){
                    var len = data.length;
                    for(var i=0;i<len;i++){
                        settings.dataMap[data[i][settings.config.meta.nodeIdField]] = data[i];
                        settings.dataMap[data[i][settings.config.meta.nodeIdField]]['checked'] = false;
                        if(data[i][settings.config.meta.childField]){
                            buildDataMap(data[i][settings.config.meta.childField]);
                        }
                    }
                }
            }

            //将选中框的所有父子关联的框选中
            var getParentCheck = function(pid){
                if(pid && !settings.dataMap[pid]['checked']){
                    settings.dataMap[pid]['checked'] = true;
                    var idxn = settings.getAllNodes.indexOf(settings.dataMap[pid]);
                    if(idxn == -1){
                        settings.getAllNodes.push(settings.dataMap[pid]);
                    }
                    var idxi = settings.getAllIds.indexOf(settings.dataMap[pid][settings.config.meta.nodeIdField]);
                    if(idxi == -1){
                        settings.getAllIds.push(settings.dataMap[pid][settings.config.meta.nodeIdField]);
                    }
                    getParentCheck(settings.dataMap[pid][settings.config.meta.parentIdField]);
                }
            };

            function updateNodeBySelectedNodeIds() {
                settings.getAllNodes = [];
                settings.getAllIds = [];
                if(settings.selectedNodeIds.length>0){
                    var len = settings.selectedNodeIds.length;
                    for(var i=0;i<len;i++){
                        if(settings.dataMap[settings.selectedNodeIds[i]]){
                            settings.dataMap[settings.selectedNodeIds[i]]['checked'] = true;

                            var idxn = settings.getAllNodes.indexOf(settings.dataMap[settings.selectedNodeIds[i]]);
                            if(idxn == -1){
                                settings.getAllNodes.push(settings.dataMap[settings.selectedNodeIds[i]]);
                            }
                            var idxi = settings.getAllIds.indexOf(settings.dataMap[settings.selectedNodeIds[i]][settings.config.meta.nodeIdField]);
                            if(idxi == -1){
                                settings.getAllIds.push(settings.dataMap[settings.selectedNodeIds[i]][settings.config.meta.nodeIdField]);
                            }

                            //如果当前节点有pid 继续查找上一层父节点 设置checked属性为true
                            if(settings.dataMap[settings.selectedNodeIds[i]][settings.config.meta.parentIdField]){
                                getParentCheck(settings.dataMap[settings.selectedNodeIds[i]][settings.config.meta.parentIdField]);
                                //getChildrenCheck(settings.dataMap[settings.selectedNodeIds[i]][settings.config.meta.childField]);
                            }
                        }
                    }
                }
            }

            function updateNode(node, srcObj){
                if(angular.isDefined(srcObj) && angular.isObject(srcObj) && angular.isDefined(node) && angular.isObject(node) ){
                    for(var key in srcObj){
                        if(angular.isDefined(node[key])){
                            node[key] = srcObj[key];
                        }
                    }
                }
                //此方法会覆盖之前所有已有的属性（包括下一级菜单children）
                // if(angular.isDefined(srcObj)){
                //     angular.extend(node, srcObj);
                // }
            }

            function getNodeById(id){
                return settings.dataMap[id];
            }

            function addNode(newNode) {
                if(angular.isDefined(newNode) && (newNode!=={})){
                    var dataMap = settings.dataMap;

                    var idField = baseSetting.config.meta.nodeIdField?baseSetting.config.meta.nodeIdField:'id';
                    var pidField = baseSetting.config.meta.parentIdField?baseSetting.config.meta.parentIdField:'pid';
                    var childrenField = baseSetting.config.meta.childField?baseSetting.config.meta.childField:'children';

                    if(newNode[pidField] && dataMap[newNode[pidField]]){
                        //若children有定义 则直接push
                        if(dataMap[newNode[pidField]][childrenField]){
                            dataMap[newNode[pidField]][childrenField].push(newNode);
                            dataMap[newNode[idField]] = newNode;
                        }else{
                            //若children无定义 则先赋为数组 然后push
                            dataMap[newNode[pidField]][childrenField] = [];
                            dataMap[newNode[pidField]][childrenField].push(newNode);
                            dataMap[newNode[idField]] = newNode;
                        }
                    }
                    if(!newNode[pidField]){
                        settings.data.push(newNode);

                        dataMap[newNode[idField]] = newNode;
                    }
                }
            }

            function changeConfirmSelected(id) {
                $timeout(function(){
                    $('#'+id).parent().next().click();
                });
            }

            function scReload(){
                settings.buildDataMap(settings.data);
                settings.updateNodeBySelectedNodeIds();
            }

            return settings;
        };
            return ScTree;
    }]);

    angular.module('ngTree').directive('scTreecontrol', scTreecontrol);
    scTreecontrol.$inject = ['$timeout','NgTree'];
    function scTreecontrol($timeout,NgTree){
        return{
            restrict:'EA',
            // template :
            // //'<button class="btn btn-default btn-info" type="button" ng-click="expandAllNodes()">展开所有节点</button>&nbsp;&nbsp;&nbsp;'+
            //                     //'<button class="btn btn-default btn-info" type="button" ng-click="collapseAllNodes()">收缩所有节点</button>'+
            //                     '<treecontrol class="tree-light" tree-model="treeModel.data" options="options" on-selection="onSelection(node)">' +
            //                         '{{node[title]}}'+
            //                     '</treecontrol>',
            template:function(elem,attr){
                var html = '<treecontrol class="tree-light" tree-model="treeModel.data" ' +
                    'options="options" on-selection="selectionNode(node)">' ;
                if(attr.displayName){
                    var name = [];
                    if(angular.isArray(attr.displayName) && attr.displayName.length>0){
                         name = attr.displayName;
                    }else{
                         name = attr.displayName.split(',');
                    }
                    for(var i=0;i<name.length;i++){
                        if(i!=0 && attr.displaySplitter){
                            html = html+attr.displaySplitter;
                        }
                        html = html+'{{node.'+name[i]+'}}';
                    }
                }else{
                    html = html+'{{node[title]}}';
                }
                html = html+'</treecontrol>';
                return html;
            },
            scope:{
                treeModel : '=?treeModel',
                onSelection : '=?onSelection',
                treeList : '=?treeData',
                showNode:'=?showNode'
            },
            controller : ['$scope', function(scope){
                if(!angular.isDefined(scope.treeModel)){
                    //判断如果scope.treeModel没有定义 再判断scope.treeList如果存在则实例化
                    if(angular.isDefined(scope.treeList)){
                        scope.treeModel = new NgTree({
                            data : scope.treeList
                        });
                    }else{
                        scope.treeModel = new NgTree({
                            data : []
                        });
                    }
                }
                scope.options = {
                    templateUrl : scope.treeModel.options.templateUrl,
                    tree : scope.treeModel
                };
            }],
            link:linkFn
        };
        function linkFn(scope,element, attrs, ctrl){
            scope.title= attrs.scTitle?attrs.scTitle:scope.treeModel.config.meta.titleField;
            scope.nodeId =  attrs.nodeId?attrs.nodeId:scope.treeModel.config.meta.nodeIdField;
            scope.parentId =  attrs.parentId?attrs.parentId:scope.treeModel.config.meta.parentIdField;
            scope.children =  attrs.children?attrs.children:scope.treeModel.config.meta.childField;

            scope.selectionNode = function(node){
                var parentNode = scope.treeModel.dataMap[node[scope.parentId]];
                if(angular.isFunction(scope.onSelection)){
                    scope.onSelection(node,parentNode);
                }
            };

            scope.$watch('treeModel.data', function(newVal, oldVal) {
                scope.treeModel.buildDataMap(newVal);
                scope.treeModel.updateNodeBySelectedNodeIds();
            });
        }
    }

    angular.module('ngTree').directive('inputCheck', inputCheck);
    inputCheck.$inject = ['$timeout'];
   function inputCheck($timeout){
       return {
           require:'^scTreecontrol',
           restrict:'EA',
           template:'<input type="checkbox"  id="{{$nodeScope.node[id]}}" ng-click="update($nodeScope.node);$event.stopPropagation()"  ng-change="modify($nodeScope.node)" ng-model="$nodeScope.node.checked" value="$nodeScope.node.checked" class="checkbox" style="display:inline-block" >',
           scope: true,
           link:linkFn
       };
       function linkFn(scope,element, attrs, ctrl){
            scope.id = 'id';
            scope.pid = 'pid';
            scope.title = 'title';
            scope.children = 'children';

           //获取当前nodeScope和数据
           scope.getTCScope = function (s) {
               if (s.$parent && s.$parent.hasOwnProperty('treeModel')) {
                   return s.$parent;
               }
               if (s.$parent) {
                   return scope.getTCScope(s.$parent);
               }
           };
           scope.getNodeScope = function (s) {
               if (s.$parent && s.$parent.node) {
                   return s.$parent;
               }
               if (s.$parent) {
                   return scope.getNodeScope(s.$parent);
               }
           };

           //每加载一次input，自动调用一次函数，直至树形全部展开
           scope.show = function(elem){
               $timeout(function(){
               if($(elem).parent('li').hasClass('tree-collapsed')){
                   $(elem).siblings('.tree-branch-head').click();
               }
               },0);
           };
           scope.show(element);

           var $ctScope = scope.getTCScope(scope);
           scope.$nodeScope = scope.getNodeScope(scope);

           var tree = $ctScope.options.tree;

           //对象数组和id数组初始化
           if(!angular.isDefined($ctScope.options.getNodes)){
               $ctScope.options.getNodes= [];
           }
           if(!angular.isDefined($ctScope.options.getIds)){
               $ctScope.options.getIds= [];
           }

           //定义所需变量
           if(angular.isDefined(tree)){
               if(angular.isDefined(tree.config)){
                   if(angular.isDefined(tree.config.meta)){
                       scope.id = tree.config.meta.nodeIdField?tree.config.meta.nodeIdField:'id';
                       scope.pid = tree.config.meta.parentIdField?tree.config.meta.parentIdField:'pid';
                       scope.title = tree.config.meta.titleField?tree.config.meta.titleField:'title';
                       scope.children = tree.config.meta.childField?tree.config.meta.childField:'children';
                   }
               }
           }

           //获取map或者生成map
           if(scope.$nodeScope.node[scope.id] ){
               tree.dataMap[scope.$nodeScope.node[scope.id] ]= scope.$nodeScope.node;
               if(!angular.isDefined(scope.$nodeScope.node['checked'])){
                   scope.$nodeScope.node['checked'] = false;
               }
           }

           //选择框联动
           scope.changeParent = function(e){
               if(tree.dataMap[e[scope.pid]]){
                   if(!tree.dataMap[e[scope.pid]]['checked']){
                       tree.dataMap[e[scope.pid]]['checked']=true;

                       $ctScope.options.modifyChecked(tree.dataMap[e[scope.pid]]);

                       scope.changeParent(tree.dataMap[e[scope.pid]]);
                   }
               }
           };
           scope.changeChildren = function(e){
               if(e[scope.children]){
                   var len=e[scope.children].length;
                   for(var i = 0; i<len; i++){
                       //子元素根据父元素的状态改变
                       e[scope.children][i]['checked']=e['checked'];

                       //获取所选中的id和node
                       if(e['checked']){
                           $ctScope.options.modifyChecked( e[scope.children][i]);
                       }else{
                           $ctScope.options.modifyUnChecked( e[scope.children][i]);
                       }
                       scope.changeChildren(e[scope.children][i]);
                   }
               }
           };

            //修改选中的数组id和数组对象
           $ctScope.options.modifyChecked = function(e){
               var list  = tree.getAllIds;
               for(var i=0;i<list.length;i++){
                   if(list[i] == e[scope.id]){
                       return;
                   }
               }
               tree.getAllNodes.push(e);
               tree.getAllIds.push(e[scope.id]);
            };
           $ctScope.options.modifyUnChecked = function(e){
               var list  = tree.getAllIds;
               for(var i=0;i<list.length;i++){
                   if(list[i] == e[scope.id]){
                       tree.getAllIds.splice(i,1);
                       tree.getAllNodes.splice(i,1);
                   }
               }
               // var indexNode = $ctScope.options.getNodes.indexOf(e);
               // var indexId = $ctScope.options.getIds.indexOf(e[scope.id]);
               // if(indexNode >=0){
               //     $ctScope.options.getNodes.splice(indexNode,1);
               // }
               // if(indexId >=0){
               //     $ctScope.options.getIds.splice(indexId,1);
               // }
           };

           //选择框刷新
           if(!angular.isFunction(scope.update)){
               scope.update = update;
           }
           function update(node){
               if(node['checked']){
                   $ctScope.options.modifyChecked(node);
                   scope.changeParent(node);
                   //当前若被选中，则需要展开
                   if(!$(element).parent().hasClass('tree-expanded') && (!$(element).parent().hasClass('tree-leaf'))){
                       $timeout(function(){
                           scope.show(element);
                       },0);
                   }
                   scope.changeChildren(node);

               }else if(!node['checked']){
                   $ctScope.options.modifyUnChecked(node);
                   scope.changeChildren(node);
               }
           }

            //子元素发生变化时，若全无选中，则父元素也不选中

           if(!angular.isFunction(scope.modify)){
               scope.modify = modify;
           }
           function modify(node){
               if(node[scope.pid] && tree.dataMap[node[scope.pid]]){
                   var lis = tree.dataMap[node[scope.pid]][scope.children];
                   var len = lis.length;
                   for(var i = 0;i<len;i++){
                       if(tree.dataMap[lis[i][scope.id]]['checked']){
                           return;
                       }
                   }
                   tree.dataMap[node[scope.pid]]['checked'] = false;
                   $ctScope.options.modifyUnChecked(tree.dataMap[node[scope.pid]]);
                   scope.modify(tree.dataMap[node[scope.pid]]);
               }

           }

            //判断是否默认被选中，做展开处理，并且将默认选中的节点放进数组
           if(scope.$nodeScope.node['checked']){
               if(!tree.config.meta.needCheck){
                   $timeout(function(){
                        $('#'+scope.$nodeScope.node[scope.id]).parent().next().click();
                    });
               }
               $ctScope.options.modifyChecked(scope.$nodeScope.node);
           }

           //获取选中的id数组
           if(tree.getSelectedIds === angular.noop){
               tree.getSelectedIds = function(){
                   return tree.getAllIds;
               }
           }

            // 获取选中的对象数组
           if(tree.getSelectedNodes === angular.noop){
               tree.getSelectedNodes = function(){
                   return tree.getAllNodes;
               }
           }
       }
   }

    angular.module('ngTree').run(['$templateCache', function ($templateCache) {
        $templateCache.put('ng-tree/tree.html',
            '<ul {{options.ulClass}}  class="checkTree">' +
            '<li  ng-repeat="node in node.{{options.nodeChildren}}" ng-class="headClass(node)" {{options.liClass}}' +
            'set-node-to-data>' +
            '<i class="tree-branch-head" ng-class="iBranchClass()" ng-click="selectNodeHead(node);$event.stopPropagation()"></i>' +
            '<i class="tree-leaf-head {{options.iLeafClass}}"></i>' +
            '<input-check ng-show="options.tree.config.meta.needCheck"></input-check>'+
            '<div class="tree-label {{options.labelClass}}" ng-class="[selectedClass(), unselectableClass()]" ng-click="selectNodeLabel(node)" tree-transclude></div>' +
            '<treeitem ng-show="nodeExpanded()"></treeitem>' +
            '</li>' +
            '</ul>'
        );
        }]);
}));
(function(angular, factory) {
    'use strict';
    if (typeof define === 'function' && define.amd) {
        define(['angular'], function(angular) {
            return factory(angular);
        });
    } else {
        return factory(angular);
    }
}(window.angular || null, function(angular) {
    'use strict';

    var app = angular.module("ngMenu", []);
    app.run(run);
    run.$inject = ['$templateCache'];
    function run($templateCache) {
        $templateCache.put('imenu.updatePassword.html', '<div class="modal-header"><h3 class="modal-title">更新密码</h3></div><div class="modal-body"><form novalidate name="$ctrl.form" class="form-horizontal"><div class="form-group"><label for="oldPassword" class="col-sm-2 control-label">旧密码</label><div class="col-sm-10"><input type="password" class="form-control" required="required" id="oldPassword" name="oldPassword" placeholder="旧密码" ng-model="$ctrl.item.oldPassword" required><div ng-show="$ctrl.form.showMsg"><div ng-show="$ctrl.form.oldPassword.$error.required" class="error-msg">旧密码不能为空.</div></div></div></div><div class="form-group"><label for="password" class="col-sm-2 control-label">新密码</label><div class="col-sm-10"><input type="password" class="form-control" required="required" id="password" name="password" placeholder="新密码" ng-model="$ctrl.item.password" required><div ng-show="$ctrl.form.showMsg"><div ng-show="$ctrl.form.password.$error.required" class="error-msg">新密码不能为空.</div><div ng-show="$ctrl.form.passwordUnmatch" class="error-msg">{{$ctrl.form.passwordUnmatch}}.</div></div></div></div><div class="form-group"><label for="password2" class="col-sm-2 control-label">再次输入新密码</label><div class="col-sm-10"><input type="password" class="form-control" required="required" name="password2" id="password2" placeholder="再次输入新密码" ng-model="$ctrl.item.password2" required><div ng-show="$ctrl.form.showMsg"><div ng-show="$ctrl.form.password2.$error.required" class="error-msg">新密码不能为空.</div><div ng-show="$ctrl.form.passwordUnmatch" class="error-msg">{{$ctrl.form.passwordUnmatch}}.</div></div></div></div></form></div><div class="modal-footer"><button class="btn btn-primary" type="button" ng-click="$ctrl.ok()">提交</button><button class="btn btn-warning" type="button" ng-click="$ctrl.cancel()">取消</button></div>');
    }

    var menuCss = "menuCss=2";
    //通过url判断是否为老菜单
    function judgeMenu() {
        var href = window.location.href;
        //如果大于-1说明url存在menuCss
        if (href.indexOf(menuCss) > -1) {
            return false;
        } else {
            return true;
        }
    }
    // judgeMenu();
    // //“样式改变”按钮改变菜单样式

    // //如果judgeMenu()为true,那么菜单风格为默认样式,否则为新样式；menu为true/false分别表示为顶部主菜单还是快捷菜单
    function judgeMenuCss(menu) {
        // console.log("judgeMenu():"+judgeMenu());
        menu = judgeMenu() ? (menu ? "templates/imenu/systemNewMenu.html" : "templates/imenu/shortcutNewMenu.html") : (menu ? "templates/imenu/systemSecMenu.html" : "templates/imenu/shortcutSecMenu.html");
        return menu;
    }

    angular.module('ngMenu').directive('scShortcutMenu', scShortcutMenu);
    scShortcutMenu.$inject = ['$timeout', '$localStorage', '$sce', '$http', '$location', '$state', '$window', 'iServiceConfig'];

    function scShortcutMenu($timeout, $localStorage, $sce, $http, $location, $state, $window, iServiceConfig) {
        return {
            restrict: 'EA',
            templateUrl: judgeMenuCss(false),
            scope: {
                dataUrl: '=?dataurl',
                menuName: '=?menuname',
                menuUrl: '=?menuurl',
                menuChildren: '=?children',
                menuId: '=?menuid',
                menuPid: '=?menupid',
                menuData: '=?menuData'
            },
            link:{post: linkFn}
        };
        function linkFn(scope, element, attrs, ctrl) {
            scope.isUndefinedOrNull = function (val) {
                return angular.isUndefined(val) || val === null;
            };
            //配置变量
            if (!scope.menuName && !angular.isDefined(scope.menuName)) {
                scope.menuName = 'menuName';
            }
            if (!scope.menuUrl && !angular.isDefined(scope.menuUrl)) {
                scope.menuUrl = 'menuUrl';
            }
            if (!scope.menuChildren && !angular.isDefined(scope.menuChildren)) {
                scope.menuChildren = 'children';
            }
            if (!scope.menuId && !angular.isDefined(scope.menuId)) {
                scope.menuId = 'menuId';
            }
            if (!scope.menuPid && !angular.isDefined(scope.menuPid)) {
                scope.menuPid = 'parentId';
            }

            //接受每次改变选中选项后的id  pid，当切换快捷菜单时需选中
            scope.id = null;
            scope.pid = null;

            if (scope.menuData) {
                scope.data = scope.menuData;
            }

            //判断是否有菜单数据
            scope.ifData = function () {
                if (angular.isDefined(scope.data) && angular.isArray(scope.data) && scope.data.length > 0) {
                    return true;
                } else {
                    return false;
                }
            };
            //判断是否有下级菜单
            scope.ifMenu = function (menu) {
                if (angular.isDefined(menu[scope.menuChildren]) && angular.isArray(menu[scope.menuChildren]) && menu[scope.menuChildren].length > 0) {
                    return true;
                } else {
                    return false;
                }
            };

            scope.isAuthenticated = function () {
                return true;
            };

            //跳转到配置快捷菜单页面
            scope.openShortcutPage = function () {
                var open = $window.open(iServiceConfig.favmenuHost + $localStorage.authenticationToken, '_blank');
            };

            //改变选中选项的状态
            scope.changeStatus = function (id, pid) {
                var div, p, bod, a;
                //如果有pid，说明点击第二级菜单
                if (pid && !scope.isUndefinedOrNull(pid)) {
                    $timeout(function(){
                        // a = $('#menu' + id + '___' + pid);
                    var pa = $('#menu'+pid);
                    // var par = a.parent();
                    //如果是大菜单  a元素父级为div
                    if (pa[0] && pa[0].tagName == 'DIV') {
                        // div = $('#menu' + pid);
                        p = pa.children('p');
                        var collBody = pa.children('div');
                        a = collBody.children('#menu' + id + '___' + pid);
                        //若p元素没有active，说明是通过url默认选中，展开该级菜单并选中该元素
                        if (p[0] && !p.hasClass('active')) {
                            p.addClass('current active');
                            collBody.slideDown();
                            a.addClass('active');
                        } else {
                            pa.parent().find('a.active').removeClass('active');
                            collBody.slideDown();
                            a.addClass('active').siblings('.active').removeClass('active');
                        }
                    } else if(pa[0] && pa[0].tagName == 'LI'){
                        //如果是小菜单  a元素父级为li
                        // var li = $('#menu' + pid);
                        a = $('#menu' + id + '___' + pid);
                        pa.siblings('li').find('a.active').removeClass('active');
                        pa.children('ul').find('a.active').removeClass('active');
                        a.addClass('active');
                    }
                    });

                } else {
                    //如果没有pid，说明点击第一级菜单
                    div = $('#menu' + id);
                    p = div.children('.Collapsing');
                    bod = div.children('.coll_body');
                    p.toggleClass('current');
                    p.toggleClass('active');
                    bod.slideToggle();
                }
            };

            scope.getData = function () {
                return new Promise(function (resolve) {
                    // var url= scope.dataUrl+  "72879155-74e6-4efd-969a-22bed5b47dc5";
                     /* console.log(url);*/
                    var url = scope.dataUrl + $localStorage.authenticationToken;
                    $sce.trustAsResourceUrl(url);
                    $http.jsonp(url).success(function (data) {
                        if (data.status === 'SUCCESS') {
                            if (angular.isDefined(data.content) && angular.isArray(data.content) && (data.content.length > 0)) {
                                // scope.data = data.content;
                                resolve(data.content);
                            } else {
                                // scope.data = [];
                                resolve([]);
                            }
                        }
                    });
                });
            };

            //请求菜单数据
            if (scope.dataUrl) {

                var url = scope.dataUrl + $localStorage.authenticationToken;
                var promise = scope.getData();
                promise.then(function (data) {
                    scope.data = data;
                    $timeout(function () {
                        var id = $location.search()['scShortcutId'];
                        if (id && id.indexOf('___')) {
                            var s = id.split('___');
                            scope.id = s[0];
                            scope.pid = s[1];
                            scope.changeStatus(s[0], s[1]);
                        }
                    }, 0);

                });

            }

            //判断是否有菜单数据
            scope.ifData = function () {
                if (angular.isDefined(scope.data) && angular.isArray(scope.data) && scope.data.length > 0) {
                    return true;
                } else {
                    return false;
                }
            };

            //判断是否有下级菜单
            scope.ifMenu = function (menu) {
                if (angular.isDefined(menu[scope.menuChildren]) && angular.isArray(menu[scope.menuChildren]) && menu[scope.menuChildren].length > 0) {
                    return true;
                } else {
                    return false;
                }
            };

            //    页面跳转
            scope.jumpUrl = function (menu) {
                //如果当前有url
                if (angular.isDefined(menu[scope.menuUrl]) && angular.isString(menu[scope.menuUrl]) && (menu[scope.menuUrl].length > 0)) {
                    //判断是以http开头 还是#/开头
                    var url = menu[scope.menuUrl].indexOf('http');
                    //如果是以http开头
                    if (url == 0) {
                        if (angular.isDefined(menu[scope.menuPid]) && ((angular.isString(menu[scope.menuPid]) && menu[scope.menuPid].length > 0) || angular.isNumber(menu[scope.menuPid]))) {
                            scope.id = menu[scope.menuId];
                            scope.pid = menu[scope.menuPid];
                            scope.changeStatus(menu[scope.menuId], menu[scope.menuPid]);
                            if (menu[scope.menuUrl].indexOf('?') > -1) {
                                window.location.href = judgeMenu() ?menu[scope.menuUrl] + '&scShortcutId=' + menu[scope.menuId] + '___' + menu[scope.menuPid] :menu[scope.menuUrl] + '&scShortcutId=' + menu[scope.menuId] + '___' + menu[scope.menuPid] + "&" + menuCss;
                                // window.location.href = menu[scope.menuUrl] + '&scShortcutId=' + menu[scope.menuId] + '___' + menu[scope.menuPid];
                            } else {
                                window.location.href = judgeMenu() ?menu[scope.menuUrl] + '?scShortcutId=' + menu[scope.menuId] + '___' + menu[scope.menuPid] :menu[scope.menuUrl] + '?scShortcutId=' + menu[scope.menuId] + '___' + menu[scope.menuPid] + "&" + menuCss;
                                // window.location.href = menu[scope.menuUrl] + '?scShortcutId=' + menu[scope.menuId] + '___' + menu[scope.menuPid];
                            }
                        }
                    } else {
                        // 否则以#/开头
                        if (angular.isDefined(menu[scope.menuPid]) && ((angular.isString(menu[scope.menuPid]) && menu[scope.menuPid].length > 0) || angular.isNumber(menu[scope.menuPid]))) {
                            scope.id = menu[scope.menuId];
                            scope.pid = menu[scope.menuPid];
                            scope.changeStatus(menu[scope.menuId], menu[scope.menuPid]);
                            var url = menu[scope.menuUrl].split("#/")[1];
                            if (url) {
                                $state.go(url);
                            } else {
                                $state.go('/');
                            }
                        } else {
                            scope.id = menu[scope.menuId];
                            scope.pid = menu[scope.menuPid];
                            scope.changeStatus(menu[scope.menuId]);
                            var url = menu[scope.menuUrl].split("#/")[1];
                            if (url) {
                                $state.go(url);
                            } else {
                                $state.go('/');
                            }
                        }
                    }
                } else {
                    //如果没有url，调用函数改变选中状态
                    if (angular.isDefined(menu[scope.menuPid]) && ((angular.isString(menu[scope.menuPid]) && menu[scope.menuPid].length > 0) || angular.isNumber(menu[scope.menuPid]))) {
                        // scope.id=menu[scope.menuId];scope.pid=menu[scope.menuPid];
                        scope.changeStatus(menu[scope.menuId], menu[scope.menuPid]);
                    } else {
                        // scope.id=menu[scope.menuId];scope.pid=menu[scope.menuPid];
                        scope.changeStatus(menu[scope.menuId]);
                    }
                }
            };
            //跳转新页面
            scope.jumpNewWindow = function (menu) {
            	console.log(menu);
                if (angular.isDefined(menu[scope.menuUrl]) && angular.isString(menu[scope.menuUrl]) && (menu[scope.menuUrl].length > 0)) {
                    if (menu[scope.menuUrl].indexOf('?') > -1) {
                        judgeMenu() ? window.open(menu[scope.menuUrl] + '&scShortcutId=' + menu[scope.menuId] + '___' + menu[scope.menuPid]) : window.open(menu[scope.menuUrl] + '&scShortcutId=' + menu[scope.menuId] + '___' + menu[scope.menuPid] + "&" + menuCss);
                    } else {
                        judgeMenu() ? window.open(menu[scope.menuUrl] + '?scShortcutId=' + menu[scope.menuId] + '___' + menu[scope.menuPid]) : window.open(menu[scope.menuUrl] + '?scShortcutId=' + menu[scope.menuId] + '___' + menu[scope.menuPid] + "&" + menuCss);
                    }
                }
            };


            //控制切换菜单
            scope.myVar = true;
            var content = $('#mainContent');
            content.css('min-width','1221px');
            scope.toggle = function () {
                scope.myVar = !scope.myVar;
                if (scope.myVar) {
                    content.css({'left':145,'min-width':'1221px'});
                } else {
                    content.css({'left':38,'min-width':'1328px'});
                }
                //切换快捷菜单时，需要默认选中之前的选中项
                $timeout(function () {
                    scope.changeStatus(scope.id, scope.pid);
                }, 0);
            };

            //****分割****//

            scope.shortcutSwitch=function () {
                var thisA = jQuery(event.target);
                if (thisA.siblings("ul").css('display') === "none") {
                    thisA.addClass("secShLiOneAHover");
                    thisA.siblings("ul").slideDown(300);
                }
                else {
                    thisA.siblings("ul").slideUp(300);
                    thisA.removeClass("secShLiOneAHover");
                }
            };

            var isMainMenu;
            scope.menuSwitch=function () {
                if(jQuery(".secUlOne").css("display")==="block"){
                    isMainMenu=true;
                }
                else {
                    isMainMenu=false;
                }
                if(jQuery(".secShortcut").css("display")==="block"){
                    if(isMainMenu){
                        jQuery(".secUlOne").css("margin-left","38px");
                    }
                    jQuery(".secShortcut").css("width", "38px");
                    jQuery(".secShLiOne>a").css("width", "29px");
                    jQuery(".secShLiTwo>a").css("width", "0");
                    jQuery(".secHeaderMenuBtn").css({"width": "37px", "backgroundImage": "url('https://static.56start.com/images/menu/right.png')","backgroundSize": "20px"});
                    jQuery(".secSetUpBtn").css("margin-left", "-130px");
                    jQuery(".menuChangeBtn").css("margin-left", "6px");
                    setTimeout(function () {
                        jQuery(".secSimple").css("display","block");
                        jQuery(".secShortcut").css("display","none");
                    },300);
                    jQuery("#mainContent").css("left","36px");
                }
                else {
                    jQuery(".secSimple").css("display","none");
                    jQuery(".secShortcut").css("display","block");
                    setTimeout(function () {
                        if (isMainMenu) {
                            jQuery(".secUlOne").css("margin-left", "165px");
                        }
                        jQuery(".secShLiOne>a").css("width", "165px");
                        jQuery(".secShortcut").css("width", "165px");
                        jQuery(".secShLiTwo>a").css("width", "136px");
                        jQuery(".secHeaderMenuBtn").css({"width": "164px","backgroundImage": "url('https://static.56start.com/images/menu/logo.png')","backgroundSize": "auto","transform": "rotate(0deg)"});
                        jQuery("#mainContent").css("left", "163px");
                        jQuery(".secSetUpBtn").css("margin-left", "10px");
                        jQuery(".menuChangeBtn").css("margin-left", "126px");
                    }, 0);
                }
            };
        }
    }

    angular.module('ngMenu').directive('scSystemMenu', scSystemMenu);
    angular.module('ngMenu').directive('onFinishRender', ['$timeout', '$parse', function($timeout, $parse) {
        return {
            restrict: 'A',
            link: function(scope, element, attr) {
                if (scope.$last === true) {
                    $timeout(function() {
                        scope.$emit('ngRepeatFinished'); //事件通知
                    });
                }
            }
        }
    }]);
    scSystemMenu.$inject = ['iServiceConfig', '$http', '$state', '$localStorage', '$sce', '$timeout', '$uibModal', 'APPID', 'toastr', '$location', '$window'];

    function scSystemMenu(iServiceConfig, $http, $state, $localStorage, $sce, $timeout, $uibModal, appid, toastr, $location, $window) {
    	return {
            restrict: 'EA',
            priority: 1009,
            templateUrl: judgeMenuCss(true),
            scope: {
                rawData: '=?scData',
                favorData: '=?scFavorData',
                dataUrl: '=?dataurl',
                favorUrl: '=?favorurl',
                shortcuturl: '=?shortcuturl',
                userpendingurl: '=?userpendingurl',
                menuSysName: '=?menuName',
                menuSysUrl: '=?menuUrl',
                children: '=?children',
                currentUser: '=?currentUser',
                logoutFn: '=?logout',
                showNavbar: '=?showNavbar'
            },
            link: {
                post: linkFn
            }
        };
        function linkFn(scope, element, attrs, ctrl) {
            var splitor = '-';
            var favorMenuMap = {};
            var favorMenuUrlMap = {};
            var unFolderItem = null;
            scope.logined = false;
            scope.selectedItems = [];
            console.log('scope.showNavbar = ' + scope.showNavbar);
            scope.menuSysName = scope.menuSysName? scope.menuSysName: 'menuName';
            scope.menuSysUrl = scope.menuSysUrl? scope.menuSysUrl: 'menuUrl';
            scope.children = scope.children? scope.children: 'children';
            scope.data = scope.rawData? simplyStructure(scope.rawData): [];
            scope.displayData = getDisplayData(scope.data);
            scope.favorData = scope.favorData? scope.favorData: [];
            scope.shortcutData = scope.shortcutData? scope.shortcutData: [];
            scope.displayFavorData = getDisplayFavorData(scope.favorData);
            scope.displayShortcutData = getDisplayShortcutData(scope.shortcutData);
            
            scope.userPendingData = {msgCount:0,todoCount:0};
            
            scope.appid = appid;
            scope.warehouseRequiredAppList = iServiceConfig.warehouseRequiredAppList;

            scope.toggleMenu = toggleMenu;

            scope.sidebarFolded = false;
            scope.toggleSideMenu = toggleSideMenu;
            scope.toggleSideMenuItem = toggleSideMenuItem;
            scope.selectItem = selectItem;
            scope.navItem = navItem;

            var unWatchKeyword = scope.$watch('keywords',function(newVal,oldVal){
                scope.displayData = getDisplayData(scope.data);
            });
            var unWatchUser = scope.$watch('currentUser',function(newVal,oldVal){
                if (newVal && newVal.userName) {
                    loadMenu();
                    scope.logined = true;
                } else {
                    scope.logined = false;
                }
            });
            scope.$on('$destroy', function () {
                unWatchKeyword();
                unWatchUser()
            });
            scope.jumpUrlA = function (menu) {
            	navItem(menu);
            	window.location.href=menu.getUrl();
            }
            scope.jumpUrlB = function (menu) {
            	selectItem(menu);
            	window.location.href=menu.getUrl();
            }
            scope.jumpUrl = function (menu) {
            	navItem(menu);
            	window.location.href=menu.getUrl();
            }
          //跳转新页面
            scope.jumpNewWindow = function (menu) {
            	window.open(menu.getUrl());
            };
            
            scope.returnFirtChar = function (menuName, len) {
            	return menuName.substring(0, len);
            };

            function loadMenu() {
                if (scope.dataUrl) {
                    var url = scope.dataUrl + $localStorage.authenticationToken;
                    $sce.trustAsResourceUrl(url);
                    $http.jsonp(url).success(function (data) {
                        if (data.status === 'SUCCESS') {
                        	var menuData = [];
                            if (angular.isDefined(data.content) && angular.isArray(data.content) && (data.content.length > 0)) {
                            	menuData = simplyStructure(data.content);
                            }

                            scope.displayData = getDisplayData(menuData);
                        }
                    });
                }
                if (scope.userpendingurl) {
                    var url = scope.userpendingurl + $localStorage.authenticationToken;
                    $sce.trustAsResourceUrl(url);
                    $http.jsonp(url).success(function (data) {
                        if (data.status === 'SUCCESS') {
                            if (angular.isDefined(data.content)) {
                            	scope.userPendingData = data.content;
                            }
                        }
                    });
                }
                
                if (scope.favorUrl) {
                    $timeout(function() {
                        var url = scope.favorUrl + $localStorage.authenticationToken;
                        $sce.trustAsResourceUrl(url);
                        $http.jsonp(url).success(function (data) {
                            if (data.status === 'SUCCESS') {
                            	var menuData = [];
                                if (angular.isDefined(data.content) && angular.isArray(data.content) && (data.content.length > 0)) {
                                	menuData = data.content;
                                }

                                scope.displayFavorData = getDisplayFavorData(menuData);

                                $timeout(function() {
                                    jQuery('[data-tip]').hover(function(e) {
                                        var $target = jQuery(this);
                                        var $toolTip = jQuery('#tooltip');
                                        var x = $target.width();
                                        var y = $target.offset().top + 3;

                                        if ($target.attr('data-tip')) {
                                            $toolTip.css('left', x + 'px');
                                            $toolTip.css('top', y + 'px');
                                            $toolTip.text($target.attr('data-tip'));
                                            $toolTip.addClass('show');
                                        }
                                    }, function(e) {
                                        var $toolTip = jQuery('#tooltip');
                                        $toolTip.removeClass('show');
                                    });
                                }, 300);
                            }
                        });
                    }, 0);
                }
                
                if (scope.shortcuturl) {
                    $timeout(function() {
                        var url = scope.shortcuturl + $localStorage.authenticationToken;
                        $sce.trustAsResourceUrl(url);
                        $http.jsonp(url).success(function (data) {
                            if (data.status === 'SUCCESS') {
                            	var menuData = [];
                                if (angular.isDefined(data.content) && angular.isArray(data.content) && (data.content.length > 0)) {
                                	menuData = data.content;
                                }

                                scope.displayShortcutData = getDisplayShortcutData(menuData);

                                $timeout(function() {
                                    jQuery('[data-tip]').hover(function(e) {
                                        var $target = jQuery(this);
                                        var $toolTip = jQuery('#tooltip');
                                        var x = $target.width();
                                        var y = $target.offset().top + 3;

                                        if ($target.attr('data-tip')) {
                                            $toolTip.css('left', x + 'px');
                                            $toolTip.css('top', y + 'px');
                                            $toolTip.text($target.attr('data-tip'));
                                            $toolTip.addClass('show');
                                        }
                                    }, function(e) {
                                        var $toolTip = jQuery('#tooltip');
                                        $toolTip.removeClass('show');
                                    });
                                }, 300);
                            }
                        });
                    }, 0);
                }
            }

            function initSelectItems() {
                var retMenuIds = [];
                var menuIds = $location.search().menuIds;
                if (menuIds && menuIds.length > 0) {
                    var menuIdArr = menuIds.split(splitor);
                    menuIdArr.forEach(function(id, idx) {
                        if (idx < menuIdArr.length - 1) {
                            var menuItem = favorMenuMap[id];
                            if (menuItem) {
                                retMenuIds.push(id);
                                toggleSideMenuItem(menuItem);
                            }
                        } else {
                            var menuItem = favorMenuMap[menuIdArr[idx-1] + splitor + id];
                            if (menuItem) {
                                retMenuIds.push(id);
                                menuItem.selected = true;
                            }
                        }
                    });
                }

                return retMenuIds;
            }

            // 导航菜单
            function navItem(item) {
                var menuUrl = item.menuUrl;

                var favorItem = favorMenuUrlMap[menuUrl];
                if (favorItem) {
                    $timeout(function() {
                        selectItem(favorItem);
                    }, 0);
                } else {
                    selectItem(null);
                }

                toggleMenu();
            }

            // 选中的父菜单，子菜单
            function selectItem(item) {
                if (item === null) {
                    if (scope.selectedItems.length !== 0) {
                        var selectParentItem = scope.selectedItems[0];
                        var selectedItem = scope.selectedItems[1];
                        var selectedItemObj = favorMenuMap[selectParentItem + splitor + selectedItem];

                        toggleSideMenuItem(favorMenuMap[selectParentItem]);

                        scope.selectedItems = [];
                        selectedItemObj.selected = false;
                    }
                } else {
                    var parentId = item.parentId;
                    if (favorMenuMap[parentId] && favorMenuMap[parentId].folded === true) {
                        toggleSideMenuItem(favorMenuMap[parentId]);
                    }

                    if (scope.selectedItems.length === 0) {
                        scope.selectedItems.push(parentId);
                        scope.selectedItems.push(item.menuId);

                        item.selected = true;
                    } else {
                        var selectParentItem = scope.selectedItems[0];
                        var selectedItem = scope.selectedItems[1];
                        var selectedItemObj = favorMenuMap[selectParentItem + splitor + selectedItem];

                        scope.selectedItems[0] = parentId;
                        scope.selectedItems[1] = item.menuId;
                        selectedItemObj.selected = false;
                        item.selected = true;
                    }
                }

            }

            // 登出
            scope.logout = function() {
                var url = iServiceConfig.logoutHost;
                if (url) {
                    url = url + $localStorage.authenticationToken;
                    $sce.trustAsResourceUrl(url);
                    $http.jsonp(url).success(function(data) {
                        if (scope.logoutFn && angular.isFunction(scope.logoutFn)) {
                            scope.logoutFn();
                        } else {
                            $window.location.reload();
                        }
                    });
                }
            };

            // 验证密码
            scope.validatePwdForm = function(item) {
                var errors = [];
                if (item.password2 !== item.password) {
                    errors.push({msg: "用户二次密码不一致"});
                }

                return errors;
            };

            // 修改密码
            scope.updatePassword = function (item) {
                var url = iServiceConfig.updatePasswordHost + $localStorage.authenticationToken;
                $http.post(url, {newPassword: item.password, unifyPassword: item.oldPassword})
                    .success(function (data) {
                        toastr.success("密码修改成功", "成功");
                        scope.logout();
                    });
            };

            scope.getStoredDeviceCode = function() {
                if(scope.currentUser.sessionData['EQUIPMENT_CODE']){
                    return {deviceNo: scope.currentUser.sessionData['EQUIPMENT_CODE']};
                }
            };

            // 修改设备
            scope.updateDevice = function (item) {
                if(item && item.deviceNo){
                    var codeModel = item.deviceNo;
                    scope.currentUser.sessionData['EQUIPMENT_CODE']= codeModel;
                    var url = iServiceConfig.issoHost + "/web/equipment/set?token="+ $localStorage.authenticationToken;
                    return new Promise(function(resovle, reject) {
                        $http.post(url, {code:codeModel})
                            .success(function (data) {
                                toastr.success('绑定设备成功');
                                resovle(true);
                            });
                    });
                }

                return false;
            };

            scope.getBindCellphone = function() {
            	var itemObj = {};
                if(scope.currentUser.cellPhone){
                	itemObj.oldCellPhone = scope.currentUser.cellPhone;
                }
                itemObj.sendCellPhoneCode = function (cellPhone) {
                    if(cellPhone){
                    	var cellPhoneObj = {cellPhone:cellPhone};
                        var url = iServiceConfig.iuserHost + "/web/userSelf/sendBindPhoneCode?token="+ $localStorage.authenticationToken;
                        return new Promise(function(resovle, reject) {
                            $http.post(url, cellPhoneObj)
                                .success(function (data) {
                                    toastr.success('手机号验证码已发送成功');
                                });
                        });
                    } else {
                    	toastr.error('请先输入手机号！');
                    }
                };
                return itemObj;
            };
            scope.bindCellPhone = function (item) {
                if(item && item.cellPhone){
                    var codeModel = {cellPhone:item.cellPhone,code:item.code};
                    var url = iServiceConfig.iuserHost + "/web/userSelf/bindCellPhone?token="+ $localStorage.authenticationToken;
                    return new Promise(function(resovle, reject) {
                        $http.post(url, codeModel)
                            .success(function (data) {
                            	scope.currentUser.cellPhone = data.content;
                                toastr.success('绑定手机号成功');
                                resovle(true);
                            });
                    });
                }

                return false;
            };
            
            // 跳转到切换仓库页面
            scope.goWarehouseSelectPage = function () {
                var redirectUrl = window.location.href;
                window.location.href = iServiceConfig.warehouseSelectUrl + '?redirectUrl=' + redirectUrl;
            };

            function simplyStructure(rawData) {
                var data = [];

                rawData.forEach(function(rd) {
                    simplyStructureL(rd, 0, null, data);
                });

                return data;
            }

            function simplyStructureL(rd, level, topRd, output) {
                if (rd.children && rd.children.length > 0) {
                    var localTopRd = null;
                    if (level === 1) {
                        localTopRd = angular.copy(rd);
                        localTopRd.children = [];
                        output.push(localTopRd);
                    } else {
                        localTopRd = topRd;
                    }
                    rd.children.forEach(function(crd) {
                        simplyStructureL(crd, level+1, localTopRd, output);
                    });
                } else {
                    if (topRd) {
                        topRd.children.push(rd);
                    } else {
                        output.push(rd);
                    }
                }

                if (rd.menuUrl) {
                    rd.getUrl = function() {
                        if (favorMenuUrlMap[rd.menuUrl]) {
                            return favorMenuUrlMap[rd.menuUrl].getUrl();
                        } else {
                            return rd.menuUrl;
                        }
                    };
                }
            }

            function getDisplayData(data) {
                if (data && data.length > 0) {
                    var fData = filterItem(data, scope.keywords);

                    var dpData = [[],[],[],[],[],[]];
                    var colSize = dpData.length;
                    for (var i = 0; i < fData.length; i++) {
                        dpData[i % colSize].push(fData[i]);
                    }
                    return dpData;
                } else {
                    return [];
                }
            }

            function toggleMenu() {
                jQuery('.pull-left.topbar-product.product-list-box').toggleClass('open');
            }

            function filterItem(data, keywords) {
                if (keywords) {
                    var fData = [];
                    angular.forEach(scope.data, function(item) {
                        var fItem = angular.copy(item);
                        fItem.children = [];

                        angular.forEach(item.children, function (child) {
                            if (child['menuName'].indexOf(keywords) > -1) {
                                fItem.children.push(child);
                            }
                        });

                        if(fItem.children.length > 0) {
                            fData.push(fItem);
                        }
                    });

                    return fData;
                } else {
                    return angular.copy(data);
                }
            }

            function toggleSideMenu() {
                scope.sidebarFolded = !scope.sidebarFolded;

                if (scope.sidebarFolded) {
                    jQuery('body').removeClass('sidebar-full');
                    jQuery('.viewFramework-body').css({left: '50px'});
                } else {
                    jQuery('body').addClass('sidebar-full');
                    jQuery('.viewFramework-body').css({left: '180px'});
                }
            }

            function toggleSideMenuItem(item) {
                item.folded = !item.folded;
                if (item.folded) {
                    item.childrenStyle = {'max-height': '0px'};
                    unFolderItem = null;
                } else {
                    var maxH = item.children.length * 40;
                    if (maxH > 400) {
                        maxH = 400;
                    }
                    item.childrenStyle = {'max-height': maxH + 'px'};

                    if (unFolderItem && unFolderItem.menuId !== item.menuId) {
                        unFolderItem.folded = true;
                        unFolderItem.childrenStyle = {'max-height': '0px'};
                    }
                    unFolderItem = item;
                }

                $timeout(function() {
                    var bH = jQuery('.sidebar').height();
                    var sH = jQuery('.sidebar-wrapper').height();
                    if (sH > bH) {
                        jQuery('.sidebar').height(sH);
                    } else {
                        jQuery('.sidebar').css('height', '');
                    }

                }, 0);
            }
            function getDisplayShortcutData(data) {
                return data;
            }
            function getDisplayFavorData(data) {
                var menuMap = {};
                var menuUrlMap = {};
                data.forEach(function(item) {
                   if (!angular.isDefined(item.active)) {
                       item.folded = true;
                   }
                    if (!angular.isDefined(item.childrenStyle)) {
                        item.childrenStyle = {'max-height': '0px'};
                    }
                    if (!angular.isDefined(item.children) || !angular.isDefined(item.children)) {
                        item.children = [];
                    }

                    item.children.forEach(function(citem) {
                        if (!angular.isDefined(citem.getUrl)) {
                            citem.getUrl = function() {
                            	if (citem.menuUrl.indexOf("?") >= 0) {
                            		return citem.menuUrl + '&menuIds=' + citem.parentId + splitor + citem.menuId;
                            	}
                            	return citem.menuUrl + '?menuIds=' + citem.parentId + splitor + citem.menuId;
                            };
                        }

                        menuMap[citem.parentId + splitor + citem.menuId] = citem;
                        menuUrlMap[citem.menuUrl] = citem;
                    });

                    menuMap[item.menuId] = item;
                    menuUrlMap[item.menuUrl] = item;
                });

                favorMenuMap = menuMap;
                favorMenuUrlMap = menuUrlMap;

                scope.selectedItems = initSelectItems();
                return data;
            }

            $timeout(function() {
                jQuery(window).resize(function() {
                    var h = jQuery(this).height() - 74;
                    jQuery('.topbar-product-category-box').css('max-height', h + 'px');
                });

                jQuery('.pull-left.topbar-product.product-list-box').keyup(function(e) {
                    var key = e.which;
                    if (key === 27) {
                        var $dropBox = jQuery(this);
                        if ($dropBox.hasClass('open')) {
                            toggleMenu();
                        }
                    }
                });

            }, 0);
        }
    }

    angular.module('ngMenu').directive('scConfigMenu', scConfigMenu);
    scConfigMenu.$inject = ['$timeout', '$http', '$state', '$localStorage', '$sce', '$uibModal'];
    function scConfigMenu($timeout, $http, $state, $localStorage, $sce, $uibModal) {
            return {
                restrict: 'EA',
                priority: 1009,
                templateUrl: 'templates/imenu/configMenu.html',
                scope: {
                    data: '=?data',
                    title: '=?title',
                    id: '=?menuId',
                    children: '=?children',
                    pid: '=?pid',
                    clear: '=?clear',
                    onSelection: '=?onSelection',
                    deleteSelection: '=?deleteSelection'
                },
                link: linkFn
            };
            function linkFn(scope, element, attrs, ctrl) {
                if (!angular.isDefined(scope.title)) {
                    scope.title = 'title';
                }
                if (!angular.isDefined(scope.id)) {
                    scope.id = 'id';
                }
                if (!angular.isDefined(scope.children)) {
                    scope.children = 'children';
                }
                if (!angular.isDefined(scope.pid)) {
                    scope.pid = 'pid';
                }

                scope.ifData = function () {
                    if (angular.isDefined(scope.data) && angular.isArray(scope.data) && scope.data.length > 0) {
                        return true;
                    } else {
                        return false;
                    }
                };

                scope.ifMenu = function (menu) {
                    if (angular.isDefined(menu[scope.children]) && angular.isArray(menu[scope.children]) && menu[scope.children].length > 0) {
                        return true;
                    } else {
                        return false;
                    }
                };

                scope.deleteMenu = function (menu, menuChild) {

                    scope.modalInstance = $uibModal.open({
                        templateUrl: 'ng-menu/menu.html',
                        controller: deleteMenuModle,
                        controllerAs: '$ctrl',
                        resolve: {
                            item: {
                                menuP: menu,
                                menuC: menuChild
                            }
                        }
                    });

                    scope.modalInstance.result.then(function (item) {
                        if (!angular.isDefined(menuChild)) {
                            var len = scope.data.length;
                            for (var i = 0; i < len; i++) {
                                if (scope.data[i] == menu) {
                                    var ul = $("#" + menu[scope.id]);
                                    if (ul.hasClass('active')) {
                                        ul.removeClass("active");
                                    }
                                    if (ul.siblings('a').hasClass('active')) {
                                        ul.siblings('a').removeClass("active");
                                    }
                                    scope.data.splice(i, 1);
                                    if (angular.isDefined(scope.deleteSelection) && angular.isFunction(scope.deleteSelection)) {
                                        scope.deleteSelection(menu);
                                    }
                                    return;
                                }
                            }
                        } else {
                            for (var i = 0; i < menu[scope.children].length; i++) {
                                if (menu[scope.children][i] === menuChild) {
                                    menu[scope.children].splice(i, 1);
                                    if (angular.isDefined(scope.deleteSelection) && angular.isFunction(scope.deleteSelection)) {
                                        scope.deleteSelection(menuChild);
                                    }
                                    return;
                                }
                            }
                        }
                    });
                };

                deleteMenuModle.$inject = ['$uibModalInstance', 'item'];
                function deleteMenuModle($uibModalInstance, item) {
                    var $ctrl = this;
                    // $ctrl.item = item;
                    $ctrl.ok = ok;
                    $ctrl.cancel = cancel;
                    function ok() {
                        $uibModalInstance.close();
                    }

                    function cancel() {
                        $uibModalInstance.dismiss('cancel');
                    }
                }

                scope.dropMenu = function (menu) {
                    if (scope.clear) {
                        scope.clear = false;
                    }
                    if (angular.isDefined(scope.onSelection) && angular.isFunction(scope.onSelection)) {
                        scope.onSelection(menu);
                    }
                    var a = $("#config" + menu[scope.id]);
                    var ul = a.siblings('ul');
                    if (!a.hasClass('active')) {
                        a.addClass('active').parent().siblings('li').children('a.active').removeClass('active');
                        if (angular.isDefined(ul) && (!ul.hasClass('active'))) {
                            ul.addClass('active');
                            ul.slideDown();
                            a.children('b').addClass('active');
                        }
                    } else {
                        if (angular.isDefined(ul)) {
                            ul.toggleClass('active');
                            ul.slideToggle();
                            a.children('b').toggleClass('active');
                        }
                    }
                };

                scope.$watch('clear', function (newVal, oldVal) {
                    if (newVal) {
                        $(".configMenu").find('.active').removeClass('active');
                    }
                });

            }
        }

    angular.module('ngMenu').run(['$templateCache', function ($templateCache) {
            $templateCache.put('ng-menu/menu.html',
                '<div class="modal-header">' +
                '<h3 class="modal-title">确认操作</h3>' +
                '</div>' +
                '<div class="modal-body">' +
                '<p>确认删除吗？' +
                '</p>' +
                '</div>' +
                '<div class="modal-footer">' +
                '<button class="btn btn-primary" type="button" ng-click="$ctrl.ok()">OK<tton>' +
                '<button class="btn btn-warning" type="button" ng-click="$ctrl.cancel()">Cancel</button>' +
                '</div>'
            );
        }]);

}));

(function(angular, factory) {
    'use strict';
    if (typeof define === 'function' && define.amd) {
        define(['angular'], function(angular) {
            return factory(angular);
        });
    } else {
        return factory(angular);
    }
}(window.angular || null, function(angular) {
    'use strict';

    var app = angular.module("scModal", []);

    app.factory('scModal', scModal);

    scModal.$inject = ['$uibModal', '$timeout', '$templateCache'];
    
    function scModal($uibModal, $timeout, $templateCache) {
        var scModal = {
            open: open,
            submit: submit,
            cancel: cancel
        };

        return scModal;

        function cancel(callback) {
            if (scModal.modalInstance) {
                scModal.modalInstance.dismiss('cancel');

                if (callback) {
                    $timeout(function() {
                        callback(item);
                    });
                }
            }
        }

        function submit(callback) {
            if (scModal.modalInstance) {
                scModal.modalInstance.close(item);

                if (callback) {
                    $timeout(function() {
                        callback(item);
                    });
                }
            }
        }

        function open(templateId, model, ok, cancel) {
            var content=$templateCache.get(templateId);
            if (content) {
                scModal.modalInstance = $uibModal.open({
                    template: content,
                    controller: ctrl,
                    controllerAs: '$ctrl',
                    resolve: {
                        item:function(){
                            return model;
                        }
                    }
                });
            }

            ctrl.$inject = ['$uibModalInstance', 'item'];
            function ctrl($uibModalInstance,item) {
                var $ctrl = this;
                $ctrl.item = item;
                if (ok) {
                    $ctrl.ok = ok;
                } else {
                    $ctrl.ok = defaultButtonAction;
                }

                if (cancel) {
                    $ctrl.cancel = cancel;
                } else {
                    $ctrl.cancel = defaultButtonAction;
                }

                function defaultButtonAction() {
                    if (scModal.modalInstance) {
                        scModal.modalInstance.dismiss('cancel');
                    }
                }
            }
        }
    }

    angular.module('scModal').directive('scConfirmModal',scConfirmModal);
    scConfirmModal.$inject=['$uibModal', '$compile','$timeout', '$interpolate'];
    function scConfirmModal($uibModal, $compile,$timeout, $interpolate){
        return{
            restrict:'EA',
            priority: 590,
            scope:{
                scOk:'&?scOk',
                scBeforeClose:'&?scBeforeClose',
                scCancel:'&?scCancel',
                scGetModel:'&?scGetModel',
                scMsg: '=?scMsg',
                scModel: '=?scModel',
                scConfirmFn:'&?scConfirmFn'
            },
            link:linkFn
        };
        function linkFn(scope,element,attrs,ctrl){
            scope.$model = scope.scModel;
            scope.msg = '确认当前操作吗？';
            if (scope.scMsg && scope.scModel) {
                scope.msg = $interpolate(scope.scMsg)(scope);
            } else if (scope.scMsg) {
                scope.msg = scope.scMsg;
            }

            scope.scOpen = function(){
                scope.modalInstance = $uibModal.open({
                    templateUrl: 'templates/scModal/confirmModal.html',
                    controller: scConfirmModal,
                    controllerAs: '$ctrl',
                    resolve: {
                        item:function() {
                            if (scope.scGetModel && angular.isFunction(scope.scGetModel)) {
                                scope.$model = scope.scGetModel();

                                if (scope.scMsg) {
                                    scope.msg = $interpolate(scope.scMsg)(scope);
                                }
                            }

                            return {
                                msg: scope.msg
                            };
                        }
                    }
                });

                scope.modalInstance.result.then(function(item) {
                    scope.scOk({$model: item});
                });
            };

            $(element).on('click',function(){
                if(angular.isFunction(scope.scConfirmFn)){
                    if(scope.scConfirmFn()){
                        scope.scOpen();
                    }
                }else{
                    scope.scOpen();
                }
            });

            scConfirmModal.$inject = ['$uibModalInstance', 'item'];
            function scConfirmModal($uibModalInstance,item) {
                var $ctrl = this;
                $ctrl.item = item;
                $ctrl.ok = ok;
                $ctrl.cancel = cancel;
                function ok() {
                    if(angular.isFunction(scope.scBeforeClose)){
                        var isClose = scope.scBeforeClose({$model: item});
                        if(isClose){
                            if (angular.isFunction(isClose.then)) {
                                isClose.then(function() {
                                    $uibModalInstance.close(item);
                                });
                            } else {
                                $uibModalInstance.close(item);
                            }
                        }
                    }else{
                        $uibModalInstance.close(item);
                    }
                }

                function cancel() {
                    $uibModalInstance.dismiss('cancel');
                    if(angular.isFunction(scope.scCancel)){
                        scope.scCancel(item);
                    }
                }
            }
        }
    }

    angular.module('scModal').directive('scDefineModal',scDefineModal);
    scDefineModal.$inject=['$uibModal', '$compile','$templateCache','$timeout'];
    function scDefineModal($uibModal, $compile,$templateCache,$timeout){
        return{
            restrict:'A',
            priority: 1009,
            scope:{
                scOk:'&?scOk',
                scBeforeClose:'&?scBeforeClose',
                scCancel:'&?scCancel',
                scModel:'=?scModel',
                scGetModel:'&?scGetModel',
                scValidate:'&?scValidate',
                scContent:'=?scContent',
                scTitle:'=?scTitle',
                scConfirmFn:'&?scConfirmFn'
            },
            link:linkFn
        };
        function linkFn(scope,element,attrs,ctrl){
            var content=$templateCache.get('templates/scModal/defineModal.html');

            if(scope.scModel) {
                scope.$model = scope.scModel;
            } else {
                scope.$model = {};
            }

            var backdrop = true;
            if (attrs.backdrop) {
                backdrop = attrs.backdrop;
            }

            if(scope.scTitle){
                content = content.replace(/scTitle/,scope.scTitle);
            }else{
                content = content.replace(/scTitle/,'');
            }

            if(scope.scContent){
                scope.scBodyContent = $('#'+scope.scContent.replace(/\./, '\\.')).html();
                content = content.replace(/scBodyContent/,scope.scBodyContent);
            }else{
                content = content.replace(/scBodyContent/,'');
            }

            var size = "lg";
            if(attrs.scModalSize) {
                size = attrs.scModalSize;
            }
            scope.scOpen = function(){
                if(scope.scModel) {
                    scope.$model = scope.scModel;
                } else {
                    scope.$model = {};
                }

                scope.modalInstance = $uibModal.open({
                    template: content,
                    controller: scDefineModal,
                    controllerAs: '$ctrl',
                    size: size,
                    backdrop: backdrop,
                    resolve: {
                        item:function(){
                            if (scope.scGetModel && angular.isFunction(scope.scGetModel)) {
                                scope.$model = scope.scGetModel();
                            }
                            return scope.$model;
                        },
                        validate: function() {
                            return function (item) {
                                if (angular.isFunction(scope.scValidate)) {
                                    return scope.scValidate({$model: item});
                                }
                                return [];
                            };
                        }
                    }
                });
                scope.modalInstance.result.then(function(item) {
                    if (angular.isFunction(scope.scOk)) {
                        scope.scOk({$model: item});
                    }
                });
            };

            $(element).on('click',function(){
                if(angular.isFunction(scope.scConfirmFn)){
                    if(scope.scConfirmFn()){
                        scope.scOpen();
                    }
                }else{
                    scope.scOpen();
                }
            });

            scDefineModal.$inject = ['$uibModalInstance', 'item', 'validate'];
            function scDefineModal($uibModalInstance,item,validate) {
                var $ctrl = this;
                $ctrl.item = item;
                $ctrl.ok = ok;
                $ctrl.cancel = cancel;
                $ctrl.keydown = keydown;

                /**
                 * keydown事件处理
                 * @param [必须]event keydown事件
                 * @param w [必须]事件触发键码 (event.which)
                 * @param target [可选]下一聚焦元素(jquery seletor)
                 */
                function keydown(event, w, target) {
                    if (event && event.which === w) {
                        var $modal = jQuery(event.currentTarget).closest('div.modal-dialog');

                        if ($modal.length > 0 && target && angular.isString(target)) {
                            var $target = jQuery($modal.find(target));
                            if ($target.length === 1) {
                                $timeout(function() {
                                    if ($target.is('button') || $target.is('a')) {
                                        $target.click();
                                    } else {
                                        $target.focus();
                                    }
                                });
                            }
                        }

                        event.preventDefault();
                    }
                }

                function ok() {
                    if ($ctrl.form) {
                        if (!$ctrl.form.$valid) return;

                        var errors = validate($ctrl.item);
                        if (errors.length > 0) return;
                    }
                    if(angular.isFunction(scope.scBeforeClose)){
                        var isClose = scope.scBeforeClose({$model: $ctrl.item});
                        if(isClose){
                            if (angular.isFunction(isClose.then)) {
                                isClose.then(function() {
                                    $uibModalInstance.close(item);
                                });
                            } else {
                                $uibModalInstance.close(item);
                            }
                        }
                    }else{
                        $uibModalInstance.close(item);
                    }
                }

                function cancel() {
                    $uibModalInstance.dismiss('cancel');
                    if(angular.isFunction(scope.scCancel)){
                        scope.scCancel(item);
                    }
                }
            }
        }
    }
}));
(function () {
    'use strict';

    angular.module('iwhStrategy', []);
})();
(function () {
    'use strict';

    // 修改时使用lotAttrs.tpl.html文件后压缩替换
    var tpl = '<div ng-class="class"><div class="lot-item" ng-style="itemStyle" ng-repeat="attr in attrs track by $index" ng-if="attr.display"><div ng-class="{\'form-group\': inline}" style="margin-top: 0; margin-bottom: 0;"><label class="control-label" ng-class="{\'col-sm-4\': inline, \'requireStar\': attr.required && ngModel.bizType === \'RECEIPT\'}" ng-style="{\'text-align\':inline ? \'right\' : \'left\'}">{{attr.displayName}}</label><div ng-class="{\'col-sm-8\': inline}"><input id="{{attr.code}}" ng-if="attr.componentType===\'INPUT\' || (attr.componentType===\'SELECT\' && !attr.options)" type="text" class="form-control" ng-model="ngModel[attr.code]" ng-keypress="keyPress($event)" maxlength="attr.maxLength || 20" ng-required="attr.required"><select id="{{attr.code}}" ng-if="attr.componentType===\'SELECT\' && attr.options" class="form-control" ng-model="ngModel[attr.code]" ng-required="attr.required" ng-keypress="keyPress($event)" ng-options="o.value as o.label for o in attr.options"></select><input id="{{attr.code}}" ng-if="attr.componentType===\'DATE_PICKER\'" type="text" class="form-control" ng-model="ngModel[attr.code]" ng-keypress="keyPress($event)" ng-required="attr.required"> </div></div></div><div ng-if="error">请求批次属性错误</div><div ng-if="!ngModel || !attrs || attrs.length === 0">没有批次属性</div></div>';

    angular.module('iwhStrategy')
        .directive('scLotAttrs', ['$http', 'iServiceConfig', function ($http, cfg) {
            var api = {
                component: cfg.iwhStrategyHost + '/web/lot-attr/component',
                valid: cfg.iwhStrategyHost + '/web/lot-attr/valid'
            };

            return {
                restrict: 'E',
                replace: true,
                scope: {
                    ngModel: '=',
                    class: '@',
                    inline: '=',
                    validHandle: '='
                },
                template: tpl,
                link: function (s, el, atts) {
                    var column = 4;
                    if (atts.scColumn && !isNaN(atts.scColumn)) {
                        column = parseInt(atts.scColumn);
                    }
                    if (column < 1 || column > 12) {
                        column = 4;
                    }
                    s.itemStyle = {
                        display: 'inline-block',
                        width: Math.floor(100 / column) + '%'
                    };

                    if (s.ngModel && !s.ngModel.skuCode) {
                        initData({
                            status: 'SUCCESS',
                            content: s.ngModel
                        });
                    }

                    s.$watch('ngModel', function (n, o) {
                        if (n && n.skuCode) {
                                if (s.ngModel && s.ngModel.skuCode) {
                                    s.error = false;
                                    $http({
                                    url: api.component,
                                        method: 'POST',
                                        data: s.ngModel,
                                        cache: false,
                                        headers: {
                                            'I-DataSource-TenantId': s.ngModel.tenantId,
                                            'I-DataSource-WarehouseCode': s.ngModel.warehouseCode
                                        }
                                    }).success(function (data, status) {
                                        initData(data);
                                    }).error(function (data) {
                                        s.error = true;
                                    });
                                }
                        } else {
                            s.attrs = null;
                            s.error = false;
                        }
                    });

                    function initData(data) {
                        if (data.status === 'SUCCESS') {
                            s.attrs = [];
                            s.lotAttrs = data.content;
                            for (var i = 0; i < 12; i++) {
                                var t = i + 1;
                                var attr = 'lotAttribute' + (t < 10 ? '0' + t : t);
                                var attrName = attr + 'Name';
                                if (data.content[attr]) {
                                    s.ngModel[attr] = data.content[attr].value;
                                    s.ngModel[attrName] = data.content[attr].displayName;
                                    s.attrs.push(data.content[attr]);
                                } else {
                                    delete s.ngModel[attr];
                                    delete s.ngModel[attrName];
                                }
                            }
                        }
                    }

                    s.keyPress = function (e) {
                        if ((window.event ? e.keyCode : e.which) === 13) {
                            e.preventDefault();
                            e.stopPropagation();
                            if (!this.$last) {
                                var nextInput = $(el).find('input,select').eq(this.$index + 1);
                                if (nextInput.length > 0) {
                                    nextInput[0].focus();
                                    if (nextInput.is('select')) {
                                        nextInput.click();
                                    } else {
                                        nextInput[0].select();
                                    }
                                }
                            } else {
                                if (api.valid) {
                                    $http({
                                        url: api.valid,
                                        method: 'POST',
                                        data: s.ngModel,
                                        cache: false,
                                        headers: {
                                            'I-DataSource-TenantId': s.ngModel.tenantId,
                                            'I-DataSource-WarehouseCode': s.ngModel.warehouseCode
                                        }
                                    }).success(function(data){
                                        if(s.validHandle && typeof s.validHandle === 'function') {
                                            s.validHandle(data);
                                        }
                                    }).error(function (data) {
                                        if(s.validHandle && typeof s.validHandle === 'function') {
                                            s.validHandle(data);
                                        }
                                        var errors = data.content.errors;
                                        if (Array.isArray(errors) && errors.length > 0) {
                                            $(el).find('#' + errors[0].field).focus();
                                        }
                                    });
                                }
                            }
                        }
                    };
                }
            };
        }]);
})();
(function(angular, factory) {
    'use strict';
    if (typeof define === 'function' && define.amd) {
        define(['angular'], function(angular) {
            return factory(angular);
        });
    } else {
        return factory(angular);
    }
}(window.angular || null, function(angular) {
    'use strict';

    angular.module("ngCron", []);

    angular.module('ngCron').directive('scCronSelector',scCronSelector);
    scCronSelector.$inject = ['$localStorage','$sce','$http','$httpParamSerializer','$timeout'];
    function scCronSelector($localStorage,$sce,$http,$httpParamSerializer,$timeout){
        return{
            restrict:'EA',
            priority: 1009,
            templateUrl:'templates/cron/cron.html',
            scope:{
                scValue:'=scValue',
                scOnlyRead:'=?scOnlyRead'
            },
            link:{
                pre:linkFn
            }
        };
        function linkFn(scope,elem,attr,ctrl){
            scope.valueList = [{},{},{},{},{},{},{}];//间隔时间值集合
            scope.list = [[],[],[],[],[],[],[]];//自选数组集合
            scope.wayList = [0,0,0,0,0,0,0];//执行方式集合

            //星期数组
            scope.scWeek = ['Mon','Tue','Wed','Thu','Fri','Sat','Sun'];
            scope.scWeekDay = [{title:'星期一',id:1},{title:'星期二',id:2},{title:'星期三',id:3},{title:'星期四',id:4},{title:'星期五',id:5},{title:'星期六',id:6},{title:'星期天',id:7}];
            scope.timeCollection =[
                {title:'分',id:1,name:'分钟',max:59,min:0,noChoose:false},
                {title:'小时',id:2,name:'小时',max:23,min:0,noChoose:false},
                {title:'天数',id:3,name:'天',max:31,min:1,noChoose:false},
                {title:'月份',id:4,name:'月',max:12,min:1,noChoose:false},
                {title:'星期',id:5,name:'天',max:7,min:1,noChoose:false},
                // {title:'年',id:6,name:'年',max:2036,min:2017}
            ];
            scope.thisYear = (new Date()).getFullYear();//当前年
            scope.isLeapYear =  (scope.thisYear % 4 == 0) && (scope.thisYear % 100 != 0 || scope.thisYear % 400 == 0);


            //循环从最小到最大获取数组
            scope.getArr = function(min,max,id){
                var a = [];
                min = parseInt(min);
                max = parseInt(max);
                if(!angular.isNumber(min) || !angular.isNumber(max) || scope.timeCollection[id-1]['max']<max){
                    return false;
                }
                for(var i=min;i<=max;i++){
                    a.push(i);
                }
                return a;
            };

            //反解析
            scope.analysisStr = function(list){
                scope.valueList = [{},{},{},{},{},{},{}];
                scope.list = [[],[],[],[],[],[],[]];
                for(var i=0;i<list.length;i++){

                    //首先判断是否当前值是否为*
                    if(list[i]=="*"){
                        scope.list[i][0] = angular.copy(list[i]);
                        continue;
                    }

                    var arrValue= {};//间隔值对象
                    var arrList = [];//自选数组
                    var lis = list[i].split(',');
                    for(var j=0;j<lis.length;j++){
                        //如果有’-‘符号
                        var n,ft,it;
                        if(lis[j].indexOf('-')!==-1){
                            var s = lis[j].split('-');
                            var idx1 = s[0];
                            var idx2 = s[1];
                            if(i==5 && angular.isString(idx1) && angular.isString(idx2)){
                                idx1 = scope.scWeek.indexOf(s[0])+1;
                                idx2 = scope.scWeek.indexOf(s[1])+1;
                                //如果其中一个为0  说明在scWeek中查找为-1  说明被解析的（星期）字符串格式错误  将该arrList置空跳出循环
                                if(idx1==0||idx2==0){
                                    arrList = [];
                                    break;
                                }
                            }
                            var a = scope.getArr(idx1,idx2,i);
                            if(!a){
                                arrList = [];
                                break;
                            }
                            arrList = arrList.concat(a);
                        }
                        //如果有’/‘符号
                        if(lis[j].indexOf('/')!==-1){
                            var v1= lis[j].split('/')[0];
                            var v2= lis[j].split('/')[1];
                            ft = parseInt(v1);
                            it = parseInt(v2);
                            if(!isNaN(ft)){
                                //解析的数字应在范围内
                                if(i>=1 && ft!==0 && ft>=scope.timeCollection[i-1]['min'] &&  ft<=scope.timeCollection[i-1]['max'] ){
                                    arrValue['0'] = ft;
                                }
                            }else{
                                arrValue = {};
                                break;
                            }
                            if(!isNaN(it)){
                                //解析的数字应在范围内
                                if(i>=1 && it!==0 && it>=scope.timeCollection[i-1]['min'] &&  it<=scope.timeCollection[i-1]['max'] ){
                                    arrValue['1'] = it;
                                }
                            }else{
                                arrValue = {};
                                break;
                            }
                            scope.wayList[i] = 1;
                        }
                        //如果既没有/ 也没有-
                        if(lis[j].indexOf('-') ===-1 && lis[j].indexOf('/') ===-1){
                            n = parseInt(lis[j]);
                            if(!isNaN(n)){
                                //解析的数字应在范围内
                                if(i>=1 && n>=scope.timeCollection[i-1]['min'] &&  n<=scope.timeCollection[i-1]['max'] ){
                                    arrList.push(n);
                                }
                            }else if(i==5){
                                //可能是星期  字符串形式
                                var ind = scope.scWeek.indexOf(lis[j]);
                                //如果其中一个为0  说明在scWeek中查找为-1  说明被解析的（星期）字符串格式错误  将该arrList置空跳出循环
                                if(ind==-1){
                                    arrList = [];
                                    break;
                                }
                                arrList.push(ind+1);
                            }
                        }
                    }
                    scope.valueList[i] = arrValue;
                    scope.list[i] = arrList;
                }
            };

            //初始化判断是否有绑定value值
            if(scope.scValue){
                scope.scValueList = scope.scValue.split(' ');
                scope.analysisStr(scope.scValueList);
            }

            //tab页初始化选中第一个
            scope.timeCollection =[
                {title:'分',id:1,name:'分钟',max:59,min:0},
                {title:'小时',id:2,name:'小时',max:23,min:0},
                {title:'天数',id:3,name:'天',max:31,min:1},
                {title:'月份',id:4,name:'月',max:12,min:1},
                {title:'星期',id:5,name:'天',max:7,min:1},
                // {title:'年',id:6,name:'年',max:2036,min:2017}
            ];
            scope.cursel = null;//控制点击tab时，切换对应页面，初始为1
            scope.intervalTitle = null;

            //初始化间隔输入框的最大值和最小值，最小值不能为0
            // scope.maxNum = scope.timeCollection[scope.cursel-1]['id']==6?(scope.timeCollection[scope.cursel-1]['max']-2016):scope.timeCollection[scope.cursel-1]['max'];
            // scope.minNum = scope.timeCollection[scope.cursel-1]['id']==6?(scope.timeCollection[scope.cursel-1]['min']-2016):(scope.timeCollection[scope.cursel-1]['min']==0?1:scope.timeCollection[scope.cursel-1]['min']);
            scope.maxNum =null;
            scope.minNum =null;

            scope.allWays = [
                {title:'自选',id:1,selected:false},{title:'间隔',id:2,selected:false},{title:'最后一天',id:3,selected:false}
            ];//所有可选择的方式
            scope.wayMany = null;//控制allWays展示几个,选中天数时有3个，下标为2
            scope.selectedWay = null;
            scope.selectedId = null;//控制下拉选择或者自填，若为1，下拉选择，若为2，手动填写

            scope.intervalTime = null;//用户选择 间隔后自己手动输入的值
            scope.fromTime = null;//间隔选择的起始时间
            scope.allTime = [];//存储各种时间可以选择的数组,若选择天数，为1-31，选择月份，为1-12
            scope.timeSelected = [];//存储当前已经选中的时间

            //将其它方式对象中的selected属性置为false
            scope.setFalse = function(ways,ind,lastId){
                //ind是当前选中的方式的在ways数组中的index
                //lastId是上次选中的方式在ways数组中的index
                if(ind !==lastId){
                    for(var i=0;i<ways.length;i++){
                        if(i==ind){
                            if(ind == 2){
                                //如果选择第三种方式（最后一天），重置scope.allTime  timeSelected   showList   然后做相似点击最后一天的操作
                                scope.list[scope.cursel] = [];
                                scope.getAllTime(scope.timeCollection[scope.cursel-1]['min'],scope.timeCollection[scope.cursel-1]['max']);
                                scope.chooseTime(scope.allTime[scope.allTime.length-2]);
                            }
                            if(lastId == 2){
                                //如果之前点击为最后一天   重置各种数组scope.allTime  timeSelected   showList
                                scope.list[scope.cursel] = [];
                                scope.getAllTime(scope.timeCollection[scope.cursel-1]['min'],scope.timeCollection[scope.cursel-1]['max']);
                            }
                            ways[i]['selected'] = true;
                        }else{
                            ways[i]['selected'] = false;
                        }
                    }
                }
            };
            // scope.setFalse(scope.allWays,0);//初始默认第一种

            //绑定input change
            scope.fromTimeChange = function(val){

                if(val ===null || angular.isNumber(val)){
                    scope.valueList[scope.cursel]['0'] = val?parseInt(val):val;
                    if(scope.list[scope.cursel].length>0){
                        scope.list[scope.cursel] = [];//如果有值输入，将重置scope.allTime  timeSelected   showList
                        scope.getAllTime(scope.timeCollection[scope.cursel-1]['min'],scope.timeCollection[scope.cursel-1]['max']);
                    }
                    scope.wayList[scope.cursel] = 1;//如果有值，将当前时间选择方式改为1---间隔
                }
            };

            scope.intervalTimeChange = function(val){
                if(val ===null || angular.isNumber(val)){
                    scope.timeCollection[scope.cursel-1]['noChoose']  = false;
                    scope.valueList[scope.cursel]['1'] = val?parseInt(val):val;
                    if(scope.list[scope.cursel].length>0){
                        scope.list[scope.cursel] = [];//如果有值输入，将重置scope.allTime  timeSelected   showList
                        scope.getAllTime(scope.timeCollection[scope.cursel-1]['min'],scope.timeCollection[scope.cursel-1]['max']);
                    }
                    scope.wayList[scope.cursel] = 1;//如果有值，将当前时间选择方式改为1---间隔
                }
            };

            //合并连续数字
            scope.combineNums = function(arr){
                var result = [];
                var tmp;
                for(var i=0; i <arr.length;i++){
                    tmp = arr[i];
                    if(result.length == 0){
                        result.push([tmp]);
                        continue;
                    }
                    var e = result[result.length - 1];
                    if(tmp == e[e.length - 1] + 1){
                        e.push(tmp);
                    }else{
                        result.push([tmp]);
                    }
                }
                return result;
            };

            //排序
            scope.sortNum = function(num,isObj){
                var tmp,i,j;
                if(isObj){
                    for(i=0;i<num.length;i++){
                        for(j=i;j<num.length;j++){
                            if(num[i]['num']>num[j]['num']){
                                tmp = num[i];
                                num[i] = num[j];
                                num[j] = tmp;
                            }
                        }
                    }
                }else{
                    for(i=0;i<num.length;i++){
                        for(j=i;j<num.length;j++){
                            if(num[i]>num[j]){
                                tmp = num[i];
                                num[i] = num[j];
                                num[j] = tmp;
                            }
                        }
                    }
                }
                return num;
            };

            //获取可展示的数组
            scope.getShowList = function(list,isSort){
                var arr= list;
                //如果只有一个或者没有 直接return
                if(arr.length<=1){
                    return arr;
                }
                if(isSort){
                    arr = scope.sortNum(list);//先排序
                }
                var aa = scope.combineNums(angular.copy(arr));//后合并连续数字，返回数组,，此处传参应克隆一个数组，因为里面shift会删除数组，改变原数组
                //生成展示的数组
                var sl = [];
                for(var i=0;i<aa.length;i++){
                    if(aa[i].length>=3){
                        sl.push(aa[i][0]+'-'+aa[i][aa[i].length-1]);
                    }else{
                        for(var j=0;j<aa[i].length;j++){
                            sl.push(aa[i][j]);
                        }
                    }
                }
                return sl;
            };

            //获取当前需要展示的时间选择列表并且获取timeSelected，主要针对已有选择过的某些时间数组，做默认展示
            scope.getAllTime =function(min,max){
                scope.allTime = [];
                scope.timeSelected = [];

                if(scope.cursel == 3){
                    var lis = scope.list[scope.cursel+1];//获取自选月份
                    //判断是否只选择了一个时间月份  如果是  天数可以有最后一天  并且最后一天由月份决定
                    if(lis.length === 1 && lis[0]!=='*'){
                        scope.wayMany = 2;//如果当前已自选月份并且只选择了一个
                        //    如果是2月份  判断是否为闰年
                        if(lis[0] == 2){
                            max = scope.isLeapYear?28:29;
                        }
                        //    如果是 4 6 9 11  最大为30
                        if(lis[0] == 4 || lis[0] == 6 || lis[0] == 9 || lis[0] == 11){
                            max = 30;
                        }
                    }else{
                        scope.wayMany = 1;
                        //如果之前天选择的方式为最后一天  那么现在将方式置为自选  并清空自选数组
                        if(scope.wayList[scope.cursel] == 2){
                            scope.wayList[scope.cursel] = 0;
                            scope.list[scope.cursel] = [];
                            // scope.selectedWay = scope.allWays[scope.wayList[0]]['title'];
                            // scope.selectedId = scope.allWays[scope.wayList[0]]['id'];
                        }
                    }
                }

                var list = scope.list[scope.cursel];
                scope.showList = scope.getShowList(list,true);
                for(var i=min;i<=max;i++){
                    var obj = {};
                    obj['num'] = i;
                    obj['selected'] = false;
                    for(var j=0;j<list.length;j++){
                        if(i == list[j]){
                            obj['selected'] = true;
                            scope.timeSelected.push(obj);
                        }
                    }
                    scope.allTime.push(obj);
                }

                //如果list数组中只有*，新建对象selected为true  push到allTime 和 timeSelected
                var o = {
                    num:'*',
                    selected:false
                };
                if(list[0] == '*'){
                    o['selected'] = true;
                    scope.timeSelected.push(o);
                }
                scope.allTime.push(o);
            };

            // scope.len = scope.timeSelected.length;//初始化为0
            //判断已选中的值是否超出容器  做合并收缩处理
            scope.getCombine = function(n){
                var allW = 0;
                var showList = scope.showList;
                if(scope.isCombine){
                    if(n<=1){
                        scope.isCombine = false;
                        elem.find('.combineSelected').slideUp();
                    }else{
                        for(var i=0;i<showList.length;i++){
                            //根据字符数计算页面元素宽度，+1是逗号 ，
                            allW+=((showList[i]+'').length+1)*6;
                        }
                        if(allW<scope.divW){
                            scope.isCombine = false;
                            elem.find('.combineSelected').children('ul').slideUp();
                        }
                    }
                }else{
                    if(n>1){
                        for(var i=0;i<showList.length;i++){
                            //根据字符数计算页面元素宽度，+1是逗号 ，
                            allW+=((showList[i]+'').length+1)*6;
                        }
                        if(allW>scope.divW){
                            scope.isCombine = true;
                        }
                    }
                }
            };

            //点击切换tab
            scope.setTab = function (cron,isClear){
                if(scope.cursel!==cron.id || isClear){
                    scope.cursel = cron.id;
                    scope.intervalTitle = scope.timeCollection[cron.id-1];
                    scope.wayMany = 1;

     //先
                    //重新获取当前应该展示的时间可选列表并且重置timeSelected
                    scope.getAllTime(cron['min'],cron['max']);
    //先

   //后
                    //重置选择方式
                    scope.selectedWay = scope.allWays[scope.wayList[cron.id]]['title'];
                    scope.selectedId = scope.allWays[scope.wayList[cron.id]]['id'];

                    //将所有可选的方法对象的selected属性置为false
                    scope.setFalse(scope.allWays,scope.wayList[cron.id]);
    //后
                    //重置间隔输入框的最大值和最小值
                    /*如果有年份*/
                    // scope.maxNum = cron['id']==6?(cron['max']-2016):cron['max'];
                    // scope.minNum = cron['id']==6?(cron['min']-2016):(cron['min']==0?1:cron['min']);
                    /*如果没有年份*/
                    scope.maxNum = cron['max'];
                    scope.minNum = cron['min']==0?1:cron['min'];

                    //获得当前列表自选数组中的个数
                    scope.len = scope.list[cron.id].length;
                    scope.getCombine(scope.len);
                }
            };
            scope.setTab(scope.timeCollection[0]);//初始化 第一次点击

            //判断是否需要合并所选
            scope.isCombine = false;//初始化时不用合并
            scope.divW = null;

            //选择方式
            scope.changeWay = function(way,event){
                var e = event || window.event;
                e.stopPropagation();
                if(scope.selectedId !==way['id']){
                    scope.setFalse(scope.allWays,way['id']-1,scope.selectedId-1);
                    if(way['id'] == 3){
                        scope.wayList[scope.cursel] = 2;
                    }
                    scope.selectedWay = way['title'];
                    scope.selectedId = way['id'];
                }
                $(e.target).parent().parent('.selectFrame').slideUp();
            };

            //判断是否存在当前选择元素
            scope.isExist = function (t) {
                //自选点击时，将input置为null；
                if(scope.valueList[scope.cursel]['0'] || scope.valueList[scope.cursel]['1']){
                    scope.valueList[scope.cursel]['0'] = null;
                    scope.valueList[scope.cursel]['1'] = null;
                }

                if(t['num'] == '*'){
                    var idx = scope.list[scope.cursel].indexOf('*');
                    scope.list[scope.cursel] = [];
                    scope.timeSelected = [];
                    scope.showList = [];
                    if(idx==-1){
                        scope.list[scope.cursel].push(t['num']);
                        scope.timeSelected.push(t);
                        scope.showList.push(t['num']);
                        for(var i=0;i<scope.allTime.length;i++){
                            if(scope.allTime[i]['selected']){
                                scope.allTime[i]['selected'] = false;
                            }
                        }
                    }
                    t['selected'] = !t['selected'];
                    return;
                }
                if(t['num'] !='*' && scope.list[scope.cursel].indexOf('*')>-1){
                    scope.list[scope.cursel] = [];
                    scope.timeSelected = [];
                    scope.showList = [];
                    scope.allTime[scope.allTime.length-1]['selected'] = false;
                }

                // var index = scope.timeSelected.indexOf(t);
                var ind = scope.list[scope.cursel].indexOf(t['num']);

                if(ind==-1){
                    scope.timeSelected.push(t);
                    scope.list[scope.cursel].push(t['num']);
                    t['selected'] = true;
                }else{
                    scope.timeSelected.splice(ind,1);
                    scope.list[scope.cursel].splice(ind,1);
                    t['selected'] = false;
                }
                scope.showList = scope.getShowList(scope.list[scope.cursel],true);
                scope.sortNum(scope.timeSelected,true);
            };

            //多选时间
            scope.chooseTime = function(t,event){
                var e =event || window.event;
                e.stopPropagation();
                scope.isExist(t);
                scope.timeCollection[scope.cursel-1]['noChoose']  = false;//将当前间隔时间为选择完整所留下的提示信息去掉
                //判断event不为undefined  是因为执行方式选到最后一天时，会在组件内部调用一次chooseTime，但是这一次不应该改变执行方式   内部调用没有event事件
                // 故通过判断事件  是否为undefined   来区别当前改变选择时间  是通过外部点击 还是内部调用
                if(event!==undefined){
                    scope.wayList[scope.cursel] = 0;//如果有值，将当前时间选择方式改为0---自选
                }

                //判断当前是选择月份  并且只选择了一个  再判断天数是否选择的最后一天  并改变最后一天的值
                if(scope.cursel == 4 && scope.list[4].length==1 && scope.wayList[3] == 2){
                    if(scope.list[4][0] != '*'){
                        var max = 31;
                        if(scope.list[4][0] == 2){
                            max = scope.isLeapYear?28:29;
                        }
                        if(scope.list[4][0] == 4 || scope.list[4][0] == 6 || scope.list[4][0] == 9 || scope.list[4][0] == 11){
                            max = 30;
                        }
                        scope.list[3][0] = max;
                    }else{
                        scope.list[3] = [];
                    }
                }

                scope.len = scope.timeSelected.length;
                scope.getCombine(scope.len);
            };

            //删除时间
            scope.deleteElem = function(li){
                scope.isExist(li);
                scope.len = scope.timeSelected.length;
                scope.getCombine(scope.len);
            };

            //判断当前指定的时间单位下是否已经选中过值
            scope.checkHasTime = function(idx){
                //当前idx等于3，说明正在判断如何取   小时  的值，小时的值依赖于上一级  天/星期   如果这两者其中一种有值   则返回为true
                //scope.list[3] scope.valueList[3]为天单位时间值   scope.list[5] scope.valueList[5]为星期单位时间值
                if(idx == 3){
                    //验证 天
                    if(scope.list[3].length>0 && scope.list[3][0]!="*"){
                        return true;
                    }
                    for(var key in scope.valueList[3]){
                        if(scope.valueList[3][key]){
                            return true;
                        }
                    }

                    //验证星期
                    if(scope.list[5].length>0 && scope.list[5][0]!="*"){
                        return true;
                    }
                    for(var key in scope.valueList[5]){
                        if(scope.valueList[5][key]){
                            return true;
                        }
                    }

                    return false;
                }

                if(idx==2 || idx==4){
                    var list = scope.list[idx];
                    var valueList = scope.valueList[idx];
                    if(list.length>0 && list[0]!="*"){
                        return true;
                    }
                    for(var key in valueList){
                        if(valueList[key]){
                            return true;
                        }
                    }
                    return false;
                }else{
                    return false;
                }
            };

            //点击确认后组建字符串表达式
            scope.getValues = function(list,id){
                var len = list.length;
                var h = '';
                //如果选择的是*号
                if(list[0]=='*'){
                    return '*';
                }

                //如果不是*号  但length大于0说明有选择的值
                if(len>0){
                    //等于5表示星期，需要特殊组装
                    if(id==5){
                        for(var j=0;j<len;j++){
                            if(angular.isString(list[j])&&list[j].indexOf('-')){
                                var str = list[j].split('-');
                                h+=scope.scWeek[str[0]-1];
                                h+='-'+scope.scWeek[str[1]-1];
                            }else{
                                h+=scope.scWeek[list[j]-1];
                            }
                            if(list[j+1]){
                                h+=',';
                            }
                        }
                    }else{
                        //根据数组以逗号组装
                        for(var i=0;i<len;i++){
                            h+=list[i];
                            if(list[i+1]){
                                h+=',';
                            }
                        }
                    }
                }else{
                    //既不是*号 也没有选择值
                    //如果上一级无值  则当前为*号  如果上一级有值  则当前选择此时间单位下的最小值
                    var hasValue = scope.checkHasTime(id+1);
                    if(hasValue){
                        h+=scope.timeCollection[id-1]['min'];
                        scope.list[id][0] = scope.timeCollection[id-1]['min'];
                    }else{
                        h+='*';
                        scope.list[id][0] = h;
                    }
                }
                return  h;
            };

            //获取间隔字符串
            scope.getInterval = function(fromTime,intervalTime,id){
                // fromTime = fromTime?fromTime:scope.timeCollection[id]['min'];
                if(!fromTime){
                    scope.valueList[id+1]['0'] = scope.timeCollection[id]['min'];
                    fromTime =  scope.timeCollection[id]['min'];
                }
                return (fromTime+'/'+intervalTime);
            };

            //点击确认后判断是否合法
            scope.isIlegal = false;
            var checkArrNull = function(list,isObj){
                var i;
                if(!isObj){
                    for(i=1;i<list.length;i++){
                        if(list[i].length>0 && list[i][0]!="*"){
                            return false;
                        }
                    }
                    return true;
                }else{
                    for(i=1;i<list.length;i++){
                        for(var key in list[i]){
                            if(list[i][key]){
                                return false;
                            }
                        }
                    }
                    return true;
                }
            };

            //点击确定，获取字符串
            var sec = scope.list[0][0] || Math.floor(Math.random()*60);
            scope.confirmSelected = function(){

                var hasList = checkArrNull(scope.list,false);
                var hasValueList = checkArrNull(scope.valueList,true);
                //如果两者都是空，跳出函数，做提示
                if(hasList && hasValueList){
                    scope.isIlegal = true;
                    scope.clearSelected();
                    return;
                }

                scope.isIlegal = false;//此时未退出函数  则有合法表达式

                //判断是否有手动输入的  以秒为单位的时间
                if(scope.list[0][0]){
                    sec = scope.list[0][0];
                }

                var min,hour,day,mon,week;
                if(scope.valueList[4]['0'] || scope.valueList[4]['1']){
                    if(scope.valueList[4]['1']){
                        mon = scope.getInterval(scope.valueList[4]['0'],scope.valueList[4]['1'],3);
                    }else{
                        scope.timeCollection[3]['noChoose']  = true;
                        scope.setTab(scope.timeCollection[3]);
                        return;
                    }
                }else{
                    mon = scope.getValues(scope.getShowList(scope.list[4]),4);
                }

                if(scope.valueList[5]['0'] || scope.valueList[5]['1']){
                    if(scope.valueList[5]['1']){
                        week = scope.getInterval(scope.valueList[5]['0'],scope.valueList[5]['1'],4);
                    }else{
                        scope.timeCollection[4]['noChoose']  = true;
                        scope.setTab(scope.timeCollection[4]);
                        return;
                    }
                }else{
                    week = scope.getValues(scope.getShowList(scope.list[5]),5);
                }

                if(scope.valueList[3]['0'] || scope.valueList[3]['1']){
                    if(scope.valueList[3]['1']){
                        day = scope.getInterval(scope.valueList[3]['0'],scope.valueList[3]['1'],2);
                    }else{
                        scope.timeCollection[2]['noChoose']  = true;
                        scope.setTab(scope.timeCollection[2]);
                        return;
                    }
                }else{
                    day = scope.getValues(scope.getShowList(scope.list[3]),3);
                }

                if(scope.valueList[2]['0'] || scope.valueList[2]['1']){
                    if(scope.valueList[2]['1']){
                        hour = scope.getInterval(scope.valueList[2]['0'],scope.valueList[2]['1'],1);
                    }else{
                        scope.timeCollection[1]['noChoose']  = true;
                        scope.setTab(scope.timeCollection[1]);
                        return;
                    }
                }else{
                    hour = scope.getValues(scope.getShowList(scope.list[2]),2);
                }

                if(scope.valueList[1]['0'] || scope.valueList[1]['1']){
                    if(scope.valueList[1]['1']){
                        min = scope.getInterval(scope.valueList[1]['0'],scope.valueList[1]['1'],0);
                    }else{
                        //如果只有第一个选了  提示用户需选中完整的间隔时间
                        scope.timeCollection[0]['noChoose']  = true;
                        scope.setTab(scope.timeCollection[0]);
                        return;
                    }
                }else{
                    min = scope.getValues(scope.getShowList(scope.list[1]),1);
                }

                scope.scValue = sec+' '+min+' '+hour+' '+day+' '+mon+' '+week;

                elem.find('.selectFrame').slideUp();
                elem.find('.combineSelected').children('ul').slideUp();
                elem.find('.scCronFrame').slideUp();
            };

            //监听是否手动改变表达式
            scope.changeValue = function(val){
                if(val){
                    scope.scValueList = scope.scValue.split(' ');
                    scope.analysisStr(scope.scValueList);
                }
                if(!val || val.length==0){
                    scope.clearSelected();
                }
            };

            //打开或关闭下拉框
            var isSlider = false;
            var scCron = elem.children('.scCron').children('.input-group').children('input');
            var isOnlyRead = scope.$watch('scOnlyRead',function(newVal,oldVal){
                if(newVal){
                    scCron.attr('disabled','disabled');
                    scope.slideCron = function(e){
                        e.stopPropagation();
                    };
                    elem.find('.selectFrame').slideUp();
                    elem.find('.combineSelected').children('ul').slideUp();
                    elem.find('.scCronFrame').slideUp();
                }else{
                    scCron.removeAttr('disabled');
                    scope.slideCron = function(e){
                        e.stopPropagation();

                        isSlider = !isSlider;//下拉展开时  执行setTab
                        if(isSlider){
                            scope.setTab(scope.timeCollection[scope.cursel-1]);
                        }
                        elem.find('.scCronFrame').slideToggle();

                        if(scope.divW<=0){
                            scope.divW = Math.round(elem.find('.selectCombine').find('.form-control').width());
                            // scope.getCombine(scope.len);
                        }
                    }
                }
            });

            scope.slideFrame = function(e){
                e.stopPropagation();
                if($(e.target)[0].tagName == 'SPAN' || $(e.target)[0].tagName == 'LI' ){
                    elem.find('.selectFrame').css('display','none');
                }else{
                    elem.find('.selectFrame').slideUp();
                    elem.find('.combineSelected').children('ul').slideUp();
                }
            };

            var menudiv  = elem.find('.menudiv');
            menudiv.delegate('.dropUl','click',function(event){
                var e = event || window.event;
                e.stopPropagation();
                $(this).children('.selectFrame').slideToggle();
                $(this).parent('.form-group').siblings().find('.selectFrame').slideUp();
            });

            //控制合并后的ul展示及收起
            scope.combineList = function(event){
                var e = event;
                e.stopPropagation();
                $(e.target).siblings('ul').slideToggle();
            };

            //点击时，隐藏合并框
            scope.closeCombine = function(event){
                var e = event;
                e.stopPropagation();
                elem.find('.combineSelected').children('ul').slideUp();
            };

            //取消选择
            scope.cancelSelected = function(){
                elem.find('.selectFrame').slideUp();
                elem.find('.combineSelected').children('ul').slideUp();
                elem.find('.scCronFrame').slideUp();
            };

            //清除选择
            scope.clearSelected = function(isUp){
                scope.scValue = undefined;
                scope.list = [[],[],[],[],[],[],[]];
                scope.valueList = [{},{},{},{},{},{},{}];
                scope.wayList = [0,0,0,0,0,0,0];
                scope.setTab(scope.timeCollection[0],true);

                if(isUp){
                    elem.find('.selectFrame').slideUp();
                    elem.find('.combineSelected').children('ul').slideUp();
                    elem.find('.scCronFrame').slideUp();
                }
            };

            $(document).on('click',function(){
                elem.find('.selectFrame').slideUp();
                elem.find('.combineSelected').children('ul').slideUp();
                elem.find('.scCronFrame').slideUp();
            });
        }
    }
}));

(function(ng, factory) {
    'use strict';
    if (typeof define === 'function' && define.amd) {
        define(['angular'], function(ng) {
            return factory(ng);
        });
    } else {
        return factory(ng);
    }
}(window.angular || null, function(ng) {
    'use strict';

    var app = angular.module('ngTable', ['smart-table']).run(run);

    /**
     * 配置smartTable
     *  pagination: {
    *  template: 'template/smart-table/pagination.html',
    *  itemsByPage: 10,
    *  displayedPages: 5
    *},
     *search: {
    *  delay: 400, // ms
    *  inputEvent: 'input'
    *},
     *select: {
    *  mode: 'single',
    *  selectedClass: 'st-selected'
    *},
     *sort: {
    *  ascentClass: 'st-sort-ascent',
    *  descentClass: 'st-sort-descent',
    *  descendingFirst: false,
    *  skipNatural: false,
    *  delay:300
    *},
     *pipe: {
    *  delay: 100 //ms
    *}
     **/
    run.$inject = ['$templateCache'];
    function run($templateCache) {
        $templateCache.put('ng-table/filters/scTableTest.html','<nav><ul class="pagination">' +
            '<li><a ng-click="selectPage(1)">首页</a>' +
            '</li><li><a ng-click="selectPage(currentPage - 1)">&lt;</a>' +
            '</li><li><a><page-select></page-select> / {{numPages}}</a>' +
            '</li><li><a ng-click="selectPage(currentPage + 1)">&gt;</a>' +
            '</li><li><a ng-click="selectPage(numPages)">尾页</a></li>' +
            '<li><span style="border-right: 0px;border-top: 0px;border-bottom: 0px;cursor: pointer;padding: 6px 10px;">每页{{stItemsByPage}}条，共{{totalItemCount}}条</span></li>' +
            '</ul>' +
            '</nav>'+
            '<div class="pageBtn btn-group">' +
            '<button type="button" ng-repeat="row in counts" class="btn btn-default" ng-click="setItemsByPage(row)" ng-class="{\'active\':stItemsByPage==row}"><span>{{row}}</span></button>'+
            // '<button type="button" ng-click="stItemsByPage=25" ng-class="{\'active\':stItemsByPage==25}" class="btn btn-default"><span>25</span></button>'+
            // '<button type="button" ng-click="stItemsByPage=50" ng-class="{\'active\':stItemsByPage==50}" class="btn btn-default"><span>50</span></button>'+
            // '<button type="button" ng-click="stItemsByPage=100" ng-class="{\'active\':stItemsByPage==100}" class="btn btn-default"><span>100</span></button>'+
            '</div>'
        );
    }

    app.factory('scTableEventsChannel',scTableEventsChannel);
    scTableEventsChannel.$inject = ['$rootScope'];
    function  scTableEventsChannel($rootScope){
        var events = {};
        events = addChangeEvent('afterReloadData', events);
        return events;

        /////////
        function addChangeEvent(eventName, target){
            var fnName = eventName.charAt(0).toUpperCase() + eventName.substring(1);
            var event = {};
            event['on' + fnName] = createEventSubscriptionFn(eventName);
            event['publish' + fnName] = createPublishEventFn(eventName);
            return angular.extend(target, event);
        }

        function createEventSubscriptionFn(eventName){
            return function subscription(handler/*[, eventSelector or $scope][, eventSelector]*/){
                // shorthand for subscriber to only receive events from a specific publisher instance
                return $rootScope.$on('ngTable:' + eventName, function(event, params/*, ...args*/){
                    // don't send events published by the internal NgTableParams created by ngTableController
                    var eventArgs = rest(arguments, 2);

                    var fnArgs = [params].concat(eventArgs);
                    // if (eventSelector.apply(this, fnArgs)){
                    handler.apply(this, fnArgs);
                    // }
                });
            }
        }

        function createPublishEventFn(eventName){
            return function publish(/*args*/){
                var fnArgs = ['ngTable:' + eventName].concat(Array.prototype.slice.call(arguments));
                $rootScope.$broadcast.apply($rootScope, fnArgs);
            }
        }

        function rest(array, n) {
            return Array.prototype.slice.call(array, n == null ? 1 : n);
        }
    }

    app.factory('ngTableEventsChannel',ngTableEventsChannel);
    ngTableEventsChannel.$inject = ['$rootScope'];
    function  ngTableEventsChannel($rootScope){
        return{};
    }

    app.factory('NgTableParams',['$timeout',function($timeout){
        var NgTableParams = function(params, settings){
            var setting = {};
            setting = angular.merge(params,settings);
            setting.reload = angular.noop;
            return setting;
        };
        return NgTableParams;
    }]);

    /**
     *表格主程序
     */
    ng.module('ngTable')
        .directive('ngTable', ngTable);
    ngTable.$inject = ['$compile','$timeout','scTableEventsChannel'];
    function ngTable($compile,$timeout,scTableEventsChannel){
        return {
            restrict: 'A',
            template:function(element,attrs){
                var colDefines = jQuery(element).find('td');
                var hasFilter = false;
                var totalCols = colDefines.length;
                angular.forEach(colDefines, function(cd, idx) {
                    var col = $(cd);
                    if(col.attr('filter')){
                        hasFilter = true;
                        return;
                    }
                });

                var f = $.trim(attrs.showFilter);

                if(f == "true"){
                    hasFilter = true;
                }else{
                    hasFilter = false;
                }

                var hasParams = true;

                if(!element.attr('ng-table')){
                    hasParams = false;
                    hasFilter = false;
                }
                var arrList = element.find('tr').attr('ng-repeat').split(' ');
                var idx = arrList.indexOf('in');
                var par = arrList[idx+1];
                par = $.trim(par);
                element.attr('my-params',par);

                element.attr('st-table','displayRows');
                element.attr('st-safe-src','rows');
                if(hasParams){
                    element.attr('st-table-params','params');
                    element.attr('st-pipe','getData');
                    element.attr('init-load','true');
                }

                var html  = "";
                var titleTr = '<tr class="ng-table-sort-header">';
                if(hasFilter){
                    var filterTr = '<tr class="ng-table-filters">';
                }

                var bodyTr = '<tr ng-repeat="row in displayRows track by $index" class="{{row.isSelected?\'ex-selected\':\'\'}}" ';
                var ngStyle = element.find('tr').attr('ng-style');
                if(ngStyle){
                    bodyTr += ' ng-style="'+ngStyle+'" ';
                }
                bodyTr += '>';


                var fixedFrontCols = attrs.scFixedFrontCounts?attrs.scFixedFrontCounts:1;
                var fixedTailCols = attrs.scFixedTailCounts?attrs.scFixedTailCounts:1;

                angular.forEach(colDefines, function(cd, idx) {
                    var col = $(cd);

                    //拼接第一行
                    var th;
                    // if(idx < fixedFrontCols || idx>=(totalCols-fixedTailCols)){
                    //     th='<th ';
                    // }else{
                    //     th='<th><span ';
                    // }

                    // if(col.attr('data-sort')){
                    //     th+=' st-table-sort="'+col.attr('data-sort')+'"';
                    // }
                    // if(col.attr('data-title')){
                    //     th+='>'+col.attr('data-title').substring(1,col.attr('data-title').length-1);
                    // }else if(col.attr('header')){
                    //     //说明需要input选择
                    //     th+= ' sc-new-select-all>';
                    // }else{
                    //     th+='>';
                    // }
                    th = jQuery('<th><span></span></th>');
                    if(col.attr('data-sort')){
                        th.children('span').append('<b class="dataSort" st-table-sort="'+col.attr('data-sort')+'"></b>');
                    }
                    if(col.attr('data-title')){
                        th.children('span').append('<span class="dragColumns" style="display:inline-block">'+col.attr('data-title').substring(1,col.attr('data-title').length-1)+'</span>');
                    }
                    th = th[0].outerHTML;
                    if(col.attr('header')){
                        th = '<th sc-new-select-all></th>';
                    }

                    // if(idx < fixedFrontCols || idx>=(totalCols-fixedTailCols)){
                    //     th+='</th>';
                    // }else{
                    //     th+='</span></th>';
                    // }
                    // th = jQuery(th).prepend('<b class="cursorLeft"></b>');
                    // titleTr+=th[0].outerHTML;
                    titleTr+=th;

                    //    拼接第二行
                    if(hasFilter){

                        var filterPop = col.attr('filter');
                        if(filterPop){
                            var str = filterPop.replace(/'/g,'"');//将所有的 ’  转换成  “
                            var obj = $.parseJSON(str);//将字符串转为obj
                            filterTr+='<th class="filter" filter="'+ filterPop+'"';
                            for(var key in obj){
                                if(obj[key] == 'select'){
                                    filterTr+='filter-data="'+col.attr('filter-data')+'"';
                                }
                            }
                            filterTr+='>';
                        }else{
                            filterTr+='<th >';
                        }
                        //如果有过滤
                        if(filterPop){
                            filterTr += '<div class="filter-cell s12';
                            if (idx == colDefines.length - 1) {
                                filterTr += ' last">';
                            } else {
                                filterTr += '">';
                            }

                            var str = filterPop.replace(/'/g,'"');//将所有的 ’  转换成  “
                            var obj = $.parseJSON(str);//将字符串转为obj
                            for(var key in obj){
                                if(obj[key] == 'select'){
                                    if(col.attr('select-model') == 'multiple'){
                                        filterTr += ('<div st-select-multiple filter-config-data="'+col.attr('filter-data')+'" filter-config-value="'+key+'"></div>');
                                    }else{
                                        filterTr += ('<div st-select filter-config-data="'+col.attr('filter-data')+'" filter-config-value="'+key+'"></div>');
                                    }
                                    // filterTr += ('<sc-tab-select filter-config="'+col.attr('filter-data')+'"></sc-tab-select>');

                                }
                                else if(obj[key] == 'startNum'){
                                    filterTr += ('<div sc-start-num class="sc-start-num" sc-code="'+key+'" ></div>');
                                }
                                else if(obj[key] == 'endNum'){
                                    filterTr += ('<div sc-end-num class="sc-end-num" sc-code="'+key+'" ></div>');
                                }
                                else if(obj[key] == 'startDate'){
                                    filterTr += ('<div sc-start-date class="sc-start-date" sc-code="'+key+'" ></div>');
                                }
                                else if(obj[key] == 'endDate'){
                                    filterTr += ('<div sc-end-date class="sc-end-date" sc-code="'+key+'" ></div>');
                                }
                                else if(obj[key] == 'number'){
                                    filterTr += ('<div sc-num-search="'+key+'"></div>');
                                }
                                else if(!obj[key] || obj[key]== 'text'){
                                    filterTr += ('<input type="text" st-search="' + key + '"  ng-table-model="params.filter[\''+key+'\']" class="input-filter form-control ng-pristine ng-untouched ng-valid ng-empty">');

                                    // filterTr += ('<div st-search="'+key+'" ng-model="params.filter['+key+']"></div>');
                                }
                            }
                            filterTr += '</div>';
                        }
                        filterTr += '</th>';
                    }

                    //    拼接第三行
                    // bodyTr+='<td ';
                    // if(col.attr('data-title')){
                    //     bodyTr+='data-title="'+col.attr('data-title')+'" >'+col.html();
                    // }else if(col.attr('header')){
                    //     //说明需要input选择
                    //     bodyTr+= ' sc-new-select-row=\'row\'>';
                    // }else{
                    //     bodyTr+='>'+col.html();
                    // }
                    // bodyTr+='</td>';
                    if(col.attr('header')){
                        col.attr('sc-new-select-row','row');
                    }
                    if(col.attr('ex-select')){
                        col.removeAttr('ex-select');
                    }
                    if(col.attr('ex-table-params')){
                        col.removeAttr('ex-table-params');
                    }
                    var hm = col[0].outerHTML;
                    bodyTr+=hm;
                });


                titleTr += '</tr>';
                if(hasFilter){
                    filterTr += '</tr>';
                }

                bodyTr += '</tr>';

                html += '<thead>';
                html += titleTr;

                if(hasFilter){
                    html += filterTr;
                }

                html += '</thead>';

                html += '<tbody>';
                html += bodyTr;
                html += '</tbody>';

                // html+='</table>';

                var table= jQuery('<table>'+html+'</table>');
                // var table = jQuery(element);
                var fixedFrontWidthAttr = element.attr('fixed-front-width');
                var fixedTailWidthAttr = element.attr('fixed-tail-width');
                var fixedHeightAttr = parseInt(element.attr('fixed-height'));

                var colCount = $(table.find('tr')[table.find('tr').length-1]).find('td').length;
                var arr = ['id','code','编码','单号','装箱号','行号','操作'];
                var isNotExsit = function(str){
                    if(str){
                        str = str.toLowerCase();
                        for(var i=0;i<arr.length;i++){
                            if(str.indexOf(arr[i])>-1){
                                return false;
                            }
                        }
                    }else if(str  ===  undefined){
                        return false;
                    }
                    return true;
                };

                if (colCount > 2 && fixedFrontWidthAttr && fixedTailWidthAttr && fixedHeightAttr) {
                    var fixedFrontWidthArr = fixedFrontWidthAttr.split(',');
                    var fixedTailWidthArr = fixedTailWidthAttr.split(',');

                    var fixedFrontWidthSum = 3;
                    var fixedTailWidthSum = 3;//存储总列数的宽度
                    angular.forEach(fixedFrontWidthArr, function(w) {
                        fixedFrontWidthSum = fixedFrontWidthSum + parseInt(w, 10);
                    });
                    angular.forEach(fixedTailWidthArr, function(w) {
                        fixedTailWidthSum = fixedTailWidthSum + parseInt(w, 10);
                    });

                    angular.forEach(table.find('tr'), function(trEle,index) {
                        if(index==1){
                            fixedHeightAttr = fixedHeightAttr<32?32:fixedHeightAttr;
                        }else{
                            fixedHeightAttr = parseInt(element.attr('fixed-height'));
                        }
                        var tr = angular.element(trEle);
                        var tdLen = tr.children().length;
                        angular.forEach(tr.children(), function(tdEle, idx) {
                            var td = angular.element(tdEle);
                            if (fixedFrontWidthArr[idx]) {
                                var ngStyle = "{'width': '" + fixedFrontWidthArr[idx] + "px'" +", 'height':'" + fixedHeightAttr + "px'" + "}";
                                td.attr('ng-style', ngStyle);
                                td.addClass('activeLeft backColor');
                            } else if(idx === fixedFrontWidthArr.length) {
                                var ngStyle = "{'padding-left': '" + fixedFrontWidthSum + "px'" +", 'height':'" + fixedHeightAttr + "px'" + "}";
                                td.attr('ng-style', ngStyle);
                            }

                            var reverseIdx = tdLen - idx - 1;
                            if(fixedTailWidthArr[reverseIdx]) {
                                var ngStyle = "{'width': '" + fixedTailWidthArr[reverseIdx] + "px'" +", 'height':'" + fixedHeightAttr + "px'" + "}";
                                td.attr('ng-style', ngStyle);
                                td.addClass('activeRight backColor');
                            } else if(reverseIdx === fixedTailWidthArr.length) {
                                if(td.attr('ng-style')) {
                                    var ngStyle = td.attr('ng-style');
                                    ngStyle = ngStyle.substring(0, ngStyle.length - 1) + ", 'padding-right': '" + fixedTailWidthSum + "px'" + "}";
                                    td.attr('ng-style', ngStyle);
                                } else {
                                    var ngStyle = "{'padding-right': '" + fixedTailWidthSum + "px'" +", 'height':'" + fixedHeightAttr + "px'" + "}";
                                    td.attr('ng-style', ngStyle);
                                    var thtdStyle = "{'padding-right': '" + fixedTailWidthSum + "px'}";
                                }
                            }
                            // var htmlStr = td.html();
                            // htmlStr = htmlStr.replace(/"/g, "'");
                            // var exprStartIdx = htmlStr.indexOf('{{');
                            // var exprEndIdx = htmlStr.indexOf('}}');
                            // var dataTitle = td.attr('data-title');

                            // if(exprStartIdx > -1 && exprEndIdx > -1 && htmlStr.indexOf('<button') === -1 && isNotExsit(dataTitle)) {
                            //     var expr = htmlStr.substring(exprStartIdx, exprEndIdx + 2);
                            //     td.html('<span tabindex="0" data-toggle="popover" data-trigger="click" data-content="' + htmlStr + '" data-html="true">' + htmlStr + '</span>');
                            // }
                        });
                    });
                }
                if(colCount>2){
                    angular.forEach(table.children('tbody').find('tr'), function(trEle) {
                        var tr = angular.element(trEle);
                        angular.forEach(tr.children(), function(tdEle, idx) {
                            var td = angular.element(tdEle);
                            var hasPopover = td.attr('has-popover')=="false"?false:true;
                            var htmlStr = td.html();
                            htmlStr = htmlStr.replace(/"/g, "'");
                            var dataTitle = td.attr('data-title');

                            if(htmlStr.indexOf('<button') === -1 && isNotExsit(dataTitle) && hasPopover) {
                                // var expr = htmlStr.substring(exprStartIdx, exprEndIdx + 2);
                                td.html('<span tabindex="0" data-toggle="popover" data-trigger="click" data-content="' + htmlStr + '" data-html="true">' + htmlStr + '</span>');
                            }
                        });
                    });
                }


                html =table.html();
                if(hasParams){
                    html+='<tfoot ng-if="displayRows.length>0 && totalCounts">' +
                    '<tr>' +
                    '<td colspan="' + colDefines.length + '">' +
                    ' <div st-pagination="" st-counts="tableCounts" st-template="ng-table/filters/scTableTest.html" >'+
                    '</div>'+
                    '</td>' +
                    '</tr>' +
                    '</tfoot>';
                }
                return html;
            },
            compile: function(el) {
                return function(scope, element, attrs, controller) {
                    var param = null;
                    // if(attrs.ngTable){
                    //     param = attrs.ngTable;
                    // }else if(element.attr('my-params')){
                    //     param = element.attr('my-params');
                    // }
                    controller.setupBindingsToInternalScope(attrs.ngTable,element.attr('my-params'));
                    controller.compileDirectiveTemplates();
                };
            },
            controller: ['$scope', '$compile', '$element', '$parse', function($scope, $compile, $element, $parse) {
                var tableBordered = $element;
                if(!tableBordered.parent().hasClass('ng-table-content')){
                    var ngTableContainer = angular.element(document.createElement('div')).addClass('ng-table-container');
                    tableBordered.wrap(ngTableContainer);
                    var ngContent = angular.element(document.createElement('div')).addClass('ng-table-content').css('position','relative');
                    tableBordered.parent('.ng-table-container').wrap(ngContent);

                    tableBordered.parent('.ng-table-container').on('scroll', function(event) {
                        $('.popover').remove();
                        $('.exDatepicker').removeClass('active');
                    });
                }

                var nowTime = new Date();
                function rnd(){
                    var random = Math.floor(Math.random()*9+1);
                    return random;
                }
                var r = "";
                for(var i=0;i<3;i++){
                    r+= rnd();
                }
                var tabId = nowTime.getTime()+r;
                $element.attr('table-id',tabId);

                $scope.displayRows = [];
                $scope.rows = [];
                $scope.totalCounts = 0;
                $scope.tableCounts = null;
                $scope.tablePageCounts = [];

                this.compileDirectiveTemplates = function() {
                    $element.removeAttr('ng-table');
                    if($element.attr('my-params')){
                        $element.removeAttr('my-params');
                    }
                    $compile($element)($scope);
                };
                this.setupBindingsToInternalScope = function(tableParamsExpr,trParamsExpr) {
                    var hasRepeat = false;
                    if(trParamsExpr && trParamsExpr != '$data'){
                        hasRepeat = true;
                        var trParamsGetter = $parse(trParamsExpr);
                        $scope.$watch(trParamsGetter, (function(params) {
                            if(params && angular.isArray(params)){
                                $scope.displayRows = params;
                                $scope.rows = $scope.displayRows;
                            }
                        }),false);
                    }

                    if(!hasRepeat){
                        var tableParamsGetter = $parse(tableParamsExpr);
                        $scope.$watch(tableParamsGetter, (function(params) {
                            if (angular.isUndefined(params)) {
                                return;
                            }
                            $scope.paramsModel = tableParamsGetter;
                            $scope.params = params;

                            $scope.params.ngTableParams = function() {
                                var ts = $scope.params.tableState();

                                return convertToNgTableParam(ts);
                            };

                            if(params && angular.isArray($scope.params) && $scope.params.length>0){
                                $scope.displayRows = angular.copy($scope.params);
                                $scope.rows = $scope.displayRows;
                            }

                            if(params && angular.isArray($scope.params.counts) && $scope.params.counts.length>0){
                                $scope.tableCounts = angular.copy($scope.params.counts);
                                $scope.tablePageCounts = angular.copy($scope.params.counts);
                            }
                        }), false);
                    }
                };

                $scope.getData = function(tableState) {
                    return $timeout(function(){
                      if($scope.params){
                        //将实例化中的获取count的函数赋给params.count,ng-table实例化中通过函数得到ps  pn，通过params.total函数返回数据总数
                        var params = convertToNgTableParam(tableState);
                        params.settings = function(obj){
                            if(angular.isArray(obj.counts) && obj.counts.length>0){
                                $scope.tableCounts = angular.copy(obj.counts);
                            }
                        };
                        params.total = function(t){
                            $scope.totalCounts=t;
                        };

                        if($scope.params.getData && angular.isFunction($scope.params.getData)) {
                            var data = $scope.params.getData(params);
                            if(data && angular.isFunction(data.then)){
                                var number = params.count();
                                data.then(function (result) {
                                    $timeout(function() {
                                        scTableEventsChannel.publishAfterReloadData(ngTable,result,$scope.displayRows,tabId);
                                        $('.popover').remove();
                                        $('.exDatepicker').removeClass('active');
                                        $scope.rows = result;
                                        $scope.displayRows = result;
                                        // tableState.pagination.numberOfPages = parseInt(params.total / number) + (params.total % number > 0?1 : 0);
                                        tableState.pagination.numberOfPages = parseInt($scope.totalCounts / number) + ($scope.totalCounts % number > 0?1 : 0);
                                        // tableState.pagination.totalItemCount = params.total;
                                        tableState.pagination.totalItemCount = $scope.totalCounts;
                                        //set the number of pages so the pagination can update
                                    });
                                });
                            }else if(angular.isArray(data) && data.length>0){
                                $scope.rows = data;
                                $scope.displayRows = data;
                            }
                        }
                        // var data = $scope.params.getData();
                        //   $timeout(function() {
                        //       // scTableEventsChannel.publishAfterReloadData(ngTable,result,$scope.displayRows,tabId);
                        //       $scope.rows = data;
                        //       $scope.displayRows = data;
                        //       // tableState.pagination.numberOfPages = parseInt(params.total / number) + (params.total % number > 0?1 : 0);
                        //       tableState.pagination.numberOfPages = 1;
                        //       // tableState.pagination.totalItemCount = params.total;
                        //       tableState.pagination.totalItemCount = 5;
                        //       //set the number of pages so the pagination can update
                        //   });
                      }
                    });
                };

                $scope.$watch('params',function(newVal,oldVal){
                    if(newVal && oldVal && newVal!=oldVal){
                        newVal.reload();
                    }
                });

                $scope.$watch(function() {
                        return $element.find('tbody').html();
                    }, function(val, oldVal) {
                        //判断是否找到  {{‘  是为了确定该html代码是编译之后的代码  此时td下已经有span
                        // if(val.indexOf('{{\'') === -1) {
                            $element.find("[data-toggle='popover']").popover({
                                placement: function (context, source) {
                                    var src = $(source);
                                    var position = src.position();

                                    if (src.parents('td,th').hasClass('activeRight')) {
                                        return 'left';
                                    }

                                    if (src.parents('td,th').hasClass('activeLeft')) {
                                        return 'right';
                                    }

                                    var conW = src.parents('div.ng-table-container').outerWidth();
                                    var srcW = src.parents('td,th').outerWidth();
                                    var popoverW = 276;

                                    var overRight = (position.left + srcW + popoverW) > conW;
                                    var overLeft = (position.left - popoverW) < 0;
                                    if (overRight && overLeft) {
                                        if (position.top < 110){
                                            return "bottom";
                                        }
                                    } else if (overRight) {
                                        return "left";
                                    } else {
                                        return "right";
                                    }

                                    return "top";
                                }
                            });
                        // }
                    });

                function cancelSelect(){
                    tableBordered.find('.stSelect.active').removeClass('active');
                }

                function convertToNgTableParam(ts) {
                    var pagination = ts.pagination;
                    var start = pagination.start || 0; // 是数据下标
                    var number = pagination.number || 10; // 页面条数
                    var page = start / number + 1; // 页数
                    var par = ts.search.predicateObject;
                    var orderB = ts.sort.predicate;
                    var orderT = 'ASC';
                    if(ts.sort.reverse){
                        orderT = 'DESC';
                    }
                    par = angular.merge({start: start}, par);
                    if(orderB){
                        par = angular.merge({orderBy:orderB,orderType:orderT},par);
                    }

                    var params = {};
                    params.count = function(){
                        return number;
                    };
                    params.page = function(){
                        return page;
                    };
                    params.filter = function(){
                        return par;
                    };

                    return params;
                }

                $(document).on('click',function(){
                    cancelSelect();
                });
                $(window).resize(function(){
                    cancelSelect();
                });
                $(document).scroll(function() {
                    cancelSelect();
                });
                tableBordered.parent().scroll(function() {
                    cancelSelect();
                });
            }],
            scope:true,
            terminal: true,
            priority: 1001
        };
    }

    /**
     *表格监听文本框初始化过滤并显示
     */
    ng.module('ngTable')
        .directive('ngTableModel', ngTableModel);
    ngTableModel.$inject = [ '$timeout'];
    function ngTableModel($timeout){
        return {
            restrict: 'A',
            scope:false,
            link:{
                pre:function(scope,elem,attrs,ctrl){
                    scope.$watch(attrs.ngTableModel,function(newVal,oldVal){
                        if (newVal){
                            $(elem).val(newVal);
                            $(elem).trigger('input');
                            if(oldVal!=newVal) jQuery(elem).change();
                        //   element.trigger("change"); use this for jQuery
                        }else if(newVal ==""){
                            $(elem).val(newVal);
                            $(elem).trigger('input');
                            if(oldVal!=newVal) jQuery(elem).change();
                        }
                    });
                }
            }
        }
    }

    /**
     *新表格主程序
     */
    ng.module('ngTable')
        .directive('ngNewTable', ngNewTable);
    ngNewTable.$inject = ['$compile','$timeout','scTableEventsChannel'];
    function ngNewTable($compile,$timeout,scTableEventsChannel) {
        return {
            restrict: 'A',
            template:function(element,attrs){
                $(element).css('visibility','hidden');
                var html='<div class="ant-spin-container">'+
                    '<div class="ant-table ant-table-large ant-table-fixed-header ant-table-scroll-position-left">'+
                    '<div class="ant-table-content">';

                var colDefines = jQuery(element).find('td');//列
                var hasFilter = false;
                var divClass = 'tableTdDiv';
                angular.forEach(colDefines, function(cd, idx) {
                    var col = $(cd);
                    if(col.attr('filter')){
                        hasFilter = true;
                    }
                    var str = col.html();
                    var w = col.attr('width')?col.attr('width'):0;
                    col.html('<div ng-style="tdWidthStyle['+idx+']" st-width="'+w+'" class="'+divClass+'">'+str+'</div>');
                });

                var f = $.trim(attrs.showFilter);

                if(f == "false"){
                    hasFilter = false;
                }

                var hasParams = true;

                if(!element.attr('ng-new-table')){
                    hasParams = false;
                    hasFilter = false;
                }
                var arrList = element.find('tr').attr('ng-repeat').split(' ');
                var idx = arrList.indexOf('in');
                var par = arrList[idx+1];
                par = $.trim(par);
                element.attr('my-params',par);

                //左中右三部分
                //中
                var scrollContent = '<div class="ant-table-scroll">';
                var colgroup = '<colgroup>';//每一列宽度

                var ngTableDrag = attrs.ngTableDrag == ''?'ng-table-drag':'no-drag';
                var ngTableDragWidth = attrs.ngTableDragWidth == ''?'ng-table-drag-width':'no-drag-width';
                //中-thead
                var scrollContentHeader =
                    '<div class="ant-table-header" style="margin-bottom: -10px; padding-bottom: 0;">'+
                    '<table class="ant-table-fixed ant-table-center-head" sc-fixed-tail-counts="'+attrs.scRightFixedCol+'" sc-fixed-front-counts="'+attrs.scLeftFixedCol+'"  '+ngTableDragWidth+'  st-table="displayRows" st-safe-src="rows"';

                if(hasParams){
                    scrollContentHeader+=' st-pipe="getData" init-load="true" st-table-params="params.stParams" ';
                }
                scrollContentHeader+='>';

                var scrollTheadTitleTr='<tr ng-style="headTrHeightStyle[0]">';//thead第一行
                var scrollTheadFilterTr='<tr ng-style="headTrHeightStyle[1]">';//thead第二行
                var scrollTbodyTr='<tr ng-repeat="row in displayRows track by $index" ng-style="bodyTrHeightStyle[$index]" ng-class="{true: \'ex-selected\', false: \'inactive\'}[row.isSelected]" class="ant-table-row  ant-table-row-level-0">';//tbody中一行

                var arrCode = ['id','code','编码','单号','装箱号','行号','操作'];
                var isNotExsit = function(str){
                    if(str){
                        str = str.toLowerCase();
                        for(var i=0;i<arrCode.length;i++){
                            if(str.indexOf(arrCode[i])>-1){
                                return false;
                            }
                        }
                    }else if(str  ===  undefined){
                        return false;
                    }
                    return true;
                };

                angular.forEach(colDefines, function(cd, idx) {
                    var col = $(cd);

                    //拼接第一行
                    var titleTh = jQuery('<th><div ng-style="tdWidthStyle['+idx+']" class="'+divClass+'"></div></th>');
                    if(col.attr('data-sort')){
                        titleTh.children('div').append('<b class="dataSort" st-table-center-sort="'+col.attr('data-sort')+'"></b>');
                    }
                    if(col.attr('data-title')){
                        titleTh.children('div').append('<span class="dragColumns" style="display:inline-block">'+col.attr('data-title').substring(1,col.attr('data-title').length-1)+'</span>');
                    }
                    titleTh = titleTh[0].outerHTML;
                    if(col.attr('header')){
                        // if(attrs.scLeftFixedCol>0){
                            // titleTh = '<th width="'+col.attr('width')+'"><div ng-style="tdWidthStyle['+idx+']" class="'+divClass+'" st-table-center-head-select="displayRows"></div></th>';
                        // }else{
                            titleTh = '<th><div ng-style="tdWidthStyle['+idx+']" class="'+divClass+'" st-table-center-head-select="displayRows"></div></th>';
                        // }
                    }
                    scrollTheadTitleTr+=titleTh;

                    //    拼接第二行
                    if(hasFilter){
                        var filterTh='';
                        var filterPop = col.attr('filter');
                        if(filterPop){
                            var str = filterPop.replace(/'/g,'"');//将所有的 ’  转换成  “
                            var obj = $.parseJSON(str);//将字符串转为obj
                            filterTh+='<th class="st-filter" filter="'+ filterPop+'"';
                            for(var key in obj){
                                if(obj[key] == 'select'){
                                    filterTh+='filter-data="'+col.attr('filter-data')+'"';
                                }
                            }
                            filterTh+='>';
                        }else{
                            filterTh+='<th><div ng-style="tdWidthStyle['+idx+']" class="'+divClass+'">';
                        }
                        //如果有过滤
                        if(filterPop){
                            // filterTh += '<div  ng-style="tdWidthStyle['+idx+']" class="filter-cell s12';
                            filterTh += '<div ng-style="tdWidthStyle['+idx+']" class="st-filter-cell s12 '+divClass;
                            if (idx == colDefines.length - 1) {
                                filterTh += ' last">';
                            } else {
                                filterTh += '">';
                            }

                            var str = filterPop.replace(/'/g,'"');//将所有的 ’  转换成  “
                            var obj = $.parseJSON(str);//将字符串转为obj
                            for(var key in obj){
                                if(obj[key] == 'select'){
                                    if(col.attr('select-model') == 'multiple'){
                                        filterTh += ('<div st-table-multi-select filter-config-data="'+col.attr('filter-data')+'" filter-config-value="'+key+'"></div>');
                                    }else{
                                        filterTh += ('<div st-table-single-select filter-config-data="'+col.attr('filter-data')+'" filter-config-value="'+key+'"></div>');
                                    }
                                }
                                else if(obj[key] == 'startNum'){
                                    filterTh += ('<div st-table-start-num class="sc-start-num" sc-code="'+key+'" ></div>');
                                }
                                else if(obj[key] == 'endNum'){
                                    filterTh += ('<div st-table-end-num class="sc-end-num" sc-code="'+key+'" ></div>');
                                }
                                else if(obj[key] == 'startDate'){
                                    filterTh += ('<div st-start-date ng-click="$event.stopPropagation()" class="st-start-date" sc-code="'+key+'" ></div>');
                                }
                                else if(obj[key] == 'endDate'){
                                    filterTh += ('<div st-end-date ng-click="$event.stopPropagation()" class="st-end-date" sc-code="'+key+'" ></div>');
                                }
                                 else if(obj[key] == 'startDateTime'){
                                     filterTh += ('<div st-start-date-time ng-click="$event.stopPropagation()" class="st-start-date" sc-code="'+key+'" ></div>');
                                 }
                                 else if(obj[key] == 'endDateTime'){
                                     filterTh += ('<div st-end-date-time ng-click="$event.stopPropagation()" class="st-end-date" sc-code="'+key+'" ></div>');
                                 }
                                else if(obj[key] == 'number'){
                                    filterTh += ('<div st-table-number="'+key+'"></div>');
                                }
                                else if(!obj[key] || obj[key]== 'text'){
                                    filterTh += ('<input type="text" st-search="' + key + '" ng-table-model="params.filter[\''+key+'\']" class="input-filter form-control">');
                                }
                            }
                        }
                        filterTh += '</div></th>';
                        scrollTheadFilterTr+=filterTh;
                    }

                    //tbody一行
                    if(col.attr('header')){
                        col.children('div').attr('st-table-center-body-select','row');
                    }
                    if(col.attr('ex-select')){
                        col.removeAttr('ex-select');
                    }
                    if(col.attr('ex-table-params')){
                        col.removeAttr('ex-table-params');
                    }

                    var htmlStr = col.children('div').html();
                    htmlStr = htmlStr.replace(/"/g, "'");
                    var dataTitle = col.attr('data-title');
                    if(htmlStr.indexOf('<button') === -1 && isNotExsit(dataTitle)) {
                        col.children('div').html('<span st-data-pop ng-click="$event.stopPropagation()" st-data-content="' + htmlStr + '">' + htmlStr + '</span>');
                    }

                    scrollTbodyTr+= col[0].outerHTML;
                    col.children('div').removeAttr('sc-new-select-row').removeAttr('st-table-center-body-select');

                });
                if(hasFilter){
                    scrollTheadFilterTr+='</tr>';
                }else{
                    scrollTheadFilterTr = '';
                }
                scrollContentHeader+='<thead class="ant-table-thead">'+scrollTheadTitleTr+'</tr>'+scrollTheadFilterTr+'</thead></table></div>';

                //中-tbody
                var scrollContentBody =
                    ' <div class="ant-table-body ant-table-body-center" ng-style="maxHeight" style="overflow-x: auto;overflow-y: scroll;">'+
                    '<table class="ant-table-fixed ant-table-center-body"   st-table-center-body st-table-left-fixed="'+attrs.scLeftFixedCol+'" st-table-right-fixed="'+attrs.scRightFixedCol+'">';
                scrollContentBody+='<tbody class="ant-table-tbody">'+scrollTbodyTr+'</tr>'+'</tbody></table></div>';

                scrollContent+=scrollContentHeader+scrollContentBody+'</div>';//中间部分拼接完成
                html+=scrollContent;

                //左
                var scLeftFixedCol = attrs.scLeftFixedCol>=0?attrs.scLeftFixedCol:0;
                if(scLeftFixedCol>0){
                    var tableLeft =  '<div class="ant-table-fixed-left">';

                    //左固定
                    var tableLeftTheadTitleTr = '<tr ng-style="headTrHeightStyle[0]">';//左固定table的thead第一行
                    var tableLeftTheadFilterTr = '<tr ng-style="headTrHeightStyle[1]">';//左固定table的thead第二行
                    var tableLeftTbodyTr = '<tr ng-repeat="row in displayRows track by $index" ng-style="bodyTrHeightStyle[$index]" ng-class="{true: \'ex-selected\', false: \'inactive\'}[row.isSelected]" class="ant-table-row  ant-table-row-level-0">';//左固定table的thead第二行

                    var temp=0;//记录循环次数

                    angular.forEach(colDefines, function(cd, idx) {
                        if(temp>=scLeftFixedCol){
                            return;
                        }
                        var col = $(cd);

                        //拼接第一行
                        var titleTh = jQuery('<th><div ng-style="tdWidthStyle['+idx+']" class="'+divClass+'"></div></th>');
                        if(col.attr('data-sort')){
                            titleTh.children('div').append('<b class="dataSort" st-table-center-sort="'+col.attr('data-sort')+'"></b>');
                        }
                        if(col.attr('data-title')){
                            titleTh.children('div').append('<span class="dragColumns" style="display:inline-block">'+col.attr('data-title').substring(1,col.attr('data-title').length-1)+'</span>');
                        }
                        titleTh = titleTh[0].outerHTML;
                        if(col.attr('header')){
                            titleTh = '<th><div ng-style="tdWidthStyle['+idx+']" class="'+divClass+'" st-table-left-head-select="displayRows"></div></th>';
                        }
                        tableLeftTheadTitleTr+=titleTh;

                        //    拼接第二行
                        if(hasFilter){
                            var filterTh='';
                            var filterPop = col.attr('filter');
                            if(filterPop){
                                var str = filterPop.replace(/'/g,'"');//将所有的 ’  转换成  “
                                var obj = $.parseJSON(str);//将字符串转为obj
                                filterTh+='<th class="st-filter" filter="'+ filterPop+'"';
                                for(var key in obj){
                                    if(obj[key] == 'select'){
                                        filterTh+='filter-data="'+col.attr('filter-data')+'"';
                                    }
                                }
                                filterTh+='>';
                            }else{
                                filterTh+='<th><div ng-style="tdWidthStyle['+idx+']" class="'+divClass+'">';
                            }
                            //如果有过滤
                            if(filterPop){
                                filterTh += '<div ng-style="tdWidthStyle['+idx+']" class="st-filter-cell s12'+divClass;
                                if (idx == colDefines.length - 1) {
                                    filterTh += ' last">';
                                } else {
                                    filterTh += '">';
                                }

                                var str = filterPop.replace(/'/g,'"');//将所有的 ’  转换成  “
                                var obj = $.parseJSON(str);//将字符串转为obj
                                for(var key in obj){
                                    if(obj[key] == 'number'){
                                        filterTh += ('<div st-table-number="'+key+'"></div>');
                                    }
                                    else if(obj[key] == 'startNum'){
                                        filterTh += ('<div st-table-start-num class="sc-start-num" sc-code="'+key+'" ></div>');
                                    }
                                    else if(obj[key] == 'endNum'){
                                        filterTh += ('<div st-table-end-num class="sc-end-num" sc-code="'+key+'" ></div>');
                                    }
                                    else if(!obj[key] || obj[key]== 'text'){
                                        filterTh += ('<div type="text" st-table-head-text="' + key + '"></div>');
                                    }
                                    else if(obj[key] == 'select'){
                                        if(col.attr('select-model') == 'multiple'){
                                            filterTh += ('<div st-table-multi-select filter-config-data="'+col.attr('filter-data')+'" filter-config-value="'+key+'"></div>');
                                        }else{
                                            filterTh += ('<div st-table-single-select filter-config-data="'+col.attr('filter-data')+'" filter-config-value="'+key+'"></div>');
                                        }
                                    }
                                    else if(obj[key] == 'startDate'){
                                        filterTh += ('<div st-start-date ng-click="$event.stopPropagation()" class="st-start-date" sc-code="'+key+'" ></div>');
                                    }
                                    else if(obj[key] == 'endDate'){
                                        filterTh += ('<div st-end-date ng-click="$event.stopPropagation()" class="st-end-date" sc-code="'+key+'" ></div>');
                                    }
                                }
                            }
                            filterTh += '</div></th>';
                            tableLeftTheadFilterTr+=filterTh;
                        }

                        //tbody一行
                        if(col.attr('header')){
                            col.children('div').attr('st-table-left-body-select','row');
                        }
                        if(col.attr('ex-select')){
                            col.removeAttr('ex-select');
                        }
                        if(col.attr('ex-table-params')){
                            col.removeAttr('ex-table-params');
                        }
                        tableLeftTbodyTr+=col[0].outerHTML;
                        temp+=1;
                        col.children('div').removeAttr('st-table-left-body-select');
                    });

                    var tableLeftHeader =
                        '<div class="ant-table-header">'+
                        '<table class="ant-table-fixed ant-table-left-head" st-table-left-head="displayRows" st-table-left-head-params="params">';
                    if(hasFilter){
                        tableLeftTheadFilterTr+='</tr>';
                    }else{
                        tableLeftTheadFilterTr = '';
                    }
                    tableLeftHeader+='<thead class="ant-table-thead">'+tableLeftTheadTitleTr+'</tr>'+tableLeftTheadFilterTr+'</thead></table></div>';

                    var tableLeftBody =
                        ' <div class="ant-table-body-outer" style="margin-bottom: -10px; padding-bottom: 0px;">' +
                        '<div class="ant-table-body-inner" ng-style="maxHeight" style=" overflow-y: scroll;">'+
                        '<table class="ant-table-fixed ant-table-left-body" st-table-left-body="displayRows">';
                    tableLeftBody+='<tbody class="ant-table-tbody">'+tableLeftTbodyTr+'</tr>'+'</tbody></table></div></div>';

                    tableLeft+=tableLeftHeader+tableLeftBody+'</div>';
                    html+=tableLeft;
                }


                //右
                var scRightFixedCol = attrs.scRightFixedCol>=0?attrs.scRightFixedCol:0;
                if(scRightFixedCol>0){
                    var tableRight =  '<div class="ant-table-fixed-right">';

                    //右固定
                    var tableRightTheadTitleTr = '<tr ng-style="headTrHeightStyle[0]">';//左固定table的thead第一行
                    var tableRightTheadFilterTr = '<tr ng-style="headTrHeightStyle[1]">';//左固定table的thead第二行
                    var tableRightTbodyTr = '<tr ng-repeat="row in displayRows track by $index" ng-style="bodyTrHeightStyle[$index]" ng-class="{true: \'ex-selected\', false: \'inactive\'}[row.isSelected]" class="ant-table-row  ant-table-row-level-0">';//左固定table的thead第二行

                    var temp=0;//记录循环次数
                    var tdLen = colDefines.length;
                    for(var k=0;k<scRightFixedCol;k++){
                        var col = $(colDefines[tdLen-scRightFixedCol+k]);
                        var idx = tdLen-scRightFixedCol+k;

                        //拼接第一行
                        var titleTh = jQuery('<th><div ng-style="tdWidthStyle['+idx+']" class="'+divClass+'"></div></th>');
                        if(col.attr('data-sort')){
                            titleTh.children('div').append('<b class="dataSort" st-table-center-sort="'+col.attr('data-sort')+'"></b>');
                        }
                        if(col.attr('data-title')){
                            titleTh.children('div').append('<span class="dragColumns" style="display:inline-block">'+col.attr('data-title').substring(1,col.attr('data-title').length-1)+'</span>');
                        }
                        titleTh = titleTh[0].outerHTML;
                        if(col.attr('header')){
                            titleTh = '<th><div ng-style="tdWidthStyle['+idx+']" class="'+divClass+'" st-table-right-head-select></div></th>';
                        }
                        tableRightTheadTitleTr+=titleTh;

                        //    拼接第二行
                        if(hasFilter){
                            var filterTh='';
                            var filterPop = col.attr('filter');
                            if(filterPop){
                                var str = filterPop.replace(/'/g,'"');//将所有的 ’  转换成  “
                                var obj = $.parseJSON(str);//将字符串转为obj
                                filterTh+='<th class="st-filter" filter="'+ filterPop+'"';
                                for(var key in obj){
                                    if(obj[key] == 'select'){
                                        filterTh+='filter-data="'+col.attr('filter-data')+'"';
                                    }
                                }
                                filterTh+='>';
                            }else{
                                filterTh+='<th><div ng-style="tdWidthStyle['+idx+']" class="'+divClass+'">';
                            }
                            //如果有过滤
                            if(filterPop){
                                filterTh += '<div ng-style="tdWidthStyle['+idx+']" class="st-filter-cell s12'+divClass;
                                if (idx == colDefines.length - 1) {
                                    filterTh += ' last">';
                                } else {
                                    filterTh += '">';
                                }

                                var str = filterPop.replace(/'/g,'"');//将所有的 ’  转换成  “
                                var obj = $.parseJSON(str);//将字符串转为obj
                                for(var key in obj){
                                    if(obj[key] == 'number'){
                                        filterTh += ('<div st-table-number="'+key+'"></div>');
                                    }
                                    else if(obj[key] == 'startNum'){
                                        filterTh += ('<div st-table-start-num class="sc-start-num" sc-code="'+key+'" ></div>');
                                    }
                                    else if(obj[key] == 'endNum'){
                                        filterTh += ('<div st-table-end-num class="sc-end-num" sc-code="'+key+'" ></div>');
                                    }
                                    else if(!obj[key] || obj[key]== 'text'){
                                        filterTh += ('<div st-table-head-text="' + key + '"></div>');
                                    }else if(obj[key] == 'select'){
                                        if(col.attr('select-model') == 'multiple'){
                                            filterTh += ('<div st-table-multi-select filter-config-data="'+col.attr('filter-data')+'" filter-config-value="'+key+'"></div>');
                                        }else{
                                            filterTh += ('<div st-table-single-select filter-config-data="'+col.attr('filter-data')+'" filter-config-value="'+key+'"></div>');
                                        }
                                    }
                                    else if(obj[key] == 'startDate'){
                                        filterTh += ('<div st-start-date ng-click="$event.stopPropagation()" class="st-start-date" sc-code="'+key+'" ></div>');
                                    }
                                    else if(obj[key] == 'endDate'){
                                        filterTh += ('<div st-end-date ng-click="$event.stopPropagation()" class="st-end-date" sc-code="'+key+'" ></div>');
                                    }
                                }
                            }
                            filterTh += '</div></th>';
                            tableRightTheadFilterTr+=filterTh;
                        }

                        //tbody一行
                        if(col.attr('ex-select')){
                            col.removeAttr('ex-select');
                        }
                        if(col.attr('ex-table-params')){
                            col.removeAttr('ex-table-params');
                        }
                        tableRightTbodyTr+=col[0].outerHTML;
                        temp+=1;
                    }

                    var tableRightHeader =
                        '<div class="ant-table-header">'+
                        '<table class="ant-table-fixed ant-table-right-head" st-table-right-head="displayRows" st-table-right-head-params="params">';
                    if(hasFilter){
                        tableRightTheadFilterTr+='</tr>';
                    }else{
                        tableRightTheadFilterTr = '';
                    }
                    tableRightHeader+='<thead class="ant-table-thead">'+tableRightTheadTitleTr+'</tr>'+tableRightTheadFilterTr+'</thead></table></div>';

                    var tableRightBody =
                        ' <div class="ant-table-body-outer" style="margin-bottom: -10px; padding-bottom: 0px;">' +
                        '<div class="ant-table-body-inner" ng-style="maxHeight" style="overflow-y:scroll;">'+
                        '<table class="ant-table-fixed ant-table-right-body" st-table-right-body="displayRows">';
                    tableRightBody+='<tbody class="ant-table-tbody">'+tableRightTbodyTr+'</tr>'+'</tbody></table></div></div>';

                    tableRight+=tableRightHeader+tableRightBody+'</div>';

                    html+=tableRight;
                }

                html+='</div></div>';

                //分页

                html+='<table class="table table-bordered"><tfoot ng-show="displayRows.length>0 && totalCounts">' +
                    '<tr>' +
                    '<td colspan="' + colDefines.length + '">' +
                    ' <div st-table-pagination="" st-counts="tableCounts" >'+
                    '</div>'+
                    '</td>' +
                    '</tr>' +
                    '</tfoot></table>';

                html+='</div>';
                return html;
            },
            scope:true,
            compile: function(el) {
                return function(scope, element, attrs, controller) {
                    // var param = null;
                    // var noTable = false;
                    // if(attrs.ngNewTable){
                    //     param = attrs.ngNewTable;
                    // }else if(element.attr('my-params')){
                    //     noTable = true;
                    //     param = element.attr('my-params');
                    // }
                    controller.setupBindingsToInternalScope(attrs.ngNewTable,element.attr('my-params'));
                    controller.compileDirectiveTemplates();
                };
            },
            controller: ['$scope', '$compile', '$element', '$parse', function($scope, $compile, $element, $parse) {
                var element = $($element);

                var initTime = 0;
                var nowTime = new Date();
                function rnd(){
                    var random = Math.floor(Math.random()*9+1);
                    return random;
                }
                var r = "";
                for(var i=0;i<3;i++){
                    r+= rnd();
                }
                var tabId = nowTime.getTime()+r;
                element.addClass('ngNewTable').attr('table-id',tabId);

                var tdLen = element.find('tr:first').children('th').length;
                var tdConfirmWidth = 50;
                var arrTdWidth = [];
                var hasWidthTd = [];
                var tds = element.find('.ant-table-center-body').children('tbody').children('tr:first').children('td');
                var confirmW = 60;
                for(var j=0;j<tdLen;j++){
                    var w = parseInt(tds.eq(j).children('div').attr('st-width'));
                    if(w>0){
                        hasWidthTd.push(j);
                    }else{
                        w = confirmW;
                    }
                    var o = {
                        width:w
                    };
                    arrTdWidth[j] = o;
                }

                $scope.tdWidthStyle= arrTdWidth;
                var mh = $element.attr('sc-height')*1>0?$element.attr('sc-height')*1:400;
                $scope.maxHeight = {'max-height':mh};

                $scope.displayRows= [];
                $scope.headTrHeightStyle = [];
                $scope.bodyTrHeightStyle= [];

                $scope.tdBodyWidthStyle = [];
                $scope.tdHeadWidthStyle = [];
                $scope.rows = [];
                $scope.totalCounts = 0;
                $scope.tableCounts = [];
                $scope.selectedArr = [];

                $scope.tableState = {
                    pagination:{
                        start:0,
                        number:10,
                        totalItemCount:0,
                        numberOfPages:0
                    },
                    search:{},
                    sort:{}
                };

                this.scLeftFixedCol = $element.attr('sc-left-fixed-col');
                this.scRightFixedCol = $element.attr('sc-right-fixed-col');

                this.getTableStyle = function(arrBodyTrHeight,arrTdWidth){
                    $scope.bodyTrHeightStyle= [];
                    // $scope.tdBodyWidthStyle = [];
                    if(angular.isArray(arrBodyTrHeight) && arrBodyTrHeight.length>0){
                        $scope.bodyTrHeightStyle = angular.copy(arrBodyTrHeight);
                    }
                    // if(angular.isArray(arrTdWidth) && arrTdWidth.length>0){
                    //     $scope.tdBodyWidthStyle = angular.copy(arrTdWidth);
                    //     contrastStyle();
                    // }
                };

                var getTableHeadHeight = function(){
                    var trs = element.find('.ant-table-center-head').children('thead').children('tr');
                    angular.forEach(trs,function (ele,idx) {
                        var t = jQuery(ele);
                        // var h = t.height();
                        var h = idx==0?31:36;
                        var style = {
                            height:h
                        };
                        $scope.headTrHeightStyle.push(style);
                    });
                };

                var setTable_w_h = function(){
                    var totalW = element.width();
                    $("body").scrollTop(10);//控制滚动条下移10px
                    if( $("body").scrollTop()==0 ){
                        // alert("没有滚动条");
                        totalW -= 10;
                    }
                    $("body").scrollTop(0);

                    var tdLens = tdLen;
                    for(var k=0;k<hasWidthTd.length;k++){
                        totalW = totalW - $scope.tdWidthStyle[hasWidthTd[k]]['width'] - 11;
                        tdLens-=1;
                    }
                    var remainder = totalW%tdLens;//总宽余未指定宽度的列数(tdLens)后的余数,将会加在某一列上
                    totalW = totalW - remainder;
                    var avgW = parseInt(totalW/tdLens)-11;
                    if(avgW>confirmW){
                        var p=1;
                        for(var f=0;f<tdLen;f++){
                            var idx = hasWidthTd.indexOf(f);
                            if(idx==-1){
                                $scope.tdWidthStyle[f]['width'] = avgW;
                                if(p===1){
                                    $scope.tdWidthStyle[f]['width'] = $scope.tdWidthStyle[f]['width']+remainder-1;
                                    p++;
                                }
                            }
                        }
                    }
                    getTableHeadHeight();
                };
                var calcuOnce = 0;
                var setTable = $scope.$watch(function(){
                    return element.width();
                },function(newVal,oldVal){
                    if(newVal>0 && calcuOnce<1){
                        setTable_w_h();
                        calcuOnce+=1;
                    }else if(newVal>0 && newVal>oldVal){
                         setTable_w_h();
                    }

                });

                this.compileDirectiveTemplates = function() {
                    $compile($element.children())($scope);
                };

                this.setupBindingsToInternalScope = function(tableParamsExpr,trParamsExpr) {
                    var hasRepeat = false;
                    if(trParamsExpr && trParamsExpr != '$data'){
                        hasRepeat = true;
                        var trParamsGetter = $parse(trParamsExpr);
                        $scope.$watch(trParamsGetter, (function(params) {
                            if(params && angular.isArray(params)){
                                $scope.displayRows = params;
                                $scope.rows = $scope.displayRows;
                                if($scope.displayRows.length == 0){
                                    $element.find('.ant-table-scroll .ant-table-header').addClass('activeScroll');
                                }
                            }
                        }),false);
                        $timeout(function(){
                            setTable_w_h();
                            $(element).css('visibility','visible');
                            scTableEventsChannel.publishAfterReloadData(ngTable,$scope.displayRows,[],tabId);
                        });
                    }
                    if(!hasRepeat){
                        var tableParamsGetter = $parse(tableParamsExpr);
                        $scope.$watch(tableParamsGetter, (function(params) {
                            if (angular.isUndefined(params)) {
                                return;
                            }
                            $scope.paramsModel = tableParamsGetter;
                            $scope.params = params;
                            $scope.params.stParams = {
                                getSelected:angular.noop,
                                reload:angular.noop
                            };

                            if(params && angular.isArray($scope.params) && $scope.params.length>0){
                                $scope.displayRows = angular.copy($scope.params);
                                $scope.rows = $scope.displayRows;
                            }

                            if(angular.isObject($scope.params)){
                                $scope.params.reload = function(){
                                    $scope.params.stParams.reload();
                                };

                                $scope.params.getSelected = function(){
                                    var arr = [];
                                    for(var r=0;r<$scope.displayRows.length;r++){
                                        if($scope.displayRows[r]['isSelected']){
                                            arr.push(angular.copy($scope.displayRows[r]));
                                        }
                                    }
                                    return arr;
                                };
                            }

                            if(params && angular.isArray($scope.params.counts) && $scope.params.counts.length>0){
                                $scope.tableCounts = angular.copy($scope.params.counts);
                                $scope.tablePageCounts = angular.copy($scope.params.counts);
                            }else{
                                $scope.tableCounts = [10,25,50,100];
                            }

                        }), false);
                    }
                };

                this.setFilterParams = function(filterFiled,filterValue){
                    if(!$scope.params.filter){
                        $scope.params.filter = {};
                    }
                    $scope.params.filter[filterFiled] = filterValue;
                };

                // var getStTableHead = function(){
                //     var ths = element.find('.ant-table-scroll').children('.ant-table-header').children('table').children('thead').children('tr:last-child').children('th');
                //     var arrWidth = [];
                //     angular.forEach(ths,function(ele){
                //         var th = $(ele);
                //         var w = th.width();
                //         w=w>400?400:w<60?60:w;
                //         var style = {
                //             width:w
                //         };
                //         arrWidth.push(style);
                //     });
                //     $scope.tdHeadWidthStyle = arrWidth;
                //     contrastStyle();
                // }

                this.getTableState = function(){
                    return $scope.tableState;
                };

                this.slicePagin = function(pagination){
                    $scope.getData(pagination,"pagination");
                };

                this.sliceSort = function(sort){
                    if(!$scope.tableState.search.predicateObject){
                        $scope.tableState.search.predicateObject = {};
                        $scope.tableState.search.predicateObject.sorts = [sort];
                        $scope.getData($scope.tableState);
                    }else{
                        var sortList = $scope.tableState.search.predicateObject.sorts;
                        if(angular.isArray(sortList) && sortList.length>0){
                            var idx = -1;
                            for(var s=0;s<sortList.length;s++){
                                if(sortList[s].orderBy == sort.orderBy){
                                    // sortList[s].orderType = sort.orderType;
                                    idx = s;
                                    break;
                                }
                            }

                            if(!sort.orderType){
                                if(idx>-1){
                                    sortList.splice(idx,1);
                                }
                            }else{
                                if(idx > -1){
                                    sortList[idx].orderType = sort.orderType;
                                }else{
                                    sortList.push(sort);
                                }
                            }

                        }else if(angular.isArray(sortList) && sortList.length==0){
                            if(sort.orderType){
                                sortList.push(sort);
                            }
                        }else if(!sortList){
                            $scope.tableState.search.predicateObject.sorts = [];
                            $scope.tableState.search.predicateObject.sorts.push(sort);
                        }
                        if(!$scope.tableState.search.predicateObject.sorts || $scope.tableState.search.predicateObject.sorts.length==0){
                            delete $scope.tableState.search.predicateObject['sorts'];
                        }
                        $scope.getData($scope.tableState);
                    }
                };

                this.sliceSearch = function(val,field){
                    if(!$scope.tableState.search.predicateObject){
                        $scope.tableState.search.predicateObject = {};
                    }
                    $scope.tableState.search.predicateObject[field] = val;
                    if(val!==0 && !val || (angular.isArray(val) && val.length==0)){
                        delete $scope.tableState.search.predicateObject[field];
                    }
                    $scope.getData($scope.tableState);
                };

                $scope.getData = function(tableState,pagin){
                    if(pagin == "pagination"){
                        $scope.tableState.pagination = tableState;
                    }else{
                        $scope.tableState.pagination.start = 0;
                        $scope.tableState.search = tableState.search;
                        $scope.tableState.sort = tableState.sort;
                    }
                    $scope.getDataFun($scope.tableState);
                };

                $scope.getDataFun = function(tableState) {
                    return $timeout(function(){
                        if($scope.params){
                            // $scope.tableState = tableState;
                            var pagination = tableState.pagination;
                            // pagin = pagin?pagin:pagination;//第一次没有传入pagin，所以加以判断 第一次可使用tableState.pagination
                            var start = pagination.start || 0; // 是数据下标
                            var number = pagination.number || $scope.tableCounts[0] || 10; // 页面条数
                            var page = start / number + 1; // 页数
                            var par = tableState.search.predicateObject;
                            var orderB = tableState.sort.predicate;
                            var orderT = 'ASC';
                            if(tableState.sort.reverse){
                                orderT = 'DESC';
                            }

                            par = angular.merge({start: start}, par);
                            if(orderB){
                                par = angular.merge({orderBy:orderB,orderType:orderT},par);
                            }

                            //将实例化中的获取count的函数赋给params.count,ng-table实例化中通过函数得到ps  pn，通过params.total函数返回数据总数
                            var params = {};
                            params.count = function(){
                                return number;
                            };
                            params.page = function(){
                                return page;
                            };
                            params.filter = function(){
                                return par;
                            };
                            params.settings = function(obj){
                                if(angular.isArray(obj.counts) && obj.counts.length>0){
                                    $scope.tableCounts = angular.copy(obj.counts);
                                    number = $scope.tableCounts[0];
                                    page = 1;
                                }
                            };

                            params.total = function(t){
                                $scope.totalCounts=t;
                            };

                            if($scope.params.getData && angular.isFunction($scope.params.getData)) {
                                var data = $scope.params.getData(params);
                                if(data && angular.isFunction(data.then)){
                                    data.then(function (result) {
                                        if(initTime<1){
                                            setTable_w_h();
                                            initTime+=1;
                                        }


                                        $timeout(function() {
                                            $(element).css('visibility','visible');
                                            scTableEventsChannel.publishAfterReloadData(ngTable,result,$scope.displayRows,tabId);
                                            $('.exDatepicker').removeClass('active');
                                            $scope.rows = result;
                                            $scope.displayRows = result;
                                            var antHeader = $element.find('.ant-table-scroll .ant-table-header');
                                            if($scope.displayRows.length == 0 && !antHeader.hasClass('activeScroll')){
                                                antHeader.addClass('activeScroll');
                                            }else if($scope.displayRows.length>0 && antHeader.hasClass('activeScroll')){
                                                antHeader.removeClass('activeScroll');
                                            }
                                            // tableState.pagination.numberOfPages = parseInt(params.total / number) + (params.total % number > 0?1 : 0);
                                            var numberOfPages = parseInt($scope.totalCounts / number) + ($scope.totalCounts % number > 0?1 : 0);
                                            if(numberOfPages != $scope.tableState.pagination.numberOfPages){
                                                $scope.tableState.pagination.numberOfPages = numberOfPages;
                                            }
                                            if($scope.tableState.pagination.totalItemCount != $scope.totalCounts){
                                                $scope.tableState.pagination.totalItemCount = $scope.totalCounts;
                                            }
                                            // tableState.pagination.totalItemCount = params.total;
                                            //set the number of pages so the pagination can update
                                        });
                                    });
                                }else if(angular.isArray(data) && data.length>0){
                                    $scope.rows = data;
                                    $scope.displayRows = data;
                                }
                            }
                        }
                    });
                };

                //table滚动联动  hover联动
                var container = element.find('.ant-spin-container');
                var antTableBodyRightInner = container.find('.ant-table-fixed-right').find('.ant-table-body-inner');
                var antTableBodyLeftInner = container.find('.ant-table-fixed-left').find('.ant-table-body-inner');
                var antTableBody = container.find('.ant-table-scroll').find('.ant-table-body');
                var arrScroll = [];
                var scrollWidth = 10;//滚动条宽度

                function cancelSelect(){
                    container.find('.stSelect.active').removeClass('active');
                    container.find(".st-data-pop.active").removeClass('left right active').css({
                        display:'none',
                        top: 0,
                        left: 0,
                        marginLeft:0,
                        marginTop:0,
                    });
                    var datePiker = container.find(".datetime-picker-dropdown");
                    if(datePiker.length>0){
                        datePiker.css('display','none');
                    }
                }

                $(document).on('click',function(){
                    cancelSelect();
                });
                $(window).resize(function(){
                    cancelSelect();
                });
                $(document).scroll(function() {
                    cancelSelect();
                });

                //  中间table绑定滚动事件
                antTableBody.scroll(function(){
                    var idx = arrScroll.indexOf('center');
                    if(idx>-1){
                        arrScroll.splice(idx,1);
                        return;
                    }
                    if(idx==-1){
                        if(arrScroll.indexOf('left')==-1) arrScroll.push('left');
                        if(arrScroll.indexOf('right')==-1) arrScroll.push('right');
                    }
                    var left = this.scrollLeft;
                    var scrollBodyWidth = container.find('.ant-table-body-center').width();
                    var canScrollWidth = container.find('.ant-table-body-center')[0].scrollWidth;
                    var isRightEdge = (left+scrollBodyWidth-scrollWidth-canScrollWidth==0);

                    if(left>0 && !isRightEdge){
                        container.find('.ant-table').removeClass('ant-table-scroll-position-left ant-table-scroll-position-right').addClass('ant-table-scroll-position-middle');
                    }else if(left==0){
                        container.find('.ant-table').removeClass('ant-table-scroll-position-middle ant-table-scroll-position-right').addClass('ant-table-scroll-position-left');
                    }else if(left>0 && isRightEdge){
                        container.find('.ant-table').removeClass('ant-table-scroll-position-middle ant-table-scroll-position-left').addClass('ant-table-scroll-position-right');
                    }
                    container.find('.ant-table-header')[0].scrollLeft = left;
                    var top=this.scrollTop;
                    antTableBodyRightInner.scrollTop(top);
                    antTableBodyLeftInner.scrollTop(top);
                    cancelSelect();
                });
                //  右边table绑定滚动事件
                antTableBodyRightInner.scroll(function(){
                    var idx = arrScroll.indexOf('right');
                    if(idx>-1){
                        arrScroll.splice(idx,1);
                        return;
                    }
                    if(idx==-1){
                        if(arrScroll.indexOf('left')==-1) arrScroll.push('left');
                        if(arrScroll.indexOf('center')==-1) arrScroll.push('center');
                    }
                    var top=this.scrollTop;
                    antTableBody.scrollTop(top);
                    antTableBodyLeftInner.scrollTop(top);
                    cancelSelect();
                });
                //  左边table绑定滚动事件
                antTableBodyLeftInner.scroll(function(){
                    var idx = arrScroll.indexOf('left');
                    if(idx>-1){
                        arrScroll.splice(idx,1);
                        return;
                    }
                    if(idx==-1){
                        if(arrScroll.indexOf('right')==-1) arrScroll.push('right');
                        if(arrScroll.indexOf('center')==-1) arrScroll.push('center');
                    }
                    var top=this.scrollTop;
                    antTableBody.scrollTop(top);
                    antTableBodyRightInner.scrollTop(top);
                    cancelSelect();
                });

                //    中间table绑定鼠标移入移出
                antTableBody.on('mouseenter','.ant-table-row',function (e) {
                    var idx = this.rowIndex;//行号
                    antTableBodyLeftInner.find('.ant-table-tbody').find('.ant-table-row').eq(idx).addClass('ant-table-row-hover').siblings('.ant-table-row-hover').removeClass('ant-table-row-hover');
                    antTableBodyRightInner.find('.ant-table-tbody').find('.ant-table-row').eq(idx).addClass('ant-table-row-hover').siblings('.ant-table-row-hover').removeClass('ant-table-row-hover');
                });
                antTableBody.on('mouseleave','.ant-table-row',function (e) {
                    antTableBodyLeftInner.find('.ant-table-tbody').find('.ant-table-row.ant-table-row-hover').removeClass('ant-table-row-hover');
                    antTableBodyRightInner.find('.ant-table-tbody').find('.ant-table-row.ant-table-row-hover').removeClass('ant-table-row-hover');
                });
                //    右边table绑定鼠标移入移出
                antTableBodyRightInner.on('mouseenter','.ant-table-row',function (e) {
                    var idx = this.rowIndex;//行号
                    antTableBody.find('.ant-table-tbody').find('.ant-table-row').eq(idx).addClass('ant-table-row-hover').siblings('.ant-table-row-hover').removeClass('ant-table-row-hover');
                    antTableBodyLeftInner.find('.ant-table-tbody').find('.ant-table-row').eq(idx).addClass('ant-table-row-hover').siblings('.ant-table-row-hover').removeClass('ant-table-row-hover');
                });
                antTableBodyRightInner.on('mouseleave','.ant-table-row',function (e) {
                    antTableBody.find('.ant-table-tbody').find('.ant-table-row.ant-table-row-hover').removeClass('ant-table-row-hover');
                    antTableBodyLeftInner.find('.ant-table-tbody').find('.ant-table-row.ant-table-row-hover').removeClass('ant-table-row-hover');
                });
                //    左边table绑定鼠标移入移出
                antTableBodyLeftInner.on('mouseenter','.ant-table-row',function (e) {
                    var idx = this.rowIndex;//行号
                    antTableBodyRightInner.find('.ant-table-tbody').find('.ant-table-row').eq(idx).addClass('ant-table-row-hover').siblings('.ant-table-row-hover').removeClass('ant-table-row-hover');
                    antTableBody.find('.ant-table-tbody').find('.ant-table-row').eq(idx).addClass('ant-table-row-hover').siblings('.ant-table-row-hover').removeClass('ant-table-row-hover');
                });
                antTableBodyLeftInner.on('mouseleave','.ant-table-row',function (e) {
                    antTableBodyRightInner.find('.ant-table-tbody').find('.ant-table-row.ant-table-row-hover').removeClass('ant-table-row-hover');
                    antTableBody.find('.ant-table-tbody').find('.ant-table-row.ant-table-row-hover').removeClass('ant-table-row-hover');
                });

                $scope.$on('$destroy', function() {
                    setTable();
                });

            }],
            terminal: true,
            priority: 1001
        }
    }

    /**
     *新popover
     */
    ng.module('ngTable')
        .directive('stDataPop', stDataPop);
    stDataPop.$inject = ['$compile', '$timeout', 'scTableEventsChannel','scPopTableEventsChannel'];
    function stDataPop($compile, $timeout, scTableEventsChannel,scPopTableEventsChannel) {
        return {
            restrict: 'A',
            require:'^ngNewTable',
            scope: {
                // stCounts:'=stCounts'
            },
            link:{
                pre:function(scope,elem,attrs,ctrl){
                    var getRandom = function(){
                        var n = '';
                        for(var i=0;i<5;i++){
                            n = n+Math.ceil(Math.random()*10);
                        }
                    };
                    var span = $(elem);
                    span.on('click',function($event){
                        var div = span.next('.st-data-pop');
                        if(div.length>0 && div.hasClass('active')){
                            div.removeClass('left right active').css({
                                display:'none',
                                top: 0,
                                left: 0,
                                marginLeft:0,
                                marginTop:0,
                            });
                        }else if(div.length>0 && !div.hasClass('active')){
                            var pop = span.parents('.ant-spin-container').find(".st-data-pop.active");
                            if(pop.length>0){
                                pop.removeClass('left right active').css({
                                    display:'none',
                                    top: 0,
                                    left: 0,
                                    marginLeft:0,
                                    marginTop:0,
                                });
                            }
                            var x = span.offset().left;
                            var y = span.offset().top;
                            var w1 = span.outerWidth();
                            var h1 = span.outerHeight();
                            var w2 = span.next().outerWidth();
                            var h2 = span.next().outerHeight();
                            var top = $(window).scrollTop();
                            y=y+h2/2-h1/2-top;
                            div.attr('st-pop-show',2);
                            if(x<300){
                                $(elem).next('.st-data-pop').addClass('right active');
                                $(elem).next('.st-data-pop').css({
                                    left:(x+w1)+'px',
                                    top:y+'px',
                                    marginLeft:'11px',
                                    marginTop:(h2*(-1)+parseInt(h1/2))+'px',
                                    display:'block'
                                });
                            }else{
                                x=x-11;
                                $(elem).next('.st-data-pop').addClass('left active');
                                $(elem).next('.st-data-pop').css({
                                    left:x+'px',
                                    top:y+'px',
                                    marginLeft:w2*(-1)+'px',
                                    marginTop:(h2*(-1)+parseInt(h1/2))+'px',
                                    display:'block'
                                });
                            }
                        }
                    });

                    var content = attrs.stDataContent;

                    var html = '<div class="st-data-pop fade in">'+
                        '<div class="arrow"></div>'+
                        '<h3 class="st-pop-title"></h3>'+
                        '<div class="popover-content">'+content+'</div>'+
                        '</div>';
                    $(elem).after(html);

                    var setPop = scope.$watch(function(){
                        return attrs.stDataContent;
                    },function(newVal){
                        span.next('.st-data-pop').find('.popover-content').html(newVal);
                    });

                    scope.$on('$destroy', function() {
                        setPop();
                    });

                }
            }
        }
    }

    /**
     *新分页pagination
     */
    ng.module('ngTable')
        .directive('stTablePagination', stTablePagination);
    stTablePagination.$inject = ['$compile', '$timeout', 'scTableEventsChannel','scPopTableEventsChannel'];
    function stTablePagination($compile, $timeout, scTableEventsChannel,scPopTableEventsChannel) {
        return {
            restrict: 'A',
            require:'^ngNewTable',
            template:'<nav><ul class="pagination">' +
            '<li><a ng-click="selectPage(1)">首页</a>' +
            '</li><li><a ng-click="selectPage(currentPage - 1)">&lt;</a>' +
            '</li><li><a><page-select></page-select> / {{numPages}}</a>' +
            '</li><li><a ng-click="selectPage(currentPage + 1)">&gt;</a>' +
            '</li><li><a ng-click="selectPage(numPages)">尾页</a></li>' +
            '<li><span style="border-right: 0px;border-top: 0px;border-bottom: 0px;cursor: pointer;padding: 6px 10px;">每页{{stItemsByPage}}条，共{{totalItemCount}}条</span></li>' +
            '</ul>' +
            '</nav>'+
            '<div class="pageBtn btn-group">' +
            '<button type="button" ng-repeat="row in counts" class="btn btn-default" ng-click="setItemsByPage(row)" ng-class="{\'active\':stItemsByPage==row}"><span>{{row}}</span></button>'+
            // '<button type="button" ng-click="stItemsByPage=25" ng-class="{\'active\':stItemsByPage==25}" class="btn btn-default"><span>25</span></button>'+
            // '<button type="button" ng-click="stItemsByPage=50" ng-class="{\'active\':stItemsByPage==50}" class="btn btn-default"><span>50</span></button>'+
            // '<button type="button" ng-click="stItemsByPage=100" ng-class="{\'active\':stItemsByPage==100}" class="btn btn-default"><span>100</span></button>'+
            '</div>',
            scope: {
                stCounts:'=stCounts'
            },
            link:{
                pre:function(scope,elem,attrs,ctrl){
                    scope.counts = [10,25,50,100];
                    scope.stItemsByPage = scope.counts[0];//
                    scope.$watch('stCounts',function(newVal){
                        if(angular.isArray(newVal)){
                            scope.counts = scope.stCounts;
                        }
                        scope.stItemsByPage = scope.counts[0];//
                    });


                    scope.currentPage = 1;
                    scope.pages = [];
                    // scope.pagination = ctrl.getTableState();

                    function redraw(){
                        var paginationState = ctrl.getTableState().pagination;
                        scope.numPages = paginationState.numberOfPages;
                        scope.currentPage = paginationState.start / paginationState.number + 1; // 页数
                        // scope.numPages = Math.ceil(paginationState.totalItemCount/scope.stItemsByPage);
                    }
                    // redraw();

                    scope.$watch(function(){
                        return ctrl.getTableState().pagination;
                    },redraw,true);


                    scope.setItemsByPage = function(num){
                        if (num !== scope.stItemsByPage) {
                            scope.stItemsByPage = num;
                            redraw();
                            scope.selectPage(1);
                        }
                    };

                    scope.selectPage = function (page) {
                        if (page > 0 && page <= scope.numPages) {
                            scope.currentPage = page;
                            var obj = ctrl.getTableState().pagination;
                            obj.start=(page - 1) * scope.stItemsByPage,
                                obj.number=scope.stItemsByPage;
                            ctrl.slicePagin(obj);
                        }
                    };
                }
            }
        }
    }

    /**
     *表格拖拽列排序
     */
    ng.module('ngTable')
        .directive('ngTableDrag', ngTableDrag);
    ngTableDrag.$inject = ['$compile', '$timeout', 'scTableEventsChannel','scPopTableEventsChannel'];
    function ngTableDrag($compile, $timeout, scTableEventsChannel,scPopTableEventsChannel) {
        return {
            restrict: 'A',
            scope: false,
            link: {
                pre: function (scope, elem, attrs, ctrl) {
                    var oneInit = 0;

                    function init(isComponent) {
                        var arrTh = [];//保存初始每个th位置的数组
                        var allThLength = elem.find('thead>tr:first-child').children('th');
                        var totalLeft = 0;
                        elem.find('thead>tr:first-child').children('th').each(function (i, e) {
                            var th = $(e);
                            var left = th.offset().left;
                            var paddingLeft,tw;
                            if(i>0 && i<parseInt(elem.attr('sc-fixed-front-counts'))){
                                left = totalLeft;
                            }

                            if (i>0 && i == parseInt(elem.attr('sc-fixed-front-counts'))) {
                                paddingLeft = arrTh[i - 1]['left'];
                                tw = th.width() + 6;
                                left = paddingLeft + tw;
                            }
                            if(allThLength.length-elem.attr('sc-fixed-tail-counts')-1<i){
                                paddingLeft = arrTh[i-1]['left'];
                                tw = th.width()+6;
                                left = paddingLeft+tw;
                            }
                            arrTh.push({'index': i, 'left': left});
                            totalLeft=arrTh[i].left+th.width()+6;
                        });
                        var thisThIndex = 0;
                        var thisThLeft = 0;//存储当前拖拽层的位置
                        var isMove = false;
                        var scFixedFrontCounts = parseInt(elem.attr('sc-fixed-front-counts'));
                        var scFixedTailCounts = parseInt(elem.attr('sc-fixed-tail-counts'));

                        $(elem).on('mousedown', 'thead>tr:first-child span.dragColumns', function (e) {
                            $('.popover').remove();
                            $('.exDatepicker').removeClass('active');
                            document.body.onselectstart = function () {
                                return false;
                            };
                            var initX = e.pageX - $(this).parents('th').offset().left;
                            var initY = $(this).parents('th').offset().top;//点击的th到页面顶部的距离
                            var thisHtml = $(this).html();//点击的th的html
                            thisThIndex = $(this).parents('th').index();//点击的th的索引
                            thisThLeft = $(this).parents('th').offset().left;//当前拖拽层的位置
                            if (thisThIndex < scFixedFrontCounts) {
                                return;
                            }
                            if (thisThIndex >= arrTh.length - scFixedTailCounts) {
                                return;
                            }
                            // $(this).css({ 'background': '#ccc' });
                            var createDiv = $('<div class="createDiv">' + thisHtml + '</div>');//创建一个div用于拖拽显示
                            var createDivBg = $('<div class="createDivBg"></div>');//创建一个背景，防止复制文字
                            $('body').append(createDiv, createDivBg);//填充到页面中
                            createDiv.css({'top': initY, 'left': $(this).parents('th').offset().left});// 'width': $(this).width(),//设置拖拽层的初始位置
                            $(document).on('mousemove', function (ev) {
                                var x = ev.pageX;
                                if (isComponent) {
                                    x += elem.parent().scrollLeft();
                                }
                                if (Math.abs(x - arrTh[thisThIndex]['left']) > 20) {//如果鼠标移动的位置超过30
                                    isMove = true;
                                    thisThLeft = ev.pageX - createDiv.width() / 2;//刷新当前拖拽层的位置

                                    if (thisThLeft <= elem.find('tr:first-child').children('th').offset().left) {
                                        thisThLeft = elem.find('tr:first-child').children().offset().left;
                                    }
                                    if (thisThLeft >= (elem.find('tr:first-child').children().offset().left + elem.width() - createDiv.width())) {
                                        thisThLeft = elem.find('tr:first-child').children().offset().left + elem.width() - createDiv.width();
                                    }

                                    createDiv.css({'left': thisThLeft, 'top': initY});//拖拽层跟着鼠标走

                                    var newsindex = 0;//循环的最后一个小于thisThLeft的索引
                                    $.each(arrTh, function (index, element) {//遍历数组
                                        var thIndex = element.index;//页面中所有th的索引
                                        var thLeft = elem.find('thead>tr:first-child').children('th').eq(thIndex).offset().left;//页面中所有th到页面右边的距离
                                        // var thLeft = element.left;//页面中所有th到页面右边的距离
                                        if(thIndex == scFixedFrontCounts){
                                            thLeft = arrTh[thIndex].left;
                                        }
                                        if (thisThLeft > thLeft) {
                                            newsindex = thIndex;//循环的最后一个小于thisThLeft的索引
                                        }
                                    });


                                    if (newsindex < scFixedFrontCounts) {
                                        newsindex = scFixedFrontCounts - 1;
                                    }
                                    if (newsindex >= (arrTh.length - scFixedTailCounts)) {
                                        newsindex = arrTh.length - scFixedTailCounts - 1;
                                    }
                                    if (thisThLeft == elem.find('tr:first-child').children().offset().left && newsindex ==  (scFixedFrontCounts-1)) {
                                        elem.children('thead').find('tr:first-child').children().eq(newsindex).css({'border-right': '2px solid red', 'border-left': '1px solid #ddd'}).siblings('th').css({'border-right': '1px solid #ddd'});//给当前th加边框
                                        elem.children('thead').find('tr:last').children().eq(newsindex).css({'border-right': '2px solid red', 'border-left': '1px solid #ddd'}).siblings('th').css({ 'border-right': '1px solid #ddd' });//给当前th加边框
                                        elem.find('tbody tr').each(function (tdindex, tdelement) {//给对应的列td加边框
                                            $(tdelement).find('td').eq(newsindex).css({'border-right': '2px solid red', 'border-left': '1px solid #ddd'}).siblings('td').css({'border-right': '1px solid #ddd'});
                                        });
                                    } else if (newsindex == (arrTh.length - scFixedTailCounts - 1)) {
                                        elem.children('thead').find('tr:first-child').children().eq(newsindex + 1).css({'border-left': '2px solid red'}).siblings('th').css({'border-right': '1px solid #ddd'});//给当前th加边框
                                        elem.children('thead').find('tr:last').children().eq(newsindex+1).css({'border-left': '2px solid red'}).siblings('th').css({ 'border-right': '1px solid #ddd' });//给当前th加边框
                                        elem.find('tbody tr').each(function (tdindex, tdelement) {//给对应的列td加边框
                                            $(tdelement).find('td').eq(newsindex + 1).css({'border-left': '2px solid red'}).siblings('td').css({'border-right': '1px solid #ddd'});
                                        });
                                    } else if (thisThLeft > elem.find('tr:first-child').children().offset().left) {
                                        elem.children('thead').find('tr:first-child').children().eq(newsindex).css({'border-right': '2px solid red', 'border-left': '1px solid #ddd'}).siblings('th').css({'border-right': '1px solid #ddd', 'border-left': '1px solid #ddd'});//给当前th加边框
                                        elem.children('thead').find('tr:last').children().eq(newsindex).css({'border-right': '2px solid red', 'border-left': '1px solid #ddd'}).siblings('th').css({'border-right': '1px solid #ddd' , 'border-left': '1px solid #ddd'});//给当前th加边框
                                        elem.find('tbody tr').each(function (tdindex, tdelement) {//给对应的列td加边框
                                            $(tdelement).find('td').eq(newsindex).css({'border-right': '2px solid red', 'border-left': '1px solid #ddd'}).siblings('td').css({'border-right': '1px solid #ddd', 'border-left': '1px solid #ddd'});
                                        });
                                    }
                                }
                            });
                        });
                        $(document).on('mouseup', function (e) {
                            elem.find('thead>tr').children('th').css({
                                'border-right': '1px solid #ddd',
                                'border-left': '1px solid #ddd'
                            });
                            elem.find('tbody td').css({
                                'border-right': '1px solid #ddd',
                                'border-left': '1px solid #ddd'
                            });
                            $('.createDiv').remove();
                            $('.createDivBg').remove();
                            $(document).unbind('mousemove');
                            if (isMove) {
                                isMove = false;
                                var newsindex = 0;//循环的最后一个小于thisThLeft的索引
                                $.each(arrTh, function (index, element) {
                                    var thIndex = element.index;
                                    var thLeft = elem.find('thead>tr:first-child').children('th').eq(thIndex).offset().left;//页面中所有th到页面右边的距离
                                    // var thLeft = element.left;
                                    if(thIndex == scFixedFrontCounts){
                                        thLeft = arrTh[thIndex].left;
                                    }
                                    if (thisThLeft > thLeft) {
                                        newsindex = thIndex;
                                    }
                                });
                                if (newsindex < scFixedFrontCounts) {
                                    newsindex = scFixedFrontCounts - 1;
                                }
                                if (newsindex >= arrTh.length - scFixedTailCounts) {
                                    newsindex = arrTh.length - scFixedTailCounts - 1;
                                }
                                elem.children().not('tfoot').find('tr').each(function (tdindex, tdelement) {
                                    var newtd = angular.copy($(tdelement).children().eq(thisThIndex));
                                    if (newsindex > thisThIndex) {
                                        if (newsindex != 0) {
                                            // $(tdelement).children().eq(thisThIndex).remove();
                                            // $(tdelement).children().eq(newsindex - 1).after(newtd);
                                            if (newsindex == arrTh.length - parseInt(elem.attr('sc-fixed-tail-counts')) - 1) {
                                                var paddingRight = $(tdelement).children().eq(newsindex).css('padding-right');
                                                $(tdelement).children().eq(thisThIndex).css('padding-right', paddingRight);
                                                $(tdelement).children().eq(newsindex).css('padding-right', '3px');
                                            }
                                            if (thisThIndex == parseInt(elem.attr('sc-fixed-front-counts')) && thisThIndex != newsindex) {
                                                var paddingLeft = $(tdelement).children().eq(thisThIndex).css('padding-left');
                                                $(tdelement).children().eq(thisThIndex + 1).css('padding-left', paddingLeft);
                                                $(tdelement).children().eq(thisThIndex).css('padding-left', '3px');
                                            }
                                            $(tdelement).children().eq(thisThIndex).insertBefore($(tdelement).children().eq(newsindex + 1));
                                        }
                                    } else if (newsindex < thisThIndex) {
                                        if ((newsindex + 2) != arrTh.length) {//如果是最后一个的情况下
                                            // $(tdelement).children().eq(thisThIndex).remove();
                                            var id = newsindex + 1;
                                            // if(newsindex==scFixedFrontCounts){
                                            //     id = newsindex;
                                            // }
                                            if (newsindex == scFixedFrontCounts - 1) {
                                                var paddingLeft = $(tdelement).children().eq(id).css('padding-left');
                                                $(tdelement).children().eq(thisThIndex).css('padding-left', paddingLeft);
                                                $(tdelement).children().eq(id).css('padding-left', '3px');
                                            }
                                            if (thisThIndex == arrTh.length - parseInt(elem.attr('sc-fixed-tail-counts')) - 1 && thisThIndex != newsindex + 1) {
                                                var paddingRight = $(tdelement).children().eq(thisThIndex).css('padding-right');
                                                $(tdelement).children().eq(thisThIndex - 1).css('padding-right', paddingRight);
                                                $(tdelement).children().eq(thisThIndex).css('padding-right', '3px');
                                            }
                                            $(tdelement).children().eq(thisThIndex).insertBefore($(tdelement).children().eq(id));
                                            // $(tdelement).children().eq(id).before(newtd);
                                        }
                                    }
                                });
                                arrTh = [];//重置保存每个th位置的数组
                                elem.find('thead>tr:first-child').children('th').each(function (i, e) {
                                    var th = $(e);
                                    var left = th.offset().left;
                                    var paddingLeft, tw;
                                    if (i>0 && i == parseInt(elem.attr('sc-fixed-front-counts'))) {
                                        paddingLeft = arrTh[i - 1]['left'];
                                        tw = th.width() + 6;
                                        left = paddingLeft + tw;
                                    }
                                    if (allThLength - elem.attr('sc-fixed-tail-counts') - 1 < i) {
                                        paddingLeft = arrTh[i - 1]['left'];
                                        tw = th.width() + 6;
                                        left = paddingLeft + tw;
                                    }
                                    arrTh.push({'index': i, 'left': left});
                                });
                            }
                        });
                    }
                    scTableEventsChannel.onAfterReloadData(function (scTable, newData, oldData,tabId) {
                        var tableId = elem.attr('table-id');
                        if (tabId == tableId && angular.isArray(newData) && newData.length > 0) {
                            $timeout(function () {
                                if (oneInit < 1) {
                                    init();
                                    oneInit += 1;
                                }
                            });
                        }
                    });
                    scPopTableEventsChannel.onAfterReloadData(function(scTable, newData, oldData,tabId) {
                        var popTableId = elem.attr('pop-table-id');
                        if(tabId == popTableId && angular.isArray(newData) && newData.length>0){
                            // $timeout(function(){
                            //     if(oneInit<1){
                            //         init();
                            //         oneInit+=1;
                            //     }
                            // });
                            if(oneInit<1){
                                scope.$watch(function () {
                                    return elem.height();
                                }, function (newVal, oldVal) {
                                    if (oneInit < 1 && newVal > 0) {
                                        init(true);
                                        oneInit += 1;
                                    }
                                });
                            }
                        }
                    });
                }
            }
        }
    }

    /**
     *表格拖拽列宽
     */
    ng.module('ngTable')
        .directive('ngTableDragWidth', ngTableDragWidth);
    ngTableDragWidth.$inject = ['$compile','$timeout','scTableEventsChannel','scPopTableEventsChannel'];
    function ngTableDragWidth($compile,$timeout,scTableEventsChannel,scPopTableEventsChannel){
        return{
            restrict:'A',
            scope:false,
            link:{
                pre:function(scope,elem,attrs,ctrl){
                    var oneInit = 0;
                    var totalColLen=parseInt(elem.children('thead').find('tr:first-child').children().length);

                    var fixedFrontCols = parseInt(elem.attr('sc-fixed-front-counts'));
                    var fixedTailCols = parseInt(elem.attr('sc-fixed-tail-counts'));
                    //计算左距
                    function totalPaddingLeft(row, idx) {
                        var total = 0;
                        for (var k = 0; k < idx; k++) {
                            if (k > 0) {
                                $(row.cells[k]).css('left', (total - 1) + 'px');
                            }
                            total += $(row.cells[k]).outerWidth();

                        }
                        return total;
                    }
                    //计算右距
                    function totalPaddingRight(row, idx) {
                        var total = 0;
                        for (var k = totalColLen - 1; k >= (totalColLen - fixedTailCols); k--) {
                            if (k < totalColLen - 1) {
                                $(row.cells[k]).css('right', (total - 1) + 'px');
                            }
                            total += $(row.cells[k]).outerWidth();
                        }
                        return total;
                    }
                    scope.canMove=false;
                    scope.downTimes = 0;
                    scope.upTimes = 0;
                    scope.createDash = undefined;
                    function init() {
                        var tTD; //用来存储当前更改宽度的Table Cell,避免快速移动鼠标的问题
                        var table = elem[0];
                        var createDash;
                        for (var j = 0; j < table.rows[0].cells.length; j++) {
                            $(table.rows[0].cells[j]).attr('cellTab',j);
                            // table.rows[0].cells[j].onmousedown = function () {
                            //     scope.canMove=true;
                            //     //记录单元格
                            //     tTD = this;
                            //     if(this.style.cursor == 'default'){
                            //         return;
                            //     }
                            //     var cidx = $(this).attr('cellTab');
                            //     if(cidx<fixedFrontCols){
                            //         return;
                            //     }
                            //     if(cidx>=totalColLen-fixedTailCols){
                            //         return;
                            //     }

                            //     if (event.offsetX > tTD.offsetWidth - 5) {
                            //         tTD.mouseDown = true;
                            //         tTD.oldX = event.x;
                            //         tTD.oldWidth = tTD.offsetWidth;
                            //     }
                            //     var nextThId = parseInt($(this).next().attr('cellTab'));//下一个兄弟是被固定的，那么当前是倒数第fixedTailCols+1个
                            //     if(nextThId==totalColLen-fixedTailCols){
                            //         var pr = parseInt($(this).css('padding-right'));
                            //         if(event.offsetX > tTD.offsetWidth - pr - 15){
                            //             tTD.mouseDown = true;
                            //             tTD.oldX = event.x;
                            //             tTD.oldWidth = tTD.offsetWidth;
                            //         }
                            //         $(this).addClass('dragCell');
                            //         scope.createDash = $('<div class="dragTableDash"></div>');
                            //         var initY = $(this).offset().top;
                            //         $('body').append(scope.createDash);
                            //         scope.createDash.css({'top': initY,'height':elem.height(), 'left': $(this).offset().left+$(this).children('span').outerWidth()+5});
                            //     }
                            //     //记录Table宽度
                            //     table = tTD;
                            //     while (table.tagName != 'TABLE') table = table.parentElement;
                            //     tTD.tableWidth = table.offsetWidth;
                            // };
                            // table.rows[0].cells[j].onmouseup = function () {
                            //     var cidx = $(this).attr('cellTab');
                            //     if(cidx<fixedFrontCols){
                            //         return;
                            //     }
                            //     if(cidx>=totalColLen-fixedTailCols){
                            //         return;
                            //     }
                            //     var nextThId = $(this).next().attr('cellTab');//下一个兄弟是被固定的，那么当前是倒数第fixedTailCols+1个
                            //     if(nextThId==totalColLen-fixedTailCols){
                            //         $(this).css('z-index','inherit');
                            //     }
                            //     //结束宽度调整
                            //     if (tTD == undefined) tTD = this;
                            //     tTD.mouseDown = false;
                            //     tTD.style.cursor = 'default';
                            // };
                            // table.rows[0].cells[j].onmousemove = function () {
                            //     var cidx = $(this).attr('cellTab');
                            //     if(cidx<fixedFrontCols){
                            //         return;
                            //     }
                            //     if(cidx>=totalColLen-fixedTailCols){
                            //         return;
                            //     }
                            //     //更改鼠标样式
                            //     if (event.offsetX > this.offsetWidth - 5)
                            //         this.style.cursor = 'col-resize';
                            //     else
                            //         this.style.cursor = 'default';

                                // var nextThId = parseInt($(tTD).next().attr('cellTab'));//下一个兄弟是被固定的，那么当前是倒数第fixedTailCols+1个
                                // var prevThId = parseInt($(tTD).prev().attr('cellTab'));//如果上一个兄弟是被固定的，那么当前是第fixedFrontCols+1个
                                // if(nextThId==totalColLen-fixedTailCols){
                                //     var pr = parseInt($(this).css('padding-right'));
                                //     if(event.offsetX > this.offsetWidth - pr - 15){
                                //         this.style.cursor = 'col-resize';
                                //     }else{
                                //         this.style.cursor = 'default';
                                //     }
                                // }
                                //取出暂存的Table Cell
                                // if (tTD == undefined) tTD = this;
                                // //调整宽度
                                // if (tTD.mouseDown != null && tTD.mouseDown == true && scope.canMove) {
                                //     tTD.style.cursor = 'default';
                                //     if (tTD.oldWidth + (event.x - tTD.oldX) > 0)
                                //         tTD.width = tTD.oldWidth + (event.x - tTD.oldX);
                                //     //调整列宽
                                //     // tTD.style.width = tTD.width;
                                //     tTD.style.cursor = 'col-resize';
                                //     //调整该列中的每个Cell
                                //     table = tTD;
                                //     while (table.tagName != 'TABLE') table = table.parentElement;
                                //
                                //     var totalLen = $(table.rows[0]).children().length;
                                //
                                //     var tTDW = tTD.width;
                                //     if(prevThId==fixedFrontCols-1){
                                //         tTDW = tTDW-parseInt($(tTD).css('padding-left'))+3;
                                //     }
                                //     if(nextThId==totalColLen-fixedTailCols){
                                //         tTDW = tTDW-parseInt($(tTD).css('padding-right'))+3;
                                //     }
                                //     var tw = tTDW < 401 ? (tTDW>40?tTDW:40): 399;
                                //     if(nextThId==totalColLen-fixedTailCols && scope.createDash && scope.createDash[0]){
                                //         scope.createDash.css('left',$(tTD).offset().left+tw+5);
                                //     }
                                //     for (j = 0; j < table.rows.length - 1; j++) {

                                        // if (tTD.cellIndex < fixedFrontCols) {

                                        //     $(table.rows[j].cells[tTD.cellIndex]).css('width', tw);
                                        //     var paddingLeft = totalPaddingLeft(table.rows[j], fixedFrontCols);
                                        //     $(table.rows[j].cells[fixedFrontCols]).css('padding-left', paddingLeft + 'px');
                                        // } else if (tTD.cellIndex >= (totalLen - fixedTailCols)) {
                                        //     $(table.rows[j].cells[tTD.cellIndex]).css('width', tw);
                                        //     var paddingRight = totalPaddingRight(table.rows[j], fixedTailCols);
                                        //     $(table.rows[j].cells[totalLen - fixedTailCols - 1]).css('padding-right', paddingRight + 'px');
                                        // } else {
                                        //     $(table.rows[j].cells[tTD.cellIndex]).children('span').css('width', tw);
                                            // $(table.rows[j].cells[tTD.cellIndex]).children('span').outerWidth(tw);
                                        // }
                                    // }
                                    //调整整个表
                                    // table.width = tTD.tableWidth + (tTD.offsetWidth - tTD.oldWidth);
                                    // table.style.width = table.width;
                                // }
                            // };
                        }
                        $(table).on('mousemove','thead>tr:first-child>th',function(){
                            var cidx = $(this).attr('cellTab');
                            if(cidx<fixedFrontCols){
                                return;
                            }
                            if(cidx>=totalColLen-fixedTailCols){
                                return;
                            }
                            //更改鼠标样式
                            if (event.offsetX > this.offsetWidth - 5)
                                this.style.cursor = 'col-resize';
                            else
                                this.style.cursor = 'default';

                            var nextThId = parseInt($(tTD).next().attr('cellTab'));//下一个兄弟是被固定的，那么当前是倒数第fixedTailCols+1个
                            var prevThId = parseInt($(tTD).prev().attr('cellTab'));//如果上一个兄弟是被固定的，那么当前是第fixedFrontCols+1个
                            if(nextThId==totalColLen-fixedTailCols){
                                var pr = parseInt($(this).css('padding-right'));
                                if(event.offsetX > this.offsetWidth - pr - 15){
                                    this.style.cursor = 'col-resize';
                                }else{
                                    this.style.cursor = 'default';
                                }
                            }
                        });
                        $(table).on('mousedown','thead>tr:first-child>th',function(){
                            $('.popover').remove();
                            $('.exDatepicker').removeClass('active');
                            scope.canMove=true;
                            //记录单元格
                            tTD = this;
                            if(this.style.cursor == 'default'){
                                return;
                            }
                            var cidx = $(this).attr('cellTab');
                            if(cidx<fixedFrontCols){
                                return;
                            }
                            if(cidx>=totalColLen-fixedTailCols){
                                return;
                            }

                            if (event.offsetX > tTD.offsetWidth - 5) {
                                tTD.mouseDown = true;
                                tTD.oldX = event.x;
                                tTD.oldWidth = tTD.offsetWidth;
                            }
                            var nextThId = parseInt($(this).next().attr('cellTab'));//下一个兄弟是被固定的，那么当前是倒数第fixedTailCols+1个
                            var prevThId = parseInt($(this).prev().attr('cellTab'));
                            if(nextThId==totalColLen-fixedTailCols){
                                var pr = parseInt($(this).css('padding-right'));
                                if(event.offsetX > tTD.offsetWidth - pr - 15){
                                    tTD.mouseDown = true;
                                    tTD.oldX = event.x;
                                    tTD.oldWidth = tTD.offsetWidth;
                                }
                                $(this).addClass('dragCell');

                            }
                            scope.createDash = $('<div class="dragTableDash"></div>');
                            var initY = $(this).offset().top;
                            $('body').append(scope.createDash);
                            scope.createDash.css({'top': initY,'height':elem.height(), 'left': $(this).offset().left+$(this).children('span').outerWidth()+5});
                            //记录Table宽度
                            table = tTD;
                            while (table.tagName != 'TABLE') table = table.parentElement;
                            tTD.tableWidth = table.offsetWidth;
                            $(document).on('mousemove', function (ev){
                                // //取出暂存的Table Cell
                                // if (tTD == undefined) tTD = this;
                                //调整宽度
                                if (tTD.mouseDown != null && tTD.mouseDown == true && scope.canMove) {
                                    // tTD.style.cursor = 'default';
                                    if (tTD.oldWidth + (event.x - tTD.oldX) > 0)
                                        // tTD.width = tTD.oldWidth + (event.x - tTD.oldX);
                                    //调整列宽
                                    // tTD.style.width = tTD.width;
                                    tTD.style.cursor = 'col-resize';
                                    //调整该列中的每个Cell
                                    table = tTD;
                                    while (table.tagName != 'TABLE') table = table.parentElement;

                                    var tTDW = parseInt(tTD.oldWidth + (event.x - tTD.oldX));
                                    if(prevThId==fixedFrontCols-1){
                                        tTDW = tTDW-parseInt($(tTD).css('padding-left'))+3;
                                    }
                                    if(nextThId==totalColLen-fixedTailCols){
                                        tTDW = tTDW-parseInt($(tTD).css('padding-right'))+3;
                                    }
                                    var tw = tTDW < 401 ? (tTDW>40?tTDW:40): 399;
                                    if(scope.createDash && scope.createDash[0]){
                                        scope.createDash.css('left',$(tTD).offset().left+tw+5);
                                    }
                                    for (j = 0; j < table.rows.length - 1; j++) {
                                        $(table.rows[j].cells[tTD.cellIndex]).children('span').css('width', tw);
                                    }
                                    //调整整个表
                                    table.width = tTD.tableWidth + (tTD.offsetWidth - tTD.oldWidth);
                                    table.style.width = table.width;
                                }
                            });
                        });
                        $(document).on('mouseup',function(e){
                            $(document).unbind('mousemove');
                            scope.canMove=false;
                            $('.dragTableDash').remove();
                            for (var k = 0; k < table.rows[0].cells.length; k++) {
                                table.rows[0].cells[k].style.cursor = 'default';
                                $(table.rows[0]).find('th.dragCell').removeClass('dragCell');
                            }
                        });
                        // document.onmouseup = function(){
                        //     scope.canMove=false;
                        //     $('.dragTableDash').remove();
                        //     for (var k = 0; k < table.rows[0].cells.length; k++) {
                        //         table.rows[0].cells[k].style.cursor = 'default';
                        //         $(table.rows[0]).find('th.dragCell').removeClass('dragCell');
                        //     }
                        // };
                    }

                    scTableEventsChannel.onAfterReloadData(function (scTable, newData, oldData,tabId) {
                        var tableId = elem.attr('table-id');
                        if (tabId == tableId && angular.isArray(newData) && newData.length > 0) {
                            $timeout(function () {
                                if (oneInit < 1) {
                                    init();
                                    oneInit += 1;
                                }
                            });
                        }
                    });

                    scPopTableEventsChannel.onAfterReloadData(function (scTable, newData, oldData,tabId) {
                        var popTableId = elem.attr('pop-table-id');
                        if (tabId == popTableId && angular.isArray(newData) && newData.length > 0) {
                            $timeout(function () {
                                if (oneInit < 1) {
                                    init();
                                    oneInit += 1;
                                }
                            });
                        }
                    });
                }
            }
        }
    }

    ////////////////////////////////////////////////////////////////

    /*下列：ngtable组件中嵌套的组件，新表格ngNewTable在html中间table头部上继续沿用下列部分组件*/

    /**
     *表格数字过滤
     */
    ng.module('ngTable')
        .directive('scNumSearch', scNumSearch);
    scNumSearch.$inject = ['$compile', '$timeout', 'scTableEventsChannel'];
    function scNumSearch($compile, $timeout, scTableEventsChannel) {
        return {
            restrict: 'A',
            require: '^stTable',
            scope: true,
            template: '<input type="number" ng-model="changeValue" ng-change="getChange(changeValue)" class="input-filter form-control">',
            link: {
                pre: function (scope, elem, attrs, ctrl) {
                    scope.getChange = function (val) {
                        if (angular.isNumber(val)) {
                            scope.changeValue = parseInt(val);
                            ctrl.search(scope.changeValue, attrs.scNumSearch);
                        }
                        if (val === null) {
                            ctrl.search(val, attrs.scNumSearch);
                        }
                    }
                }
            }
        }
    }

    /**
     *表格日前区间过滤--起始
     */
    ng.module('ngTable').directive('scStartDateTime', scStartDateTime);
    scStartDateTime.$inject = [];
    function scStartDateTime() {
        return {
            restrict: 'EA',
            require:'^stTable',
            template: function(element, attrs) {
                var dtFormat = 'yyyy-MM-dd HH:mm';
                if (attrs.scDateFormat) {
                    dtFormat = attrs.scDateFormat;
                }
                var html = '<p class="input-group">' +
                    '<input ng-show="false" type="text" class="form-control" datetime-picker="' + dtFormat + '" ng-model="scModel" is-open="isOpen" datepicker-options="datepickerOptions" timepicker-options="timepickerOptions" />' +
                    '<span ng-show="scModel"  ng-click="openCalendar($event)">{{showTime}}</span>'+
                    '<span ng-show="!scModel" class="glyphicon glyphicon-time" ng-click="openCalendar($event)">' +
                    // '<button type="button" class="btn btn-default" ng-click="openCalendar($event)"><i class="glyphicon glyphicon-time"></i></button>' +
                    '</span>' +
                    '</p>';

                return html;
            },
            scope:true,
            link: {
                pre: linkFn
            }
        };

        function linkFn(scope,element, attrs, ctrl) {
            scope.isOpen = false;
            scope.openCalendar = openCalendar;
            scope.datepickerOptions = {};
            scope.timepickerOptions = {showMeridian: false};

            scope.showTime = null;

            function openCalendar(event) {
                scope.isOpen = !scope.isOpen;
            }

            function formatDateTime(dt) {
                var date = new Date(dt);
                var y = date.getFullYear();
                var m = date.getMonth() + 1;
                m = m < 10 ? ('0' + m) : m;
                var d = date.getDate();
                d = d < 10 ? ('0' + d) : d;
                var h = date.getHours();
                h = h < 10 ? ('0' + h) : h;
                var minute = date.getMinutes();
                minute = minute < 10 ? ('0' + minute) : minute;
                // var seconds = date.getSeconds();
                // seconds = seconds < 10 ? ('0' + seconds) : seconds;
                return y + '-' + m + '-' + d+' '+h+':'+minute;
            }

            var unwatch = scope.$watch('scModel', function(newVal, oldVal) {
                if (!(newVal == undefined && oldVal == undefined)) {
                    if (newVal) {
                        if (angular.isString(newVal)) {
                            scope.scModel = new Date(newVal);
                        } else if (angular.isNumber(newVal)) {
                            scope.scModel = new Date(newVal);
                        }
                        scope.showTime = formatDateTime(newVal);
                    }else{
                        scope.showTime = newVal;
                    }
                }else{
                    scope.showTime = newVal;
                }
                if(newVal){
                    ctrl.search(newVal.getTime(),attrs.scCode);
                }
            });

            scope.$on('$destroy', function() {
                unwatch();
            });
        }
    }

    /**
     *表格日期区间过滤--截止
     */
    ng.module('ngTable').directive('scEndDateTime', scEndDateTime);
    scEndDateTime.$inject = [];
    function scEndDateTime() {
        return {
            restrict: 'EA',
            require:'^stTable',
            template: function(element, attrs) {
                var dtFormat = 'yyyy-MM-dd HH:mm';
                if (attrs.scDateFormat) {
                    dtFormat = attrs.scDateFormat;
                }
                var html = '<span>~</span><p class="input-group">' +
                    '<input ng-show="false" type="text" class="form-control" datetime-picker="' + dtFormat + '" ng-model="scModel" is-open="isOpen" datepicker-options="datepickerOptions" timepicker-options="timepickerOptions" />' +
                    '<span ng-show="scModel"  ng-click="openCalendar($event)">{{showTime}}</span>'+
                    '<span ng-show="!scModel" class="glyphicon glyphicon-time" ng-click="openCalendar($event)">' +
                    // '<button type="button" class="btn btn-default" ng-click="openCalendar($event)"><i class="glyphicon glyphicon-time"></i></button>' +
                    '</span>' +
                    '</p>';

                return html;
            },
            scope:true,
            link: {
                pre: linkFn
            }
        };

        function linkFn(scope,element, attrs, ctrl) {
            scope.isOpen = false;
            scope.openCalendar = openCalendar;
            scope.datepickerOptions = {};
            scope.timepickerOptions = {showMeridian: false};

            function openCalendar(event) {
                scope.isOpen = !scope.isOpen;
            }

            scope.showTime = null;

            function formatDateTime(dt) {
                var date = new Date(dt);
                var y = date.getFullYear();
                var m = date.getMonth() + 1;
                m = m < 10 ? ('0' + m) : m;
                var d = date.getDate();
                d = d < 10 ? ('0' + d) : d;
                var h = date.getHours();
                h = h < 10 ? ('0' + h) : h;
                var minute = date.getMinutes();
                minute = minute < 10 ? ('0' + minute) : minute;
                // var seconds = date.getSeconds();
                // seconds = seconds < 10 ? ('0' + seconds) : seconds;
                return y + '-' + m + '-' + d+' '+h+':'+minute;
            }

            var unwatch = scope.$watch('scModel', function(newVal, oldVal) {
                if (!(newVal == undefined && oldVal == undefined)) {
                    if (newVal) {
                        if (angular.isString(newVal)) {
                            scope.scModel = new Date(newVal);
                        } else if (angular.isNumber(newVal)) {
                            scope.scModel = new Date(newVal);
                        }
                        scope.showTime = formatDateTime(newVal);
                    }else{
                        scope.showTime = newVal;
                    }
                }else{
                    scope.showTime = newVal;
                }
                if(newVal){
                    ctrl.search(newVal.getTime(),attrs.scCode);
                }
            });

            scope.$on('$destroy', function() {
                unwatch();
            });
        }
    }

        /**
     *表格日前区间过滤--起始
     */
    ng.module('ngTable').directive('scStartDate', scStartDate);
    scStartDate.$inject = [];
    function scStartDate() {
        return {
            restrict: 'EA',
            require:'^stTable',
            template: function(element, attrs) {
                var dtFormat = 'yyyy-MM-dd';
                if (attrs.scDateFormat) {
                    dtFormat = attrs.scDateFormat;
                }
                var html = '<p class="input-group">' +
                    '<input ng-show="false" type="text" class="form-control" datetime-picker="' + dtFormat + '"  enable-time="false" ng-model="scModel" is-open="isOpen" datepicker-options="datepickerOptions" timepicker-options="timepickerOptions" />' +
                    '<span ng-show="scModel"  ng-click="openCalendar($event)">{{showTime}}</span>'+
                    '<span ng-show="!scModel" class="glyphicon glyphicon-time" ng-click="openCalendar($event)">' +
                    // '<button type="button" class="btn btn-default" ng-click="openCalendar($event)"><i class="glyphicon glyphicon-time"></i></button>' +
                    '</span>' +
                    '</p>';

                return html;
            },
            scope:true,
            link: {
                pre: linkFn
            }
        };

        function linkFn(scope,element, attrs, ctrl) {
            scope.isOpen = false;
            scope.openCalendar = openCalendar;
            scope.datepickerOptions = {};

            scope.timepickerOptions = {showMeridian: false};

            scope.showTime = null;

            function openCalendar(event) {
                var left = $(element).offset().left;
                if(left<300){
                    if(!scope.isOpen){
                        $(element).addClass('active');
                    }
                }
                // if(scope.isOpen){
                //     $(element).removeClass('active');
                // }
                scope.isOpen = !scope.isOpen;
            }

            scope.$watch('isOpen',function(newVal){
                if(!newVal){
                    $(element).removeClass('active');
                }
            });

            function formatDateTime(dt) {
                var date = new Date(dt);
                var y = date.getFullYear();
                var m = date.getMonth() + 1;
                m = m < 10 ? ('0' + m) : m;
                var d = date.getDate();
                d = d < 10 ? ('0' + d) : d;
                var h = date.getHours();
                h = h < 10 ? ('0' + h) : h;
                var minute = date.getMinutes();
                minute = minute < 10 ? ('0' + minute) : minute;
                // var seconds = date.getSeconds();
                // seconds = seconds < 10 ? ('0' + seconds) : seconds;
                return y + '-' + m + '-' + d;
            }

            var unwatch = scope.$watch('scModel', function(newVal, oldVal) {
                if (!(newVal == undefined && oldVal == undefined)) {
                    if (newVal) {
                        if (angular.isString(newVal)) {
                            scope.scModel = new Date(newVal);
                        } else if (angular.isNumber(newVal)) {
                            scope.scModel = new Date(newVal);
                        }
                        scope.showTime = formatDateTime(newVal);
                    }else{
                        scope.showTime = newVal;
                    }
                }else{
                    scope.showTime = newVal;
                }
                if(newVal){
                    ctrl.search(newVal.getTime(),attrs.scCode);
                }
                if(newVal === null){
                    ctrl.search(null,attrs.scCode);
                }
            });

            scope.$on('$destroy', function() {
                unwatch();
            });
        }
    }

    /**
     *表格日期区间过滤--截止
     */
    ng.module('ngTable').directive('scEndDate', scEndDate);
    scEndDate.$inject = [];
    function scEndDate() {
        return {
            restrict: 'EA',
            require:'^stTable',
            template: function(element, attrs) {
                var dtFormat = 'yyyy-MM-dd';
                if (attrs.scDateFormat) {
                    dtFormat = attrs.scDateFormat;
                }
                var html = '<span>~</span><p class="input-group">' +
                    '<input ng-show="false" type="text" class="form-control" datetime-picker="' + dtFormat + '"  enable-time="false" ng-model="scModel" is-open="isOpen" datepicker-options="datepickerOptions" timepicker-options="timepickerOptions" />' +
                    '<span ng-show="scModel"  ng-click="openCalendar($event)">{{showTime}}</span>'+
                    '<span ng-show="!scModel" class="glyphicon glyphicon-time" ng-click="openCalendar($event)">' +
                    // '<button type="button" class="btn btn-default" ng-click="openCalendar($event)"><i class="glyphicon glyphicon-time"></i></button>' +
                    '</span>' +
                    '</p>';

                return html;
            },
            scope:true,
            link: {
                pre: linkFn
            }
        };

        function linkFn(scope,element, attrs, ctrl) {
            scope.isOpen = false;
            scope.openCalendar = openCalendar;
            scope.datepickerOptions = {
                enableTime: false
            };
            scope.timepickerOptions = {showMeridian: false};

            function openCalendar(event) {
                var left = $(element).offset().left;
                if(left<300){
                    if(!scope.isOpen){
                        $(element).addClass('active');
                    }
                }
                // if(scope.isOpen){
                //     $(element).removeClass('active');
                // }
                scope.isOpen = !scope.isOpen;
            }
            scope.$watch('isOpen',function(newVal){
                if(!newVal){
                    $(element).removeClass('active');
                }
            });

            scope.showTime = null;
            scope.ms = ((((23*60)+59)*60)+59)*1000;


            function formatDateTime(dt) {
                var date = new Date(dt);
                var y = date.getFullYear();
                var m = date.getMonth() + 1;
                m = m < 10 ? ('0' + m) : m;
                var d = date.getDate();
                d = d < 10 ? ('0' + d) : d;
                var h = date.getHours();
                h = h < 10 ? ('0' + h) : h;
                var minute = date.getMinutes();
                minute = minute < 10 ? ('0' + minute) : minute;
                // var seconds = date.getSeconds();
                // seconds = seconds < 10 ? ('0' + seconds) : seconds;
                return y + '-' + m + '-' + d;
            }

            var unwatch = scope.$watch('scModel', function(newVal, oldVal) {
                if (!(newVal == undefined && oldVal == undefined)) {
                    if (newVal) {
                        if (angular.isString(newVal)) {
                            scope.scModel = new Date(newVal);
                        } else if (angular.isNumber(newVal)) {
                            scope.scModel = new Date(newVal);
                        }
                        scope.showTime = formatDateTime(newVal);
                    }else{
                        scope.showTime = newVal;
                    }
                }else{
                    scope.showTime = newVal;
                }
                if(newVal){
                    ctrl.search(newVal.getTime()+scope.ms,attrs.scCode);
                }
                if(newVal === null){
                    ctrl.search(null,attrs.scCode);
                }
            });

            scope.$on('$destroy', function() {
                unwatch();
            });
        }
    }

    /**
     *表格数字区间过滤--起始
     */
    ng.module('ngTable').directive('scStartNum', scStartNum);
    scStartNum.$inject = ['$timeout'];
    function scStartNum($timeout) {
        return {
            restrict:'EA',
            require:'^stTable',
            priority: 1009,
            template:
                '<input type="text" class="form-control"  ng-change="numChanged(num)" ng-model="num">',
            scope: true,
            link: linkFn
        };
        function linkFn(scope,elem,attrs,ctrl){
            scope.numChanged = function(num) {
                var query = {};
                if(num === '' || num === null || num===undefined){
                    query.distinct = '';
                }else{
                    query.distinct = num;
                }
                ctrl.search(query.distinct,attrs.scCode);
            };
        }
    }

    /**
     *表格数字区间过滤--截止
     */
    ng.module('ngTable').directive('scEndNum', scEndNum);
    scEndNum.$inject = ['$timeout'];
    function scEndNum($timeout) {
        return {
            restrict:'EA',
            require:'^stTable',
            priority: 1009,
            template:
                '<span>~</span><input type="text"  class="form-control"  ng-change="numChanged(num)" ng-model="num">',
            scope: true,
            link: linkFn
        };
        function linkFn(scope,elem,attrs,ctrl){
            scope.numChanged = function(num) {
                var query = {};
                if(num === '' || num === null || num===undefined){
                    query.distinct = '';
                }else{
                    query.distinct = num;
                }
                ctrl.search(query.distinct,attrs.scCode);
            };
        }
    }

    /**
     *ngTable中间部分排序
     */
     ng.module('ngTable')
        .directive('stTableSort',stTableSort);
    stTableSort.$inject =['$parse'];
    function stTableSort($parse){
        return{
            restrict:'EA',
            require:'^stTable',
            template:'<span class="sortArrowBottom active"></span><span class="sortArrowTop active"></span>',
            scope:true,
            link:{
                pre:function(scope,elem,attr,ctrl){
                    var sortField = attr.stTableSort;
                    
                    elem.on('click',function(e){
                        e.stopPropagation();
                        var cc = 5;
                        var tr = elem.parent();
                        while(tr[0].localName != 'tr' && cc > 0) {
                        	tr = tr.parent();
                        	cc = cc - 1;
                        }
                        var isSort = elem.attr('class').indexOf('st-table-sort-ascent') > -1 ? 1 : elem.attr('class').indexOf('st-table-sort-descent') > -1 ? 2 : 0;
                        
                        tr.find('b.st-table-sort-ascent[st-table-sort] span').addClass('active');
                        tr.find('b.st-table-sort-descent[st-table-sort] span').addClass('active');
                        tr.find('b.st-table-sort-ascent').removeClass('st-table-sort-ascent');
                        tr.find('b.st-table-sort-descent').removeClass('st-table-sort-descent');
                        
                        if(isSort == 2){
                            isSort = 0;
                            elem.children('span').addClass('active');
                        }else{
                            isSort+=1;
                            elem.children('span').removeClass('active');
                        }
                        
                        var obj = {
                            "orderBy":sortField,
                            "orderType":null
                        };
                        if(isSort == 1){
                            $(this).addClass('st-table-sort-ascent');
                            obj.orderType = "ASC";
                        }
                        if(isSort == 2){
                            $(this).removeClass('st-table-sort-ascent');
                            $(this).addClass('st-table-sort-descent');
                            obj.orderType = "DESC";
                        }
                        if(isSort == 0){
                            $(this).removeClass('st-table-sort-ascent');
                            $(this).removeClass('st-table-sort-descent');
                            obj.orderType = null;
                        }
                        var sorts = [];
                        sorts[0] = obj;
                        if(sorts[0].orderType){
                             ctrl.search(sorts,"sorts");
                        }else{
                            ctrl.search(null,"sorts");
                        }
                    });
                }
            }
        }
    }

    /**
     *ngTable下拉选择过滤多选
     */
    ng.module('ngTable')
        .directive('stSelectMultiple',stSelectMultiple);
    stSelectMultiple.$inject =['$parse'];
    function stSelectMultiple($parse){
        return{
            restrict:'EA',
            require:'^stTable',
            template:function(elem,attrs){
                // return '<select class="form-control" ng-model="selectedOption" ng-change="optionChanged(selectedOption)">'+
                //         '<option value="{{opt[markProdicate]}}" ng-repeat="opt in distinctItems"><input type="checkbox" />{{opt[showProdicate]}}</option>'+
                //         '</select>';
                return '<div class="form-control stSelect" ng-click="showOption($event)"><span>{{selectedOption[showProdicate]}}</span><b></b>'+
                            '<ul ng-click="$event.stopPropagation()">'+
                                '<li ng-repeat="opt in distinctItems"><input type="checkbox" ng-model="opt.check" ng-change="optionChanged(opt,opt.check)">{{opt[showProdicate]}}</li>'+
                            '</ul>'+
                        '</div>';
            },
            // scope:{
            //     // filterConfigData:'&filterConfigData',
            //     // filterConfigValue:'=?filterConfigValue'
            // },
            scope:true,
            link:{
                pre:function(scope,elem,attr,ctrl){
                    scope.isShow = false;

                    scope.distinctItems = [{id:null,title:'All',check:false}];//展示数组
                    scope.showProdicate = 'title';
                    scope.markProdicate = 'id';//初始化配置
                    scope.searchProdicate = attr.filterConfigValue?attr.filterConfigValue:'id';//search字段
                    scope.searchList = [];//多选过滤条件集合

                    //获取显示的数组
                    scope.getArray = function(data){
                        if(angular.isArray(data) && data.length>0){
                            scope.distinctItems = scope.distinctItems.concat(data);
                        }
                        // scope.selectedOption = scope.distinctItems[0];
                        scope.selectedOption = {};
                    };

                    var filterConfigData = $parse(attr.filterConfigData);
                    var result = filterConfigData(scope);
                    if(angular.isArray(result)){
                        scope.getArray(result);
                    }else if((angular.isObject(result) && (angular.isObject(result.promise) || angular.isFunction(result.then)))){
                        var pData = angular.isFunction(result.then) ? result : result.promise;
                        pData.then(function(data){
                            if (!angular.isArray(data) && !angular.isFunction(data) && !angular.isObject(data)) {
                                data = [];
                            }
                            scope.getArray(data);
                        });
                    }
                    scope.showOption = function(e){
                        var div = $(elem);
                        e.stopPropagation();
                        var x = $(elem).offset().left;
                        var y = $(elem).offset().top;
                        var w = $(elem).outerWidth();
                        var h = $(elem).outerHeight();
                        var top = $(window).scrollTop();
                        y=y+h-top;
                        $(elem).find('ul').css({
                            left:x+'px',
                            width:w+'px',
                            top:y+'px'
                        });

                        if(!div.children('.stSelect').hasClass('active')){
                            div.parents('.ng-table-container').find('.stSelect.active').removeClass('active');
                            div.children('.stSelect').addClass('active');
                        }else{
                            div.children('.stSelect').removeClass('active');
                        }
                    };

                    scope.optionChanged = function(selectedOption,checked) {
                        if(selectedOption[scope.markProdicate] === null && checked){
                            scope.searchList = [];
                            scope.selectedOption = scope.distinctItems[0];
                            for(var i=1;i<scope.distinctItems.length;i++){
                                scope.distinctItems[i].check = false;
                            }
                            ctrl.search([],scope.searchProdicate);
                            return;
                        }

                        if(selectedOption[scope.markProdicate] === null && !checked){
                            scope.selectedOption = {};
                            return;
                        }

                        scope.distinctItems[0].check = false;

                        var idx = -1;
                        for(var j=0;j<scope.searchList.length;j++){
                            if(scope.searchList[j][scope.markProdicate] === selectedOption[scope.markProdicate]){
                                idx = j;
                                break;
                            }
                        }

                        if(checked && idx === -1){
                            scope.searchList.push(selectedOption);
                        }else if(!checked && idx>-1){
                            scope.searchList.splice(idx,1);
                        }
                        scope.selectedOption = scope.searchList[0];
                        var distinct = [];
                        for(var k=0;k<scope.searchList.length;k++){
                            if(scope.searchList[k][scope.markProdicate] || scope.searchList[k][scope.markProdicate] == 0){
                                distinct.push(scope.searchList[k][scope.markProdicate]);
                            }
                        }

                        ctrl.search(distinct,scope.searchProdicate);
                    };
                }
            }
        }
    }

    /**
     *ngTable下拉选择过滤单选
     */
    ng.module('ngTable')
        .directive('stSelect',stSelect);
    stSelect.$inject =['$parse'];
    function stSelect($parse){
        return{
            restrict:'EA',
            require:'^stTable',
            template:function(elem,attrs){
                return '<select class="form-control" ng-model="selectedOption" ng-change="optionChanged(selectedOption)" ng-options="opt[markProdicate] as opt[showProdicate] for opt in distinctItems"></select>';
            },
            // scope:{
            //     // filterConfigData:'&filterConfigData',
            //     // filterConfigValue:'=?filterConfigValue'
            // },
            scope:true,
            link:{
                pre:function(scope,element,attr,ctrl){

                    scope.distinctItems = [{id:null,title:'All'}];//展示数组
                    scope.showProdicate = 'title';
                    scope.markProdicate = 'id';//初始化配置
                    scope.searchProdicate = attr.filterConfigValue?attr.filterConfigValue:'id';//search字段

                    //获取显示的数组
                    scope.getArray = function(data){
                        if(angular.isArray(data) && data.length>0){
                            scope.distinctItems = scope.distinctItems.concat(data);
                        }
                        scope.selectedOption = scope.distinctItems[0];
                    };

                    var filterConfigData = $parse(attr.filterConfigData);
                    var result = filterConfigData(scope);
                    if(angular.isArray(result)){
                        scope.getArray(result);
                    }else if((angular.isObject(result) && (angular.isObject(result.promise) || angular.isFunction(result.then)))){
                        var pData = angular.isFunction(result.then) ? result : result.promise;
                        pData.then(function(data){
                            if (!angular.isArray(data) && !angular.isFunction(data) && !angular.isObject(data)) {
                                data = [];
                            }
                            scope.getArray(data);
                        });
                    }

                    scope.optionChanged = function(selectedOption) {
                        var query = {};
                        if(selectedOption === null){
                            query.distinct = '';
                        }else{
                            query.distinct = selectedOption;
                        }
                        ctrl.search(query.distinct,scope.searchProdicate);
                    };
                }
            }
        }
    }

    /**
     * 选择所有行checkbox
     */
    ng.module('ngTable')
        .directive('scNewSelectAll', ['stConfig','scTableEventsChannel','$timeout', function (stConfig,scTableEventsChannel,$timeout) {
            return {
                restrict: 'A',
                require:'^stTable',
                template: '<input type="checkbox">',
                link: function (scope, element, attr, ctrl) {
                    element.bind('click', function () {
                        var ele = jQuery(element);
                        var checked = ele.find('input[type="checkbox"]').prop('checked');
                        ele.closest('table').find('.sc-select-row').each(function(idx, item) {
                            var $item = jQuery(item);
                            if ($item.prop('checked') != checked) {
                                $item.click();
                            }
                        });
                    });

                    var c = 'id';
                    scTableEventsChannel.onAfterReloadData(function(scTable, newData, oldData) {
                        ng.forEach(newData, function(item) {
                            if(item['code']){
                                c = 'code';
                            }
                            ng.forEach(oldData, function (oldItem) {
                                if ((oldItem[c] && item[c] === oldItem[c]) && oldItem.isSelected) {
                                    $timeout(function() {
                                        ctrl.select(item, 'multiple',c);
                                    });
                                }
                            });
                        });
                    });
                }
            };
        }]);

    /**
     * 选择行checkbox
     */
    ng.module('ngTable')
        .directive('scNewSelectRow', ['stConfig', function (stConfig) {
            return {
                restrict: 'A',
                require: '^stTable',
                template: '<input type="checkbox" class="sc-select-row" ng-checked="row.isSelected">',
                scope: {
                    row: '=scNewSelectRow'
                },
                link: function (scope, element, attr, ctrl) {
                    // var mode = attr.stSelectMode || stConfig.select.mode;
                    element.bind('click', function () {
                        scope.$apply(function () {
                            ctrl.select(scope.row, 'multiple');
                        });
                    });

                    scope.$watch('row.isSelected', function (newValue) {
                        if (newValue === true) {
                            element.parent('tr').addClass(stConfig.select.selectedClass);
                        } else {
                            element.parent('tr').removeClass(stConfig.select.selectedClass);
                        }
                    });
                }
            };
        }]);

////////////////////////////////////////////////////////////////

    /*新表格---中间tbody指令*/
    ng.module('ngTable')
        .directive('stTableCenterBody',stTableCenterBody);
    stTableCenterBody.$inject =['$parse','$timeout','scTableEventsChannel'];
    function stTableCenterBody($parse,$timeout,scTableEventsChannel) {
        return{
            restrict:'A',
            require:'^ngNewTable',
            scope:true,
            link:{
                pre:function(scope,elem,attrs,ctrl){

                    var leftFixedCols = attrs.stTableLeftFixed>=0?attrs.stTableLeftFixed:0;
                    var rightFixedCols = attrs.stTableRightFixed>=0?attrs.stTableRightFixed:0;

                    var getConfig = function(){
                        var arrHeight = [];//左侧body tr高度
                        var arrWidth = [];//左侧table td宽度

                        var tr = jQuery(elem).find('tbody').children('tr');
                        angular.forEach(tr,function(ele){
                            var tr = jQuery(ele);
                            var h = tr.height();
                            //暂时定死高33px
                            var style = {
                                height:33
                            };
                            arrHeight.push(style);
                        });

                        ctrl.getTableStyle(arrHeight,arrWidth);

                        jQuery(elem).find("[data-toggle='popover']").popover({
                            placement: function (context, source) {
                                var src = $(source);
                                var position = src.position();

                                if (src.parents('td,th').hasClass('activeRight')) {
                                    return 'left';
                                }

                                if (src.parents('td,th').hasClass('activeLeft')) {
                                    return 'right';
                                }

                                var conW = src.parents('div.ng-table-container').outerWidth();
                                var srcW = src.parents('td,th').outerWidth();
                                var popoverW = 276;

                                var overRight = (position.left + srcW + popoverW) > conW;
                                var overLeft = (position.left - popoverW) < 0;
                                if (overRight && overLeft) {
                                    if (position.top < 110){
                                        return "bottom";
                                    }
                                } else if (overRight) {
                                    return "left";
                                } else {
                                    return "right";
                                }

                                return "top";
                            }
                        });
                    };

                    scTableEventsChannel.onAfterReloadData(function (scTable, newData, oldData,tabId) {
                        var tableId = elem.parents('.ngNewTable').attr('table-id');
                        if (tabId == tableId && angular.isArray(newData) && newData.length > 0) {
                            $timeout(function () {
                                getConfig();
                            });
                        }
                    });
                }
            }
        }
    }

    /**
     * 新表格 中间table头部head选中所有 checkbox（与旧ngTable不同，这里依赖NgNewTable，旧ngTable依赖stTable）
     */
    ng.module('ngTable')
        .directive('stTableCenterHeadSelect', ['stConfig','scTableEventsChannel','$timeout', function (stConfig,scTableEventsChannel,$timeout){
            return{
                restrict:'A',
                require:'^ngNewTable',
                scope:{
                    displayRows:'=?stTableCenterHeadSelect'
                },
                template:'<input type="checkbox" ng-click="selectAll(isSelected)" ng-model="isSelected">',
                link:function(scope, element, attr, ctrl){
                    scope.hasFixedCol = ctrl.scLeftFixedCol*1>0?true:false;
                    scope.isSelected= false;
                    scope.selectAll = function(isSelected){
                        var len = scope.displayRows.length;
                        for(var i=0;i<len;i++){
                            scope.displayRows[i]['isSelected'] = isSelected;
                        }
                    };
                    var c = 'id';
                    scTableEventsChannel.onAfterReloadData(function(scTable, newData, oldData) {
                        if(scope.hasFixedCol)return;//如果有固定左列，该input被覆盖，不应再执行
                        if(newData){
                            scope.isSelected= false;
                        }
                        ng.forEach(newData, function(item) {
                            if(item['code']){
                                c = 'code';
                            }
                            ng.forEach(oldData, function (oldItem) {
                                if ((oldItem[c] && item[c] === oldItem[c]) && oldItem.isSelected) {
                                    $timeout(function() {
                                        item['isSelected'] = true;
                                        // ctrl.select(item, 'multiple',c);
                                    });
                                }
                            });
                        });
                    });
                }
            }
        }]);

    /**
     * 新表格 中间table中部body选中一行 checkbox（与旧ngTable不同，这里依赖NgNewTable，旧ngTable依赖stTable）
     */
    ng.module('ngTable')
        .directive('stTableCenterBodySelect', ['stConfig','scTableEventsChannel','$timeout', function (stConfig,scTableEventsChannel,$timeout){
            return{
                restrict:'A',
                require:'^ngNewTable',
                scope:{
                    row:'=?stTableCenterBodySelect'
                },
                template:'<input type="checkbox" ng-model="row.isSelected">',
                link:function(scope, element, attr, ctrl){
                }
            }
        }]);

    /**
     *新表格中 中间/左边/右边部分 排序
     */
     ng.module('ngTable')
        .directive('stTableCenterSort',stTableCenterSort);
    stTableCenterSort.$inject =['$parse'];
    function stTableCenterSort($parse){
        return{
            restrict:'EA',
            require:'^ngNewTable',
            template:'<span class="sortArrowBottom active"></span><span class="sortArrowTop active"></span>',
            scope:true,
            link:{
                pre:function(scope,elem,attr,ctrl){
                    var isSort = 0;
                    var sortField = attr.stTableCenterSort;

                    elem.on('click',function(e){
                        e.stopPropagation();
                        var tr = elem.parent('tr');
                        tr.find('th.st-table-sort-ascent').removeClass('st-table-sort-ascent');
                        tr.find('th.st-table-sort-descent').removeClass('st-table-sort-descent');

                        if(isSort == 2){
                            isSort = 0;
                            elem.children('span').addClass('active');
                        }else{
                            isSort+=1;
                            elem.children('span').removeClass('active');
                        }
                        var sorts = [];
                        var obj = {
                            "orderBy":sortField,
                            "orderType":null
                        };
                        if(isSort == 1){
                            $(this).addClass('st-table-sort-ascent');
                            obj.orderType = "ASC";
                        }
                        if(isSort == 2){
                            $(this).removeClass('st-table-sort-ascent');
                            $(this).addClass('st-table-sort-descent');
                            obj.orderType = "DESC";
                        }
                        if(isSort == 0){
                            $(this).removeClass('st-table-sort-ascent');
                            $(this).removeClass('st-table-sort-descent');
                            obj.orderType = null;
                        }
                        sorts[0] = obj;
                        if(obj.orderType){
                             ctrl.sliceSort(obj);
                        }else{
                            ctrl.sliceSort(obj);
                        }
                    });
                }
            }
        }
    }

        /**
     *新表格中 中间/左边/右边部分 table 选择框过滤--单选
     */
    ng.module('ngTable')
        .directive('stTableSingleSelect', stTableSingleSelect);
    stTableSingleSelect.$inject = ['$compile', '$timeout', 'scTableEventsChannel','$parse'];
    function stTableSingleSelect($compile, $timeout, scTableEventsChannel,$parse) {
        return {
            restrict: 'A',
            require: '^ngNewTable',
            scope: true,
            template: '<select class="form-control" ng-model="selectedOption" ng-change="optionChanged(selectedOption)" ng-options="opt[markProdicate] as opt[showProdicate] for opt in distinctItems"></select>',
            link: {
                pre: function (scope, elem, attr, ctrl) {

                    scope.distinctItems = [{id:null,title:'All'}];//展示数组
                    scope.showProdicate = 'title';
                    scope.markProdicate = 'id';//初始化配置
                    scope.searchProdicate = attr.filterConfigValue?attr.filterConfigValue:'id';//search字段

                    //获取显示的数组
                    scope.getArray = function(data){
                        if(angular.isArray(data) && data.length>0){
                            scope.distinctItems = scope.distinctItems.concat(data);
                        }
                        scope.selectedOption = scope.distinctItems[0];
                    };

                    var filterConfigData = $parse(attr.filterConfigData);
                    var result = filterConfigData(scope);
                    if(angular.isArray(result)){
                        scope.getArray(result);
                    }else if((angular.isObject(result) && (angular.isObject(result.promise) || angular.isFunction(result.then)))){
                        var pData = angular.isFunction(result.then) ? result : result.promise;
                        pData.then(function(data){
                            if (!angular.isArray(data) && !angular.isFunction(data) && !angular.isObject(data)) {
                                data = [];
                            }
                            scope.getArray(data);
                        });
                    }

                    scope.optionChanged = function(selectedOption) {
                        var query = {};
                        if(selectedOption === null){
                            query.distinct = null;
                        }else{
                            query.distinct = selectedOption;
                        }
                        ctrl.sliceSearch(query.distinct,scope.searchProdicate);
                    };
                }
            }
        }
    }

    /**
     *新表格中 中间/左边/右边部分 table 选择框过滤--多选
     */
    ng.module('ngTable')
        .directive('stTableMultiSelect', stTableMultiSelect);
    stTableMultiSelect.$inject = ['$compile', '$timeout', 'scTableEventsChannel','$parse'];
    function stTableMultiSelect($compile, $timeout, scTableEventsChannel,$parse) {
        return {
            restrict: 'A',
            require: '^ngNewTable',
            scope: true,
            template:function(elem,attrs){
                return '<div class="form-control stSelect" ng-click="showOption($event)"><span title="{{selectedOption[showProdicate]}}">{{selectedOption[showProdicate]}}</span><b></b>'+
                    '<ul ng-click="$event.stopPropagation()">'+
                    '<li ng-repeat="opt in distinctItems"><input type="checkbox" ng-model="opt.check" ng-change="optionChanged(opt,opt.check)">{{opt[showProdicate]}}</li>'+
                    '</ul>'+
                    '</div>';
            },
            link: {
                pre: function (scope, elem, attr, ctrl) {
                    scope.isShow = false;
                    scope.distinctItems = [{id:null,title:'All',check:false}];//展示数组
                    scope.showProdicate = 'title';
                    scope.markProdicate = 'id';//初始化配置
                    scope.searchProdicate = attr.filterConfigValue?attr.filterConfigValue:'id';//search字段
                    scope.searchList = [];//多选过滤条件集合

                    //获取显示的数组
                    scope.getArray = function(data){
                        if(angular.isArray(data) && data.length>0){
                            scope.distinctItems = scope.distinctItems.concat(data);
                        }
                        // scope.selectedOption = scope.distinctItems[0];
                        scope.selectedOption = {};
                    };

                    var filterConfigData = $parse(attr.filterConfigData);
                    var result = filterConfigData(scope);
                    if(angular.isArray(result)){
                        scope.getArray(result);
                    }else if((angular.isObject(result) && (angular.isObject(result.promise) || angular.isFunction(result.then)))){
                        var pData = angular.isFunction(result.then) ? result : result.promise;
                        pData.then(function(data){
                            if (!angular.isArray(data) && !angular.isFunction(data) && !angular.isObject(data)) {
                                data = [];
                            }
                            scope.getArray(data);
                        });
                    }
                    scope.showOption = function(e){
                        var div = $(elem);
                        e.stopPropagation();
                        var x = $(elem).offset().left;
                        var y = $(elem).offset().top;
                        var w = $(elem).outerWidth();
                        var h = $(elem).outerHeight();
                        var top = $(window).scrollTop();
                        y=y+h-top;
                        $(elem).find('ul').css({
                            left:x+'px',
                            minWidth:w+'px',
                            top:y+'px'
                        });
                        // scope.isShow = !scope.isShow;
                        if(!div.children('.stSelect').hasClass('active')){
                            div.parents('.ant-spin-container').find('.stSelect.active').removeClass('active');
                            div.children('.stSelect').addClass('active');
                        }else{
                            div.children('.stSelect').removeClass('active');
                        }
                    };

                    scope.optionChanged = function(selectedOption,checked) {
                        var distinct = [];
                        if(selectedOption[scope.markProdicate] === null && selectedOption[scope.showProdicate]==='All' && checked){
                            scope.searchList = [];
                            scope.selectedOption = scope.distinctItems[0];
                            for(var i=1;i<scope.distinctItems.length;i++){
                                scope.distinctItems[i].check = true;
                                scope.searchList.push(scope.distinctItems[i]);
                                distinct.push(scope.distinctItems[i][scope.markProdicate]);
                            }
                            ctrl.sliceSearch(distinct,scope.searchProdicate);
                            return;
                        }

                        if(selectedOption[scope.markProdicate] === null && selectedOption[scope.showProdicate]==='All' && !checked){
                            scope.selectedOption = {};
                            scope.searchList = [];
                            distinct = [];
                            for(var i=0;i<scope.distinctItems.length;i++){
                                scope.distinctItems[i].check = false;
                            }
                            ctrl.sliceSearch(distinct,scope.searchProdicate);
                            return;
                        }

                        var idx = -1;
                        for(var j=0;j<scope.searchList.length;j++){
                            if(scope.searchList[j][scope.markProdicate] === selectedOption[scope.markProdicate]){
                                idx = j;
                                break;
                            }
                        }

                        if(checked && idx === -1){
                            scope.searchList.push(selectedOption);
                            if(scope.searchList.length === scope.distinctItems.length-1){
                                scope.distinctItems[0].check = true;
                            }
                        }else if(!checked && idx>-1){
                            scope.searchList.splice(idx,1);
                            scope.distinctItems[0].check = false;
                        }
                        //如果第一个对象All被选中，则作为展示元素，否则，以被选中的第一个元素作为展示元素
                        if(!scope.distinctItems[0].check){
                            scope.selectedOption = scope.searchList[0];
                        }else{
                            scope.selectedOption = scope.distinctItems[0];
                        }

                        for(var k=0;k<scope.searchList.length;k++){
                            if(scope.searchList[k][scope.markProdicate] || scope.searchList[k][scope.markProdicate] == 0){
                                distinct.push(scope.searchList[k][scope.markProdicate]);
                            }
                        }

                        ctrl.sliceSearch(distinct,scope.searchProdicate);
                    };
                }
            }
        }
    }

    /**
     * 新表格 表格日期区间过滤--起始
     */
    ng.module('ngTable').directive('stStartDate', stStartDate);
    stStartDate.$inject = ['$timeout'];
    function stStartDate($timeout) {
        return {
            restrict: 'EA',
            require:'^ngNewTable',
            template: function(element, attrs) {
                var dtFormat = 'yyyy-MM-dd';
                if (attrs.scDateFormat) {
                    dtFormat = attrs.scDateFormat;
                }
                var html = '<p class="input-group">' +
                    '<input ng-show="false" type="text" class="form-control" datetime-picker="' + dtFormat + '"  enable-time="false" ng-model="scModel" is-open="isOpen" datepicker-options="datepickerOptions" timepicker-options="timepickerOptions" />' +
                    '<span ng-show="scModel"  ng-click="openCalendar($event)">{{showTime}}</span>'+
                    '<span ng-show="!scModel" class="glyphicon glyphicon-time" ng-click="openCalendar($event)">' +
                    // '<button type="button" class="btn btn-default" ng-click="openCalendar($event)"><i class="glyphicon glyphicon-time"></i></button>' +
                    '</span>' +
                    '</p>';

                return html;
            },
            scope:true,
            link: {
                pre: linkFn
            }
        };

        function linkFn(scope,element, attrs, ctrl) {
            scope.isOpen = false;
            scope.openCalendar = openCalendar;
            scope.datepickerOptions = {};

            scope.timepickerOptions = {showMeridian: false};

            scope.showTime = null;

            function openCalendar(event) {
                var datePiker = element.parents('.ant-spin-container').find(".datetime-picker-dropdown");
                if(datePiker.length>0){
                    datePiker.css('display','none');
                }
                scope.isOpen = !scope.isOpen;
                var left = $(element).offset().left;
                var docWidth = $(document).width();
                var offset = 0;
                if(left<300){
                    if(scope.isOpen){
                        offset = 100;
                    }
                }
                if(docWidth-left<300){
                    offset = -100;
                }

                if(scope.isOpen){
                    $timeout(function(){
                        var x = $(element).offset().left;
                        var y = $(element).offset().top;
                        var w = $(element).outerWidth();
                        var h = $(element).outerHeight();
                        var top = $(window).scrollTop();
                        y=y+h-top;
                        $(element).find('ul.dropdown-menu').css({
                            left:(x+offset)+'px',
                            top:y+'px'
                        });
                    });
                }
            }

            function formatDateTime(dt) {
                var date = new Date(dt);
                var y = date.getFullYear();
                var m = date.getMonth() + 1;
                m = m < 10 ? ('0' + m) : m;
                var d = date.getDate();
                d = d < 10 ? ('0' + d) : d;
                var h = date.getHours();
                h = h < 10 ? ('0' + h) : h;
                var minute = date.getMinutes();
                minute = minute < 10 ? ('0' + minute) : minute;
                // var seconds = date.getSeconds();
                // seconds = seconds < 10 ? ('0' + seconds) : seconds;
                return y + '-' + m + '-' + d;
            }

            var unwatch = scope.$watch('scModel', function(newVal, oldVal) {
                if (!(newVal == undefined && oldVal == undefined)) {
                    if (newVal) {
                        if (angular.isString(newVal)) {
                            scope.scModel = new Date(newVal);
                        } else if (angular.isNumber(newVal)) {
                            scope.scModel = new Date(newVal);
                        }
                        scope.showTime = formatDateTime(newVal);
                    }else{
                        scope.showTime = newVal;
                    }
                }else{
                    scope.showTime = newVal;
                }
                if(newVal){
                    ctrl.sliceSearch(newVal.getTime(),attrs.scCode);
                }
                if(newVal === null){
                    ctrl.sliceSearch(null,attrs.scCode);
                }
            });

            scope.$on('$destroy', function() {
                unwatch();
            });
        }
    }

    /**
     *新表格 表格日期区间过滤--截止
     */
    ng.module('ngTable').directive('stEndDate', stEndDate);
    stEndDate.$inject = ['$timeout'];
    function stEndDate($timeout) {
        return {
            restrict: 'EA',
            require:'^ngNewTable',
            template: function(element, attrs) {
                var dtFormat = 'yyyy-MM-dd';
                if (attrs.scDateFormat) {
                    dtFormat = attrs.scDateFormat;
                }
                var html = '<span>~</span><p class="input-group">' +
                    '<input ng-show="false" type="text" class="form-control" datetime-picker="' + dtFormat + '"  enable-time="false" ng-model="scModel" is-open="isOpen" datepicker-options="datepickerOptions" timepicker-options="timepickerOptions" />' +
                    '<span ng-show="scModel"  ng-click="openCalendar($event)">{{showTime}}</span>'+
                    '<span ng-show="!scModel" class="glyphicon glyphicon-time" ng-click="openCalendar($event)">' +
                    // '<button type="button" class="btn btn-default" ng-click="openCalendar($event)"><i class="glyphicon glyphicon-time"></i></button>' +
                    '</span>' +
                    '</p>';

                return html;
            },
            scope:true,
            link: {
                pre: linkFn
            }
        };

        function linkFn(scope,element, attrs, ctrl) {
            scope.isOpen = false;
            scope.openCalendar = openCalendar;
            scope.datepickerOptions = {
                enableTime: false
            };
            scope.timepickerOptions = {showMeridian: false};

            function openCalendar(event) {
                var datePiker = element.parents('.ant-spin-container').find(".datetime-picker-dropdown");
                if(datePiker.length>0){
                    datePiker.css('display','none');
                }
                scope.isOpen = !scope.isOpen;
                var left = $(element).offset().left;
                var docWidth = $(document).width();
                var offset = 0;
                if(left<300){
                    if(scope.isOpen){
                        offset = 100;
                    }
                }
                if(docWidth-left<300){
                    offset = -100;
                }

                if(scope.isOpen){
                    $timeout(function(){
                        var x = $(element).offset().left;
                        var y = $(element).offset().top;
                        var w = $(element).outerWidth();
                        var h = $(element).outerHeight();
                        var top = $(window).scrollTop();
                        y=y+h-top;
                        $(element).find('ul.dropdown-menu').css({
                            left:(x+offset)+'px',
                            top:y+'px'
                        });
                    });
                }
            }

            scope.showTime = null;
            scope.ms = ((((23*60)+59)*60)+59)*1000;

            function formatDateTime(dt) {
                var date = new Date(dt);
                var y = date.getFullYear();
                var m = date.getMonth() + 1;
                m = m < 10 ? ('0' + m) : m;
                var d = date.getDate();
                d = d < 10 ? ('0' + d) : d;
                var h = date.getHours();
                h = h < 10 ? ('0' + h) : h;
                var minute = date.getMinutes();
                minute = minute < 10 ? ('0' + minute) : minute;
                // var seconds = date.getSeconds();
                // seconds = seconds < 10 ? ('0' + seconds) : seconds;
                return y + '-' + m + '-' + d;
            }

            var unwatch = scope.$watch('scModel', function(newVal, oldVal) {
                if (!(newVal == undefined && oldVal == undefined)) {
                    if (newVal) {
                        if (angular.isString(newVal)) {
                            scope.scModel = new Date(newVal);
                        } else if (angular.isNumber(newVal)) {
                            scope.scModel = new Date(newVal);
                        }
                        scope.showTime = formatDateTime(newVal);
                    }else{
                        scope.showTime = newVal;
                    }
                }else{
                    scope.showTime = newVal;
                }
                if(newVal){
                    ctrl.sliceSearch(newVal.getTime()+scope.ms,attrs.scCode);
                }
                if(newVal === null){
                    ctrl.sliceSearch(null,attrs.scCode);
                }
            });

            scope.$on('$destroy', function() {
                unwatch();
            });
        }
    }


    /**
     *新表格中 左边/右边部分 table 文本过滤
     */
    ng.module('ngTable')
        .directive('stTableHeadText', stTableHeadText);
    stTableHeadText.$inject = ['$compile', '$timeout', 'scTableEventsChannel','$parse'];
    function stTableHeadText($compile, $timeout, scTableEventsChannel,$parse) {
        return {
            restrict: 'A',
            require: '^ngNewTable',
            scope: true,
            template: '<input type="text" ng-model="changeValue" ng-change="getChange(changeValue)" class="input-filter form-control ">',
            link: {
                pre: function (scope, elem, attrs, ctrl) {
                    var field = attrs.stTableHeadText;
                    scope.getChange = function (val) {
                        if(field){
                            scope.changeValue = val;
                            ctrl.setFilterParams(field,val);
                        }
                    }
                }
            }
        }
    }

    /**
     * 新表格中 中间/左边/右边部分 table 数字过滤
     */
    ng.module('ngTable')
        .directive('stTableNumber',stTableNumber);
    stTableNumber.$inject =['$parse'];
    function stTableNumber($parse){
        return{
            restrict:'A',
            require:'^ngNewTable',
            template:'<input type="number" ng-model="changeValue" ng-change="getChange(changeValue)" class="input-filter form-control">',
            scope:true,
            link:{
                pre:function(scope,elem,attrs,ctrl){
                    scope.getChange = function (val) {
                        if (angular.isNumber(val)) {
                            scope.changeValue = parseInt(val);
                            ctrl.sliceSearch(scope.changeValue, attrs.stTableNumber);
                        }
                        if (val === null) {
                            ctrl.sliceSearch(val, attrs.stTableNumber);
                        }
                    }
                }
            }
        }
    }

    /**
     *新表格中 数字区间过滤--起始
     */
    ng.module('ngTable').directive('stTableStartNum', stTableStartNum);
    stTableStartNum.$inject = ['$timeout'];
    function stTableStartNum($timeout) {
        return {
            restrict:'EA',
            require:'^ngNewTable',
            priority: 1009,
            template:
                '<input type="number" class="form-control"  ng-change="numChanged(num)" ng-model="num">',
            scope: true,
            link: linkFn
        };
        function linkFn(scope,elem,attrs,ctrl){
            scope.numChanged = function(num) {
                var query = {};
                if(num === '' || num === null || num===undefined){
                    query.distinct = '';
                }else{
                    query.distinct = num;
                }
                ctrl.sliceSearch(query.distinct,attrs.scCode);
            };
        }
    }

    /**
     *新表格中 数字区间过滤--截止
     */
    ng.module('ngTable').directive('stTableEndNum', stTableEndNum);
    stTableEndNum.$inject = ['$timeout'];
    function stTableEndNum($timeout) {
        return {
            restrict:'EA',
            require:'^ngNewTable',
            priority: 1009,
            template:
                '<span>~</span><input type="number"  class="form-control"  ng-change="numChanged(num)" ng-model="num">',
            scope: true,
            link: linkFn
        };
        function linkFn(scope,elem,attrs,ctrl){
            scope.numChanged = function(num) {
                var query = {};
                if(num === '' || num === null || num===undefined){
                    query.distinct = '';
                }else{
                    query.distinct = num;
                }
                ctrl.sliceSearch(query.distinct,attrs.scCode);
            };
        }
    }

    ////////////////////////////////////////////////////////////////

    /*左固定*/

    /**
     * 新表格中 左边固定部分 头部部分（Head） table指令---暂未使用
     */
    ng.module('ngTable')
        .directive('stTableLeftHead',stTableLeftHead);
    stTableLeftHead.$inject =['$parse','$timeout'];
    function stTableLeftHead($parse,$timeout) {
        return{
            restrict:'EA',
            require:'^ngNewTable',
            // template:'<span class="sortArrowBottom active"></span><span class="sortArrowTop active"></span>',
            scope:true,
            controller: ['$scope', '$compile', '$element', '$parse', function($scope, $compile, $element, $parse) {
                this.search = function(){}
            }],
            link:{
                pre:function(scope,elem,attr,ctrl){

                }
            }
        }
    }

    /**
     * 新表格中 左边固定部分 中部部分（body） table指令---暂未使用
     */
    ng.module('ngTable')
        .directive('stTableLeftBody',stTableLeftBody);
    stTableLeftBody.$inject =['$parse','$timeout','scTableEventsChannel'];
    function stTableLeftBody($parse,$timeout,scTableEventsChannel) {
        return{
            restrict:'A',
            require:'^ngNewTable',
            scope:true,
            link:{
                pre:function(scope,elem,attrs,ctrl){
                    var getConfig = function(){
                        jQuery(elem).find("[data-toggle='popover']").popover({
                            placement: function (context, source) {
                                var src = $(source);
                                var position = src.position();

                                if (src.parents('td,th').hasClass('activeRight')) {
                                    return 'left';
                                }

                                if (src.parents('td,th').hasClass('activeLeft')) {
                                    return 'right';
                                }

                                var conW = src.parents('div.ng-table-container').outerWidth();
                                var srcW = src.parents('td,th').outerWidth();
                                var popoverW = 276;

                                var overRight = (position.left + srcW + popoverW) > conW;
                                var overLeft = (position.left - popoverW) < 0;
                                if (overRight && overLeft) {
                                    if (position.top < 110){
                                        return "bottom";
                                    }
                                } else if (overRight) {
                                    return "left";
                                } else {
                                    return "right";
                                }

                                return "top";
                            }
                        });
                    };

                    // scTableEventsChannel.onAfterReloadData(function (scTable, newData, oldData,tabId) {
                    //     var tableId = elem.parents('.ngNewTable').attr('table-id');
                    //     if (tabId == tableId && angular.isArray(newData) && newData.length > 0) {
                    //         $timeout(function () {
                    //             getConfig();
                    //         });
                    //     }
                    // });

                }
            }
        }
    }

    /**
     * 新表格中 左边固定部分 head部分 table 选择所有行checkbox
     */
    ng.module('ngTable')
        .directive('stTableLeftHeadSelect', ['stConfig','scTableEventsChannel','$timeout', function (stConfig,scTableEventsChannel,$timeout){
            return{
                restrict:'A',
                require:'^ngNewTable',
                scope:{
                    stTableLeftHeadSelect:'=?stTableLeftHeadSelect'
                },
                template:'<input type="checkbox" ng-click="selectAll(isSelected)" ng-model="isSelected">',
                link:function(scope, element, attr, ctrl){
                    scope.isSelected= false;
                    scope.selectAll = function(isSelected){
                        var len = scope.stTableLeftHeadSelect.length;
                        for(var i=0;i<len;i++){
                            scope.stTableLeftHeadSelect[i]['isSelected'] = isSelected;
                        }
                    };
                    var c = 'id';
                    scTableEventsChannel.onAfterReloadData(function(scTable, newData, oldData) {
                        if(newData){
                            scope.isSelected= false;
                        }
                        ng.forEach(newData, function(item) {
                            if(item['code']){
                                c = 'code';
                            }
                            ng.forEach(oldData, function (oldItem) {
                                if ((oldItem[c] && item[c] === oldItem[c]) && oldItem.isSelected) {
                                    $timeout(function() {
                                        item['isSelected'] = true;
                                        // ctrl.select(item, 'multiple',c);
                                    });
                                }
                            });
                        });
                    });
                }
            }
        }]);

    /**
     * 新表格中 左边固定部分 body部分 table 选择当前行checkbox
     */
    ng.module('ngTable')
        .directive('stTableLeftBodySelect', ['stConfig','scTableEventsChannel','$timeout', function (stConfig,scTableEventsChannel,$timeout){
            return{
                restrict:'A',
                require:'^ngNewTable',
                scope:{
                    row:'=stTableLeftBodySelect'
                },
                template:'<input type="checkbox" ng-model="row.isSelected">',
                link:function(scope, element, attr, ctrl){
                    // scope.$watch('row.isSelected', function (newValue) {
                    // ctrl.selectedArr(scope.row,newValue)

                    // });
                }
            }
        }]);

    ////////////////////////////////////////////////////////////////

    /*右固定*/

    /**
     * 新表格中 右边固定部分 头部部分（Head） table指令---暂未使用
     */
    ng.module('ngTable')
        .directive('stTableRightHead',stTableRightHead);
    stTableRightHead.$inject =['$parse','$timeout'];
    function stTableRightHead($parse,$timeout) {
        return{
            restrict:'EA',
            require:'^ngNewTable',
            // template:'<span class="sortArrowBottom active"></span><span class="sortArrowTop active"></span>',
            scope:true,
            controller: ['$scope', '$compile', '$element', '$parse', function($scope, $compile, $element, $parse) {
                this.search = function(){}
            }],
            link:{
                pre:function(scope,elem,attr,ctrl){

                }
            }
        }
    }

    /**
     *新表格中 右边tbody---暂未使用
     */
    ng.module('ngTable')
        .directive('stTableRightBody',stTableRightBody);
    stTableRightBody.$inject =['$parse','$timeout','scTableEventsChannel'];
    function stTableRightBody($parse,$timeout,scTableEventsChannel) {
        return{
            restrict:'A',
            require:'^ngNewTable',
            scope:true,
            link:{
                pre:function(scope,elem,attrs,ctrl){
                    var getConfig = function(){
                        jQuery(elem).find("[data-toggle='popover']").popover({
                            placement: function (context, source) {
                                var src = $(source);
                                var position = src.position();

                                if (src.parents('td,th').hasClass('activeRight')) {
                                    return 'left';
                                }

                                if (src.parents('td,th').hasClass('activeLeft')) {
                                    return 'right';
                                }

                                var conW = src.parents('div.ng-table-container').outerWidth();
                                var srcW = src.parents('td,th').outerWidth();
                                var popoverW = 276;

                                var overRight = (position.left + srcW + popoverW) > conW;
                                var overLeft = (position.left - popoverW) < 0;
                                if (overRight && overLeft) {
                                    if (position.top < 110){
                                        return "bottom";
                                    }
                                } else if (overRight) {
                                    return "left";
                                } else {
                                    return "right";
                                }

                                return "top";
                            }
                        });
                    };

                    // scTableEventsChannel.onAfterReloadData(function (scTable, newData, oldData,tabId) {
                    //     var tableId = elem.parents('.ngNewTable').attr('table-id');
                    //     if (tabId == tableId && angular.isArray(newData) && newData.length > 0) {
                    //         $timeout(function () {
                    //             getConfig();
                    //         });
                    //     }
                    // });

                }
            }
        }
    }

}));




(function(angular, factory) {
    'use strict';
    if (typeof define === 'function' && define.amd) {
        define(['angular'], function(angular) {
            return factory(angular);
        });
    } else {
        return factory(angular);
    }
}(window.angular || null, function(angular) {
    'use strict';

    angular.module("scExportBtn", []).run(run);

    run.$inject = ['$templateCache'];
    function run($templateCache) {
        $templateCache.put('sc-export/download.html',
            '<div class="modal-header">\n' +
            '    <h3 class="modal-title">导出</h3>\n' +
            '</div>\n' +
            '<div class="modal-body">\n' +
            '<div class="row">' +
            '    <div class="col-sm-10"><uib-progressbar class="progress-striped active" value="$ctrl.item.value" max="$ctrl.item.max" type="info"><span style="color:white; white-space:nowrap;">{{$ctrl.item.value}} / {{$ctrl.item.max}}</span></uib-progressbar></div>\n' +
            '    <div class="col-sm-2"><button type="button" class="btn btn-primary" ng-click="$ctrl.item.download()" ng-disabled="$ctrl.item.value < $ctrl.item.max">下载</button></div>\n' +
            '</div>\n' +
            '<div class="row" ng-show="$ctrl.item.errorMsg">' +
            '    <div class="col-sm-12"><div class="alert alert-danger">{{$ctrl.item.errorMsg}}</div></div>' +
            '</div>\n' +
            '<div class="row" ng-show="!$ctrl.item.errorMsg && $ctrl.item.value >= $ctrl.item.max">' +
            '    <div class="col-sm-12"><div class="alert alert-success">文件将保留30分钟，请及时下载</div></div>' +
            '</div>\n' +
            '</div>' +
            '</div>');
    }

    angular.module('scExportBtn').directive('scExport',scExport);
    scExport.$inject = ['$localStorage','scModal','$http','$httpParamSerializer','$interval','iServiceConfig'];
    function scExport($localStorage,scModal,$http,$httpParamSerializer,$interval, iServiceConfig){
        return {
            restrict:'EA',
            priority: 1010,
            template: function(element,attrs){
                var ele = jQuery(element);
                ele.attr('ng-click', 'confirmSubmit()');
                ele.prepend('<span class="glyphicon glyphicon-export" title="导出Excel"></span>');
                ele.attr('sc-export-model', ele.attr('sc-export'));
                ele.removeAttr('sc-export');

                return element.get(0).outerHTML;
            },
            replace: true,
            scope:{
                ngTable:'=scExportModel'
            },
            link:linkFn
        };
        function linkFn(scope,elem,attr,ctrl){
            var templateCode = attr.scExportCode;
            var submitUrl = iServiceConfig.ibusHost + '/web/exportTask/submit';
            var logsUrl = iServiceConfig.ibusHost + '/web/exportTask/logs';
            var downloadUrl = iServiceConfig.ibusHost + '/web/exportTask/download';
            var scheduler = null;
            var taskId = null;
            scope.confirmSubmit = confirmSubmit;
            scope.submitTask = submitTask;
            scope.getTaskgetLog = getTaskgetLog;
            scope.download = download;
            scope.value = 0;
            scope.max = 999;

            function confirmSubmit() {
                scModal.open('templates/scModal/confirmModal.html', {msg: "请确认导出excel"}, submitTask);
            }

            function submitTask(){
                clear();

				var params = scope.ngTable.ngTableParams().filter();
				console.log(params);
				if (scope.ngTable.httpParams) {
					params = angular.merge(scope.ngTable.httpParams, params);
				}
				
				$http.post(submitUrl, {templateCode: templateCode, jsonParam:JSON.stringify(params)})
				    .success(function (data) {
				        scModal.cancel();
				        taskId = data.content;
				        scModal.open('sc-export/download.html', scope);
				        scheduler = $interval(getTaskgetLog, 1000);
				    });
            }

            function getTaskgetLog(){
                $http.post(logsUrl+'?taskId='+taskId)
                    .success(function (data) {
                        if (data.status !== 'SUCCESS') {
                            scope.errorMsg = data.errorMsg;
                            if (scheduler) {
                                $interval.cancel(scheduler);
                                scheduler = null;
                            }
                        } else if (data.content.status === 'EXPORTING') {
                            scope.value = parseInt(data.content.current);
                            scope.max = parseInt(data.content.total);

                            scope.errorMsg = data.errorMsg;
                        } else if (data.content.status === 'SUCCESS') {
                        scope.value = parseInt(data.content.current);
                        scope.max = parseInt(data.content.total);

                            if (scheduler) {
                                $interval.cancel(scheduler);
                                scheduler = null;
                            }
                        } else {
                            scope.errorMsg = data.content.errorMsg;

                            if (scheduler) {
                                $interval.cancel(scheduler);
                                scheduler = null;
                            }
                        }
                    }).fail(function(data) {
                            if (scheduler) {
                                $interval.cancel(scheduler);
                                scheduler = null;
                            }
                    });
            }

            function download(){
                window.open(downloadUrl + '?taskId=' + taskId + '&token=' + $localStorage.authenticationToken);
            }

            function clear() {
                if (scheduler) {
                    $interval.cancel(scheduler);
                    scheduler = null;
                }

                taskId = null;
                scope.value = 0;
                scope.max = 999;
                scope.errorMsg = '';
            }
        }
    }
}));

(function(angular, factory) {
    'use strict';
    if (typeof define === 'function' && define.amd) {
        define(['angular'], function(angular) {
            return factory(angular);
        });
    } else {
        return factory(angular);
    }
}(window.angular || null, function(angular) {
    'use strict';

    angular.module("ngBtnGroup", []);

    angular.module('ngBtnGroup').directive('scBtnGroup',scBtnGroup);
    scBtnGroup.$inject = ['$localStorage','$sce','$http','$httpParamSerializer','$timeout','iServiceConfig'];
    function scBtnGroup($localStorage,$sce,$http,$httpParamSerializer,$timeout, iServiceConfig){
        return {
            restrict:'EA',
            priority: 1009,
            template:
            '<div class="scBtnGroup" ng-click="$event.stopPropagation()">'+
                '<div class="btnHeader">' +
                    '<button type="button" class="btn btn-info " ng-click="showBtnGroup($event)">报表<b></b></button>'+
                '</div>'+
                '<div class="btn-group-vertical" role="group">'+
                    '<button type="button" class="btn btn-default btn-lg" ng-repeat="btn in data track by $index" ng-click="getReport(btn,$event)">' +
                        '{{btn.reportName}}' +
                        '<div class="alert alert-info" role="alert"  ng-click="$event.stopPropagation()">提示：未找到url</div>'+
                    '</button>'+
                '</div>'+
                '<p ng-show="false">' +
                '</p>'+
            '</div>',
            scope:{
                scCode:'=scCode',
                scBuildParam:'&scBuildParam',
                scTransferType:'=?scTransferType'
            },
            link:linkFn
        };
        function linkFn(scope,elem,attr,ctrl){
            scope.data = [];
            if(!attr.scTransferType){
                attr.scTransferType = 'url';
            }
            scope.$reportCode = undefined;

            //请求数据
            scope.getData = function(code){
                var url = iServiceConfig.iwhStrategyHost + '/web/reportConfigRest/list?callback=JSON_CALLBACK&token='+$localStorage.authenticationToken;
                if(code){
                    url = url +'&functionCode='+code;
                }
                $sce.trustAsResourceUrl(url);
                $http.jsonp(url).success(function(data) {
                    if(data.status === 'SUCCESS') {
                        if (angular.isDefined(data.content) && angular.isArray(data.content) && (data.content.length > 0)) {
                            scope.data = data.content;
                        } else {
                            scope.data = [];
                        }
                    }
                });
            };
            if(attr.scCode){
                scope.getData(attr.scCode);
            }else if(scope.scCode){
                scope.getData(scope.scCode);
            }


            scope.jumpList = function(obj,data){
                var o = angular.copy(obj);
                delete o.previewUrl;
                o["datas"] = angular.toJson(data);

                if(attr.scTransferType == 'url'){
                    var url = obj.previewUrl+'?token='+ $localStorage.authenticationToken+'&'+$httpParamSerializer(o);
                    // var url = obj.previewUrl+'?token='+ 'ade880b8-6968-4f23-873f-2e604bd7f625&'+$httpParamSerializer(o);
                    window.open(url);
                }else{
                    var formBtn = $('#formBtn');
                    if(formBtn[0]){
                        formBtn.remove();
                    }
                    var url = obj.previewUrl;
                    if(url.indexOf('?')>-1){
                        url +='&'+$localStorage.authenticationToken;
                    }else{
                        url +='?token='+$localStorage.authenticationToken;
                    }

                    var html='<form id="formBtn" action="'+url+'" method="post" target="_blank'+'">';
                    for(var key in o){
                        html+="<input name='"+key+"'  value='"+o[key]+"'>";
                    }
                    html+='</form>';
                    var p = elem.find('p');
                    if(!p.children('form')[0]){
                        p.append(html);
                    }
                    elem.find('#formBtn').submit();
                }
            };
            scope.getReport = function(obj,event){
                scope.$reportCode = obj.reportCode;
                var e = event || window.event;
                if(obj.previewUrl){
                    var datas = null;
                    if (scope.scBuildParam && angular.isFunction(scope.scBuildParam.then)) {
                        scope.scBuildParam.then(function(data){
                            datas = data;
                            scope.jumpList(obj,datas);
                        });
                    }else if(scope.scBuildParam && angular.isFunction(scope.scBuildParam)) {
                        $timeout(function(){
                            datas = scope.$apply(scope.scBuildParam);
                            scope.jumpList(obj,datas);
                        });

                    }
                }else{
                    var btn = $(e.target);
                    btn.children('div').addClass('active');
                    btn.siblings('button').find('.active').removeClass('active');
                }
            };

            var bgv = elem.find('.btn-group-vertical');
            var slide = false;
            scope.showBtnGroup = function(e){
                e.stopPropagation();
                bgv.slideToggle();
                slide = !slide;
            };

            $(document).on('click',function(){
                if(slide){
                    bgv.slideUp();
                    slide = false;
                }
                elem.children('.scBtnGroup').find('div.active').removeClass('active');
            });
        }
    }
}));

(function(ng) {
    'use strict';

    ng.module('scInput', []);

    ng.module('scInput').directive('scInput', scInput);
    scInput.$inject = [];
    function scInput() {
        return {
            restrict: 'A',
            terminal: true,
            priority: 1001,
            compile: function(ele) {
                var $ele = jQuery(ele);

                var $form = $ele.parents('form');
                var formName = undefined;
                if ($form.length > 0) {
                    // if ($form.attr('name') === undefined) {
                    //     formName = 'form-' + (new Date()).getTime() + Math.random().toString(16).slice(10);
                    //     console.log(formName);
                    //     $form.attr('name', formName);
                    // } else {
                        formName = $form.attr('name');
                    // }
                }

                var validator = $ele.attr('validator');
                if (validator) {
                    var nameAttr = $ele.attr('name');
                    // if (nameAttr === undefined) {
                    //     nameAttr = 'input-' + (new Date()).getTime() + Math.random().toString(16).slice(10);
                    //     $ele.attr('name', nameAttr);
                    // }

                    var split = validator.split(',');
                    if (split.length > 0) {
                        var $div = jQuery('<div class="valid-div" ng-show="' + formName + '.$submitted || ' + formName + '.' + nameAttr + '.$touched"></div>').insertAfter($ele);
                        for (var i = 0; i < split.length; i++) {
                            var rule = split[i].trim();
                            $ele.attr(rule, "");

                            // if (rule === 'required') {
                            //     var displayRule = formName + "." + nameAttr + ".$error.required";
                            //     jQuery('<span class="valid-span" ng-show="' + displayRule + '"><p>必填项</p></span>').appendTo($div);
                            // } else if (rule === 'pos-integer') {
                            //     var displayRule = formName + "." + nameAttr + ".$error.posInteger";
                            //     jQuery('<span class="valid-span" ng-show="' + displayRule + '"><p>需为正整数</p></span>').appendTo($div);
                            // }
                        }
                    }

                    // $ele.attr('ng-model-options', "{updateOn: 'blur'}");
                }

                return function(scope, element, attrs, controller) {
                    controller.compileDirectiveTemplates();
                };
            },
            controller: ['$scope', '$compile', '$element', '$parse', function($scope, $compile, $element, $parse) {
                this.compileDirectiveTemplates = function() {
                    if($element.attr('sc-input') !== undefined){
                        $element.removeAttr('sc-input');

                        $compile(jQuery($element).siblings('div.valid-div'))($scope);

                        $compile($element)($scope);
                    }
                };
            }]
        };
    }

    var INTEGER_REGEXP = /^\d+$/;//正整数验证
    ng.module('scInput').directive('posInteger', function() {
        return {
            require: 'ngModel',
            link: function(scope, elm, attrs, ctrl) {
                ctrl.$validators.posInteger = function(modelValue, viewValue) {
                    if (ctrl.$isEmpty(modelValue)) {
                        elm[0].setCustomValidity("");
                        return true;
                    }

                    if(INTEGER_REGEXP.test(viewValue)){
                        elm[0].setCustomValidity("");
                        return true;
                    }else{
                        elm[0].setCustomValidity("请输入正整数");
                        return false;
                    }
                };
            }
        };
    });

    var PLUS_REGEXP = /^\d+(\.\d)?/;//正数验证
    ng.module('scInput').directive('posPlus', function() {
        return {
            require: 'ngModel',
            link: function(scope, elm, attrs, ctrl) {
                ctrl.$validators.posPlus = function(modelValue, viewValue) {
                    if (ctrl.$isEmpty(modelValue)) {
                        elm[0].setCustomValidity("");
                        return true;
                    }

                    if(PLUS_REGEXP.test(viewValue)){
                        elm[0].setCustomValidity("");
                        return true;
                    }else{
                        elm[0].setCustomValidity("请输入正数");
                        return false;
                    }
                };
            }
        };
    });

    var NONNEGATIVE_REGEXP = /^[1-9]\d*$/;//正整数
    ng.module('scInput').directive('negativePlus', function() {
        return {
            require: 'ngModel',
            link: function(scope, elm, attrs, ctrl) {
                ctrl.$validators.posPlus = function(modelValue, viewValue) {
                    if (ctrl.$isEmpty(modelValue)) {
                        elm[0].setCustomValidity("");
                        return true;
                    }

                    if(NONNEGATIVE_REGEXP.test(viewValue)){
                        elm[0].setCustomValidity("");
                        return true;
                    }else{
                        elm[0].setCustomValidity("请输入正整数");
                        return false;
                    }
                };
            }
        };
    });

}(angular));
(function(angular, factory) {
    'use strict';
    if (typeof define === 'function' && define.amd) {
        define(['angular'], function(angular) {
            return factory(angular);
        });
    } else {
        return factory(angular);
    }
}(window.angular || null, function(angular) {
    'use strict';

    angular.module("scMap", []).run();

    angular.module('scMap').directive('scMap', scMap);
    scMap.$inject = ['$timeout', '$localStorage', '$sce', '$http', '$location', '$state', '$window', 'iServiceConfig'];

    function scMap($timeout, $localStorage, $sce, $http, $location, $state, $window, iServiceConfig) {
        return {
            restrict: 'E',
            template: '<div id="mapContainer"></div>',
            scope: {
                options:'='
            },
            link:{post: linkFn}
        };
        function linkFn(scope, element, attrs, ctrl) {
            var map = new AMap.Map("mapContainer", {
                resizeEnable : true,
                zoom : 17
            });
            // var marker = new AMap.Marker({
            //     map : map,
            //     bubble : true ,
            //     content: '<div class="marker-route marker-marker-bus-from"></div>'  //自定义点标记覆盖物内容,
            // });
            //
            // marker.setLabel({
            //     offset: new AMap.Pixel(20, 0),
            //     content: "我在这里"
            // });
            $scope.$watch("options", function (newValue, oldValue) {
                if ($scope.options && $scope.options.lng && $scope.options.lat){
                    map.setCenter([$scope.options.lng ,$scope.options.lat]);
                    // marker.setPosition([$scope.options.lng ,$scope.options.lat]);
                }
            },true);
        }
    }

}));
(function () {
    'use strict';

    angular
        .module('iservice.base')
        .config(cfpLoadingBarProvider);

    cfpLoadingBarProvider.$inject = ['cfpLoadingBarProvider'];

    function cfpLoadingBarProvider(cfpLoadingBarProvider) {
        cfpLoadingBarProvider.spinnerTemplate = '<div class="ajaxLoadingBox"><span>正在执行，请稍候...</div>';
    }
})();
(function() {
    'use strict';

    angular
        .module('iservice.base')
        .config(toastrConfig);

    toastrConfig.$inject = ['toastrConfig'];

    function toastrConfig(toastrConfig) {
        angular.extend(toastrConfig, {
            allowHtml: true,
            closeButton: false,
            closeHtml: '<button>&times;</button>',
            extendedTimeOut: 1000,
            iconClasses: {
                error: 'toast-error',
                info: 'toast-info',
                success: 'toast-success',
                warning: 'toast-warning'
            },
            positionClass: 'toast-top-center',
            messageClass: 'toast-message',
            onHidden: null,
            onShown: null,
            onTap: null,
            progressBar: false,
            tapToDismiss: true,
            timeOut: 5000,
            titleClass: 'toast-title',
            toastClass: 'toast'
        });
    }
})();

(function() {
    'use strict';

    angular
        .module('iservice.base')
        .config(compileServiceConfig);

    compileServiceConfig.$inject = ['$compileProvider','iServiceConfig'];

    function compileServiceConfig($compileProvider,iServiceConfig) {
        // disable debug data on prod profile to improve performance
        $compileProvider.debugInfoEnabled(iServiceConfig.consoleDebug);

        /*
        If you wish to debug an application with this information
        then you should open up a debug console in the browser
        then call this method directly in this console:

		angular.reloadWithDebugInfo();
		*/
    }
})();

(function() {
    'use strict';

    angular
        .module('iservice.base')
        .config(httpConfig);

    httpConfig.$inject = ['$urlRouterProvider', '$httpProvider', 'httpRequestInterceptorCacheBusterProvider', '$urlMatcherFactoryProvider'];

    function httpConfig($urlRouterProvider, $httpProvider, httpRequestInterceptorCacheBusterProvider, $urlMatcherFactoryProvider) {

        //Cache everything except rest api requests
        httpRequestInterceptorCacheBusterProvider.setMatchlist([/.*api.*/, /.*protected.*/], true);

        $urlRouterProvider.otherwise('/');

        $httpProvider.interceptors.push('requestConfigInterceptor');
        $httpProvider.interceptors.push('errorHandlerInterceptor');
        $httpProvider.interceptors.push('authInterceptor');

        $urlMatcherFactoryProvider.type('boolean', {
            name : 'boolean',
            decode: function(val) { return val === true || val === 'true'; },
            encode: function(val) { return val ? 1 : 0; },
            equals: function(a, b) { return this.is(a) && a === b; },
            is: function(val) { return [true,false,0,1].indexOf(val) >= 0; },
            pattern: /bool|true|0|1/
        });
    }
})();

(function() {
    'use strict';

    angular
        .module('iservice.base')
        .config(localStorageConfig);

    localStorageConfig.$inject = ['$localStorageProvider', '$sessionStorageProvider', 'iServiceConfig'];

    function localStorageConfig($localStorageProvider, $sessionStorageProvider, iServiceConfig) {
        $localStorageProvider.setKeyPrefix(iServiceConfig.appid + '-');
        $sessionStorageProvider.setKeyPrefix(iServiceConfig.appid + '-');
    }
})();

(function() {
    'use strict';

    angular
        .module('iservice.base')
        .factory('stateHandler', stateHandler);

    stateHandler.$inject = ['$rootScope', '$window', '$location', 'iServiceConfig'];

    function stateHandler($rootScope,  $window, $location, iServiceConfig) {
        return {
            initialize: initialize
        };

        function initialize() {
            var history = [];
            var stateChangeStart = $rootScope.$on('$stateChangeStart', function (event, toState, toStateParams, fromState) {
                $rootScope.toState = toState;
                $rootScope.toStateParams = toStateParams;
                $rootScope.fromState = fromState;
                //在状态改变时，把这个函数置空
                $rootScope.focusValidate=undefined;

                // Redirect to a state with an external URL (https://stackoverflow.com/a/30221248/1098564)
                if (toState.external) {
                    event.preventDefault();
                    $window.open(toState.url, '_self');
                }
            });

            var stateChangeSuccess = $rootScope.$on('$stateChangeSuccess',  function(event, toState, toParams, fromState, fromParams) {
                var titleKey = iServiceConfig.appid;

                $rootScope.previousStateName = fromState.name;
                $rootScope.previousStateParams = fromParams;
                history.push({stateName: toState.name, stateParams: toParams});

                // Set the page title key to the one configured in state or use default one
                if (toState.data.pageTitle) {
                    titleKey = toState.data.pageTitle;
                }
                $window.document.title = titleKey;

                if (toState.name !== 'redirect') {
                    var absUrl = $location.absUrl();
                    var url = absUrl;
                    if (absUrl.indexOf('#') > 0) {
                        url = absUrl.substr(0, absUrl.indexOf('#'));
                    }
                }

                //处理input框focus
                setTimeout(function () {
                    var list=jQuery('[sc-focus]');
                    var len=list.length;
                    var inputArray=[];
                    //定义一个让sc-focus下的input focus的函数
                    function inputFocus(i) {
                        //下一个标签的各种情况处理
                        if(list.eq(i).get(0).tagName==='INPUT' || list.eq(i).get(0).tagName==='SELECT'){
                            //如果是,直接让这个标签focus
                            list.eq(i).focus();
                            list.eq(i).select();
                        }else{
                            //如果不是，让该标签内第一个input focus
                            if(list.eq(i).find('input').length===0 && list.eq(i).find('select').length===0){
                                //第一次浏览时此sc-focus下的input都还未加载出来
                                list.eq(i+1).find('input').eq(0).focus();
                                list.eq(i+1).find('input').eq(0).select();
                                list.eq(i+1).find('select').eq(0).focus();
                                list.eq(i+1).find('select').eq(0).select();
                            }else{
                                list.eq(i).find('input').eq(0).focus();
                                list.eq(i).find('input').eq(0).select();
                                list.eq(i).find('select').eq(0).focus();
                                list.eq(i).find('select').eq(0).select();
                            }
                        }
                    }
                    for(var i=0;i<len;i++){
                        inputArray.push(list.eq(i)[0]);
                    }
                    list.keyup(function (e) {
                        if(e.keyCode===13){
                            //因为回车还判定了其他事件，所以先做处理来判断是否要进行以下事件

                            if(jQuery(this).attr('sc-focus')!=='' && $rootScope.focusValidate){
                                var callback= $rootScope.focusValidate();
                                if(!callback){
                                    return;
                                }
                            }

                            //如果值为空，就不让他跳到下一个输入框，也就是终止下面的操作
                            if(e.target.getAttribute('required')===''){
                                if(e.target.value===''){
                                    //值为空的话就不进行跳转操作
                                    return;
                                }
                            }
                            if(e.target.getAttribute('sc-focus')===''){
                                //因为用户选中然后按回车的地方必定是input/select框，而该框上就有sc-focus
                                //所以表明这个带有sc-focus的标签就是input/select
                                // console.log(inputArray.indexOf(e.target),'是否在里面');
                                var index=inputArray.indexOf(e.target); //在数组里的序号、
                                if(index!==-1){
                                    if(index===len-1){
                                        //表示这是最后一个标签了
                                        //判断这个标签是不是input
                                        inputFocus(0);
                                    }else{
                                        //表示这不是最后一个标签，可能在开头，也可能在中间
                                        inputFocus(index+1);
                                    }
                                }
                            }else{
                                //表面该标签不是input，而是div
                                //表明该标签在数组中的哪个位置
                                var serial=inputArray.indexOf(jQuery(this).eq(0)[0]);
                                // console.log(len,'长度');
                                // console.log(serial,'当前位置');
                                if(serial===len-1){
                                    //表明在末尾
                                    //末尾的div不一定只有一个input/select,如果有多个，要现在内部进行跳转
                                    if(jQuery(this).eq(0).find('input').length+jQuery(this).eq(0).find('select').length===1){
                                        //如果只有一个input/select，那就直接跳回去
                                        inputFocus(0);
                                    }else{
                                        //如果不止一个
                                        var extraInputArrays=[];
                                        var extraLists=jQuery(this).find('input');
                                        for(var k=0;k<extraLists.length;k++){
                                            extraInputArrays.push(extraLists.eq(k)[0]);
                                        }
                                        //该focus的input处在数组的哪个位置
                                        var newSerials=extraInputArrays.indexOf(e.target);
                                        if(newSerials===extraInputArrays.length-1){
                                            //代表他是这个小组里的最后一个input，应该跳出该sc-focus到下一个去
                                            inputFocus(0);
                                        }else{
                                            //不是最后一个，跳到该sc-focus的下一个input
                                            extraLists.eq(newSerials+1).focus();
                                        }
                                    }


                                }else{
                                    //表明在中间、开头
                                    if(jQuery(this).eq(0).find('input').length+jQuery(this).eq(0).find('select').length===1){
                                        //表面该sc-focus中就一个input/select，回车时就跳到下一个sc-focus
                                        inputFocus(serial+1);
                                    }else{
                                        //有多个input
                                        //创建一个数组来存放包含多个Input的sc-focus下的input/select
                                        var extraInputArray=[];
                                        var extraList=jQuery(this).find('input');
                                        for(var j=0;j<extraList.length;j++){
                                            extraInputArray.push(extraList.eq(j)[0]);
                                        }
                                        //该focus的input处在数组的哪个位置
                                        var newSerial=extraInputArray.indexOf(e.target);
                                        if(newSerial===extraInputArray.length-1){
                                            //代表他是这个小组里的最后一个input，应该跳出该sc-focus到下一个去
                                            inputFocus(serial+1);
                                        }else{
                                            //不是最后一个，跳到该sc-focus的下一个input
                                            extraList.eq(newSerial+1).focus();
                                        }
                                    }
                                }
                            }
                        }
                    });
                },0);







            });



            $rootScope.$on('$destroy', function () {
                if(angular.isDefined(stateChangeStart) && stateChangeStart !== null){
                    stateChangeStart();
                }
                if(angular.isDefined(stateChangeSuccess) && stateChangeSuccess !== null){
                    stateChangeSuccess();
                }
            });
        }
    }
})();

(function() {
    'use strict';

    angular
        .module('iservice.base')
        .factory('authInterceptor', authInterceptor);

    authInterceptor.$inject = ['$localStorage', '$window'];

    function authInterceptor ($localStorage, $window) {
        var service = {
            request: request
        };

        return service;

        function request (config) {
            config.headers = config.headers || {};
            var token = $localStorage.authenticationToken;

            if (token) {
                config.headers['I-Authorization-Token'] = token;
            }
            config.headers['I-Origin'] = $window.location.href;

            return config;
        }
    }
})();

(function() {
    'use strict';

    angular
        .module('iservice.base')
        .factory('errorHandlerInterceptor', errorHandlerInterceptor);

    errorHandlerInterceptor.$inject = ['$q', '$rootScope','$localStorage', '$window', 'iServiceConfig', '$timeout'];

    function errorHandlerInterceptor ($q, $rootScope, $localStorage, $window, iServiceConfig, $timeout) {
        var service = {
            response: responseNormal,
            responseError: responseError
        };

        return service;

        function responseNormal (response) {
            if(response.status < 400) {
                if(response.status === 200) {
                    if (response.data.status === 'ERROR') {
                        if (response.data.errorCode === '1201') {
                            delete $localStorage.authenticationToken;
                        }

                        if(response.data.content && response.data.content.redirectUrl) {
                            var href = $window.location.href;
                            href = href.replace(/(\?|&)token=.+/, '');
                            var url = response.data.content.redirectUrl.replace(/\?redirectUrl=.+/g, '?redirectUrl=' + href);
                            // 消除页面
                            $rootScope.$destroy();
                            $timeout(function(){
                                $window.location.assign(url);
                            }, 0);
                        } else {
                            $rootScope.$emit(iServiceConfig.appid + '.httpError', response);
                            return $q.reject(response);
                        }
                    }
                }

                return response;
            } else {
                if (!(response.status === 401 && (response.data === '' || (response.data.path && response.data.path.indexOf('/api/account') === 0 )))) {
                    $rootScope.$emit(iServiceConfig.appid + '.httpError', response);
                }

                return $q.reject(response);
            }
        }

        function responseError (response) {
        	if (response.status === -1 && (response.config && response.config.url.indexOf('/api/account') > -1 ) && window.location.href.indexOf('login') > -1 ) {
        		return $q.reject(response);
        		return;
        	} else if (response.status === -1) {
                $rootScope.$emit(iServiceConfig.appid + '.httpError', {data: {errorMsg: "连接不上服务器"}});
            } else if (response.status >= 500) {
                $rootScope.$emit(iServiceConfig.appid + '.httpError', {data: {errorMsg: "服务器异常，请联系管理员"}});
            }


            return $q.reject(response);
        }
    }
})();

(function() {
    'use strict';

    angular
        .module('iservice.base')
        .factory('requestConfigInterceptor', requestConfigInterceptor);

    requestConfigInterceptor.$inject = ['$q', '$rootScope', 'iServiceConfig'];

    function requestConfigInterceptor ($q, $rootScope, iServiceConfig) {
        var service = {
            request: requestConfig
        };

        return service;

        function requestConfig (config) {
            config.timeout = iServiceConfig.httpTimeout? iServiceConfig.httpTimeout: 10000;
            return config;
        }
    }
})();
(function() {
    'use strict';

    angular
        .module('iservice.base')
        .factory('enumService', enumService);

    enumService.$inject = ['$resource','iServiceConfig', '$timeout'];

    function enumService ($resource, iServiceConfig, $timeout) {
        var resource = $resource('api/enum', {}, {
            'all': {url: iServiceConfig.iergateHost + '/api/enum/all', method: 'GET'},
            'common': {url: iServiceConfig.iergateHost + '/api/enum/common', method: 'GET'}
        });

        var service = {
            init: init
        };

        return service;

        function init() {
            return new Promise(function(resolve, reject) {
                resource.common({}, {}, function(data) {
                    resolve(function(enumName) {
                        var item = data.content[enumName];
                        if (!angular.isArray(item)) {
                            item = [];
                        }

                        return _buildEnum(item);
                    });
                }, function() {
                    resolve(function(enumName) {
                        return _buildEnum([]);
                    });
                });
            });
        }

        function _buildEnum(item) {
            if (!angular.isArray(item)) {
                item = [];
            }

            var valueMap = {};
            var nameMap = {};
            angular.forEach(item, function(elem) {
                valueMap[elem.value] = elem;
                nameMap[elem.name] = elem;
            });

            return {
                label: function(value) {
                    if (valueMap[value]) {
                        return valueMap[value].label;
                    }

                    return undefined;
                },

                value: function(name) {
                    if (nameMap[name]) {
                        return nameMap[name].value;
                    }

                    return undefined;
                },

                list: function(labelField, valueField) {
                    var tmpList = [];
                    var labelField = labelField? labelField: 'label';
                    var valueField = valueField? valueField: 'value';
                    angular.forEach(item, function(elem) {
                        var obj = {};
                        obj[labelField] = elem.label;
                        obj[valueField] = elem.value;
                        tmpList.push(obj);
                    });

                    return tmpList;
                }
            };
        }
    }
})();
(function() {
    'use strict';

    angular
        .module('iservice.base')
        .factory('SSOAccount', SSOAccount);

    SSOAccount.$inject = ['$resource', 'iServiceConfig'];

    function SSOAccount ($resource, iServiceConfig) {
        var service = $resource(iServiceConfig.iuserHost + '/sso/account', {}, {
            'get': { method: 'GET', params: {}, isArray: false,
                interceptor: {
                    response: function(response) {
                        return response;
                    }
                }
            }
        });

        return service;
    }
})();
(function() {
    'use strict';

    angular
        .module('iservice.base')
        .factory('SSOAuth', SSOAuth);

    SSOAuth.$inject = ['$sessionStorage', '$state', 'Principal', '$rootScope', '$location', '$localStorage', '$window'];

    function SSOAuth ($sessionStorage, $state, Principal, $rootScope, $location, $localStorage, $window) {
        var service = {
            authorize: authorize,
            getPreviousState: getPreviousState,
            resetPreviousState: resetPreviousState,
            storePreviousState: storePreviousState
        };

        return service;

        function authorize () {
            var queryParams = $location.search();
            if (queryParams && queryParams.token) {
                $localStorage.authenticationToken = queryParams.token;
            }

            var reqParam = $window.location.href;

            var authReturn = Principal.identity(reqParam).then(authThen);

            return authReturn;

            function authThen () {
                var isAuthenticated = Principal.isAuthenticated();

                // recover and clear previousState after external login redirect (e.g. oauth2)
                if (isAuthenticated && !$rootScope.fromState.name && getPreviousState()) {
                    var previousState = getPreviousState();
                    resetPreviousState();
                    $state.go(previousState.name, previousState.params);
                }
            }
        }

        function getPreviousState() {
            var previousState = $sessionStorage.previousState;
            return previousState;
        }

        function resetPreviousState() {
            delete $sessionStorage.previousState;
        }

        function storePreviousState(previousStateName, previousStateParams) {
            var previousState = { "name": previousStateName, "params": previousStateParams };
            $sessionStorage.previousState = previousState;
        }
    }
})();

(function() {
    'use strict';

    angular
        .module('iservice.base')
        .factory('Principal', Principal);

    Principal.$inject = ['$q', 'SSOAccount'];

    function Principal ($q, Account) {
        var _identity,
            _authenticated = false;

        var service = {
            authenticate: authenticate,
            hasAnyAuthority: hasAnyAuthority,
            hasAuthority: hasAuthority,
            identity: identity,
            isAuthenticated: isAuthenticated,
            isIdentityResolved: isIdentityResolved
        };

        return service;

        function authenticate (identity) {

        }

        function hasAnyAuthority (authorities) {
            return true;
        }

        function hasAuthority (authority) {
            return true;
        }

        function identity (reqParam) {
            var deferred = $q.defer();

            if (reqParam && reqParam.force === true) {
                _identity = undefined;
            }

            if (angular.isDefined(_identity)) {
                deferred.resolve(_identity);

                return deferred.promise;
            }

            if (reqParam) {
                Account.get(reqParam).$promise
                    .then(getAccountThen)
                    .catch(getAccountCatch);
            } else {
                Account.get().$promise
                    .then(getAccountThen)
                    .catch(getAccountCatch);
            }

            return deferred.promise;

            function getAccountThen (account) {
                _identity = account.data.content;
                _authenticated = true;
                deferred.resolve(_identity);
            }

            function getAccountCatch () {
                _identity = null;
                _authenticated = false;
                deferred.resolve(_identity);
            }
        }

        function isAuthenticated () {
            return true;
        }

        function isIdentityResolved () {
            return true;
        }
    }
})();

(function(module) {
try {
  module = angular.module('iservice.base');
} catch (e) {
  module = angular.module('iservice.base', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('templates/cron/cron.html',
    '<div class="scCron">\n' +
    '        <div class="scCronModel input-group">\n' +
    '            <!--<span ng-if="scValue" title="{{scValue}}">{{scValue}}</span>-->\n' +
    '            <!--<span ng-if="!scValue">请选择</span>-->\n' +
    '            <!--<input type="text" class="form-control" value="请选择" ng-if="!scValue">-->\n' +
    '            <input type="text" class="form-control" ng-model="scValue"   ng-change="changeValue(scValue)" placeholder="请选择">\n' +
    '            <span class="input-group-btn">\n' +
    '                <button type="button" class="btn btn-default" ng-click="slideCron($event)">\n' +
    '                    <i class="glyphicon glyphicon-calendar"></i>\n' +
    '                </button>\n' +
    '            </span>\n' +
    '        </div>\n' +
    '\n' +
    '\n' +
    '        <div class="scCronFrame" ng-click="slideFrame($event)">\n' +
    '            <p>\n' +
    '                <button type="button" class="btn btn-info" ng-click="confirmSelected()">确定</button>\n' +
    '                <button type="button" class="btn btn-default" ng-click="cancelSelected()">取消</button>\n' +
    '                <button type="button" class="btn btn-default" ng-click="clearSelected()">清除</button>\n' +
    '                <span ng-show="isIlegal" style="float: right;display: inline-block;height: 22px;line-height: 22px;color: red;"><b>!</b> 此表达式不合法，请重新选择</span>\n' +
    '            </p>\n' +
    '\n' +
    '            <div  class="tab1" id="tab1">\n' +
    '                <div class="menu">\n' +
    '                    <ul>\n' +
    '                        <li ng-click="setTab(cron)" class="{{cursel==cron.id?\'off\':\'\'}}" ng-repeat="cron in timeCollection track by $index" id="menuli_{{div.id}}">\n' +
    '                        <span>{{cron.title}} </span>\n' +
    '                    </li>\n' +
    '                    </ul>\n' +
    '                </div>\n' +
    '\n' +
    '                <div class="menudiv">\n' +
    '                    <div class="{{cursel===div.id?\'active\':\'\'}}" ng-repeat="div in timeCollection track by $index" id="menudiv_{{div.id}}">\n' +
    '\n' +
    '                        <div class="form-group">\n' +
    '                            <label  class="control-label">设置方式</label>\n' +
    '                            <div class=" dropUl">\n' +
    '                                <div class="form-control">\n' +
    '                                    <span>{{selectedWay}}</span>\n' +
    '                                    <b class="caret"></b>\n' +
    '                                </div>\n' +
    '                                <div class="selectFrame">\n' +
    '                                    <ul class="selectWay">\n' +
    '                                        <li ng-if="$index<=wayMany" ng-class="{\'active\':way.selected ==true}" ng-click="changeWay(way,$event)" ng-repeat="way in allWays track by $index">{{way.title}}</li>\n' +
    '                                    </ul>\n' +
    '                                </div>\n' +
    '                            </div>\n' +
    '                        </div>\n' +
    '\n' +
    '                        <div class="form-group selectCombine">\n' +
    '                            <label class="control-label">设置</label>\n' +
    '                            <!--如果选择的方式为自选  或者  选择天数的最后一天-->\n' +
    '                            <div ng-if="selectedId ==1" class=" dropUl">\n' +
    '\n' +
    '                                <div class="form-control">\n' +
    '\n' +
    '                                    <span ng-if="!showList.length>0">请选择</span>\n' +
    '\n' +
    '                                    <div ng-if="showList.length>0">\n' +
    '\n' +
    '                                        <!--选择的个数未超出父元素  无需合并时-->\n' +
    '                                        <div ng-show="!isCombine" class="hasSelected">\n' +
    '                                            <span ng-repeat="t in showList track by $index">{{t}}<b ng-if="showList[$index+1]">,</b></span>\n' +
    '                                        </div>\n' +
    '\n' +
    '                                        <!--选择的个数超出父元素  需合并时-->\n' +
    '                                        <div ng-show="isCombine" class="combineSelected">\n' +
    '                                            <span ng-click="combineList($event)">已选择{{len}}个 <b class="caret"></b></span>\n' +
    '                                            <ul ng-click="$event.stopPropagation()">\n' +
    '                                                <li ng-repeat="li in timeSelected track by $index">\n' +
    '                                                    <span>{{li.num}}</span>\n' +
    '                                                    <b ng-click="deleteElem(li);$event.stopPropagation()">x</b>\n' +
    '                                                </li>\n' +
    '                                            </ul>\n' +
    '                                        </div>\n' +
    '\n' +
    '                                    </div>\n' +
    '                                    <b class="caret"></b>\n' +
    '                                </div>\n' +
    '\n' +
    '                                <div class="selectFrame" ng-click="closeCombine($event)">\n' +
    '                                    <ul class="selectTime">\n' +
    '                                        <li class="{{cursel==6?\'moreWidth\':\'\'}}" ng-class="{\'active\':t.selected ==true}" ng-click="chooseTime(t,$event)" ng-repeat="t in allTime track by $index">{{t.num}}</li>\n' +
    '                                    </ul>\n' +
    '                                    <div style="    text-align: center;padding-bottom: 5px;">* 表示当前时间单位下的任意时刻</div>\n' +
    '                                </div>\n' +
    '\n' +
    '                            </div>\n' +
    '                            <!--如果选择的方式为间隔-->\n' +
    '                            <div ng-if="selectedId ==2" class="dropUl">\n' +
    '                                <label>从第</label>\n' +
    '                                <select style="width: 65px;display: inline-block;margin: 0 10px;" ng-if="div.id === 5" class="form-control" ng-model="valueList[div.id][\'0\']" ng-options="day.id as day.title for day in scWeekDay" ></select>\n' +
    '                                <input type="number" ng-if="div.id !== 5" ng-model="valueList[div.id][\'0\']"  ng-change="fromTimeChange(valueList[div.id][\'0\'])" class="form-control" placeholder="请输入" max="{{maxNum}}" min="{{div.min}}">\n' +
    '                                <label  ng-if="div.id !== 5">{{div.name}}</label>\n' +
    '                                <label>开始执行且&nbsp;每隔</label>\n' +
    '                                <input type="number" ng-model="valueList[div.id][\'1\']" ng-change="intervalTimeChange(valueList[div.id][\'1\'])" class="form-control" placeholder="请输入" max="{{maxNum}}" min="{{minNum}}">\n' +
    '                                <label><span ng-if="div.id == 4">个</span>{{div.name}}&nbsp;执行一次</label>\n' +
    '                            </div>\n' +
    '                            <!--如果选择的方式为最后一天-->\n' +
    '                            <div ng-if="selectedId ==3" class="dropUl">\n' +
    '                                <input type="text" ng-model="showList[0]" readonly style="margin: 0;padding: 0px 10px;line-height: 25px;height: 25px;border-radius: 4px;border: 1px solid #ccc;\n' +
    '                                    width: 100%;">\n' +
    '                            </div>\n' +
    '                        </div>\n' +
    '\n' +
    '                        <p ng-show="div.noChoose" class="warningChoose" style="text-align: center;margin-bottom: 0;color: red;">请完成间隔时间的选择</p>\n' +
    '                    </div>\n' +
    '                </div>\n' +
    '\n' +
    '\n' +
    '            </div>\n' +
    '        </div>\n' +
    '</div>');
}]);
})();

(function(module) {
try {
  module = angular.module('iservice.base');
} catch (e) {
  module = angular.module('iservice.base', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('templates/imenu/configMenu.html',
    '<div ng-if="ifData()" class="configMenu">\n' +
    '    <ul>\n' +
    '        <li ng-repeat="menu in data track by $index">\n' +
    '            <a id="config{{menu[id]}}" ng-click="dropMenu(menu)">\n' +
    '                <span class="deleteOne" ng-click="deleteMenu(menu);$event.stopPropagation()">×</span>&nbsp;&nbsp;{{menu[title]}}&nbsp;&nbsp;\n' +
    '                <b ng-if="ifMenu(menu)"></b>\n' +
    '            </a>\n' +
    '            <ul ng-if="ifMenu(menu)">\n' +
    '                <li ng-repeat="menu1 in menu[children] track by $index">\n' +
    '                    <a  ng-click="$event.preventDefault()"><span class="deleteTwo" ng-click="deleteMenu(menu,menu1);$event.stopPropagation()">×</span>&nbsp;&nbsp;{{menu1[title]}}&nbsp;&nbsp;\n' +
    '                        </a>\n' +
    '                    </li>\n' +
    '                </ul>\n' +
    '            </li>\n' +
    '        </ul>\n' +
    '    </div>');
}]);
})();

(function(module) {
try {
  module = angular.module('iservice.base');
} catch (e) {
  module = angular.module('iservice.base', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('templates/imenu/shortcutMenu.html',
    '<div id="shortcut-nav" class=" nav-menu navmenu navmenu-default navmenu-fixed-left slider-menu offcanvas in">\n' +
    '    <div class="navbar navbar-default shortcut-nav-header">\n' +
    '        <button type="button" class="navbar-toggle slider-navbar-toggle" data-autohide="false" data-toggle="offcanvas" data-target=".slider-menu" data-canvas="#mainContent" ng-show="isAuthenticated()">\n' +
    '            <span class="sr-only">Toggle navigation</span>\n' +
    '            <span class="icon-bar icon-bar-x"></span>\n' +
    '            <span class="icon-bar icon-bar-x"></span>\n' +
    '            <span class="icon-bar icon-bar-x"></span>\n' +
    '            </button>\n' +
    '        <a href="" class="icon-container" title="配置快捷菜单" ng-click="openShortcutPage()"><i class="glyphicon glyphicon-cog"></i></a>\n' +
    '        </div>\n' +
    '    <div ng-if="ifData()"><sc-shortcut-menu-ul id="shortcut" shortcutdata="data" menupid="menuPid" menuid="menuId" menuname="menuName" menuurl="menuUrl" children="menuChildren"></sc-shortcut-menu-ul>\n' +
    '    </div>\n' +
    '</div>');
}]);
})();

(function(module) {
try {
  module = angular.module('iservice.base');
} catch (e) {
  module = angular.module('iservice.base', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('templates/imenu/shortcutMenuUl.html',
    '<ul class="shortcut-menu nav navmenu-nav">\n' +
    '    <li ng-repeat="menu in shortcutData track by $index">\n' +
    '        <a id="menu{{menu[shortcutId]}}" ng-click="jumpUrl(menu);$event.stopPropagation()">{{menu[shortcutMenuName]}}&nbsp;&nbsp;\n' +
    '            <b ng-if="ifMenu(menu)"></b>\n' +
    '            </a>\n' +
    '        <ul ng-if="ifMenu(menu)">\n' +
    '            <li ng-repeat="menu1 in menu[shortcutChildren] track by $index">\n' +
    '                <a id="menu{{menu1[shortcutId]}}___{{menu1[shortcutPid]}}" ng-click="jumpUrl(menu1);$event.stopPropagation()">{{menu1[shortcutMenuName]}}&nbsp;&nbsp;\n' +
    '                    </a>\n' +
    '                </li>\n' +
    '            </ul>\n' +
    '        </li>\n' +
    '    </ul>');
}]);
})();

(function(module) {
try {
  module = angular.module('iservice.base');
} catch (e) {
  module = angular.module('iservice.base', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('templates/imenu/shortcutNewMenu.html',
    '<div class="scSimpleMenu" ng-if="!myVar">\n' +
    '    <ul style="padding-top: 54px" ng-if="ifData()">\n' +
    '        <li class="scMenuIcon"><a href="" ng-click="toggle()"><img src="https://static.56start.com/images/menu/icon-list.png" alt=""></a></li>\n' +
    '        <li id="menu{{menu[menuId]}}" ng-repeat="menu in data track by $index">\n' +
    '            <a ng-click="jumpUrl(menu);$event.stopPropagation()">{{menu[menuName][0]}}</a>\n' +
    '            <ul  ng-if="ifMenu(menu)" class="littleMenu">\n' +
    '                <li><p>{{menu[menuName]}}</p></li>\n' +
    '                <li ng-repeat="menu1 in menu[menuChildren] track by $index">\n' +
    '                    <a id="menu{{menu1[menuId]}}___{{menu1[menuPid]}}" ng-click="jumpUrl(menu1);$event.stopPropagation()" title="{{menu1[menuName]}}">{{menu1[menuName]}}</a>\n' +
    '                    <span ng-click="jumpNewWindow(menu1)">+</span>\n' +
    '                    </li>\n' +
    '                </ul>\n' +
    '            </li>\n' +
    '        </ul>\n' +
    '    </div>\n' +
    '<div class="scShortcutMenu" ng-if="myVar">\n' +
    '    <div class="btnMenu">\n' +
    '        <a class="iconSetUp" ng-click="openShortcutPage()"><img src="https://static.56start.com/images/menu/icon-Set-up.png" alt=""></a>\n' +
    '        <a ng-click="toggle()" class="iconList"><img src="https://static.56start.com/images/menu/icon-list.png" alt=""></a>\n' +
    '        </div>\n' +
    '    <div class="containerMenu">\n' +
    '        <div id="menu{{menu[menuId]}}" ng-repeat="menu in data track by $index" ng-click="jumpUrl(menu);$event.stopPropagation()">\n' +
    '            <p class="Collapsing">{{menu[menuName]}}</p>\n' +
    '            <div class="coll_body"  ng-if="ifMenu(menu)" ng-repeat="menu1 in menu[menuChildren] track by $index">\n' +
    '                <a id="menu{{menu1[menuId]}}___{{menu1[menuPid]}}" ng-click="jumpUrl(menu1);$event.stopPropagation()" title="{{menu1[menuName]}}">{{menu1[menuName]}}\n' +
    '                    <span ng-click="jumpNewWindow(menu1);$event.stopPropagation()">+</span>\n' +
    '                </a>\n' +
    '                </div>\n' +
    '            </div>\n' +
    '        </div>\n' +
    '    </div>');
}]);
})();

(function(module) {
try {
  module = angular.module('iservice.base');
} catch (e) {
  module = angular.module('iservice.base', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('templates/imenu/shortcutSecMenu.html',
    '<div class="secShortcut">\n' +
    '    <div class="menuChangeBtnBox">\n' +
    '        <button class="btn secSetUpBtn" ng-click="openShortcutPage()">\n' +
    '            <img src="https://static.56start.com/images/menu/Set-up.png" alt="">\n' +
    '        </button>\n' +
    '        <button class="btn menuChangeBtn" ng-click="menuSwitch()">\n' +
    '            <img src="https://static.56start.com/images/menu/list.png" alt="" style="width: 16px;height: 16px;transform:rotate(90deg)">\n' +
    '        </button>\n' +
    '    </div>\n' +
    '    <div class="secShortCutMenu">\n' +
    '        <ul class="secShUlOne">\n' +
    '            <li class="secShLiOne" ng-repeat="menu in data track by $index">\n' +
    '                <a ng-click="shortcutSwitch()">{{menu[menuName]}}</a>\n' +
    '                <ul class="secShUlTwo">\n' +
    '                    <li class="secShLiTwo" ng-repeat="menu1 in menu[menuChildren] track by $index">\n' +
    '                        <a title="{{menu1[menuName]}}"  ng-click="jumpUrl(menu1)">{{menu1[menuName]}}</a>\n' +
    '                        <span ng-click="jumpNewWindow(menu1)" title="在新窗口打开">+</span>\n' +
    '                    </li>\n' +
    '                </ul>\n' +
    '            </li>\n' +
    '        </ul>\n' +
    '    </div>\n' +
    '</div>\n' +
    '<div class="secSimple">\n' +
    '    <div class="menuChangeBtnBox">\n' +
    '        <button class="btn menuChangeBtn" ng-click="menuSwitch()">\n' +
    '            <img src="https://static.56start.com/images/menu/list.png" alt="" style="width: 16px;height: 16px;">\n' +
    '        </button>\n' +
    '    </div>\n' +
    '    <div class="secSimpleMenu">\n' +
    '        <ul class="secSiUlOne">\n' +
    '            <li class="secSiLiOne" ng-repeat="menu in data track by $index">\n' +
    '                <a>{{menu[menuName][0]}}</a>\n' +
    '                <ul class="secSiUlTwo">\n' +
    '                    <p>{{menu[menuName]}}</p>\n' +
    '                    <li class="secSiLiTwo" ng-repeat="menu1 in menu[menuChildren] track by $index">\n' +
    '                        <a title="{{menu1[menuName]}}" ng-click="jumpUrl(menu1)">{{menu1[menuName]}}</a>\n' +
    '                        <span ng-click="jumpNewWindow(menu1)" title="在新窗口打开">+</span>\n' +
    '                    </li>\n' +
    '                </ul>\n' +
    '            </li>\n' +
    '        </ul>\n' +
    '    </div>\n' +
    '</div>');
}]);
})();

(function(module) {
try {
  module = angular.module('iservice.base');
} catch (e) {
  module = angular.module('iservice.base', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('templates/imenu/systemMenu.html',
    '<div class="mainMenu">\n' +
    '\n' +
    '    <div class="navMenu">\n' +
    '        <div class="navMenu_left">\n' +
    '            <img src="https://static.56start.com/images/isso/logo.png" alt="">&nbsp;&nbsp;\n' +
    '            <span>SCM 供应链管理平台</span>\n' +
    '            </div>\n' +
    '        <div class="navMenu_center">\n' +
    '            <ul>\n' +
    '                <li ng-repeat="sys in data track by $index" index="main{{sys[menuId]}}">\n' +
    '                     <!--<span class="glyphicon glyphicon-home"></span>&nbsp;-->\n' +
    '                    <a ng-click="jumpUrl(sys)">{{sys[menuSysName]}}</a>&nbsp;\n' +
    '                     <!--<b></b>-->\n' +
    '                    </li>\n' +
    '                </ul>\n' +
    '            </div>\n' +
    '        <div class="navMenu_right" ng-if="currentUser.userName">\n' +
    '            <ul>\n' +
    '\n' +
    '                <li ng-if="warehouseRequiredAppList.indexOf(appid) >= 0" id="currentWarehouse" class="menu currentWarehouse"><a href=""><span></span>&nbsp;【{{currentUser.warehouseName ? currentUser.warehouseName : "未选择仓库 "}}】</a>\n' +
    '                    <ul class="dropdown-menu">\n' +
    '                        <li><a href="" ng-click="goWarehouseSelectPage()"><i class="glyphicon glyphicon-cog"></i>&nbsp;切换仓库</a></li>\n' +
    '                        </ul>\n' +
    '                    </li>\n' +
    '\n' +
    '                <li id="userMenu" class="menu"><a href=""><span class="glyphicon glyphicon-user"></span>&nbsp;【{{currentUser.userName}}】</a>\n' +
    '                    <ul class="dropdown-menu">\n' +
    '                        <li><a href="" ng-click="updatePasswordModal()"><i class="glyphicon glyphicon-cog"></i>&nbsp;修改密码</a></li>\n' +
    '                        <li><a href="" ng-click="logout()"><i class="glyphicon glyphicon-log-out"></i>&nbsp;退出系统</a></li>\n' +
    '                        </ul>\n' +
    '                    </li>\n' +
    '                </ul>\n' +
    '            </div>\n' +
    '        </div>\n' +
    '    </div>\n' +
    '\n' +
    '<div class="mainDropMenu animate">\n' +
    '    <div class="level0" id="main{{sys[menuId]}}"  ng-if="ifMenu(sys)" ng-repeat="sys in data track by $index">\n' +
    '        <ul class="level-1" ng-if="sys[children].length>0">\n' +
    '            <li ng-repeat="sys1 in sys[children] track by $index" index="main{{sys1[menuId]}}">\n' +
    '                <a ng-click="jumpUrl(sys1)">{{sys1[menuSysName]}}<b ng-if="ifUrl(sys1)" ng-click="jumpNewWindow(sys1);$event.stopPropagation()">+</b></a>\n' +
    '                </li>\n' +
    '            </ul>\n' +
    '\n' +
    '        <div class="level1"  id="main{{sys1[menuId]}}"  ng-if="ifMenu(sys1)" ng-repeat="sys1 in sys[children] track by $index">\n' +
    '            <ul class="level-2">\n' +
    '                <li ng-repeat="sys2 in sys1[children] track by $index"  index="main{{sys2[menuId]}}">\n' +
    '                    <a ng-click="jumpUrl(sys2)">{{sys2[menuSysName]}}<b ng-if="ifUrl(sys2)" ng-click="jumpNewWindow(sys2);$event.stopPropagation()">+</b></a>\n' +
    '                    </li>\n' +
    '                </ul>\n' +
    '            <div class="level2"  id="main{{sys2[menuId]}}"  ng-if="ifMenu(sys2)" ng-repeat="sys2 in sys1[children] track by $index">\n' +
    '                <ul class="level-3">\n' +
    '                    <li ng-repeat="sys3 in sys2[children] track by $index"   ng-click="jumpUrl(sys3)" index="main{{sys3[menuId]}}">\n' +
    '                        <a>\n' +
    '                            <span>{{sys3[menuSysName]}}</span>\n' +
    '                            <p>{{sys3[menuDetailName]}}</p>\n' +
    '                            </a>\n' +
    '                        <b ng-if="ifUrl(sys3)" ng-click="jumpNewWindow(sys3);$event.stopPropagation()">+</b>\n' +
    '                        </li>\n' +
    '                    </ul>\n' +
    '                </div>\n' +
    '            </div>\n' +
    '        </div>\n' +
    '    </div>');
}]);
})();

(function(module) {
try {
  module = angular.module('iservice.base');
} catch (e) {
  module = angular.module('iservice.base', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('templates/imenu/systemNewMenu.html',
    '<div class="viewframeContainer ng-scope" ng-if="showNavbar">\n' +
    '    <div class="wms-console-base-bar">\n' +
    '        <div class="topbar ">\n' +
    '            <div class="wms-logo pull-left logo-box">\n' +
    '                <div class="wms-logo-wrapper pull-left"><a href="/" target="_blank"\n' +
    '                                                              class="wms-icon pull-left"><span\n' +
    '                        class="glyphicon glyphicon-home"></span></a>\n' +
    '                </div>\n' +
    '            </div>\n' +
    '            <div class="pull-left topbar-product product-list-box" ng-show="logined">\n' +
    '                <div class="topbar-logo-btn" ng-show="currentUser.systemName">\n' +
    '                    <span class="topbar-info-dropdown-toggle">{{currentUser.systemName}}</span>\n' +
    '                </div>\n' +
    '                <div class="topbar-btn topbar-product-btn dropdown-toggle" style="border-left: 1px solid #2a2f32;" ng-click="toggleMenu()">\n' +
    '                    <span>控制台</span>\n' +
    '                    <span class="topbar-sidebar-arrow-down"></span>\n' +
    '                </div>\n' +
    '                <div class="topbar-product-dropdown">\n' +
    '                    <div class="topbar-product-container topbar-clearfix  topbar-product-container-simple">\n' +
    '                        <!-- 计算 -->\n' +
    '                        <div class="topbar-product-category-box " style="max-height: 654px; overflow-y: scroll;">\n' +
    '                            <div class="topbar-product-hd">\n' +
    '                                <div class="topbar-product-search"><input type="text" class="J_inputKeyword"\n' +
    '                                                                          placeholder="请输入关键词" ng-model="keywords"><span\n' +
    '                                        class="topbar-sidebar-search topbar-product-search-icon"\n' +
    '                                        href="javascript:;"></span>\n' +
    '                                </div>\n' +
    '                            </div>\n' +
    '                            <div class="topbar-product-bd">\n' +
    '                                <div class="topbar-product-by-category">\n' +
    '                                    <div class="waterfall-container column-6">\n' +
    '                                        <div class="waterfall-group"\n' +
    '                                             ng-repeat="rowItems in displayData track by $index">\n' +
    '                                            <div class="waterfall-group-wrapper">\n' +
    '                                                <dl ng-repeat="rowItem in rowItems track by $index">\n' +
    '                                                    <dt>{{rowItem.menuName}}</dt>\n' +
    '                                                    <dd ng-repeat="item in rowItem.children track by $index">\n' +
    '                                                        <a ng-href="" title="{{item.menuName}}" ng-click="jumpUrlA(item);$event.stopPropagation();">\n' +
    '                                                            <span ng-click="jumpUrl(item);$event.stopPropagation();" >{{item.menuName}}</span>\n' +
    '                                                            <span ng-click="jumpNewWindow(item);$event.stopPropagation();" class="openNewWindowMenu" title="在新窗口打开">+</span>\n' +
    '                                                        </a>\n' +
    '                                                    </dd>\n' +
    '                                                </dl>\n' +
    '                                            </div>\n' +
    '                                        </div>\n' +
    '                                    </div>\n' +
    '                                </div>\n' +
    '                            </div>\n' +
    '                        </div>\n' +
    '                        <div class="topbar-product-close" ng-click="toggleMenu()"><span\n' +
    '                                class="icon-double-arrow"><i></i><i></i></span></div>\n' +
    '                    </div>\n' +
    '                </div>\n' +
    '            </div>\n' +
    '            <div class="pull-right topbar-info clearfix" ng-show="logined">\n' +
    '                <!-- \n' +
    '                <div class="pull-left topbar-info-item topbar-info-dropdown i18n-box" style="min-width: 100px;">\n' +
    '                    <span ng-if="userPendingData.msgCount > 0" \n' +
    '                        class="lebel label-warning pull-right">{{userPendingData.msgCount > 100 ? \'..\' : userPendingData.msgCount }}</span>\n' +
    '                    <a href="#/portal/systemMessage"><span class="topbar-btn topbar-info-dropdown-toggle">消息/公告</span></a>\n' +
    '                </div>\n' +
    '                <div class="pull-left topbar-info-item topbar-info-dropdown i18n-box" style="min-width: 100px;">\n' +
    '                    <span ng-if="userPendingData.todoCount > 0" \n' +
    '                            class="lebel label-warning pull-right">{{userPendingData.todoCount > 100 ? \'..\' : userPendingData.todoCount }}</span>\n' +
    '                    <a href="#/portal/userTodo"><span class="topbar-btn topbar-info-dropdown-toggle">代办任务</span>\n' +
    '                    </a>\n' +
    '                </div>\n' +
    '                 -->\n' +
    '                <div class="pull-left topbar-info-item topbar-info-dropdown i18n-box" style="min-width: 100px;"><span\n' +
    '                        class="topbar-btn topbar-info-dropdown-toggle">快捷菜单</span>\n' +
    '                        \n' +
    '                    <ul class="topbar-info-dropdown-memu topbar-info-dropdown-memu-list" ng-repeat="item in displayShortcutData track by $index">\n' +
    '                        <li class="topbar-info-btn"><a ng-href="{{childItem.menuUrl}}" ng-repeat="childItem in item.children track by $index"><span>{{childItem.menuName}}</span></a></li>\n' +
    '                    </ul>\n' +
    '                    \n' +
    '                </div>\n' +
    '                <div class="pull-left topbar-info-item topbar-info-dropdown i18n-box"><span\n' +
    '                        class="topbar-btn topbar-info-dropdown-toggle">【{{currentUser.warehouseName ? currentUser.warehouseName : "未选择仓库"}}】</span>\n' +
    '                    <ul class="topbar-info-dropdown-memu topbar-info-dropdown-memu-list">\n' +
    '                        <li class="topbar-info-btn"><a ng-click="goWarehouseSelectPage()"><span>切换仓库</span></a></li>\n' +
    '                    </ul>\n' +
    '                </div>\n' +
    '                <div class="pull-left topbar-user user-box ">\n' +
    '                    <div class="topbar-info-dropdown topbar-info-item"><a href=""\n' +
    '                                                                          class="topbar-info-dropdown-toggle topbar-btn"><img\n' +
    '                            class="topbar-user-avatar"\n' +
    '                            src="https://static.56start.com/images/menu/default_handsome.jpg"></a>\n' +
    '                        <div class="topbar-info-dropdown-memu topbar-align-right">\n' +
    '                            <div class="topbar-user-info"><img class="topbar-user-avatar"\n' +
    '                                                               src="https://static.56start.com/images/menu/default_handsome.jpg">\n' +
    '                                <p class="topbar-user-email"><span title="{{currentUser.userName}}">{{currentUser.userName}}</span>\n' +
    '                                </p>\n' +
    '                            </div>\n' +
    '                            <div class="topbar-user-entrance-list">\n' +
    '                                <a class="topbar-user-entrance" href=""\n' +
    '                                   sc-define-modal\n' +
    '                                   sc-title="\'更新密码\'"\n' +
    '                                   sc-content="\'updatePassword.tpl\'"\n' +
    '                                   sc-before-close="updatePassword($model)"\n' +
    '                                   sc-validate="validatePwdForm($model)"><span\n' +
    '                                        class="topbar-user-entrance-logo icon-topbar-secure-control topbar-sidebar-secure-setting"></span><span>修改密码</span></a>\n' +
    '                                <a class="topbar-user-entrance" sc-define-modal\n' +
    '                                   sc-title="\'绑定设备\'"\n' +
    '                                   sc-content="\'bindDevice.tpl\'"\n' +
    '                                   sc-get-model="getStoredDeviceCode()"\n' +
    '                                   sc-before-close="updateDevice($model)"><span\n' +
    '                                        class="topbar-user-entrance-logo icon-topbar-secure-setting topbar-sidebar-secure-control"></span><span>绑定设备</span></a>\n' +
    '                                <a class="topbar-user-entrance" sc-define-modal\n' +
    '                                   sc-title="\'绑定手机号\'"\n' +
    '                                   sc-content="\'bindCellphone.tpl\'"\n' +
    '                                   sc-get-model="getBindCellphone()"\n' +
    '                                   sc-before-close="bindCellPhone($model)"><span\n' +
    '                                        class="topbar-user-entrance-logo icon-topbar-secure-setting topbar-sidebar-secure-control"></span><span>绑定手机号</span></a>\n' +
    '                                <a class="topbar-user-entrance" href="#/user/shortcutmenu-maintenance"><span\n' +
    '                                        class="topbar-user-entrance-logo icon-topbar-secure-setting topbar-sidebar-secure-control"></span><span>快捷菜单</span></a>\n' +
    '                                <a class="topbar-user-entrance" href="#/user/favmenu-maintenance"><span\n' +
    '                                        class="topbar-user-entrance-logo icon-topbar-secure-setting topbar-sidebar-secure-control"></span><span>侧边栏菜单</span></a>\n' +
    '                            </div>\n' +
    '                            <div>\n' +
    '                                <div class="user-btn-list"><a class="user-btn-link"\n' +
    '                                                              ng-click="logout()"><span>退出系统</span></a></div>\n' +
    '                            </div>\n' +
    '                        </div>\n' +
    '                    </div>\n' +
    '                </div>\n' +
    '            </div>\n' +
    '        </div>\n' +
    '        <div class="sidebar" ng-class="{\'sidebar-mini\':sidebarFolded}" ng-if="displayFavorData && displayFavorData.length > 0">\n' +
    '            <div class="sidebar-wrapper ">\n' +
    '                <div class="sidebar-content">\n' +
    '                    <div class="sidebar-inner">\n' +
    '                        <div class="sidebar-fold"\n' +
    '                             ng-class="{\'topbar-sidebar-fold\':sidebarFolded, \'topbar-sidebar-unfold\':!sidebarFolded}"\n' +
    '                             ng-click="toggleSideMenu()"></div>\n' +
    '                        <div class="sidebar-nav" ng-class="{\'sidebar-nav-active\':!item.folded, \'show\': item}" ng-repeat="item in displayFavorData track by $index">\n' +
    '                            <div class="sidebar-title sidebar-trans" data-tip="{{sidebarFolded? item.menuName: \'\'}}"\n' +
    '                                 currentitem="false">\n' +
    '                                <div class="sidebar-title-inner" ng-click="toggleSideMenuItem(item)">\n' +
    '                                    <span class="sidebar-title-icon topbar-sidebar-arrow-right" style="{{sidebarFolded ? \'margin: 0 5px 0 5px;\' : \'\'}}">{{ sidebarFolded ? returnFirtChar(item.menuName, 1) : \'\'}}</span>\n' +
    '                                    <span class="sidebar-title-text">{{item.menuName}}</span>\n' +
    '                                </div>\n' +
    '                            </div>\n' +
    '                            <ul class="sidebar-trans" ng-style="item.childrenStyle">\n' +
    '                                <li class="nav-item" ng-class="{\'active\':childItem.selected}" ng-repeat="childItem in item.children track by $index">\n' +
    '                                    <a ng-href=""\n' +
    '                                        ng-click="jumpUrlB(childItem);$event.stopPropagation();"\n' +
    '                                        data-tip="{{sidebarFolded? childItem.menuName: \'\'}}" class="sidebar-trans"\n' +
    '                                        currentitem="false">\n' +
    '                                    <div class="nav-icon sidebar-trans" ng-show="sidebarFolded" style="font-size: 12px;">\n' +
    '                                        <span class="">{{returnFirtChar(childItem.menuName, 2)}}</span>\n' +
    '                                    </div>\n' +
    '                                    \n' +
    '                                    <div class="nav-icon sidebar-trans" ng-show="!sidebarFolded" style="font-size: 12px;">\n' +
    '                                        <span style="padding: 5px 10px;" title="在新窗口打开" ng-click="jumpNewWindow(childItem);$event.stopPropagation();">+</span>\n' +
    '                                    </div>\n' +
    '                                    \n' +
    '                                    <span class="nav-title" title="{{childItem.menuName}}">{{childItem.menuName}}\n' +
    '                                    </span>\n' +
    '                                    </a>\n' +
    '                                    </li>\n' +
    '                            </ul>\n' +
    '                        </div>\n' +
    '                    </div>\n' +
    '                </div>\n' +
    '            </div>\n' +
    '            <div class="__react_component_tooltip place-right type-dark " id="tooltip"></div>\n' +
    '        </div>\n' +
    '    </div>\n' +
    '</div>\n' +
    '\n' +
    '\n' +
    '<style id="react-tooltip">\n' +
    '    .__react_component_tooltip {\n' +
    '        border-radius: 3px;\n' +
    '\n' +
    '        display: inline-block;\n' +
    '        font-size: 13px;\n' +
    '        left: -999em;\n' +
    '        opacity: 0;\n' +
    '        padding: 8px 21px;\n' +
    '        position: fixed;\n' +
    '        pointer-events: none;\n' +
    '        transition: opacity 0.3s ease-out;\n' +
    '        top: -999em;\n' +
    '        visibility: hidden;\n' +
    '        z-index: 999\n' +
    '    }\n' +
    '\n' +
    '    .__react_component_tooltip:before, .__react_component_tooltip:after {\n' +
    '        content: "";\n' +
    '        width: 0;\n' +
    '        height: 0;\n' +
    '        position: absolute\n' +
    '    }\n' +
    '\n' +
    '    .__react_component_tooltip.show {\n' +
    '        opacity: 0.9;\n' +
    '        margin-top: 0px;\n' +
    '        margin-left: 0px;\n' +
    '        visibility: visible\n' +
    '    }\n' +
    '\n' +
    '    .__react_component_tooltip.type-dark {\n' +
    '        color: #fff;\n' +
    '        background-color: #222\n' +
    '    }\n' +
    '\n' +
    '    .__react_component_tooltip.type-dark.place-top:after {\n' +
    '        border-top-color: #222;\n' +
    '        border-top-style: solid;\n' +
    '        border-top-width: 6px\n' +
    '    }\n' +
    '\n' +
    '    .__react_component_tooltip.type-dark.place-bottom:after {\n' +
    '        border-bottom-color: #222;\n' +
    '        border-bottom-style: solid;\n' +
    '        border-bottom-width: 6px\n' +
    '    }\n' +
    '\n' +
    '    .__react_component_tooltip.type-dark.place-left:after {\n' +
    '        border-left-color: #222;\n' +
    '        border-left-style: solid;\n' +
    '        border-left-width: 6px\n' +
    '    }\n' +
    '\n' +
    '    .__react_component_tooltip.type-dark.place-right:after {\n' +
    '        border-right-color: #222;\n' +
    '        border-right-style: solid;\n' +
    '        border-right-width: 6px\n' +
    '    }\n' +
    '\n' +
    '    .__react_component_tooltip.type-dark.border {\n' +
    '        border: 1px solid #fff\n' +
    '    }\n' +
    '\n' +
    '    .__react_component_tooltip.type-dark.border.place-top:before {\n' +
    '        border-top: 8px solid #fff\n' +
    '    }\n' +
    '\n' +
    '    .__react_component_tooltip.type-dark.border.place-bottom:before {\n' +
    '        border-bottom: 8px solid #fff\n' +
    '    }\n' +
    '\n' +
    '    .__react_component_tooltip.type-dark.border.place-left:before {\n' +
    '        border-left: 8px solid #fff\n' +
    '    }\n' +
    '\n' +
    '    .__react_component_tooltip.type-dark.border.place-right:before {\n' +
    '        border-right: 8px solid #fff\n' +
    '    }\n' +
    '\n' +
    '    .__react_component_tooltip.type-success {\n' +
    '        color: #fff;\n' +
    '        background-color: #8DC572\n' +
    '    }\n' +
    '\n' +
    '    .__react_component_tooltip.type-success.place-top:after {\n' +
    '        border-top-color: #8DC572;\n' +
    '        border-top-style: solid;\n' +
    '        border-top-width: 6px\n' +
    '    }\n' +
    '\n' +
    '    .__react_component_tooltip.type-success.place-bottom:after {\n' +
    '        border-bottom-color: #8DC572;\n' +
    '        border-bottom-style: solid;\n' +
    '        border-bottom-width: 6px\n' +
    '    }\n' +
    '\n' +
    '    .__react_component_tooltip.type-success.place-left:after {\n' +
    '        border-left-color: #8DC572;\n' +
    '        border-left-style: solid;\n' +
    '        border-left-width: 6px\n' +
    '    }\n' +
    '\n' +
    '    .__react_component_tooltip.type-success.place-right:after {\n' +
    '        border-right-color: #8DC572;\n' +
    '        border-right-style: solid;\n' +
    '        border-right-width: 6px\n' +
    '    }\n' +
    '\n' +
    '    .__react_component_tooltip.type-success.border {\n' +
    '        border: 1px solid #fff\n' +
    '    }\n' +
    '\n' +
    '    .__react_component_tooltip.type-success.border.place-top:before {\n' +
    '        border-top: 8px solid #fff\n' +
    '    }\n' +
    '\n' +
    '    .__react_component_tooltip.type-success.border.place-bottom:before {\n' +
    '        border-bottom: 8px solid #fff\n' +
    '    }\n' +
    '\n' +
    '    .__react_component_tooltip.type-success.border.place-left:before {\n' +
    '        border-left: 8px solid #fff\n' +
    '    }\n' +
    '\n' +
    '    .__react_component_tooltip.type-success.border.place-right:before {\n' +
    '        border-right: 8px solid #fff\n' +
    '    }\n' +
    '\n' +
    '    .__react_component_tooltip.type-warning {\n' +
    '        color: #fff;\n' +
    '        background-color: #F0AD4E\n' +
    '    }\n' +
    '\n' +
    '    .__react_component_tooltip.type-warning.place-top:after {\n' +
    '        border-top-color: #F0AD4E;\n' +
    '        border-top-style: solid;\n' +
    '        border-top-width: 6px\n' +
    '    }\n' +
    '\n' +
    '    .__react_component_tooltip.type-warning.place-bottom:after {\n' +
    '        border-bottom-color: #F0AD4E;\n' +
    '        border-bottom-style: solid;\n' +
    '        border-bottom-width: 6px\n' +
    '    }\n' +
    '\n' +
    '    .__react_component_tooltip.type-warning.place-left:after {\n' +
    '        border-left-color: #F0AD4E;\n' +
    '        border-left-style: solid;\n' +
    '        border-left-width: 6px\n' +
    '    }\n' +
    '\n' +
    '    .__react_component_tooltip.type-warning.place-right:after {\n' +
    '        border-right-color: #F0AD4E;\n' +
    '        border-right-style: solid;\n' +
    '        border-right-width: 6px\n' +
    '    }\n' +
    '\n' +
    '    .__react_component_tooltip.type-warning.border {\n' +
    '        border: 1px solid #fff\n' +
    '    }\n' +
    '\n' +
    '    .__react_component_tooltip.type-warning.border.place-top:before {\n' +
    '        border-top: 8px solid #fff\n' +
    '    }\n' +
    '\n' +
    '    .__react_component_tooltip.type-warning.border.place-bottom:before {\n' +
    '        border-bottom: 8px solid #fff\n' +
    '    }\n' +
    '\n' +
    '    .__react_component_tooltip.type-warning.border.place-left:before {\n' +
    '        border-left: 8px solid #fff\n' +
    '    }\n' +
    '\n' +
    '    .__react_component_tooltip.type-warning.border.place-right:before {\n' +
    '        border-right: 8px solid #fff\n' +
    '    }\n' +
    '\n' +
    '    .__react_component_tooltip.type-error {\n' +
    '        color: #fff;\n' +
    '        background-color: #BE6464\n' +
    '    }\n' +
    '\n' +
    '    .__react_component_tooltip.type-error.place-top:after {\n' +
    '        border-top-color: #BE6464;\n' +
    '        border-top-style: solid;\n' +
    '        border-top-width: 6px\n' +
    '    }\n' +
    '\n' +
    '    .__react_component_tooltip.type-error.place-bottom:after {\n' +
    '        border-bottom-color: #BE6464;\n' +
    '        border-bottom-style: solid;\n' +
    '        border-bottom-width: 6px\n' +
    '    }\n' +
    '\n' +
    '    .__react_component_tooltip.type-error.place-left:after {\n' +
    '        border-left-color: #BE6464;\n' +
    '        border-left-style: solid;\n' +
    '        border-left-width: 6px\n' +
    '    }\n' +
    '\n' +
    '    .__react_component_tooltip.type-error.place-right:after {\n' +
    '        border-right-color: #BE6464;\n' +
    '        border-right-style: solid;\n' +
    '        border-right-width: 6px\n' +
    '    }\n' +
    '\n' +
    '    .__react_component_tooltip.type-error.border {\n' +
    '        border: 1px solid #fff\n' +
    '    }\n' +
    '\n' +
    '    .__react_component_tooltip.type-error.border.place-top:before {\n' +
    '        border-top: 8px solid #fff\n' +
    '    }\n' +
    '\n' +
    '    .__react_component_tooltip.type-error.border.place-bottom:before {\n' +
    '        border-bottom: 8px solid #fff\n' +
    '    }\n' +
    '\n' +
    '    .__react_component_tooltip.type-error.border.place-left:before {\n' +
    '        border-left: 8px solid #fff\n' +
    '    }\n' +
    '\n' +
    '    .__react_component_tooltip.type-error.border.place-right:before {\n' +
    '        border-right: 8px solid #fff\n' +
    '    }\n' +
    '\n' +
    '    .__react_component_tooltip.type-info {\n' +
    '        color: #fff;\n' +
    '        background-color: #337AB7\n' +
    '    }\n' +
    '\n' +
    '    .__react_component_tooltip.type-info.place-top:after {\n' +
    '        border-top-color: #337AB7;\n' +
    '        border-top-style: solid;\n' +
    '        border-top-width: 6px\n' +
    '    }\n' +
    '\n' +
    '    .__react_component_tooltip.type-info.place-bottom:after {\n' +
    '        border-bottom-color: #337AB7;\n' +
    '        border-bottom-style: solid;\n' +
    '        border-bottom-width: 6px\n' +
    '    }\n' +
    '\n' +
    '    .__react_component_tooltip.type-info.place-left:after {\n' +
    '        border-left-color: #337AB7;\n' +
    '        border-left-style: solid;\n' +
    '        border-left-width: 6px\n' +
    '    }\n' +
    '\n' +
    '    .__react_component_tooltip.type-info.place-right:after {\n' +
    '        border-right-color: #337AB7;\n' +
    '        border-right-style: solid;\n' +
    '        border-right-width: 6px\n' +
    '    }\n' +
    '\n' +
    '    .__react_component_tooltip.type-info.border {\n' +
    '        border: 1px solid #fff\n' +
    '    }\n' +
    '\n' +
    '    .__react_component_tooltip.type-info.border.place-top:before {\n' +
    '        border-top: 8px solid #fff\n' +
    '    }\n' +
    '\n' +
    '    .__react_component_tooltip.type-info.border.place-bottom:before {\n' +
    '        border-bottom: 8px solid #fff\n' +
    '    }\n' +
    '\n' +
    '    .__react_component_tooltip.type-info.border.place-left:before {\n' +
    '        border-left: 8px solid #fff\n' +
    '    }\n' +
    '\n' +
    '    .__react_component_tooltip.type-info.border.place-right:before {\n' +
    '        border-right: 8px solid #fff\n' +
    '    }\n' +
    '\n' +
    '    .__react_component_tooltip.type-light {\n' +
    '        color: #222;\n' +
    '        background-color: #fff\n' +
    '    }\n' +
    '\n' +
    '    .__react_component_tooltip.type-light.place-top:after {\n' +
    '        border-top-color: #fff;\n' +
    '        border-top-style: solid;\n' +
    '        border-top-width: 6px\n' +
    '    }\n' +
    '\n' +
    '    .__react_component_tooltip.type-light.place-bottom:after {\n' +
    '        border-bottom-color: #fff;\n' +
    '        border-bottom-style: solid;\n' +
    '        border-bottom-width: 6px\n' +
    '    }\n' +
    '\n' +
    '    .__react_component_tooltip.type-light.place-left:after {\n' +
    '        border-left-color: #fff;\n' +
    '        border-left-style: solid;\n' +
    '        border-left-width: 6px\n' +
    '    }\n' +
    '\n' +
    '    .__react_component_tooltip.type-light.place-right:after {\n' +
    '        border-right-color: #fff;\n' +
    '        border-right-style: solid;\n' +
    '        border-right-width: 6px\n' +
    '    }\n' +
    '\n' +
    '    .__react_component_tooltip.type-light.border {\n' +
    '        border: 1px solid #222\n' +
    '    }\n' +
    '\n' +
    '    .__react_component_tooltip.type-light.border.place-top:before {\n' +
    '        border-top: 8px solid #222\n' +
    '    }\n' +
    '\n' +
    '    .__react_component_tooltip.type-light.border.place-bottom:before {\n' +
    '        border-bottom: 8px solid #222\n' +
    '    }\n' +
    '\n' +
    '    .__react_component_tooltip.type-light.border.place-left:before {\n' +
    '        border-left: 8px solid #222\n' +
    '    }\n' +
    '\n' +
    '    .__react_component_tooltip.type-light.border.place-right:before {\n' +
    '        border-right: 8px solid #222\n' +
    '    }\n' +
    '\n' +
    '    .__react_component_tooltip.place-top {\n' +
    '        margin-top: -10px\n' +
    '    }\n' +
    '\n' +
    '    .__react_component_tooltip.place-top:before {\n' +
    '        border-left: 10px solid transparent;\n' +
    '        border-right: 10px solid transparent;\n' +
    '        bottom: -8px;\n' +
    '        left: 50%;\n' +
    '        margin-left: -10px\n' +
    '    }\n' +
    '\n' +
    '    .__react_component_tooltip.place-top:after {\n' +
    '        border-left: 8px solid transparent;\n' +
    '        border-right: 8px solid transparent;\n' +
    '        bottom: -6px;\n' +
    '        left: 50%;\n' +
    '        margin-left: -8px\n' +
    '    }\n' +
    '\n' +
    '    .__react_component_tooltip.place-bottom {\n' +
    '        margin-top: 10px\n' +
    '    }\n' +
    '\n' +
    '    .__react_component_tooltip.place-bottom:before {\n' +
    '        border-left: 10px solid transparent;\n' +
    '        border-right: 10px solid transparent;\n' +
    '        top: -8px;\n' +
    '        left: 50%;\n' +
    '        margin-left: -10px\n' +
    '    }\n' +
    '\n' +
    '    .__react_component_tooltip.place-bottom:after {\n' +
    '        border-left: 8px solid transparent;\n' +
    '        border-right: 8px solid transparent;\n' +
    '        top: -6px;\n' +
    '        left: 50%;\n' +
    '        margin-left: -8px\n' +
    '    }\n' +
    '\n' +
    '    .__react_component_tooltip.place-left {\n' +
    '        margin-left: -10px\n' +
    '    }\n' +
    '\n' +
    '    .__react_component_tooltip.place-left:before {\n' +
    '        border-top: 6px solid transparent;\n' +
    '        border-bottom: 6px solid transparent;\n' +
    '        right: -8px;\n' +
    '        top: 50%;\n' +
    '        margin-top: -5px\n' +
    '    }\n' +
    '\n' +
    '    .__react_component_tooltip.place-left:after {\n' +
    '        border-top: 5px solid transparent;\n' +
    '        border-bottom: 5px solid transparent;\n' +
    '        right: -6px;\n' +
    '        top: 50%;\n' +
    '        margin-top: -4px\n' +
    '    }\n' +
    '\n' +
    '    .__react_component_tooltip.place-right {\n' +
    '        margin-left: 10px\n' +
    '    }\n' +
    '\n' +
    '    .__react_component_tooltip.place-right:before {\n' +
    '        border-top: 6px solid transparent;\n' +
    '        border-bottom: 6px solid transparent;\n' +
    '        left: -8px;\n' +
    '        top: 50%;\n' +
    '        margin-top: -5px\n' +
    '    }\n' +
    '\n' +
    '    .__react_component_tooltip.place-right:after {\n' +
    '        border-top: 5px solid transparent;\n' +
    '        border-bottom: 5px solid transparent;\n' +
    '        left: -6px;\n' +
    '        top: 50%;\n' +
    '        margin-top: -4px\n' +
    '    }\n' +
    '\n' +
    '    .__react_component_tooltip .multi-line {\n' +
    '        display: block;\n' +
    '        padding: 2px 0px;\n' +
    '        text-align: center\n' +
    '    }\n' +
    '\n' +
    '    .wms-logo .wms-icon {\n' +
    '        border-right: 0px;\n' +
    '    }\n' +
    '\n' +
    '    .logo-box span {\n' +
    '        font-size: 20px;\n' +
    '    }\n' +
    '</style>\n' +
    '\n' +
    '\n' +
    '<script id="updatePassword.tpl" type="text/ng-template">\n' +
    '    <form novalidate name="$ctrl.form" class="form-horizontal">\n' +
    '        <div class="form-group"><label for="oldPassword" class="col-sm-2 control-label">旧密码</label>\n' +
    '            <div class="col-sm-10"><input type="password" class="form-control" required="required" id="oldPassword"\n' +
    '                                          name="oldPassword" placeholder="旧密码" ng-model="$ctrl.item.oldPassword"\n' +
    '                                          required>\n' +
    '                <div ng-show="$ctrl.form.showMsg">\n' +
    '                    <div ng-show="$ctrl.form.oldPassword.$error.required" class="error-msg">旧密码不能为空.</div>\n' +
    '                </div>\n' +
    '            </div>\n' +
    '        </div>\n' +
    '        <div class="form-group"><label for="password" class="col-sm-2 control-label">新密码</label>\n' +
    '            <div class="col-sm-10"><input type="password" class="form-control" required="required" id="password"\n' +
    '                                          name="password" placeholder="新密码" ng-model="$ctrl.item.password" required>\n' +
    '                <div ng-show="$ctrl.form.showMsg">\n' +
    '                    <div ng-show="$ctrl.form.password.$error.required" class="error-msg">新密码不能为空.</div>\n' +
    '                    <div ng-show="$ctrl.form.passwordUnmatch" class="error-msg">{{$ctrl.form.passwordUnmatch}}.\n' +
    '                    </div>\n' +
    '                </div>\n' +
    '            </div>\n' +
    '        </div>\n' +
    '        <div class="form-group"><label for="password2" class="col-sm-2 control-label">再次输入新密码</label>\n' +
    '            <div class="col-sm-10"><input type="password" class="form-control" required="required" name="password2"\n' +
    '                                          id="password2" placeholder="再次输入新密码" ng-model="$ctrl.item.password2"\n' +
    '                                          required>\n' +
    '                <div ng-show="$ctrl.form.showMsg">\n' +
    '                    <div ng-show="$ctrl.form.password2.$error.required" class="error-msg">新密码不能为空.</div>\n' +
    '                    <div ng-show="$ctrl.form.passwordUnmatch" class="error-msg">{{$ctrl.form.passwordUnmatch}}.\n' +
    '                    </div>\n' +
    '                </div>\n' +
    '            </div>\n' +
    '        </div>\n' +
    '    </form>\n' +
    '</script>\n' +
    '\n' +
    '<script id="bindDevice.tpl" type="text/ng-template">\n' +
    '    <form novalidate name="$ctrl.form" class="form-horizontal">\n' +
    '        <div class="form-group"><label for="deviceNo" class="col-sm-2 control-label">请输入/扫描设备编号</label>\n' +
    '            <div class="col-sm-10"><input type="text" class="form-control" required="required" id="deviceNo"\n' +
    '                                          name="deviceNo" placeholder="设备编码" ng-model="$ctrl.item.deviceNo"\n' +
    '                                          required>\n' +
    '                <div ng-show="$ctrl.form.showMsg">\n' +
    '                    <div ng-show="$ctrl.form.deviceNo.$error.required" class="error-msg">设备编号不能为空.</div>\n' +
    '                </div>\n' +
    '            </div>\n' +
    '        </div>\n' +
    '    </form>\n' +
    '</script>\n' +
    '\n' +
    '<script id="bindCellphone.tpl" type="text/ng-template">\n' +
    '    <form novalidate name="$ctrl.form" class="form-horizontal">\n' +
    '        <div class="form-group" ng-show="$ctrl.item.oldCellPhone">\n' +
    '            <label for="oldCellPhone" class="col-sm-2 control-label">原绑定手机号</label>\n' +
    '            <div class="col-sm-10">\n' +
    '                <span>{{ $ctrl.item.oldCellPhone }}</span>\n' +
    '            </div>\n' +
    '        </div>\n' +
    '        <div class="form-group">\n' +
    '            <label for="cellPhone" class="col-sm-2 control-label">手机号</label>\n' +
    '            <div class="col-sm-10">\n' +
    '                <input type="text" class="form-control" required="required" id="cellPhone"\n' +
    '                                          name="cellPhone" placeholder="请输入手机号" ng-model="$ctrl.item.cellPhone" autofocus=""\n' +
    '                                          required>\n' +
    '                <div ng-show="$ctrl.form.showMsg">\n' +
    '                    <div ng-show="$ctrl.form.cellPhone.$error.required" class="error-msg">请输入手机号</div>\n' +
    '                </div>\n' +
    '                <button class="btn btn-primary" type="button" ng-click="$ctrl.item.sendCellPhoneCode($ctrl.item.cellPhone)">发送验证码</button>\n' +
    '            </div>\n' +
    '        </div>\n' +
    '        <div class="form-group">\n' +
    '            <label for="code" class="col-sm-2 control-label">验证码</label>\n' +
    '            <div class="col-sm-10">\n' +
    '                <input type="text" class="form-control" required="required" id="code"\n' +
    '                                          name="code" placeholder="请输入手机验证码" ng-model="$ctrl.item.code"\n' +
    '                                          required>\n' +
    '                <div ng-show="$ctrl.form.showMsg">\n' +
    '                    <div ng-show="$ctrl.form.code.$error.required" class="error-msg">请输入手机验证码</div>\n' +
    '                </div>\n' +
    '            </div>\n' +
    '        </div>\n' +
    '    </form>\n' +
    '</script>\n' +
    '\n' +
    '');
}]);
})();

(function(module) {
try {
  module = angular.module('iservice.base');
} catch (e) {
  module = angular.module('iservice.base', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('templates/imenu/systemSecMenu.html',
    '<div class="secHeaderMenu" ng-cloak>\n' +
    '    <li class="secList">\n' +
    '        <button class="secHeaderMenuBtn" ng-click="menuChange()"></button>\n' +
    '        <ul class="secUlOne">\n' +
    '            <li ng-repeat="menu1 in data track by $index" class="secLiOne" on-finish-render-two="callMethod()">\n' +
    '                <a>{{menu1[menuSysName]}}</a>\n' +
    '                <ul class="secUlTwo" ng-if="ifMenu(menu1)">\n' +
    '                    <div class="tri1"></div>\n' +
    '                    <li ng-repeat="menu2 in menu1[children] track by $index" class="secLiTwo">\n' +
    '                        <a>{{menu2[menuSysName]}}</a>\n' +
    '                        <ul class="secUlThree" ng-if="ifMenu(menu2)">\n' +
    '                            <div class="triBox" ng-if="ifMenu(menu2)"><div class="tri2"></div></div>\n' +
    '                            <li ng-repeat="menu3 in menu2[children] track by $index" class="secLiThree">\n' +
    '                                <a ng-click="jumpUrl(menu3)">{{menu3[menuSysName]}}</a>\n' +
    '                                <span ng-click="jumpNewWindow(menu3)" ng-if="!ifMenu(menu3)" title="在新窗口打开"></span>\n' +
    '                                <ul class="secUlFour" ng-if="ifMenu(menu3)">\n' +
    '                                    <div class="triBox" ng-if="ifMenu(menu3)"><div class="tri3"></div></div>\n' +
    '                                    <li ng-repeat="menu4 in menu3[children] track by $index" class="secLiFour">\n' +
    '                                        <a ng-click="jumpUrl(menu4)">{{menu4[menuSysName]}}</a>\n' +
    '                                        <span ng-click="jumpNewWindow(menu4)" title="在新窗口打开"></span>\n' +
    '                                    </li>\n' +
    '                                </ul>\n' +
    '                            </li>\n' +
    '                        </ul>\n' +
    '                    </li>\n' +
    '                </ul>\n' +
    '            </li>\n' +
    '            <li class="secLiOne"  ng-if="warehouseRequiredAppList.indexOf(appid) >= 0" id="currentWarehouse">\n' +
    '                <a>【{{currentUser.warehouseName ? currentUser.warehouseName : "未选择仓库"}}】</a>\n' +
    '                <ul class="currentWarehouseUl">\n' +
    '                    <li><a href="" ng-click="goWarehouseSelectPage()">切换仓库</a></li>\n' +
    '                </ul>\n' +
    '            </li>\n' +
    '            <li class="secLiOne">\n' +
    '                <a>【{{currentUser.userName}}】</a>\n' +
    '                <ul class="secUlTwo">\n' +
    '                    <div class="tri1"></div>\n' +
    '                    <li class="secLiTwo">\n' +
    '                        <a ng-click="logout()">退出系统</a>\n' +
    '                    </li>\n' +
    '                    <li class="secLiTwo">\n' +
    '                        <a ng-click="updatePasswordModal()">修改密码</a>\n' +
    '                    </li>\n' +
    '                    <li class="secLiTwo">\n' +
    '                        <a ng-click="bandModel()">绑定设备</a>\n' +
    '                    </li>\n' +
    '                </ul>\n' +
    '            </li>\n' +
    '            <li style="display: inline-block;margin-left: 15px">\n' +
    '                <button class="styleChangeBtn" ng-click="btnJudgeMenu()" style="color: white">改变样式</button>\n' +
    '            </li>\n' +
    '        </ul>\n' +
    '    </li>\n' +
    '\n' +
    '    <!--弹出的模态框-->\n' +
    '    <div class="modalMask"></div>\n' +
    '    <div class="modalLogin">\n' +
    '        <h2>绑定设备</h2>\n' +
    '        <p>请输入/扫描设备编号</p>\n' +
    '        <input type="text" ng-model="codeModel">\n' +
    '        <div class="modalWarning"><!--用于显示框内编号的验证结果：该编号已被绑定/请输入正确的编号。暂时不能用--></div>\n' +
    '        <button class="modalConfirm" ng-click="modelConfirm()">确定</button>\n' +
    '        <button class="modalClose" ng-click="modelCancel()">取消</button>\n' +
    '    </div>\n' +
    '</div>');
}]);
})();

(function(module) {
try {
  module = angular.module('iservice.base');
} catch (e) {
  module = angular.module('iservice.base', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('templates/scModal/confirmModal.html',
    '<div class="modal-header">\n' +
    '    <h3 class="modal-title">确认操作</h3>\n' +
    '</div>\n' +
    '<div class="modal-body" style="font-size: 18px; font-weight: bold; color: RED;">\n' +
    '    <p>{{$ctrl.item.msg}}</p>\n' +
    '</div>\n' +
    '<div class="modal-footer">\n' +
    '    <button class="btn btn-primary" type="button" ng-click="$ctrl.ok()" autofocus style="min-width: 80px; min-height: 30px;">确认</button>\n' +
    '    <button class="btn btn-warning" type="button" ng-click="$ctrl.cancel()" style="min-width: 80px; min-height: 30px;">取消</button>\n' +
    '</div>');
}]);
})();

(function(module) {
try {
  module = angular.module('iservice.base');
} catch (e) {
  module = angular.module('iservice.base', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('templates/scModal/defineModal.html',
    '<div class="modal-header" backdrop="">\n' +
    '    <button type="button" class="close" ng-click="$ctrl.cancel()" aria-label="Close"><span aria-hidden="true">&times;</span></button>\n' +
    '    <h3 class="modal-title">scTitle</h3>\n' +
    '</div>\n' +
    '<div class="modal-body">\n' +
    '    scBodyContent\n' +
    '</div>\n' +
    '<div class="modal-footer">\n' +
    '    <button class="btn btn-primary" type="button" ng-click="$ctrl.ok()" autofocus style="min-width: 80px; min-height: 30px;">确认</button>\n' +
    '    <button class="btn btn-warning" type="button" ng-click="$ctrl.cancel()" style="min-width: 80px; min-height: 30px;">取消</button>\n' +
    '</div>');
}]);
})();
